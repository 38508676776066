import SingleBlock from "components/Block/SingleBlock";
import Page from "components/Layout/Page";
import Loader from "components/Loader";
import useBlockOwnerInfo from "hooks/services/blocks/useBlockOwnerInfo";
import { FC } from "react";
import { useParams } from "react-router-dom";

const SingleModel: FC = () => {
	const { id } = useParams();

	if (!id) throw new Error("This component requires an id in the url params");

	const { data: block, isLoading: isLoadingBlock, error: blockError } = useBlockOwnerInfo(id);

	if (blockError) throw new Error();

	if (isLoadingBlock) return <Loader />;

	return <Page>{block && <SingleBlock block={block.data} />}</Page>;
};

export default SingleModel;
