import { FC, useState } from "react";
import CreateCollectionModal from "components/Collection/CreateCollectionModal";
import CollectionAdder from "components/Collection/Single/CollectionAdder";

const CreateCollectionFolder: FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <CreateCollectionModal
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <CollectionAdder onClick={() => setOpenModal(true)} />
    </>
  );
};

export default CreateCollectionFolder;
