import styled from "styled-components";
import { colors } from "styles/theme";

export const ShareStatusCardWrapper = styled.div`
  .active-card {
    color: ${colors.green};
  }

  .removed-card {
    color: ${colors.red};
  }

  .block-card {
    background: ${colors.lighterBlack};
    padding: 5px 10px;
    border-radius: 15px;
  }
`;
