import { Block, BlockInfoInputs } from "types/block";
import * as Yup from "yup";

export const formatResponse = (block: Block) => {
  const { title, depth, length, height, weight, material, geologicalFamily } =
    block;
  return { title, depth, length, height, weight, material, geologicalFamily };
};

export const validationSchema = Yup.object({
  title: Yup.string().required(),
  material: Yup.string().required(),
  geologicalFamily: Yup.string(),
  depth: Yup.number().positive().required(),
  length: Yup.number().positive().required(),
  height: Yup.number().positive().required(),
  weight: Yup.number().positive().required(),
});

export const initialValues: BlockInfoInputs = {
  title: "",
  material: "",
  geologicalFamily: "",
  depth: 0,
  length: 0,
  height: 0,
  weight: 0,
};
