import * as Yup from "yup";
import i18n from "i18n";
import { TransferStatus } from "types/transfer";

export interface Inputs {
  status: TransferStatus | string;
  startDate: Date | null;
  endDate: Date | null;
}

export const validationSchema = Yup.object({
  status: Yup.mixed().oneOf(Object.values(TransferStatus)),
  startDate: Yup.date().typeError(i18n.t("Invalid Date")).nullable(),
  endDate: Yup.date()
    .typeError(i18n.t("Invalid Date"))
    .nullable()
    .test(
      "End date must come after start date",
      "End date must come after start date",
      function () {
        if (!this.parent.startDate || !this.parent.endDate) return true;
        return this.parent.startDate.getTime() <= this.parent.endDate.getTime();
      }
    ),
});

export const initialValues = {
  status: "",
  startDate: null,
  endDate: null,
};
