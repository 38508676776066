import { User } from "types/user";
import * as Yup from "yup";

export interface Inputs {
  from: string;
  reason: string;
  text: string;
}

export const validationSchema = Yup.object({
  from: Yup.string().email().required(),
  reason: Yup.string(),
  text: Yup.string().required(),
});

export const initialValues = {
  from: "",
  reason: "general",
  text: "",
};

export const initialValuesAuth = (user: User) => {
  return {
    from: user.username,
    reason: "general",
    text: "",
  };
};
