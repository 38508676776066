import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { Request } from "types/request";
import { Response } from "types/response";
import { UserInvite } from "types/user";
import client from "utils/client";

export default function useDeclineTransfer() {
  const queryClient = useQueryClient();

  const acceptTransfer = (id: string) => {
    const request: Request = {
      url: generatePath(ENDPOINTS.transfers.decline, { id }),
      config: {
        method: "PATCH",
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<Response<null>, Error, string>(
    acceptTransfer,
    {
      onSettled: () => queryClient.invalidateQueries([ENDPOINTS.transfers.all]),
    }
  );

  return { data, mutateAsync, ...rest };
}
