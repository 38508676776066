import styled from "styled-components";

export const ActionMenuWrapper = styled.div`
  .button-icon {
    margin-right: 5px;
  }

  .menu-btn {
    width: 90px;
    justify-content: center;
  }
`;
