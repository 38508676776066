import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";

export default function useCreateTransfer() {
  const queryClient = useQueryClient();

  const createTransfer = ({ blockId, recipient }: { blockId: string; recipient: string }) => {
    const request: Request = {
      url: generatePath(ENDPOINTS.transfers.create, { blockId }),
      config: {
        method: "POST",
        data: { recipient },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<null>, Error, { blockId: string; recipient: string }>(
    createTransfer,
    {
      onSettled: (data, error, blockId) => {
        queryClient.invalidateQueries([ENDPOINTS.transfers.create, blockId]);
        queryClient.invalidateQueries([ENDPOINTS.transfers.all]);
      },
    }
  );

  return { data, mutate, ...rest };
}
