import { Divider, Typography } from "@mui/material";



interface MRDividerProps {
  text?: string,
  colorDivider?: string,
}

export default function MRDivider(props: MRDividerProps) {

  return (
    <Divider component={"div"} role={"presentation"}
      sx={{
        "&::before, &::after": {
          borderColor: props.colorDivider,
        }
      }}
    >
      <Typography color={props.colorDivider}>{props.text}</Typography>
    </Divider>
  )
}