import NoModelErrorFallback from "components/ErrorFallbacks/NoModelErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import PATH from "routing/path";
import Models from "./views/all";
import EditModelSeller from "./views/edit";
import SingleModel from "./views/single";
import { windowReplace } from "utils/helpers";

const onResetErrorBoundary = () => {
  //refresh all state and redirect to homepage
  console.warn(`Error, redirecting to: ${PATH.DASHBOARD}`)
  windowReplace(PATH.DASHBOARD);
};

const routes = [
  {
    index: true,
    name: "Models",
    element: <Models />,
  },
  {
    path: "edit/:id",
    name: "Model Detail",
    element: <EditModelSeller />,
  },
  {
    path: ":id",
    name: "Model",
    element: (
      <ErrorBoundary FallbackComponent={NoModelErrorFallback} onReset={onResetErrorBoundary}>
        <SingleModel />
      </ErrorBoundary>
    ),
  },
];

export default routes;
