import { Stack } from "@mui/material";
import { FC } from "react";
import { CollectionAdderWrapper } from "./styles";
import { CollectionAdderProps } from "./types";
import { useTranslation } from "react-i18next";

const CollectionAdder: FC<CollectionAdderProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <CollectionAdderWrapper onClick={onClick}>
      <Stack direction="row" className="folder" />
      <Stack direction="column" mt={2} ml={2}>
        {t("Create new collection")}
      </Stack>
    </CollectionAdderWrapper>
  );
};

export default CollectionAdder;
