import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";
import { AcquisitionInfoInputs } from "types/acquisition";

interface IEditAcquisitionInfo extends Omit<AcquisitionInfoInputs, "createdAt"> {
  id: string;
}

export default function useEditAcquisitionInfo() {
  const queryClient = useQueryClient();

  const editAcquisitionInfo = (updateAcquisition: IEditAcquisitionInfo) => {
    const { id, ...data } = updateAcquisition;
    const request: Request = {
      url: generatePath(ENDPOINTS.acquisitions.one, { id }),
      config: {
        method: "patch",
        data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<null>, Error, IEditAcquisitionInfo>(
    editAcquisitionInfo,
    {
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([ENDPOINTS.acquisitions.all]);
        queryClient.invalidateQueries([ENDPOINTS.acquisitions.one, variables.id]);
      },
    }
  );

  return { data, mutate, ...rest };
}
