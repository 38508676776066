import { MRCard } from "components/material";
import { getBlockColor } from "helpers";
import { FC } from "react";
import styled from "styled-components";
import { colors } from "styles/theme";
import { BlockStatus } from "types/block";

export interface BlockCardProps {
  variant: "owned" | "shared" | "trashed";
  status: BlockStatus;
  children: React.ReactNode;
}

const BlockCardWrapper = styled.div`
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const BlockCard: FC<BlockCardProps> = ({ children, variant, status }) => {
  return (
    <BlockCardWrapper>
      <MRCard
        className="block-grid-elem"
        sx={{
          border: `1px solid ${getBlockColor(variant, status)} !important`,
        }}
      >
        {children}
      </MRCard>
    </BlockCardWrapper>
  );
};

export default BlockCard;
