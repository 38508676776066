import { useState } from 'react'
import { ButtonBase, Box, Popover, Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import MRDivider from '../ui-components/MRDivider';

export default function HelpButtonMobile(props: any) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box component={"div"} sx={{ display: "flex", height: "100%" }}>
      <ButtonBase disableRipple aria-describedby={id} onClick={handleClick}>
        <HelpOutlineIcon sx={{ color: "#4BBECF" }} />
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        elevation={0}
        slotProps={{
          paper: {
            style: { backgroundColor: "transparent" }
          }
        }}
        marginThreshold={0}
      >
        <Box
          component={"div"}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            backgroundColor: "rgba(6,11,12,0.75)",
            justifyContent: "center"
          }}
          padding={2}
        >
          <MRDivider text={"USAGE"} colorDivider={"#A3D8E7"} />
          
          <Typography color={"#00A4B7"} sx={{display: "flex", justifyContent: "center"}}>Left mouse button/drag</Typography>
          <Typography color={"#A3D8E7"} sx={{display: "flex", justifyContent: "center", backgroundColor:"#060B0C"}}>Rotation</Typography>
          <Typography color={"#00A4B7"} sx={{display: "flex", justifyContent: "center"}}>Mouse wheel/pinch</Typography>
          <Typography color={"#A3D8E7"} sx={{display: "flex", justifyContent: "center", backgroundColor:"#060B0C"}}>Zoom</Typography>
          <Typography color={"#00A4B7"} sx={{display: "flex", justifyContent: "center"}}>Right mouse button/Two fingers drag</Typography>
          <Typography color={"#A3D8E7"} sx={{display: "flex", justifyContent: "center", backgroundColor:"#060B0C"}} >Panning</Typography>
        </Box>
      </Popover>
    </Box>
  )
}