import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";
import { AcquisitionInfoInputs } from "types/acquisition";

export default function useNotifyModelUpload() {
  const queryClient = useQueryClient();

  const notifyModelUpload = (acquisitionId: string) => {
    const request: Request = {
      url: generatePath(ENDPOINTS.acquisitions.notifyModelUpload, {
        id: acquisitionId,
      }),
      config: {
        method: "patch",
        data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<null>, Error, string>(
    notifyModelUpload,
    {
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([ENDPOINTS.acquisitions.all]);
        queryClient.invalidateQueries([ENDPOINTS.acquisitions.one, variables]);
      },
    }
  );

  return { data, mutate, ...rest };
}
