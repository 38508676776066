import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { ConfirmModalContent, ConfirmModalContextType } from "./types";
import { useTranslation } from "react-i18next";

export const ConfirmModalContext = createContext<ConfirmModalContextType | undefined>(undefined);
ConfirmModalContext.displayName = "ConfirmModalContext";

const ConfirmModalProvider: FC<{children: React.ReactNode}> = ({ children }) => {
  const { t } = useTranslation();

  const [confirmModalContent, setConfirmModalContent] = useState<ConfirmModalContent | null>(null);

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (confirmModalContent)
      MySwal.fire({
        title: confirmModalContent.title || t("Are you sure?"),
        showCancelButton: true,
        cancelButtonText: confirmModalContent.cancelText || t("Cancel"),
        confirmButtonText: confirmModalContent.confirmText || t("Confirm"),
        heightAuto: false,
        customClass: "confirm-modal",
        icon: "warning",
        ...(confirmModalContent.text && { text: confirmModalContent.text }),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          confirmModalContent.confirmFunction();
        }
        setConfirmModalContent(null);
      });
  }, [confirmModalContent]);

  return (
    <ConfirmModalContext.Provider value={{ openModal: setConfirmModalContent }}>
      {children}
    </ConfirmModalContext.Provider>
  );
};

export function useConfirmModal() {
  const context = useContext(ConfirmModalContext);
  if (context === undefined) {
    throw new Error(`useConfirmModal must be used within a ConfirmModalProvider`);
  }

  return context;
}

export default ConfirmModalProvider;
