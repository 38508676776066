import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { Contact } from "types/user";

interface CreateFriendshipRequestInfo {
  recipientEmail: string;
  alias?: string;
}

export default function useCreateFriendshipRequest() {
  const queryClient = useQueryClient();

  const createFriendshipRequest = (data: CreateFriendshipRequestInfo) => {
    const request: Request = {
      url: ENDPOINTS.friendshipRequest.create,
      config: {
        method: "put",
        data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<Contact>, Error, CreateFriendshipRequestInfo>(
    createFriendshipRequest,
    {
      onSettled: (data, error, variables) => {
        //queryClient.invalidateQueries([ENDPOINTS.users.contacts]);
      },
    }
  );

  return { data, mutate, ...rest };
}
