import useAuth from "hooks/useAuth";
import { FC, useEffect } from "react";
import { SignupPage } from "./styles";
import { useNavigate } from "react-router-dom";
import PATH from "routing/path";
import SignupForm from "./SignupForm";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "assets/icons/logo-white.svg";
import MRCard from "components/material/Card";
import { colors } from "styles/theme";

const Signup: FC = () => {
  const { user, clearData } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      clearData();
    }
  }, [user]);

  return (
    <SignupPage>
      <Stack direction="row" justifyContent={"center"} alignItems="center">
        <Stack direction="column" justifyContent={"center"} alignItems={"center"}>
          <MRCard className="signup-card">
            <Stack sx={{ marginBottom: "30px" }} alignItems="center">
              <Logo width="70px" height="70px" />
              <h3 style={{ marginBottom: "0px" }}>{t("Welcome to Marmoreal")}</h3>
              <span>
                {t("Already have an account?")}{" "}
                <a href={PATH.LOGIN} style={{ color: colors.green, textDecoration: "none" }}>
                  {t("Log in")}
                </a>
              </span>
              <SignupForm />
            </Stack>
          </MRCard>
        </Stack>
      </Stack>
    </SignupPage>
  );
};

export default Signup;
