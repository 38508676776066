import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { Contact } from "types/user";
import { generatePath } from "react-router-dom";

interface DeleteContactInfo {
  contactId: string;
}

export default function useDeleteContact() {
  const queryClient = useQueryClient();

  const deleteContact = (data: DeleteContactInfo) => {
    const { contactId } = data;
    const request: Request = {
      url: generatePath(ENDPOINTS.users.contactDelete, { id: contactId }),
      config: {
        method: "delete",
        data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<Contact>, Error, DeleteContactInfo>(
    deleteContact,
    {
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([ENDPOINTS.users.contacts]);
      },
    }
  );

  return { data, mutate, ...rest };
}
