import useSharesMonthlyCount from "hooks/services/shares/useSharesMonthlyCount";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { colors } from "styles/theme";
import { ShareChartsProps } from "types/share";

const ShareChart: FC<ShareChartsProps> = ({
  maxHeight,
  children,
  ...props
}) => {
  const COLORS = [colors.blue, colors.primary];

  const { t } = useTranslation();

  const {
    data: sharesMonthly,
    isLoading: loadingShares,
    isError: sharesError,
  } = useSharesMonthlyCount({ ...props });

  const months = [
    t("Jan"),
    t("Feb"),
    t("Mar"),
    t("Apr"),
    t("May"),
    t("Jun"),
    t("Jul"),
    t("Aug"),
    t("Sep"),
    t("Oct"),
    t("Nov"),
    t("Dec"),
  ];

  const getData = useMemo(() => {
    if (!sharesMonthly) return;
    const myData = months.map((month, idx) => {
      return {
        timeRange: month,
        Shares:
          sharesMonthly.find((el) => el._id === idx + 1)?.sharesNumber ?? 0,
      };
    });
    return myData;
  }, [sharesMonthly]);

  return (
    <ResponsiveContainer height={"100%"} maxHeight={maxHeight} aspect={0}>
      <BarChart data={getData} layout="vertical" barCategoryGap="25%">
        <XAxis type="number" />
        <YAxis type="category" dataKey="timeRange" />
        <Tooltip />
        <Bar dataKey="Shares" radius={[0, 10, 10, 0]}>
          {months.map((entry, index) => (
            <Cell fill={COLORS[index % COLORS.length]} key={`cell-${index}`} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ShareChart;
