export const toggleDrawer =
  (open: boolean, setState: React.Dispatch<React.SetStateAction<boolean>>) =>
  (
    event:
      | React.KeyboardEvent
      | React.MouseEvent
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    if (
      event.type === "keydown" &&
      (event as React.KeyboardEvent).key !== "Escape"
    ) {
      return;
    }

    setState(open);
  };
