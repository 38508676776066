import { FC, useState, useMemo } from "react";
import { ShareTableProps } from "./types";
import { Stack } from "@mui/material";
import { MRPagination, MRDrawer } from "components/material";
import useShares from "hooks/services/shares/useShares";
import { useTranslation } from "react-i18next";
import useMultiSelect from "hooks/useMultiSelect";
import ShareBulkActionsControl from "components/TablesComponents/Share/ShareBulkActionsControl";
import MRSharesTable from "components/TablesComponents/Share/SharesTable";
import { Inputs as ShareFilters } from "components/Share/ShareFilterForm/model";
import { PAGE_SIZE } from "config/pagination";
import TitleCounter from "components/TablesComponents/TitleCounter";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import ShareFilterForm from "components/Share/ShareFilterForm";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";
import UserShareBulkActionsControl from "components/TablesComponents/Share/UserShareBulkActionsControl";
import ROLE from "types/role";

const ShareTable: FC<ShareTableProps> = ({ ownerId, userRole }) => {
  const [page, setPage] = useState(1);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<ShareFilters | null>(null);

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data: shares,
    isLoading: loadingShares,
    isError: sharesError,
  } = useShares(page, PAGE_SIZE, {
    owner: ownerId,
    ...(formattedFilters && formattedFilters),
  });

  if (sharesError) throw new Error("Cannot get shares");

  const { selected, toggleSelection, toggleAll, resetSelection } =
    useMultiSelect();
  const { t } = useTranslation();

  return (
    <>
      <MRDrawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={toggleDrawer(false, setFilterDrawerOpen)}
      >
        <ShareFilterForm
          userRole={ROLE.SELLER}
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </MRDrawer>
      <Stack
        direction="row"
        gap={3}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "5px", sm: 3 },
        }}
      >
        <TitleCounter title={t("Shares")} count={shares?.data.length || 0} />
        <Stack direction="row" alignItems="center" gap="10px">
          <FilterButton
            onClick={() => setFilterDrawerOpen(true)}
            activeFilters={
              formattedFilters ? Object.keys(formattedFilters).length : 0
            }
          />
          {userRole === ROLE.ADMIN || userRole === ROLE.OPERATOR ? (
            <ShareBulkActionsControl
              selectedIds={selected}
              resetSelection={resetSelection}
            />
          ) : (
            <UserShareBulkActionsControl
              selectedIds={selected}
              resetSelection={resetSelection}
            />
          )}
        </Stack>
      </Stack>
      <MRSharesTable
        userRole={userRole}
        shares={shares?.data || []}
        selected={selected}
        toggleSelection={toggleSelection}
        toggleAll={toggleAll}
      />
      <Stack direction="row" justifyContent="center">
        {shares?.data && shares.data.length ? (
          <MRPagination
            count={
              shares
                ? Math.ceil(shares?.pagination[0]?.total / PAGE_SIZE) || 1
                : 1
            }
            page={page}
            onChange={(e, page) => setPage(page)}
          />
        ) : (
          <NoDataRow>{t("No Data")}</NoDataRow>
        )}
      </Stack>
    </>
  );
};

export default ShareTable;
