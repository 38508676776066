import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { Acquisition, AcquisitionInfoInputs } from "types/acquisition";

interface CreateAcquisitionInfo
  extends Omit<AcquisitionInfoInputs, "createdAt"> {
  owner: string;
}

export default function useCreateAcquisition() {
  const queryClient = useQueryClient();

  const createAcquisition = (data: CreateAcquisitionInfo) => {
    const request: Request = {
      url: ENDPOINTS.acquisitions.all,
      config: {
        method: "post",
        data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<Acquisition>,
    Error,
    CreateAcquisitionInfo
  >(createAcquisition, {
    onSettled: (data, error, id) => {
      queryClient.invalidateQueries([ENDPOINTS.acquisitions.all]);
    },
  });

  return { data, mutate, ...rest };
}
