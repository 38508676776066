import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Request } from "types/request";
import { Response } from "types/response";
import { User } from "types/user";
import client from "utils/client";

export default function useVerifyEmail(code: string | null) {
  const request: Request = {
    url: ENDPOINTS.auth.verifyEmail,
    config: {
      method: "POST",
      data: { code },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.auth.verifyEmail, code],
    queryFn: () => client(request),
    enabled: !!code,
  };

  const { data, ...rest } = useQuery<Response<User & { accessToken: string; refreshToken: string }>, Error>(
    requestConfig
  );

  return { data, ...rest };
}
