import { FC, useState } from "react";
import { useDebounce } from "use-debounce";
import { SelectContactProps } from "./types";
import { SelectContactWrapper } from "./styles";
import MRTextField from "components/material/TextField";
import useInfiniteContacts from "hooks/services/users/useInfiniteContacts";
import { PAGE_SIZE } from "config/pagination";
import Loader from "components/Loader";
import ContactRow from "./ContactRow";
import { Stack } from "@mui/material";
import { flattenPages } from "helpers";
import { useTranslation } from "react-i18next";

const SelectContact: FC<SelectContactProps> = ({
  selectedContact,
  setSelectedContact,
}) => {
  const { t } = useTranslation();
  const [text, setText] = useState<string>("");
  const [debouncedText] = useDebounce(text, 500);

  const {
    data: pages,
    isLoading: isLoadingContacts,
    error: contactsError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteContacts({ size: PAGE_SIZE, query: { text: debouncedText } });

  if (contactsError) throw new Error("Cannot get contacts");

  const handleScroll = (e: any) => {
    if (
      e.target.clientHeight + e.target.scrollTop === e.target.scrollHeight &&
      hasNextPage
    ) {
      fetchNextPage();
    }
  };

  const avatarColors = [
    "#2DB5C7",
    "#2FE5A7",
    "#3772FF",
    "#EF452F",
    "#3A36DB",
    "#F4D35E",
  ];

  return (
    <SelectContactWrapper>
      <Stack direction="column" gap={2}>
        <MRTextField
          placeholder={t("Search by alias or email..")}
          className="search-contact"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />

        <div className="contacts-dialog-box" onScroll={(e) => handleScroll(e)}>
          {isLoadingContacts ? (
            <Loader /> // TODO: Check why it's not showing
          ) : (
            flattenPages(pages).map((contact, idx) => {
              return (
                <ContactRow
                  key={contact.email}
                  contact={contact}
                  bgColor={avatarColors[idx % 5]}
                  selectedContact={selectedContact}
                  setSelectedContact={setSelectedContact}
                />
              );
            })
          )}
          {isFetchingNextPage ? <Loader /> : <></>}
        </div>
      </Stack>
    </SelectContactWrapper>
  );
};

export default SelectContact;
