import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery } from "react-query";
import { Request } from "types/request";
import { PaginatedResponse } from "types/response";
import Error from "types/error";
import { FriendshipRequest } from "types/friendship-request";

export default function useFriendshpiRequestReceived(
  page?: number,
  size?: number,
  search?: Record<string, string>
) {
  const request: Request = {
    url: ENDPOINTS.friendshipRequest.received,
    config: {
      method: "GET",
      params: { page, size, ...search },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.friendshipRequest.received],
    queryFn: () => client(request).then((res) => res.data),
  };

  const { data, ...rest } = useQuery<PaginatedResponse<FriendshipRequest[]>, Error>(requestConfig);

  return { data, ...rest };
}
