import { FC, useEffect } from "react";
import { ContactModalProps } from "./types";
import { StyledDialog } from "./styles";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Stack } from "@mui/material";
import { colors } from "styles/theme";
import { MRTextField } from "components/material";
import { useTranslation } from "react-i18next";
import { MRButtonPrimary } from "components/material/Button";
import { initialValues, validationSchema } from "./model";
import { useFormik } from "formik";
import useToasts from "hooks/useToasts";
import useCreateFriendshipRequest from "hooks/services/friendship-request/useCreateFriendshipRequest";
import { AxiosError } from "axios";

const ContactModal: FC<ContactModalProps> = ({
  open,
  setOpen,
  contact,
  setContact,
}) => {
  const handleClose = (e: object, reason: string) => {
    if (reason === "escapeKeyDown") {
      setContact(null);
      setOpen(false);
    }
  };
  const { t } = useTranslation();

  const {
    mutate: createFriendshipRequest,
    isLoading: isLoadingCreateFriendshipRequest,
    isSuccess: isSuccessCreateFriendshipRequest,
    error: createFriendshipRequestError,
  } = useCreateFriendshipRequest();

  useEffect(() => {
    if (isSuccessCreateFriendshipRequest) {
      setContact(null);
      setOpen(false);
    }
  }, [isSuccessCreateFriendshipRequest]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: contact ?? initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (!contact) {
        createFriendshipRequest({
          recipientEmail: values.email,
          ...(values.alias && { alias: values.alias }),
        });
        return;
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [open]);

  const requestToYourselfError =
    !!createFriendshipRequestError &&
    (createFriendshipRequestError as AxiosError).response?.data.name ===
      "InvalidEmail";

  const alreadyFriendsError =
    !!createFriendshipRequestError &&
    (createFriendshipRequestError as AxiosError).response?.data.name ===
      "AlreadyFriends";

  const requestAlreadySentError =
    !!createFriendshipRequestError &&
    (createFriendshipRequestError as AxiosError).response?.data.name ===
      "RequestAlreadySent";

  useToasts([
    {
      severity: "error",
      message: t(
        "There was an error while creating the contact request, please try again later."
      ),
      condition:
        !!createFriendshipRequestError &&
        !requestToYourselfError &&
        !alreadyFriendsError &&
        !requestAlreadySentError,
    },
    {
      severity: "error",
      message: t("Can't send contact request to yourself"),
      condition: requestToYourselfError,
    },
    {
      severity: "error",
      message: t("This user is already in your contacts list"),
      condition: alreadyFriendsError,
    },
    {
      severity: "error",
      message: t("You already sent a contact request to this email"),
      condition: requestAlreadySentError,
    },
    {
      severity: "success",
      message: t("Contact request created successfully"),
      condition: isSuccessCreateFriendshipRequest,
    },
  ]);

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      transitionDuration={{ exit: 0 }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="create-collection-modal"
      PaperProps={{
        style: {
          background: colors.lightBlack,
          backgroundImage: "none",
          width: "100%",
          padding: "20px",
          border: `1px solid ${colors.white}`,
          borderRadius: "20px",
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={"10px"}
      >
        <h2>{!contact ? t("Add new contact") : t("Edit contact")}</h2>
        <div className="close-button-container">
          <IconButton onClick={(e) => handleClose(e, "escapeKeyDown")}>
            <ClearIcon sx={{ color: colors.green }} />
          </IconButton>
        </div>
      </Stack>
      <form onSubmit={formik.handleSubmit}>
        <Stack alignItems="start" justifyContent="center" marginBottom="20px">
          {!contact ? (
            <>
              <p>{t("E-mail") + " *"}</p>
              <MRTextField
                placeholder={"E-mail"}
                fullWidth
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <p>{t("Alias")}</p>
              <MRTextField
                placeholder={"Alias"}
                fullWidth
                name="alias"
                value={formik.values.alias}
                onChange={formik.handleChange}
                error={formik.touched.alias && Boolean(formik.errors.alias)}
                helperText={formik.touched.alias && formik.errors.alias}
              />
            </>
          ) : (
            <></>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <MRButtonPrimary
            type="submit"
            loading={isLoadingCreateFriendshipRequest}
          >
            {!contact ? t("Add") : t("Save")}
          </MRButtonPrimary>
        </Stack>
      </form>
    </StyledDialog>
  );
};

export default ContactModal;
