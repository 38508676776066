import { FC, useMemo, useState } from "react";
import { PAGE_SIZE } from "config/pagination";
import useInfiniteSharedCollection from "hooks/services/collections/useInfiniteSharedCollections";
import CollectionsGrid from "./CollectionsGrid";
import { Stack } from "@mui/material";
import { Inputs as CollectionFilters } from "components/Collection/CollectionFilterForm/model";
import CollectionFilterForm from "components/Collection/CollectionFilterForm";
import { MRDrawer } from "components/material";
import { useTranslation } from "react-i18next";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";

const SharedCatalogues: FC = () => {
  const { t } = useTranslation();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState<CollectionFilters | null>(null);

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data,
    isLoading,
    isSuccess,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteSharedCollection({
    size: PAGE_SIZE,
    ...(formattedFilters && { query: formattedFilters }),
  });

  return (
    <>
      <MRDrawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={toggleDrawer(false, setFilterDrawerOpen)}
      >
        <CollectionFilterForm
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </MRDrawer>
      <Stack direction="row" justifyContent="end" alignItems="center">
        <FilterButton
          onClick={() => setFilterDrawerOpen(true)}
          activeFilters={
            formattedFilters ? Object.keys(formattedFilters).length : 0
          }
        />
      </Stack>
      <CollectionsGrid
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
        error={error}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        variant="shared"
      />
    </>
  );
};

export default SharedCatalogues;
