import { FC, useMemo } from "react";
import useBulkAction from "hooks/useBulkAction";
import { BulkAction, BulkActionProps } from "../BulkActionsControls/types";
import { trash2 } from "assets/icons";
import BulkActionsControlBase from "../BulkActionsControls/BulkActionsControlBase";
import useRemoveAcquisition from "hooks/services/acquisitions/useRemoveAcquisition";
import { useTranslation } from "react-i18next";

const AcquisitionBulkActionControl: FC<BulkActionProps> = ({ ...props }) => {
  const { mutateAsync: removeAcquisitionsAsync } = useRemoveAcquisition();

  const { t } = useTranslation();

  const {
    bulkAction: deleteAcquisitionBulk,
    isLoading: isDeletingAcquisitionBulk,
    bulkResult: deleteAcquisitionBulkResult,
  } = useBulkAction(removeAcquisitionsAsync);

  const actions: BulkAction[] = useMemo(
    () => [
      {
        name: t("Remove"),
        icon: trash2,
        confirmMessage: t(
          "Are you sure you want to delete these acquisitions?"
        ),
        bulkAction: deleteAcquisitionBulk,
        bulkActionLoading: isDeletingAcquisitionBulk,
        bulkActionResult: deleteAcquisitionBulkResult,
      },
    ],
    [
      deleteAcquisitionBulk,
      isDeletingAcquisitionBulk,
      deleteAcquisitionBulkResult,
    ]
  );

  return <BulkActionsControlBase {...props} actions={actions} />;
};

export default AcquisitionBulkActionControl;
