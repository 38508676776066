import { FC, useState, useMemo } from "react";
import MRAcquisitionTable from "components/TablesComponents/Acquisition/AcquistionTable";
import AcquisitionUserBulkActionControl from "components/TablesComponents/Acquisition/AcquisitionUserBulkActionsControl";
import useAcquisitions from "hooks/services/acquisitions/useAcquisitions";
import { PAGE_SIZE } from "config/pagination";
import useMultiSelect from "hooks/useMultiSelect";
import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { MRPagination, MRDrawer } from "components/material";
import ROLE from "types/role";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import AcquisitionFilterForm from "components/Acquisition/AcquisitionFilterForm";
import { Inputs as AcquisitionFilters } from "components/Acquisition/AcquisitionFilterForm/model";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";
import { AcquisitionStatus } from "types/acquisition";

const AcquisitionSellerTable: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [acquisitionPage, setAcquisitionPage] = useState(1);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<AcquisitionFilters | null>({
    status: [
      AcquisitionStatus.IDLE,
      AcquisitionStatus.PENDING_PROCESSING,
      AcquisitionStatus.PROCESSING,
    ],
  } as AcquisitionFilters);

  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("_id");

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data: acquisitions,
    isLoading: loadingAcquisitions,
    isError: acquisitionError,
  } = useAcquisitions({
    page: acquisitionPage,
    size: PAGE_SIZE,
    order,
    sort: orderBy,
    owner: user?._id || "",
    ...(formattedFilters && formattedFilters),
  });

  const sortHandler = (orderBy: string) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(orderBy);
  };

  if (acquisitionError) throw new Error("Cannot get acquisition");

  const { selected, toggleSelection, toggleAll, resetSelection } = useMultiSelect();

  return (
    <>
      <MRDrawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={toggleDrawer(false, setFilterDrawerOpen)}
      >
        <AcquisitionFilterForm
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
          isUser
        />
      </MRDrawer>
      <Stack direction="row" justifyContent="end" alignItems="center">
        <Stack direction="row" alignItems="center" gap="10px">
          <FilterButton
            onClick={() => setFilterDrawerOpen(true)}
            activeFilters={formattedFilters ? Object.keys(formattedFilters).length : 0}
          />
          <AcquisitionUserBulkActionControl
            selectedIds={selected}
            resetSelection={resetSelection}
          />
        </Stack>
      </Stack>
      <MRAcquisitionTable
        acquisitions={acquisitions?.data || []}
        selected={selected}
        toggleSelection={toggleSelection}
        toggleAll={toggleAll}
        userRole={ROLE.SELLER}
        order={order}
        orderBy={orderBy}
        sortHandler={sortHandler}
      />
      <Stack justifyContent="center" alignItems="center">
        {acquisitions?.data && acquisitions.data.length ? (
          <MRPagination
            count={
              acquisitions ? Math.ceil(acquisitions?.pagination[0]?.total / PAGE_SIZE) || 1 : 1
            }
            page={acquisitionPage}
            onChange={(e, page) => setAcquisitionPage(page)}
          />
        ) : (
          <NoDataRow>{t("No Data")}</NoDataRow>
        )}
      </Stack>
    </>
  );
};

export default AcquisitionSellerTable;
