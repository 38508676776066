import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "components/material/Dialog";
import { CreateTransferModalProps } from "./types";
import { CreateTransferModalWrapper } from "./styles";
import { colors } from "styles/theme";
import { IconButton, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SelectContact from "components/Contact/SelectContact";
import { MRButtonquaternary } from "components/material/Button";
import useCreateTransfer from "hooks/services/transfers/useCreateTransfer";
import useToasts from "hooks/useToasts";
import { Contact } from "types/user";
import { AxiosError } from "axios";

const CreateTransferModal: FC<CreateTransferModalProps> = ({
  open,
  setOpen,
  blockId,
}) => {
  const { t } = useTranslation();

  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);

  const {
    mutateAsync: createTransfer,
    isLoading: createTransferLoading,
    error: createTransferError,
    isSuccess: createTransferSuccess,
  } = useCreateTransfer();

  const handleClose = (e: object, reason: string) => {
    if (reason === "escapeKeyDown") {
      setOpen(false);
      setSelectedContact(null);
    }
  };

  const handleCreateTransfer = () => {
    // TODO: Test it
    if (selectedContact && blockId) {
      createTransfer({ blockId, recipient: selectedContact.email }).then(() => {
        setOpen(false);
        setSelectedContact(null);
      });
    }
  };

  const userIsNotSeller =
    (createTransferError as AxiosError)?.response?.data.name ===
    "UserNotSeller";
  const transferAlreadyExists =
    (createTransferError as AxiosError)?.response?.data.name ===
    "TransferAlreadyExists";

  useToasts([
    {
      severity: "error",
      message: t(
        "There was an error during the transfer. Please try again later."
      ),
      condition:
        !!createTransferError && !userIsNotSeller && !transferAlreadyExists,
    },
    {
      severity: "error",
      message: t(
        "The selected user is not a PRO account. You can transfer blocks only to PRO accounts."
      ),
      condition: userIsNotSeller,
    },
    {
      severity: "error",
      message: t(
        "A transfer for this block already exists, delete the previously created transfers for this block, then try again!"
      ),
      condition: transferAlreadyExists,
    },
    {
      severity: "success",
      message: t("Transfer request created correctly"),
      condition: createTransferSuccess,
    },
  ]);

  return (
    <CreateTransferModalWrapper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="dialog"
        PaperProps={{
          style: {
            background: colors.lightBlack,
            backgroundImage: "none",
            width: "100%",
            padding: "20px",
            border: `1px solid ${colors.white}`,
            borderRadius: "20px",
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          sx={{ marginBottom: "10px" }}
        >
          <Stack className="modal-title">
            <h2>{t("Property Transfer")}</h2>
            <span>
              {t("Choose the user, you would like transfert the block to")}
            </span>
          </Stack>

          <div className="close-button-container">
            <IconButton onClick={(e) => handleClose(e, "escapeKeyDown")}>
              <ClearIcon sx={{ color: colors.green }} />
            </IconButton>
          </div>
        </Stack>

        <SelectContact
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
        />

        {selectedContact && (
          <MRButtonquaternary
            onClick={handleCreateTransfer}
            loading={createTransferLoading}
          >
            {t("Send")}
          </MRButtonquaternary>
        )}
      </Dialog>
    </CreateTransferModalWrapper>
  );
};

export default CreateTransferModal;
