import { FC } from "react";
import { TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { MRCheckbox, MRTable } from "components/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import TransferStatusCard from "./TransferStatusCard";
import TransferRowActions from "./TransferRowActions";
import { TransfersTableProps, TranferTableActionsProps } from "./types";
import { TableWrapper } from "../Tables/styles";
import useAuth from "hooks/useAuth";
import ROLE from "types/role";
import { TransferStatus } from "types/transfer";
import TransferRecipientRowActions from "./TransferRecipientRowActions";

const TransferTableActions: FC<TranferTableActionsProps> = ({
  userRole,
  transfer,
}) => {
  const { user } = useAuth();

  if (transfer.status !== TransferStatus.PENDING) return <></>;

  if (userRole === ROLE.ADMIN) return <TransferRowActions id={transfer._id} />;

  if (transfer.sender._id === user?._id)
    return <TransferRowActions id={transfer._id} />;

  return <TransferRecipientRowActions id={transfer._id} />;
};

const TransfersTable: FC<TransfersTableProps> = ({
  transfers,
  selected,
  toggleAll,
  toggleSelection,
  userRole,
}) => {
  const { t } = useTranslation();

  return (
    <TableWrapper>
      <MRTable>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <MRCheckbox
                checked={
                  selected.length ===
                    transfers.filter(
                      (transfer) => transfer.status === TransferStatus.PENDING
                    ).length && selected.length !== 0
                }
                onChange={() =>
                  toggleAll(
                    transfers
                      .filter(
                        (transfer) => transfer.status === TransferStatus.PENDING
                      )
                      .map((transfer) => transfer._id) || []
                  )
                }
              />
            </TableCell>
            <TableCell align="center">{t("Date")}</TableCell>
            <TableCell align="center">{t("Name")}</TableCell>
            <TableCell align="center">{t("Transfer from")}</TableCell>
            <TableCell align="center">{t("Transfer to")}</TableCell>
            <TableCell align="center">{t("Status")}</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transfers.map((transfer) => (
            <TableRow key={transfer._id}>
              <TableCell align="center">
                {transfer.status === TransferStatus.PENDING && (
                  <MRCheckbox
                    checked={selected.includes(transfer._id)}
                    onChange={() => toggleSelection(transfer._id)}
                  />
                )}
              </TableCell>
              <TableCell align="center">
                {moment(transfer.createdAt).format("D MMM YYYY")}
              </TableCell>
              <TableCell align="center">{transfer.block.title}</TableCell>
              <TableCell align="center">{transfer.sender.email}</TableCell>
              <TableCell align="center">{transfer.recipient.email}</TableCell>
              <TableCell align="center">
                {<TransferStatusCard status={transfer.status} />}
              </TableCell>
              <TableCell align="center" className="table-icon-container">
                <TransferTableActions transfer={transfer} userRole={userRole} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MRTable>
    </TableWrapper>
  );
};

export default TransfersTable;
