import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { Block } from "types/block";
import { Request } from "types/request";
import { Response } from "types/response";
import client from "utils/client";

export default function useSetThumbnail(id: string) {
  const queryClient = useQueryClient();

  const setThumbnail = (data: FormData) => {
    const request: Request = {
      url: generatePath(ENDPOINTS.acquisitions.thumbnail, { id }),
      config: {
        method: "PATCH",
        data,
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<Response<Block>, Error, FormData>(
    setThumbnail,
    {
      onSettled: () => queryClient.invalidateQueries([ENDPOINTS.acquisitions.thumbnail, id]),
    }
  );

  return { data, mutateAsync, ...rest };
}
