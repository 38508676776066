import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";
import { UserInfoInputs } from "types/user";

interface IEditUserProfile extends UserInfoInputs {
  id: string;
}

export default function useAdminEditUserProfile() {
  const queryClient = useQueryClient();

  const editUserProfile = (updateProfile: IEditUserProfile) => {
    const { id, status, roles, downloadEnabled, ...profile } = updateProfile;
    const request: Request = {
      url: generatePath(ENDPOINTS.users.user, { id }),
      config: {
        method: "patch",
        data: {
          profile,
          status,
          roles,
          downloadEnabled,
        },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    Error,
    IEditUserProfile
  >(editUserProfile, {
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.users.all]);
      queryClient.invalidateQueries([ENDPOINTS.users.one, variables.id]);
    },
  });

  return { data, mutate, ...rest };
}
