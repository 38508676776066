import { BlockStatus } from "types/block";
import * as Yup from "yup";
import i18n from "i18n";

export interface Inputs {
  title: string;
  material: string;
  username: string;
  status: BlockStatus[] | string[];
  startDate: Date | null;
  endDate: Date | null;
}

export const validationSchema = Yup.object({
  title: Yup.string(),
  material: Yup.string(),
  username: Yup.string(),
  status: Yup.array().of(Yup.mixed().oneOf(Object.values(BlockStatus))),
  startDate: Yup.date().typeError(i18n.t("Invalid Date")).nullable(),
  endDate: Yup.date()
    .typeError(i18n.t("Invalid Date"))
    .nullable()
    .test(
      "End date must come after start date",
      "End date must come after start date",
      function () {
        if (!this.parent.startDate || !this.parent.endDate) return true;
        return this.parent.startDate.getTime() <= this.parent.endDate.getTime();
      }
    ),
});

export const initialValues = {
  title: "",
  material: "",
  username: "",
  status: [],
  startDate: null,
  endDate: null,
};
