import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery } from "react-query";
import { Request } from "types/request";
import { Response } from "types/response";
import { User } from "types/user";
import Error from "types/error";
import { generatePath } from "react-router-dom";

export default function useCheckInvite(code: string | null) {
  const request: Request = {
    url: ENDPOINTS.auth.checkInvite,
    config: {
      method: "POST",
      data: { code },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.auth.checkInvite, code],
    queryFn: () => client(request),
    enabled: !!code,
  };

  const { data, ...rest } = useQuery<Response<User>, Error>(requestConfig);

  return { data, ...rest };
}
