import { FC } from "react";
import { TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { MRCheckbox, MRTable } from "components/material";
import LinkStatusCard from "./LinkStatusCard";
import moment from "moment";
import { useTranslation } from "react-i18next";
import LinkRowActions from "./LinkRowActions";
import { LinkTableProps } from "./types";
import { TableWrapper } from "../Tables/styles";

const LinkTable: FC<LinkTableProps> = ({
  links,
  selected,
  toggleAll,
  toggleSelection,
}) => {
  const { t } = useTranslation();

  return (
    <TableWrapper>
      <MRTable>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <MRCheckbox
                checked={
                  selected.length === links.length && selected.length !== 0
                }
                onChange={() => toggleAll(links.map((link) => link._id) || [])}
              />
            </TableCell>
            <TableCell align="center">{t("Code")}</TableCell>
            <TableCell align="center">{t("User")}</TableCell>
            <TableCell align="center">{t("Block")}</TableCell>
            <TableCell align="center">{t("Expiry")}</TableCell>
            <TableCell align="center">{t("Status")}</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {links.map((link) => (
            <TableRow key={link._id}>
              <TableCell align="center">
                <MRCheckbox
                  checked={selected.includes(link._id)}
                  onChange={() => toggleSelection(link._id)}
                />
              </TableCell>
              <TableCell align="center">{link.code}</TableCell>
              <TableCell align="center">{link.username}</TableCell>
              <TableCell align="center">{link.blockTitle}</TableCell>
              <TableCell align="center">
                {moment(link.expireDate).format("D MMM YYYY")}
              </TableCell>
              <TableCell align="center">
                <LinkStatusCard status={link.status} />
              </TableCell>
              <TableCell align="center" className="table-icon-container">
                <LinkRowActions id={link._id} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MRTable>
    </TableWrapper>
  );
};

export default LinkTable;
