import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { Request } from "types/request";
import { Response } from "types/response";
import { UserInvite } from "types/user";
import client from "utils/client";

export default function useAcceptTransfer() {
  const queryClient = useQueryClient();

  const acceptTransfer = (variables: { transferId: string; keepShares?: boolean }) => {
    const { transferId, keepShares } = variables;

    const request: Request = {
      url: generatePath(ENDPOINTS.transfers.accept, { id: transferId }),
      config: {
        method: "PATCH",
        data: { ...(keepShares && { keepShares }) },
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<
    Response<any>,
    Error,
    { transferId: string; keepShares?: boolean }
  >(acceptTransfer, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.transfers.all]);
      queryClient.invalidateQueries([ENDPOINTS.block.all]);
    },
  });

  return { data, mutateAsync, ...rest };
}
