import { FC, createRef, useState } from "react";
import { UploadModelWrapper } from "./styles";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { Stack } from "@mui/material";
import { MRButtonPrimary } from "components/material/Button";
import { uploadWhite } from "assets/icons";
import { useTranslation } from "react-i18next";
//mport useS3 from "hooks/useS3";
import { UploadPhotosProps } from "./types";
//import useAcquisitionUploadInfo from "hooks/services/acquisitions/useAcquisitionUploadInfo";
import Loader from "components/Loader";
import { S3Upload } from "utils/s3upload/s3Upload";
import { CHUNK_SIZE, MAX_ATTEMPTS_PER_CHUNK } from "config/s3upload";
import useToasts from "hooks/useToasts";
import { getAcqusitionPhotosUploadInfo } from "hooks/services/acquisitions/useAcquisitionUploadInfo";
import { completeUploadPhotos } from "hooks/services/acquisitions/useCompleteUpload";
import useNotifyPhotosUpload from "hooks/services/acquisitions/useNotifyPhotosUpload";
import useSetProcessing from "hooks/services/acquisitions/useSetProcessing";

const UploadPhotos: FC<UploadPhotosProps> = ({ acquisition }) => {
  const dropzoneRef = createRef<DropzoneRef>();

  const [totChunks, setTotChunks] = useState(0);
  const [chunksUploaded, setChunksUploaded] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const { t } = useTranslation();

  const {
    mutateAsync: notifyPhotosUpload,
    isLoading: isLoadingNotifyPhotosUpload,
    error: notifyPhotosUploadError,
    isSuccess: isNotifyPhotosUploadSuccess,
  } = useNotifyPhotosUpload();

  const {
    data: setProcessingResponse,
    mutate: setProcessing,
    isLoading: setProcessingLoading,
    error: setProcessingError,
  } = useSetProcessing(acquisition._id);

  const handleUpload = async (files: File[]) => {
    const partsNum = Math.ceil(files[0].size / CHUNK_SIZE);
    setTotChunks(partsNum);
    setChunksUploaded(0);
    setUploadError(false);
    setUploading(true);
    const uploadInfo = await getAcqusitionPhotosUploadInfo(
      acquisition._id,
      partsNum
    );

    S3Upload(
      files[0],
      "eu-central-1",
      uploadInfo.data.Bucket,
      uploadInfo.data.UploadId,
      uploadInfo.data.Key,
      uploadInfo.data.signedUrls,
      CHUNK_SIZE,
      MAX_ATTEMPTS_PER_CHUNK,
      completeUploadPhotos,
      () => setChunksUploaded((prev) => prev + 1),
      () => {
        setUploading(false);
        notifyPhotosUpload(acquisition._id).then((res) => setProcessing());
      },
      () => setUploadError(true)
    );
  };

  useToasts([
    {
      message: t(
        "An error occured while uploading the photos, please try again"
      ),
      severity: "error",
      condition: !!uploadError,
    },
    {
      message: t("Photos uploaded successfully"),
      severity: "success",
      condition: isNotifyPhotosUploadSuccess,
    },
    {
      message: t(
        "The photos were uploaded correcty, but I was unable to notify the success of the operation"
      ),
      severity: "error",
      condition: !!notifyPhotosUploadError,
    },
  ]);

  if (uploading)
    return (
      <Stack
        height="100%"
        alignItems="center"
        justifyContent="center"
        padding={2}
      >
        <Loader />
        <span className="percentage">
          {Math.round((chunksUploaded / totChunks) * 100)}%
        </span>
      </Stack>
    );

  return (
    <Dropzone ref={dropzoneRef} multiple={false} onDrop={handleUpload}>
      {({ getRootProps, getInputProps }) => (
        <Stack
          {...getRootProps()}
          className="dropzone-container"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <span>{t("Drag your photos here")}</span>
          <MRButtonPrimary>
            <Stack direction="row" alignItems="center" gap={1}>
              <img src={uploadWhite} />
              {t("Upload")}
            </Stack>
          </MRButtonPrimary>
          <input {...getInputProps()} />
        </Stack>
      )}
    </Dropzone>
  );
};

export default UploadPhotos;
