import { Drawer } from "@mui/material";
import styled from "styled-components";
import { colors } from "styles/theme";

const MRDrawer = styled(Drawer)`
  .MuiPaper-root {
    background: ${colors.lightBlack};
  }
`;

export default MRDrawer;
