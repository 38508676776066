import * as Yup from "yup";
import i18n from "i18n";

export const validationSchema = Yup.object({
  email: Yup.string()
    .email(i18n.t("Please enter a valid email"))
    .required(i18n.t("E-mail is required")),
});

export const initialValues = {
  email: "",
};
