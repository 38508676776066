import { Stack } from "@mui/material";
import UserCredits from "components/User/Credits";
import UserStorage from "components/User/Storage";
import { FC } from "react";

const PageHeader: FC<{children: React.ReactNode}> = ({ children }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        flexWrap: { xs: "wrap", sm: "nowrap" },
        justifyContent: { xs: "center", sm: "space-between" },
        marginBottom: "10px",
      }}
    >
      {children}

      <Stack direction="row" gap={2} className="cta-credits">
        <UserStorage />
        <UserCredits />
      </Stack>
    </Stack>
  );
};

export default PageHeader;
