import { FC, useState, useMemo } from "react";
import { BlockTableProps } from "./types";
import { Stack } from "@mui/material";
import { MRPagination, MRDrawer } from "components/material";
import useBlocks from "hooks/services/blocks/useBlocks";
import { useTranslation } from "react-i18next";
import useMultiSelect from "hooks/useMultiSelect";
import BlockBulkActionsControl from "components/TablesComponents/Block/BlockBulkActionsControl";
import MRBlockTable from "components/TablesComponents/Block/BlockTable";
import { PAGE_SIZE } from "config/pagination";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import TitleCounter from "components/TablesComponents/TitleCounter";
import { Inputs as BlockFilters } from "components/Block/BlockFilterForm/model";
import BlockFilterForm from "components/Block/BlockFilterForm";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";

const BlockTable: FC<BlockTableProps> = ({ ownerId }) => {
  const [page, setPage] = useState(1);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState<BlockFilters | null>(null);

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("_id");

  const {
    data: blocks,
    isLoading: isLoadingBlocks,
    isError: blocksError,
  } = useBlocks({
    page,
    size: PAGE_SIZE,
    order,
    sort: orderBy,
    owner: ownerId,
    ...(formattedFilters && formattedFilters),
  });

  const sortHandler = (orderBy: string) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(orderBy);
  };

  if (blocksError) throw new Error("Cannot get block");

  const { selected, toggleSelection, toggleAll, resetSelection } = useMultiSelect();
  const { t } = useTranslation();

  return (
    <>
      <MRDrawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={toggleDrawer(false, setFilterDrawerOpen)}
      >
        <BlockFilterForm
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </MRDrawer>
      <Stack
        direction="row"
        gap={3}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "5px", sm: 3 },
        }}
      >
        <TitleCounter title={t("Models processed")} count={blocks?.pagination[0]?.total || 0} />
        <Stack direction="row" gap="10px" alignItems="center">
          <FilterButton
            onClick={() => setFilterDrawerOpen(true)}
            activeFilters={formattedFilters ? Object.keys(formattedFilters).length : 0}
          />
          <BlockBulkActionsControl selectedIds={selected} resetSelection={resetSelection} />
        </Stack>
      </Stack>
      <MRBlockTable
        blocks={blocks?.data || []}
        selected={selected}
        toggleSelection={toggleSelection}
        toggleAll={toggleAll}
        order={order}
        orderBy={orderBy}
        sortHandler={sortHandler}
      />
      <Stack direction="row" justifyContent="center">
        {blocks?.data && blocks.data.length ? (
          <MRPagination
            count={blocks ? Math.ceil(blocks?.pagination[0]?.total / PAGE_SIZE) || 1 : 1}
            page={page}
            onChange={(e, page) => setPage(page)}
          />
        ) : (
          <NoDataRow>{t("No Data")}</NoDataRow>
        )}
      </Stack>
    </>
  );
};

export default BlockTable;
