import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery } from "react-query";
import { Request } from "types/request";
import { Response } from "types/response";
import { Block } from "types/block";
import Error from "types/error";
import { generatePath } from "react-router-dom";

export default function useCanForwardCollection(id: string) {
  const request: Request = {
    url: generatePath(ENDPOINTS.shares.canForwardCollection, { id }),
    config: {
      method: "GET",
    },
  };

  const requestConfig = {
    queryFn: () => client(request),
  };

  const { data, ...rest } = useQuery<Response<boolean>, Error>(requestConfig);

  return { data, ...rest };
}
