import Loader from "components/Loader";
import useUser from "hooks/services/users/useUser";
import { FC, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { arrow_left, to_process } from "assets/icons/index";
import { Button, Grid, Stack, Collapse, InputAdornment } from "@mui/material";
import { HeaderButtonsContainer, UserWrapper } from "./style";
import { MRCard } from "components/material";
import IncrementDecrementButton from "components/IncrementDecrementButton";
import MRTextField from "components/material/TextField";
import useEditUserSellerInfo from "hooks/services/users/useEditUserSellerInfo";
import ProfileForm from "../../../../components/User/ProfileForm";
import {
  MRButtonPrimary,
  MRButtonSecondary,
  MRButtonQuinary,
} from "components/material/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ShareChart from "components/Charts/shareChart";
import ChartYearSelector from "components/Charts/chartYearSelector";
import AcquisitionTable from "./tables/AcquisitionTable";
import BlockTable from "./tables/BlockTable";
import CollectionTable from "./tables/CollectionTable";
import UserShareTable from "components/Share/UserShareTable";
import UserTransferTable from "components/Transfer/UserTransferTable";
import Page from "components/Layout/Page";
import toast from "react-hot-toast";
import ROLE from "types/role";
import { useConfirmModal } from "context/confirmModal";
import useDeleteUser from "hooks/services/users/useDeleteUser";
import useAuth from "hooks/useAuth";
import useRestoreUser from "hooks/services/users/useRestoreUser";
import useDeleteUserApiKey from "hooks/services/users/useDeleteUserApiKey";
import useEditUserApiKey from "hooks/services/users/useEditUserApiKey";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const UserDetails: FC = () => {
  const navigate = useNavigate();
  const { user: authUser } = useAuth();

  const { id } = useParams<{ id: string }>();
  if (!id) {
    throw new Error("user not found");
  }

  const {
    data: user,
    isLoading: loadingUser,
    isError: userError,
  } = useUser(id);

  if (userError) {
    throw new Error("user not found");
  }

  const { t } = useTranslation();
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );

  const [storage, setStorage] = useState<number>(0);
  const [credits, setCredits] = useState<number>(0);
  const [bandwidth, setBandwidth] = useState<number>(0);

  const {
    data: sellerInfoData,
    mutate: editSellerInfo,
    isLoading: loadingEditSellerInfo,
    error: editSellerInfoError,
  } = useEditUserSellerInfo();

  const {
    mutate: deleteApiKey,
    isLoading: loadingDeleteApiKey,
    error: errorDeleteApiKey,
  } = useDeleteUserApiKey();

  const {
    mutate: editApiKey,
    isLoading: loadingEditApiKey,
    error: errorEditApiKey,
  } = useEditUserApiKey();

  useEffect(() => {
    setStorage(user?.data.storage ?? 0);
    setCredits(user?.data.credits ?? 0);
    setBandwidth(user?.data.bandwidth ?? 0);
  }, [user]);

  const handleEditSellerInfo = (field: string, value: number) => {
    editSellerInfo({ id, [field]: value });
    toast(t(`${field} update succesfully`));
  };

  const handleDeleteUserApiKey = () => {
    deleteApiKey(id);
  };

  const handleEditUserApiKey = () => {
    editApiKey(id);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(user?.data.apiKey ?? "");
    toast(t("Api key copied to clipboard!"));
  };

  const { mutateAsync: deleteUser } = useDeleteUser();
  const { mutateAsync: restoreUser } = useRestoreUser();

  const { openModal } = useConfirmModal();

  const handleDeleteUser = () => {
    openModal({
      title: t("Are you sure you want to delete this user?"),
      text: t("The operation is not reversible"),
      confirmFunction: () =>
        deleteUser(id)
          .then(() => navigate("/users"))
          .catch(() => toast(t("Error deleting this user"))),
    });
  };

  const handleRestoreUser = () => {
    openModal({
      title: t("Are you sure you want to restore this user?"),
      text: t(
        "This user will be able to login again with his old credentials."
      ),
      confirmFunction: () =>
        restoreUser(id)
          .then(() => toast(t("User restored correctly!")))
          .catch(() => toast(t("An error occurred while restoring user."))),
    });
  };

  const memoizedShareChart = useMemo(() => {
    return (
      <ShareChart
        {...{
          year: selectedYear.toString(),
          userId: id,
          maxHeight: 500,
        }}
      />
    );
  }, [selectedYear, id]);

  if (!id || loadingUser) {
    return <Loader />;
  }

  return (
    <Page>
      <UserWrapper>
        <Link to={"/users"}>
          <Button className="back-button">
            <img src={arrow_left} /> {t("Users")}
          </Button>
        </Link>
        <Stack direction="row" justifyContent={"space-between"}>
          {user?.data.profile ? (
            <h2>
              {user?.data.profile.firstname + " " + user?.data.profile.lastname}
            </h2>
          ) : (
            <h2>{user?.data.username}</h2>
          )}
          <HeaderButtonsContainer>
            {authUser?._id !== id ? (
              <>
                {user?.data.status === "deleted" && (
                  <MRButtonPrimary
                    className="restore-user"
                    onClick={handleRestoreUser}
                  >
                    {t("Restore")}
                  </MRButtonPrimary>
                )}

                <MRButtonSecondary
                  className="delete-user"
                  sx={{ border: "1px solid red !important" }}
                  onClick={handleDeleteUser}
                >
                  {t("Delete")}
                </MRButtonSecondary>
              </>
            ) : (
              <></>
            )}
          </HeaderButtonsContainer>
        </Stack>
        {/* first cards row */}
        <Collapse
          in={user?.data.roles === "seller"}
          unmountOnExit={true}
          style={{ marginBottom: "20px" }}
        >
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MRCard className="user-top-card">
                <div className="user-top-card-content">
                  <div className="icon-container">
                    <img src={to_process} />
                  </div>
                  <div>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <p>{t("Storage")} </p>
                      {user?.data.storage !== storage && (
                        <Button
                          onClick={() =>
                            handleEditSellerInfo("storage", storage)
                          }
                        >
                          {t("Save")}
                        </Button>
                      )}
                    </Stack>
                    <Stack direction={"row"} className="input-container">
                      <MRTextField
                        className="user-top-card-input"
                        value={storage}
                        type="number"
                        onChange={(e) => setStorage(Number(e.target.value))}
                      ></MRTextField>
                      <IncrementDecrementButton setState={setStorage} />
                    </Stack>
                  </div>
                </div>
              </MRCard>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MRCard className="user-top-card">
                <div className="user-top-card-content">
                  <div className="icon-container">
                    <img src={to_process} />
                  </div>
                  <div>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <p>{t("Credits")}</p>
                      {user?.data.credits !== credits && (
                        <Button
                          onClick={() =>
                            handleEditSellerInfo("credits", credits)
                          }
                        >
                          {t("Save")}
                        </Button>
                      )}
                    </Stack>
                    <Stack direction={"row"} className="input-container">
                      <MRTextField
                        className="user-top-card-input"
                        value={credits}
                        type="number"
                        onChange={(e) => setCredits(Number(e.target.value))}
                      ></MRTextField>
                      <IncrementDecrementButton setState={setCredits} />
                    </Stack>
                  </div>
                </div>
              </MRCard>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MRCard className="user-top-card">
                <div className="user-top-card-content">
                  <div className="icon-container">
                    <img src={to_process} />
                  </div>
                  <div>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <p>{t("Bandwidth")} </p>
                      <Button
                        onClick={() =>
                          handleEditSellerInfo("bandwidth", bandwidth)
                        }
                      >
                        {t("Save")}
                      </Button>
                    </Stack>
                    <Stack direction={"row"} className="input-container">
                      <MRTextField
                        className="user-top-card-input"
                        value={Math.floor(Number(bandwidth) * 100) / 100}
                        type="number"
                        onChange={(e) => setBandwidth(Number(e.target.value))}
                      ></MRTextField>
                      <IncrementDecrementButton setState={setBandwidth} />
                    </Stack>
                  </div>
                </div>
              </MRCard>
            </Grid>
          </Grid>
        </Collapse>
        {/* first cards row END*/}
        {/* second cards row */}
        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <MRCard sx={{ padding: "20px" }}>
              {user && <ProfileForm user={user.data} />}
            </MRCard>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <MRCard>
              <div className="share-chart-card-header">
                <h2>
                  {t("Shares")} {selectedYear}
                </h2>
                <ChartYearSelector setSelectedYear={setSelectedYear} />
              </div>
              {memoizedShareChart}
            </MRCard>
          </Grid>
        </Grid>
        {/* second cards row END*/}

        {/* APIkey card*/}
        <MRCard sx={{ padding: "20px", width: "100%", marginTop: 3 }}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <p>{t("Api key")}</p>
              <MRTextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {user?.data.apiKey && (
                        <ContentCopyIcon
                          onClick={copyToClipboard}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                fullWidth
                className="text-field"
                id="api key"
                name="api key"
                placeholder={t("Apy Key")}
                value={user?.data.apiKey}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Stack
                direction={"row"}
                width={"100%"}
                marginTop={{ xs: 0, md: "calc(2em + 17px)" }}
                justifyContent={"space-around"}
              >
                <MRButtonQuinary
                  onClick={handleEditUserApiKey}
                  sx={{
                    padding: "13px 30px",
                  }}
                >
                  Generate
                </MRButtonQuinary>

                <MRButtonSecondary
                  onClick={handleDeleteUserApiKey}
                  sx={{
                    padding: "13px 30px",
                    border: "1px solid red !important",
                  }}
                >
                  Delete
                </MRButtonSecondary>
              </Stack>
            </Grid>
          </Grid>
        </MRCard>
        {/* APIkey card END*/}

        {/*Table Section*/}
        <Stack gap={3} marginTop={3}>
          <AcquisitionTable ownerId={id} />
          <BlockTable ownerId={id} />
          <CollectionTable ownerId={id} />
          {user && (
            <UserShareTable userRole={ROLE.ADMIN} ownerId={user?.data._id} />
          )}
          {user && <UserTransferTable user={user.data} />}
        </Stack>
      </UserWrapper>
    </Page>
  );
};
export default UserDetails;
