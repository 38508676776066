import styled from "styled-components";
import { colors } from "styles/theme";

export const ProcessedModelWrapper = styled.div`
  h1,
  h3 {
    margin: 5px 0px;
  }

  .secondary-title {
    font-weight: 400;
    color: ${colors.green};
  }

  .secondary-title span {
    font-weight: 700;
  }

  .thumbnail-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .thumbnail-container img {
    height: 256px;
    width: 256px;
    object-fit: contain;
  }

  .download-photos-btn {
    min-width: 160px;
  }

  .share-chart-card-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
`;
