import { FC, useMemo } from "react";
import { RowActionProps, RowAction } from "../RowActions/types";
import RowActionsBase from "../RowActions/RowActionsBase";
import { trash2 } from "assets/icons";
import useRevokeLink from "hooks/services/links/useRevokeLink";
import { useTranslation } from "react-i18next";

const LinkRowActions: FC<RowActionProps> = ({ id }) => {
  const {
    mutate: revokeLink,
    isLoading: isLoadingRevokeLink,
    isSuccess: isSuccessRevokeLink,
    error: revokeLinkError,
  } = useRevokeLink();

  const { t } = useTranslation();

  const actions: RowAction[] = useMemo(
    () => [
      {
        icon: trash2,
        action: () => revokeLink(id),

        confirmMessage: t("Are you sure you want to delete this acquisition?"),
        actionState: {
          isLoading: isLoadingRevokeLink,
          isSuccess: isSuccessRevokeLink,
          successMessage: t("Acquisition deleted successfully"),
          isError: !!revokeLinkError,
          errorMessage: t(
            "An error occurred while deleting the acquisition, please try again"
          ),
        },
      },
    ],
    [useRevokeLink, isLoadingRevokeLink, isSuccessRevokeLink, revokeLinkError]
  );

  return <RowActionsBase variant="icons" actions={actions} />;
};

export default LinkRowActions;
