import { MRCard, MRTextField } from "components/material";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ForgotPasswordPage } from "./styles";
import { InputLabel, Stack } from "@mui/material";
import { ReactComponent as Logo } from "assets/icons/logo-white.svg";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./model";
import { MRButtonPrimary } from "components/material/Button";
import { colors } from "styles/theme";
import useAskResetPassword from "hooks/services/password/useAskResetPassword";
import useToasts from "hooks/useToasts";
import { useNavigate } from "react-router-dom";

const ForgotPassword: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    mutate: askResetPassword,
    isLoading: isLoadingAskResetPassword,
    error: askResetPasswordError,
    isSuccess: isSuccessAskResetPassword,
  } = useAskResetPassword();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      askResetPassword(values);
    },
  });

  useEffect(() => {
    if (isSuccessAskResetPassword) navigate("/login");
  }, [isSuccessAskResetPassword]);

  useToasts([
    {
      severity: "success",
      message: t(
        "The email to recover the password has been sent to provided email."
      ),
      condition: isSuccessAskResetPassword,
    },
    {
      severity: "error",
      message: t("There was an error. Please try again later."),
      condition: !!askResetPasswordError,
    },
  ]);

  return (
    <ForgotPasswordPage>
      <MRCard className="signup-card">
        <form onSubmit={formik.handleSubmit}>
          <Stack sx={{ marginBottom: "30px" }} alignItems="center">
            <Logo width="70px" height="70px" />
            <h3 style={{ marginBottom: "0px" }}>
              {t("Forgot your password?")}
            </h3>
            <span>{t("Enter your e-mail to recover it!")} </span>

            <Stack alignItems="start" marginTop="20px" marginBottom="20px">
              <InputLabel>{t("Email address")}</InputLabel>
              <MRTextField
                fullWidth
                id="email"
                name="email"
                placeholder={t("E-mail")}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Stack>

            <MRButtonPrimary
              type="submit"
              loading={isLoadingAskResetPassword}
              sx={{
                bgcolor: `${colors.green} !important`,
                color: `${colors.black} !important`,
              }}
            >
              {t("Send")}
            </MRButtonPrimary>
          </Stack>
        </form>
      </MRCard>
    </ForgotPasswordPage>
  );
};

export default ForgotPassword;
