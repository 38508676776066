import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { Expiry, Link } from "types/link";
import { Request } from "types/request";
import { Response } from "types/response";
import client from "utils/client";

export interface CreateLinkInput {
  blockId: string;
  expiry: Expiry;
  showOwner: boolean;
}

export default function useCreateBlockLink() {
  const queryClient = useQueryClient();

  const createLink = (createLinkInput: CreateLinkInput) => {
    const { blockId, ...data } = createLinkInput;
    const request: Request = {
      url: generatePath(ENDPOINTS.links.block, { id: blockId }),
      config: {
        method: "POST",
        data,
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<
    Response<Link>,
    Error,
    CreateLinkInput
  >(createLink, {
    onSettled: () => queryClient.invalidateQueries([ENDPOINTS.links.block]),
  });

  return { data, mutateAsync, ...rest };
}
