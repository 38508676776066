import styled from "styled-components";
import { colors } from "styles/theme";

export const IncrementDecrementButtonWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-right: 10px;

  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${colors.green};
  }

  .icon {
    font-size: 18px;
  }

  .divider {
    transform: rotate(90deg);
  }
`;
