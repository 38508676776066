import { FC, useMemo } from "react";
import useDeleteCollection from "hooks/services/collections/useDeleteCollection";
import useBulkAction from "hooks/useBulkAction";
import { BulkAction, BulkActionProps } from "../BulkActionsControls/types";
import { trash2 } from "assets/icons";
import BulkActionsControlBase from "../BulkActionsControls/BulkActionsControlBase";
import { useTranslation } from "react-i18next";

const CollectionBulkActionsControl: FC<BulkActionProps> = (props) => {
  const { mutateAsync: deleteCollectionAsync } = useDeleteCollection();

  const { t } = useTranslation();

  const {
    bulkAction: deleteCollectionBulk,
    isLoading: isDeletingCollectionBulk,
    bulkResult: deleteCollectionBulkResult,
  } = useBulkAction(deleteCollectionAsync);

  const actions: BulkAction[] = useMemo(
    () => [
      {
        name: t("Remove"),
        icon: trash2,
        confirmMessage: t("Are you sure you want to delete these collections?"),
        bulkAction: deleteCollectionBulk,
        bulkActionLoading: isDeletingCollectionBulk,
        bulkActionResult: deleteCollectionBulkResult,
      },
    ],
    [deleteCollectionBulk, isDeletingCollectionBulk, deleteCollectionBulkResult]
  );
  return <BulkActionsControlBase {...props} actions={actions} />;
};

export default CollectionBulkActionsControl;
