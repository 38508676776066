import { FC } from "react";
import { IconButton, Slide, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { ModalHeaderProps } from "./types";
import { useTranslation } from "react-i18next";
import { colors } from "styles/theme";

const ModalHeader: FC<ModalHeaderProps> = ({ step, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" justifyContent={"space-between"}>
      {step === 0 && (
        <Slide direction="right" in={step === 0}>
          <h2>{t("Name your new catalog")}</h2>
        </Slide>
      )}

      {step === 1 && (
        <Slide direction="left" in={step === 1}>
          <h2>{t("Select which models you want to insert")}</h2>
        </Slide>
      )}
      <div className="close-button-container">
        <IconButton onClick={(e) => handleClose(e, "escapeKeyDown")}>
          <ClearIcon sx={{ color: colors.green }} />
        </IconButton>
      </div>
    </Stack>
  );
};

export default ModalHeader;
