import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import { Request } from "types/request";
import { Response } from "types/response";
import client from "utils/client";

export default function useCheckEmail() {
  const queryClient = useQueryClient();

  const signup = (username: string) => {
    const request: Request = {
      url: ENDPOINTS.auth.checkEmail,
      config: {
        method: "POST",
        data: { username },
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<
    Response<boolean>,
    Error,
    string
  >(signup, {
    onSettled: () => queryClient.invalidateQueries([ENDPOINTS.auth.checkEmail]),
  });

  return { data, mutateAsync, ...rest };
}
