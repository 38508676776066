import { Stack } from "@mui/material";
import Loader from "components/Loader";
import { FC } from "react";
import { HorizontalScrollerWrapper } from "./styles";
import { HorizontalScrollerProps } from "./types";

const HorizontalScroller: FC<HorizontalScrollerProps> = ({
  children,
  isLoading,
  fetchNext,
  hasNextPage,
  isLoadingNextPage,
}) => {
  const handleScroll = (e: any) => {
    if (e.target.clientWidth + e.target.scrollLeft === e.target.scrollWidth && hasNextPage) {
      fetchNext();
    }
  };

  return (
    <HorizontalScrollerWrapper onScroll={(e) => handleScroll(e)}>
      <Stack direction="row" gap={4}>
        {isLoading ? <Loader /> : children}
        {isLoadingNextPage && <Loader />}
      </Stack>
    </HorizontalScrollerWrapper>
  );
};

export default HorizontalScroller;
