import { Grid } from "@mui/material";
import useAcquisitions from "hooks/services/acquisitions/useAcquisitions";
import useBlocks from "hooks/services/blocks/useBlocks";
import { FC } from "react";
import { to_process, processed, users } from "assets/icons/index";
import PATH from "routing/path";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MRCard } from "components/material";
import Loader from "components/Loader";
import { SellerCountersWrapper } from "./styles";
import useInfiniteContacts from "hooks/services/users/useInfiniteContacts";
import { AcquisitionStatus } from "types/acquisition";

const SellerCounters: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: blocksCount,
    isLoading: loadingBlocks,
    isError: blocksError,
  } = useBlocks({ count: "true" });

  const {
    data: acquisitionsCount,
    isLoading: loadingAcquisitions,
    isError: acquisitionError,
  } = useAcquisitions({
    count: "true",
    status: [
      AcquisitionStatus.IDLE,
      AcquisitionStatus.PENDING_PROCESSING,
      AcquisitionStatus.PROCESSING,
    ],
  });

  const {
    data: contacts,
    isLoading: isLoadingContacts,
    error: contactsError,
  } = useInfiniteContacts({ size: 1 });

  const dashboardTopCards = [
    {
      title: t("Models to process"),
      counter: acquisitionsCount,
      loader: loadingAcquisitions,
      icon: to_process,
      link: PATH.MODELS + "?activeTab=models_to_process",
    },
    {
      title: t("Processed models"),
      counter: blocksCount,
      loader: loadingBlocks,
      icon: processed,
      link: PATH.MODELS,
    },
    {
      title: t("Contacts"),
      counter: (contacts?.pages[0].pagination[0]?.total ?? 0) as any,
      loader: loadingBlocks,
      icon: users,
      link: PATH.CONTACTS,
    },
  ];

  return (
    <SellerCountersWrapper>
      <Grid container spacing={3}>
        {dashboardTopCards.map((card, idx) => {
          return (
            <Grid item xs={12} sm={12} md={3} lg={3} key={idx}>
              <MRCard>
                {card.loader && <Loader />}
                {!card.loader && (
                  <div
                    className="dashboard-top-card"
                    onClick={() => navigate(card.link)}
                  >
                    <div>
                      <img src={card.icon} />
                    </div>
                    <div>
                      <h2>{card.counter}</h2>
                      <p>{card.title}</p>
                    </div>
                  </div>
                )}
              </MRCard>
            </Grid>
          );
        })}
      </Grid>
    </SellerCountersWrapper>
  );
};

export default SellerCounters;
