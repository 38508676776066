import AppRoutes from "routing/AppRoutes";
import AppRouter from "routing/AppRouter";
import { ErrorBoundary } from "react-error-boundary";
import CssBaseline from "@mui/material/CssBaseline";
import FullpageErroFallBack from "components/ErrorFallbacks/FullpageErrorFallback";
import PATH from "routing/path";
import MRLayout from "components/Layout/MRLayout";
import { ThemeProvider } from "@mui/material";
import { theme } from "styles/theme";
import { FC } from "react";
import type { BrowserHistory } from "history";
import AuthProvider from "context/auth/auth";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Toaster } from "react-hot-toast";
import ConfirmModalProvider from "context/confirmModal";
import SocketIOProvider from "./SocketIoProvider";
import { windowReplace } from "utils/helpers";

const queryClient = new QueryClient();

const App: FC<{ history: BrowserHistory }> = ({ history }) => {
  const onResetErrorBoundary = () => {
    //refresh all state and redirect to homepage
    windowReplace(PATH.DASHBOARD);
  };

  return (
    <ErrorBoundary FallbackComponent={FullpageErroFallBack} onReset={onResetErrorBoundary}>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <ConfirmModalProvider>
              <AppRouter history={history}>
                <Toaster />
                <AuthProvider>
                  <SocketIOProvider>
                    <MRLayout>
                      <AppRoutes />
                    </MRLayout>
                  </SocketIOProvider>
                </AuthProvider>
              </AppRouter>
            </ConfirmModalProvider>
          </ThemeProvider>
        </div>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
