import { Grid, Stack } from "@mui/material";
import { MRTextField } from "components/material";
import { MRButtonPrimary } from "components/material/Button";
import { useFormik } from "formik";
import useEditBlockInfo from "hooks/services/blocks/useEditBlockInfo";
import useToasts from "hooks/useToasts";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Block, BlockInfoInputs } from "types/block";
import { formatResponse, validationSchema, initialValues } from "./model";
import { lock4, lock4Open } from "assets/icons";

interface EditBlockInfoProps {
  block: Block;
}

const EditBlockInfo: FC<EditBlockInfoProps> = ({ block }) => {
  const { t } = useTranslation();
  const [isLocked, setIsLocked] = useState<boolean>(true);

  const {
    mutate: editBlockInfo,
    isLoading: isLoadingEditBlockInfo,
    error: editBlockInfoError,
    isSuccess: isSuccessEditBlockInfo,
  } = useEditBlockInfo();

  const formik = useFormik<BlockInfoInputs>({
    enableReinitialize: true,
    initialValues: block ? formatResponse(block) : initialValues,
    validationSchema: validationSchema,
    onSubmit: (blockInfo) => {
      editBlockInfo({ id: block._id, ...blockInfo });
    },
  });

  useToasts([
    {
      condition: !!editBlockInfoError,
      severity: "error",
      message: t("An error occured while editing the block, please try again"),
    },
    {
      condition: isSuccessEditBlockInfo,
      severity: "success",
      message: t("Block modified correctly"),
    },
  ]);

  function toggleLock() {
    setIsLocked((prev) => !prev);
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          flexDirection={"row"}
          sx={{
            justifyContent: { xs: "space-between", sm: "start" },
            alignItems: "end",
          }}
        >
          <h2 style={{ display: "inline-block", margin: "10px 20px 0px 20px" }}>
            {t("Model info")}
          </h2>
          <img
            style={{ width: "40px", height: "40px", cursor: "pointer" }}
            src={isLocked ? lock4 : lock4Open}
            onClick={toggleLock}
          ></img>
        </Stack>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={6} padding="20px">
            <p>{t("Title")}</p>
            <MRTextField
              fullWidth
              className="user-top-card-input"
              id="title"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              disabled={isLocked}
            />
            <p>{t("Height")} (cm)</p>
            <MRTextField
              className="user-top-card-input"
              type="number"
              fullWidth
              id="height"
              name="height"
              value={formik.values.height}
              onChange={formik.handleChange}
              error={formik.touched.height && Boolean(formik.errors.height)}
              helperText={formik.touched.height && formik.errors.height}
              disabled={isLocked}
            />
            <p>{t("Length")} (cm)</p>
            <MRTextField
              className="user-top-card-input"
              type="number"
              fullWidth
              id="length"
              name="length"
              value={formik.values.length}
              onChange={formik.handleChange}
              error={formik.touched.length && Boolean(formik.errors.length)}
              helperText={formik.touched.length && formik.errors.length}
              disabled={isLocked}
            />

            <p>{t("Geological Family")}</p>
            <MRTextField
              className="user-top-card-input"
              fullWidth
              id="geologicalFamily"
              name="geologicalFamily"
              value={formik.values.geologicalFamily}
              onChange={formik.handleChange}
              error={
                formik.touched.geologicalFamily &&
                Boolean(formik.errors.geologicalFamily)
              }
              helperText={
                formik.touched.geologicalFamily &&
                formik.errors.geologicalFamily
              }
              disabled={isLocked}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} padding="20px">
            <p>{t("Material")}</p>
            <MRTextField
              className="user-top-card-input"
              type="text"
              fullWidth
              id="material"
              name="material"
              value={formik.values.material}
              onChange={formik.handleChange}
              error={formik.touched.material && Boolean(formik.errors.material)}
              helperText={formik.touched.material && formik.errors.material}
              disabled={isLocked}
            />
            <p>{t("Depth")} (cm)</p>
            <MRTextField
              className="user-top-card-input"
              type="number"
              fullWidth
              id="depth"
              name="depth"
              value={formik.values.depth}
              onChange={formik.handleChange}
              error={formik.touched.depth && Boolean(formik.errors.depth)}
              helperText={formik.touched.depth && formik.errors.depth}
              disabled={isLocked}
            />
            <p>{t("Weight")} (Kg)</p>
            <MRTextField
              className="user-top-card-input"
              type="number"
              fullWidth
              id="weight"
              name="weight"
              value={formik.values.weight}
              onChange={formik.handleChange}
              error={formik.touched.weight && Boolean(formik.errors.weight)}
              helperText={formik.touched.weight && formik.errors.weight}
              disabled={isLocked}
            />
            <Stack alignItems="center" justifyContent="center" marginTop="40px">
              <MRButtonPrimary type="submit">{t("Save")}</MRButtonPrimary>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EditBlockInfo;
