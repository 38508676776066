import * as Yup from "yup";
import i18n from "../../i18n";

export const step1ValidationSchema = Yup.object({
  password: Yup.string().min(6).required(i18n.t("Password is required")),
  confirmPassword: Yup.string()
    .required("Please confirm password")
    .oneOf([Yup.ref("password"), null], i18n.t("Passwords must match")),
});

export const step1InitialValues = { password: "", confirmPassword: "" };

export const step2ValidationSchema = Yup.object({
  firstname: Yup.string().required(i18n.t("Firstname is required")),
  lastname: Yup.string().required(i18n.t("Lastname is required")),
  companyName: Yup.string().required(i18n.t("Company name is required")),
  country: Yup.string().required(i18n.t("Country is required")),
  phone: Yup.string().required(i18n.t("Phone is required")),
  companyField: Yup.string().required(i18n.t("Company field is required")),
  vat: Yup.string().required(i18n.t("VAT is required")),
});

export const step2InitialValues = {
  firstname: "",
  lastname: "",
  companyName: "",
  country: "Italy",
  phone: "",
  companyField: "",
  vat: "",
};
