import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery } from "react-query";
import { Request } from "types/request";
import { PaginatedResponse } from "types/response";
import { Block } from "types/block";
import Error from "types/error";

export default function useBlocks(params: Record<string, any>) {
  const request: Request = {
    url: ENDPOINTS.block.all,
    config: {
      method: "GET",
      params,
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.block.all, params && JSON.stringify(params)],
    queryFn: () => client(request).then((res) => res.data),
  };

  const { data, ...rest } = useQuery<PaginatedResponse<Block[]>, Error>(
    requestConfig
  );

  return { data, ...rest };
}
