import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery } from "react-query";
import { Request } from "types/request";
import { Response } from "types/response";
import { Block } from "types/block";
import Error from "types/error";
import { generatePath } from "react-router-dom";
import { Transfer } from "types/transfer";

export default function useTransfer(id: string) {
  const request: Request = {
    url: generatePath(ENDPOINTS.transfers.one, { id }),
    config: {
      method: "GET",
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.transfers.one, id],
    queryFn: () => client(request),
    enabled: !!id,
  };

  const { data, ...rest } = useQuery<Response<Transfer>, Error>(requestConfig);

  return { data, ...rest };
}
