import styled from "@emotion/styled/macro";

export const AdminDashboardWrapper = styled.div`
  h2 {
    margin-bottom: 70px;
  }

  .dashboard-top-card {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 50px 25px;
    gap: 25px;
    h2 {
      margin: 0px;
    }
    p {
      margin: 0px;
    }
  }

  .dashboard-chart-card {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    h2 {
      margin: 0px;
    }

    .dashboard-chart-card-header {
      padding: 25px 50px;
      display: flex;
      justify-content: space-between;
    }
  }

  .profile-name {
    cursor: pointer;
  }
`;

export const PieChartWrapper = styled.div`
  svg.recharts-surface {
    border-radius: 3px;
  }

  ul.recharts-default-legend {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  span.recharts-legend-item-text {
    color: white !important;
  }

  .inside-text {
    color: white;
  }
`;

export const AcquisitionChartWrapper = styled.div`
  color: black;

  .custom-tooltip {
    background: white;
    padding: 10px 20px;
    border-radius: 10px;
    text-align: center;
  }
  .tooltip-label {
    font-size: 14px;
  }
  .tooltip-value {
    font-size: 20px;
  }
`;
