import { MRButtonPrimary } from "components/material/Button";
import { FC, useState } from "react";
import { Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { colors } from "styles/theme";
import { useNavigate, generatePath, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMultiSelect from "hooks/useMultiSelect";
import ShareBulkActionsControl from "components/TablesComponents/Share/ShareBulkActionsControl";
import { MRPagination } from "components/material";
import useShares from "hooks/services/shares/useShares";
import { PAGE_SIZE } from "config/pagination";
import SharesTable from "components/TablesComponents/Share/SharesTable";
import Loader from "components/Loader";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import ROLE from "types/role";
import useAuth from "hooks/useAuth";
import UserShareBulkActionsControl from "components/TablesComponents/Share/UserShareBulkActionsControl";

const Shares: FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("Missing params :id");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();

  const [page, setPage] = useState(1);

  const {
    data: shares,
    isLoading: loadingShares,
    isError: sharesError,
  } = useShares(page, PAGE_SIZE, { blockCollection: id });

  if (sharesError) throw new Error("Cannot get shares");

  const { selected, toggleSelection, toggleAll, resetSelection } =
    useMultiSelect();

  if (loadingShares) return <Loader />;

  return (
    <>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        direction="row"
      >
        <Link to={generatePath("/catalogues/:id", { id })}>
          <MRButtonPrimary
            sx={{ bgcolor: `${colors.lightBlack}!important` }}
            variant="contained"
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <ArrowBackIcon />
              {t("Models")}
            </Stack>
          </MRButtonPrimary>
        </Link>

        {(user?.roles === ROLE.ADMIN || user?.roles === ROLE.OPERATOR) && (
          <ShareBulkActionsControl
            selectedIds={selected}
            resetSelection={resetSelection}
          />
        )}

        {user?.roles === ROLE.SELLER && (
          <UserShareBulkActionsControl
            selectedIds={selected}
            resetSelection={resetSelection}
          />
        )}
      </Stack>
      <SharesTable
        userRole={ROLE.SELLER}
        shares={shares?.data || []}
        selected={selected}
        toggleSelection={toggleSelection}
        toggleAll={toggleAll}
      />
      <Stack direction="row" justifyContent="center">
        {shares?.data && shares.data.length ? (
          <MRPagination
            count={
              shares
                ? Math.ceil(shares?.pagination[0]?.total / PAGE_SIZE) || 1
                : 1
            }
            page={page}
            onChange={(e, page) => setPage(page)}
          />
        ) : (
          <NoDataRow>{t("No Data")}</NoDataRow>
        )}
      </Stack>
    </>
  );
};

export default Shares;
