import i18n from "../../i18n";
import { GENERIC_ERROR, parseError } from "utils/errors";
import Error from "types/error";

export const getErrorMessage = (error: Error | null | undefined) => {
  if (!error) return "";
  const { name } = parseError(error);

  switch (name) {
    case "InvalidCredentials":
      return i18n.t("Wrong credentials. Please try again.");
    default:
      return GENERIC_ERROR;
  }
};
