import { FC, useState } from "react";
import useAuth from "hooks/useAuth";
import ROLE from "types/role";
import AdminDashboard from "./admin";
import UserDashboard from "./user";
import AcceptTransferModal from "components/Transfer/AcceptTransferModal";
import { useLocation } from "react-router-dom";

const Dashboard: FC = () => {
  const { user } = useAuth();
  const location = useLocation();

  let transferId = (location.state as any)?.transferId; // @ https://github.com/remix-run/react-router/issues/7670

  const [acceptTransferModal, setAcceptTransferModal] = useState<boolean>(
    transferId ? true : false
  );

  // TODO Check user exists and guard (?)
  return (
    <>
      {((user && user.roles === ROLE.ADMIN) || (user && user.roles === ROLE.OPERATOR)) && (
        <AdminDashboard />
      )}
      {((user && user.roles === ROLE.SELLER) || (user && user.roles === ROLE.BUYER)) && (
        <UserDashboard />
      )}

      {transferId && (
        <AcceptTransferModal
          open={acceptTransferModal}
          setOpen={setAcceptTransferModal}
          transferId={transferId}
        />
      )}
    </>
  );
};

export default Dashboard;
