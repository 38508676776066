import { FC, useMemo, useState } from "react";
import useBulkAction from "hooks/useBulkAction";
import { BulkAction, BulkActionProps } from "../BulkActionsControls/types";
import { share2Green, trash2, folder_green_small } from "assets/icons";
import BulkActionsControlBase from "../BulkActionsControls/BulkActionsControlBase";
import useTrashBlock from "hooks/services/blocks/useTrashBlock";
import BulkShareModal from "components/Share/BulkShareModal";
import { useTranslation } from "react-i18next";
import AddBlockToCollectionModal from "components/Block/AddBlockToCollectionModal";

const UserBlockBulkActionControl: FC<BulkActionProps> = (props) => {
  const { t } = useTranslation();
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openAddToCollectionModal, setOpenAddToCollectionModal] = useState(false);

  const { mutateAsync: thrashBlocksAsync } = useTrashBlock();

  const {
    bulkAction: thrashBlockBulk,
    isLoading: isThrashingBlockBulk,
    bulkResult: thrashBlockBulkResult,
  } = useBulkAction(thrashBlocksAsync);

  const actions: BulkAction[] = useMemo(
    () => [
      {
        name: t("Share"),
        icon: share2Green,
        confirmMessage: null,
        bulkAction: (blockIds: string[]) => {
          if (blockIds.length) {
            setOpenShareModal(true);
          }
        },
        bulkActionLoading: null,
        bulkActionResult: null,
      },
      {
        name: t("Add"),
        icon: folder_green_small,
        confirmMessage: null,
        bulkAction: (blockIds: string[]) => {
          if (blockIds.length) {
            setOpenAddToCollectionModal(true);
          }
        },
        bulkActionLoading: null,
        bulkActionResult: null,
      },
      {
        name: t("Remove"),
        icon: trash2,
        confirmMessage: t("Are you sure you want to move these blocks into the trashbin?"),
        bulkAction: thrashBlockBulk,
        bulkActionLoading: isThrashingBlockBulk,
        bulkActionResult: thrashBlockBulkResult,
      },
    ],
    [thrashBlockBulk, isThrashingBlockBulk, thrashBlockBulkResult]
  );
  return (
    <>
      <BulkShareModal
        open={openShareModal}
        setOpen={setOpenShareModal}
        blocksIds={props.selectedIds}
      />
      <BulkActionsControlBase {...props} actions={actions} />
      <AddBlockToCollectionModal
        openModal={openAddToCollectionModal}
        setOpenModal={setOpenAddToCollectionModal}
        blockId={props.selectedIds}
      />
    </>
  );
};

export default UserBlockBulkActionControl;
