import { List } from "@mui/material";
import { match } from "helpers";
import useAuth from "hooks/useAuth";
import { FC, useMemo } from "react";
import ROLE from "types/role";
import { NavigationItem, NavigationProps } from "./types";
import { adminNavigation } from "./navs/admin";
import { buyerNavigation } from "./navs/buyer";
import { sellerNavigation } from "./navs/seller";
import NavItem from "./NavItem";

const Navigation: FC<NavigationProps> = ({ active, setActive, open }) => {
  const { user } = useAuth();

  const navigation: NavigationItem[] = useMemo(
    () =>
      match(user?.roles)
        .on(
          (r: ROLE) => r === ROLE.ADMIN || r === ROLE.OPERATOR,
          () => adminNavigation
        )
        .on(
          (r: ROLE) => r === ROLE.SELLER,
          () => sellerNavigation
        )
        .on(
          (r: ROLE) => r === ROLE.BUYER,
          () => buyerNavigation
        )
        .otherwise(() => []),
    [user?.roles, adminNavigation, buyerNavigation, sellerNavigation]
  );

  return (
    <List>
      {navigation.map((navItem) => (
        <NavItem key={navItem.path} item={navItem} open={open} />
      ))}
    </List>
  );
};

export default Navigation;
