import { FC, useMemo, useState } from "react";
import { UsersWrapper } from "./styles";
import { Stack } from "@mui/material";
import InviteUser from "./InviteUser";
import { MRButtonPrimary } from "components/material/Button";
import { MRSearchbar, MRPagination, MRDrawer } from "components/material";
import { plus } from "assets/icons";
import UserBulkActionsControl from "components/TablesComponents/User/UserBulkActionsControl";
import useMultiSelect from "hooks/useMultiSelect";
import { useTranslation } from "react-i18next";
import UserTable from "components/TablesComponents/User/UserTable";
import useUsers from "hooks/services/users/useUsers";
import { PAGE_SIZE } from "config/pagination";
import Page from "components/Layout/Page";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import { useDebounce } from "use-debounce";
import Loader from "components/Loader";
import UserFilterForm from "components/User/UserFilterForm";
import { Inputs as UserFilters, formatFilters } from "components/User/UserFilterForm/model";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";

const Users: FC = () => {
  const [inviteDrawerOpen, setInviteDrawerOpen] = useState<boolean>(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const [searchbarText, setSearchbarText] = useState<string>("");
  const [debouncedSearchbarText] = useDebounce(searchbarText, 500);
  const [filters, setFilters] = useState<UserFilters | null>(null);

  const { selected, toggleSelection, toggleAll, resetSelection } = useMultiSelect();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("_id");

  const sortHandler = (orderBy: string) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(orderBy);
  };

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(formatFilters(filters));
  }, [filters]);

  const {
    data: users,
    isLoading: isLoadingUsers,
    error: usersError,
  } = useUsers(page, PAGE_SIZE, orderBy, order, {
    text: debouncedSearchbarText,
    ...(formattedFilters && formattedFilters),
  });

  if (usersError) throw new Error("Cannot get users");

  return (
    <Page>
      <UsersWrapper
        onClick={() => {
          toggleDrawer(false, setInviteDrawerOpen);
          toggleDrawer(false, setFilterDrawerOpen);
        }}
        onKeyDown={() => {
          toggleDrawer(false, setInviteDrawerOpen);
          toggleDrawer(false, setFilterDrawerOpen);
        }}
      >
        <MRDrawer
          anchor="right"
          open={inviteDrawerOpen}
          onClose={() => toggleDrawer(false, setInviteDrawerOpen)}
        >
          <InviteUser setDrawerOpen={setInviteDrawerOpen} />
        </MRDrawer>

        <MRDrawer
          anchor="right"
          open={filterDrawerOpen}
          onClose={() => toggleDrawer(false, setFilterDrawerOpen)}
        >
          <UserFilterForm
            appliedFilters={filters}
            onClearFilters={() => setFilters(null)}
            onApplyFilters={(values) => {
              setFilters(values);
              setFilterDrawerOpen(false);
            }}
            onClose={() => setFilterDrawerOpen(false)}
          />
        </MRDrawer>

        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <h1>{t("Users")}</h1>
            <FilterButton
              onClick={() => setFilterDrawerOpen(true)}
              activeFilters={formattedFilters ? Object.keys(formattedFilters).length : 0}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            width="100%"
            flexWrap="wrap"
            rowGap="10px"
            justifyContent={"space-between"}
          >
            <MRButtonPrimary variant="contained">
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                onClick={() => setInviteDrawerOpen(true)}
              >
                <img src={plus} />
                {t("Add new")}
              </Stack>
            </MRButtonPrimary>

            <Stack direction="row" gap={3} alignItems="center">
              <MRSearchbar
                value={searchbarText}
                onChange={(event) => setSearchbarText(event.target.value)}
              />
              <UserBulkActionsControl selectedIds={selected} resetSelection={resetSelection} />
            </Stack>
          </Stack>
          {isLoadingUsers ? (
            <Loader />
          ) : (
            <>
              <UserTable
                users={users?.data || []}
                selected={selected}
                toggleSelection={toggleSelection}
                toggleAll={toggleAll}
                order={order}
                orderBy={orderBy}
                sortHandler={sortHandler}
              />
              <Stack direction="row" justifyContent="center">
                {users?.data && users.data.length ? (
                  <MRPagination
                    count={users ? Math.ceil(users?.pagination[0]?.total / PAGE_SIZE) || 1 : 1}
                    page={page}
                    onChange={(e, page) => setPage(page)}
                  />
                ) : (
                  <NoDataRow>{t("No Data")}</NoDataRow>
                )}
              </Stack>
            </>
          )}
        </>
      </UsersWrapper>
    </Page>
  );
};

export default Users;
