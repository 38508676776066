import { Block } from "./block";

export interface Transfer {
  _id: string;
  block:
    | {
        _id: string;
        title: string;
      }
    | Block;
  sender: {
    _id: string;
    email: string;
    firstname: string;
    lastname: string;
  };
  recipient: {
    _id: string;
    email: string;
  };
  status: TransferStatus;
  createdAt: string;
}

export enum TransferStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  DECLINED = "declined",
  REMOVED = "removed",
}
