import { S3 } from "aws-sdk";
import axios, { AxiosPromise } from "axios";
import { CompleteUploadPayload } from "hooks/services/acquisitions/useCompleteUpload";

const uploadChunck: (
  s3: any,
  url: string,
  chunk: Blob,
  chunkNumber: number,
  maxAttempts: number,
  notifyChunckCompleted?: () => unknown,
  attempt?: number
) => Promise<any> = async (
  s3: any,
  url: string,
  chunk: Blob,
  chunkNumber: number,
  maxAttempts: number,
  notifyChunckCompleted?: () => unknown,
  attempt: number = 0
) => {
  return axios
    .put(url, chunk)
    .then((response: any) => {
      notifyChunckCompleted && notifyChunckCompleted();
      return {
        PartNumber: chunkNumber,
        ETag: (response as any).headers.etag,
      };
    })
    .catch((error: any) => {
      if (attempt === maxAttempts) {
        return Promise.reject(`Impossible to upload chunk nr ${chunkNumber}`);
      }
      return uploadChunck(
        s3,
        url,
        chunk,
        chunkNumber,
        maxAttempts,
        notifyChunckCompleted,
        attempt + 1
      );
    });
};

export const S3Upload = async (
  file: File,
  region: string,
  bucket: string,
  uploadId: string,
  uploadKey: string,
  signedUrls: string[],
  chunkSize: number,
  maxAttemptsPerChunk: number,
  completeUpload: (data: CompleteUploadPayload) => AxiosPromise,
  notifyChunckCompleted?: () => unknown,
  notifyCompleted?: () => unknown,
  notifyError?: () => unknown
) => {
  const s3 = new S3({
    region,
  });
  let uploadPromises: Array<
    Promise<{
      PartNumber: number;
      ETag: string | undefined;
    }>
  > = [];

  let totBit = 0;

  for (let [start, i] = [0, 1]; start < file.size; start += chunkSize, i += 1) {
    let chunk;

    chunk = file.slice(start, start + chunkSize);

    totBit += chunk.size;

    uploadPromises.push(
      uploadChunck(
        s3,
        signedUrls[i - 1],
        chunk,
        i,
        maxAttemptsPerChunk,
        notifyChunckCompleted
      )
    );
  }

  Promise.all(uploadPromises)
    .then((uploadedParts) => {
      completeUpload({
        Key: uploadKey,
        MultipartUpload: {
          Parts: uploadedParts as { ETag: string; PartNumber: number }[],
        },
        UploadId: uploadId,
      }).then(() => {
        notifyCompleted && notifyCompleted();
      });
    })
    .catch((error) => {
      notifyError && notifyError();
    });
};
