import Users from "./views/all";
import UserDetails from "./views/single";

const routes = [
  {
    index: true,
    name: "Main",
    element: <Users />,
  },
  {
    path: ":id/profile",
    name: "Detail",
    element: <UserDetails />,
  },
];

export default routes;
