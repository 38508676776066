import { Grid } from "@mui/material";
import Page from "components/Layout/Page";
import { MRCard } from "components/material";
import { FC, useState } from "react";
import { to_process, processed, users } from "assets/icons/index";
import useBlocks from "hooks/services/blocks/useBlocks";
import useAcquisitions from "hooks/services/acquisitions/useAcquisitions";
import useUsers from "hooks/services/users/useUsers";
import Loader from "components/Loader";
import { useTranslation } from "react-i18next";
import { AdminDashboardWrapper } from "./styles";
import UsersChart from "./usersChart";
import AcquisitionChart from "./AcquisitionChart";
import ChartYearSelector from "components/Charts/chartYearSelector";
import useAuth from "hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import PATH from "routing/path";
import { AcquisitionStatus } from "types/acquisition";

const AdminDashboard: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );

  const {
    data: blocksCount,
    isLoading: loadingBlocks,
    isError: blocksError,
  } = useBlocks({ count: "true" });

  const {
    data: acquisitionsCount,
    isLoading: loadingAcquisitions,
    isError: acquisitionError,
  } = useAcquisitions({
    count: "true",
    status: [
      AcquisitionStatus.IDLE,
      AcquisitionStatus.PENDING_PROCESSING,
      AcquisitionStatus.PROCESSING,
    ],
  });

  const {
    data: proUsersCount,
    isLoading: laodingProUsers,
    isError: proUsersCountError,
  } = useUsers(undefined, undefined, undefined, undefined, {
    roles: "seller",
    count: "true",
  });

  const {
    data: freeUsersCount,
    isLoading: laodingFreeUsers,
    isError: freeUsersCountError,
  } = useUsers(undefined, undefined, undefined, undefined, {
    roles: "buyer",
    count: "true",
  });

  const dashboardTopCards = [
    {
      title: t("Models to process"),
      counter: acquisitionsCount as any,
      loader: loadingAcquisitions,
      icon: to_process,
      link: PATH.MODELS_TO_PROCESS,
    },
    {
      title: t("Processed models"),
      counter: blocksCount,
      loader: loadingBlocks,
      icon: processed,
      link: PATH.PROCESSED_MODELS,
    },
    {
      title: t("Pro users"),
      counter: proUsersCount,
      loader: laodingProUsers,
      icon: users,
      link: PATH.USERS,
    },
    {
      title: t("Free users"),
      counter: freeUsersCount,
      loader: laodingFreeUsers,
      icon: users,
      link: PATH.USERS,
    },
  ];

  // TODO: Add error handling toasts

  return (
    <Page>
      <AdminDashboardWrapper>
        <h2>
          {t("Hello")},{" "}
          <span className="profile-name" onClick={() => navigate(PATH.PROFILE)}>
            {user?.profile?.firstname}
          </span>
        </h2>
        <Grid container spacing={3}>
          {dashboardTopCards.map((card, idx) => {
            return (
              <Grid item xs={12} sm={12} md={3} lg={3} key={idx}>
                <Link to={card.link}>
                  <MRCard>
                    {card.loader && <Loader />}
                    {!card.loader && (
                      <div className="dashboard-top-card">
                        <div>
                          <img src={card.icon} />
                        </div>
                        <div>
                          <h2>{card.counter}</h2>
                          <p>{card.title}</p>
                        </div>
                      </div>
                    )}
                  </MRCard>
                </Link>
              </Grid>
            );
          })}

          <Grid item xs={12} sm={12} md={8} lg={8}>
            <MRCard>
              <div className="dashboard-chart-card">
                <div className="dashboard-chart-card-header">
                  <h2>
                    {t("Processing requests")} {selectedYear}
                  </h2>
                  <ChartYearSelector setSelectedYear={setSelectedYear} />
                </div>
                <AcquisitionChart year={selectedYear} />
              </div>
            </MRCard>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <MRCard>
              <div className="dashboard-chart-card">
                <div className="dashboard-chart-card-header">
                  <h2>{t("Users")}</h2>
                </div>
                <UsersChart
                  data={[
                    { name: "PRO", value: Number(proUsersCount) },
                    { name: "Free", value: Number(freeUsersCount) },
                  ]}
                />
              </div>
            </MRCard>
          </Grid>
        </Grid>
      </AdminDashboardWrapper>
    </Page>
  );
};

export default AdminDashboard;
