import { FC, useRef, useState } from "react";
import { SignupFormWrapper } from "./styles";
import { useFormik } from "formik";
import {
  Stack,
  Slide,
  Avatar,
  Badge,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { MRButtonPrimary, MRButtonSecondary } from "components/material/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MRTextField from "components/material/TextField";
import useCheckInvite from "hooks/services/signup/useCheckInvite";
import Loader from "components/Loader";
import useToasts from "hooks/useToasts";
import { InputLabel } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReactComponent as DeleteLogo } from "assets/icons/close.svg";
import {
  step1InitialValues,
  step1ValidationSchema,
  step2InitialValues,
  step2ValidationSchema,
} from "./model";
import useSignup from "hooks/services/signup/useSignup";
import useAuth from "hooks/useAuth";
import PATH from "routing/path";
import { colors } from "styles/theme";
import { Profile } from "types/user";
import { camera } from "assets/icons";
import jsonCountries from "assets/countries.json";
import toast from "react-hot-toast";

const SignupForm: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [step, setStep] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const [code] = useState<string | null | undefined>(
    searchParams.get("verify")
  );
  const propicInput = useRef<HTMLInputElement>(null);
  const companyLogoInput = useRef<HTMLInputElement>(null);

  const [propicFile, setPropicFile] = useState<File | null>(null);
  const [propic, setPropic] = useState<string>("");

  const [companyLogoFile, setCompanyLogoFile] = useState<File | null>(null);
  const [companyLogo, setCompanyLogo] = useState<string>("");

  if (!code) throw new Error("Invalid code");

  const {
    data: checkInviteResponse,
    isLoading: checkInviteLoading,
    isError: checkInviteIsError,
  } = useCheckInvite(code);

  const {
    mutateAsync: signup,
    isLoading: signupLoading,
    isError: signupError,
  } = useSignup();

  const formikStep1 = useFormik({
    initialValues: step1InitialValues,
    validationSchema: step1ValidationSchema,
    onSubmit: () => {
      setStep(1);
    },
  });

  const formikStep2 = useFormik({
    initialValues: step2InitialValues,
    validationSchema: step2ValidationSchema,
    onSubmit: async (values) => {
      const username = checkInviteResponse?.data.username;
      const password = formikStep1.values.password;
      if (username && password) {
        const formdata = createFormData(values, password);
        await signup(formdata);
        login({ username, password });
        navigate(PATH.DASHBOARD);
      }
    },
  });

  const createFormData = (values: Profile, password: string) => {
    let formdata = new FormData();

    formdata.set("code", code);
    formdata.set("password", password);
    for (const [key, value] of Object.entries(values)) {
      formdata.set(key, value);
    }
    if (propicFile) formdata.set("propic", propicFile);
    if (companyLogoFile) formdata.set("companyLogo", companyLogoFile);

    return formdata;
  };

  useToasts([
    {
      severity: "error",
      message: t(
        "There was an error during the signup. Please contact the administrator."
      ),
      condition: !!signupError,
    },
  ]);

  const uploadPic = (e: any, type: "propic" | "companyLogo") => {
    const maxSize = 1024 * 1024; // Maximum file size in bytes (1MB)

    if (e.target.files[0]) {
      if (e.target.files[0].size > maxSize) {
        e.target.value = "";
        toast(t("ERROR: Image is too big!"));
        return;
      }

      const uploadedFile = e.target.files[0];
      type === "propic"
        ? setPropicFile(uploadedFile)
        : setCompanyLogoFile(uploadedFile);
      const reader = new FileReader();
      reader.onload = function (event) {
        if (event?.target?.result) {
          type === "propic"
            ? setPropic(event.target.result.toString())
            : setCompanyLogo(event.target.result.toString());
        }
      };
      reader.readAsDataURL(uploadedFile);
    }
  };

  const countryWOFlag = (value: any) => {
    let [first, ...rest] = value.split(" ");
    rest = rest.join(" ");
    return rest;
  };

  const handleDeleteCompanyLogo = () => {
    setCompanyLogo("");
    setCompanyLogoFile(null);
  };

  if (checkInviteIsError)
    throw new Error(t("The link provided is invalid or expired"));

  if (checkInviteLoading) return <Loader />;

  return (
    <SignupFormWrapper>
      <Stack sx={{ height: "fit-content", margin: "10px auto" }} width="100%">
        {step === 0 && (
          <form onSubmit={formikStep1.handleSubmit}>
            <Slide direction="right" in={step === 0}>
              <Stack direction="column" gap="10px">
                <InputLabel>{t("Email address")}</InputLabel>
                <MRTextField
                  fullWidth
                  id="username"
                  name="username"
                  placeholder={t("Email")}
                  data-testid="username-input"
                  value={checkInviteResponse?.data.username}
                  disabled
                />

                <InputLabel sx={{ marginTop: "20px" }}>
                  {t("Choose password")}
                </InputLabel>
                <MRTextField
                  fullWidth
                  id="password"
                  name="password"
                  type="password"
                  placeholder={t("Password")}
                  data-testid="password-input"
                  onChange={formikStep1.handleChange}
                  value={formikStep1.values.password}
                  error={
                    formikStep1.touched.password &&
                    Boolean(formikStep1.errors.password)
                  }
                  helperText={
                    formikStep1.touched.password && formikStep1.errors.password
                  }
                />

                <MRTextField
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder={t("Confirm Password")}
                  data-testid="confirmPassword-input"
                  onChange={formikStep1.handleChange}
                  value={formikStep1.values.confirmPassword}
                  error={
                    formikStep1.touched.confirmPassword &&
                    Boolean(formikStep1.errors.confirmPassword)
                  }
                  helperText={
                    formikStep1.touched.confirmPassword &&
                    formikStep1.errors.confirmPassword
                  }
                />

                <MRButtonPrimary
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="next-button"
                  data-testid="next-button"
                  sx={{ bgcolor: `${colors.green} !important` }}
                >
                  {t("Next")} <ArrowForwardIcon />
                </MRButtonPrimary>
              </Stack>
            </Slide>
          </form>
        )}
        {step === 1 && (
          <form onSubmit={formikStep2.handleSubmit}>
            <Slide direction="right" in={step === 1}>
              <Stack direction="column" gap="10px" className="stakkkkk">
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={propicInput}
                  type="file"
                  onChange={(e) => uploadPic(e, "propic")}
                />

                <Badge
                  sx={{ width: "fit-content", margin: "auto" }}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <Avatar
                      onClick={() => propicInput?.current?.click()}
                      sx={{
                        width: 25,
                        height: 25,
                        bgcolor: colors.white,
                        cursor: "pointer",
                      }}
                      alt="Edit propic"
                      src={camera}
                    />
                  }
                >
                  <Avatar
                    src={propic}
                    style={{
                      margin: "10px auto",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                </Badge>

                <MRTextField
                  id="firstname"
                  name="firstname"
                  placeholder={t("Firstname")}
                  data-testid="firstname-input"
                  onChange={formikStep2.handleChange}
                  value={formikStep2.values.firstname}
                  error={
                    formikStep2.touched.firstname &&
                    Boolean(formikStep2.errors.firstname)
                  }
                  helperText={
                    formikStep2.touched.firstname &&
                    formikStep2.errors.firstname
                  }
                />

                <MRTextField
                  id="lastname"
                  name="lastname"
                  placeholder={t("Lastname")}
                  data-testid="lastname-input"
                  onChange={formikStep2.handleChange}
                  value={formikStep2.values.lastname}
                  error={
                    formikStep2.touched.lastname &&
                    Boolean(formikStep2.errors.lastname)
                  }
                  helperText={
                    formikStep2.touched.lastname && formikStep2.errors.lastname
                  }
                />

                <MRTextField
                  id="companyName"
                  name="companyName"
                  placeholder={t("Company name")}
                  data-testid="companyName-input"
                  onChange={formikStep2.handleChange}
                  value={formikStep2.values.companyName}
                  error={
                    formikStep2.touched.companyName &&
                    Boolean(formikStep2.errors.companyName)
                  }
                  helperText={
                    formikStep2.touched.companyName &&
                    formikStep2.errors.companyName
                  }
                />

                <MRTextField
                  id="companyField"
                  name="companyField"
                  placeholder={t("Company Field")}
                  data-testid="companyField-input"
                  onChange={formikStep2.handleChange}
                  value={formikStep2.values.companyField}
                  error={
                    formikStep2.touched.companyField &&
                    Boolean(formikStep2.errors.companyField)
                  }
                  helperText={
                    formikStep2.touched.companyField &&
                    formikStep2.errors.companyField
                  }
                />

                <MRTextField
                  id="vat"
                  name="vat"
                  placeholder={t("VAT")}
                  data-testid="vat-input"
                  onChange={formikStep2.handleChange}
                  value={formikStep2.values.vat}
                  error={
                    formikStep2.touched.vat && Boolean(formikStep2.errors.vat)
                  }
                  helperText={formikStep2.touched.vat && formikStep2.errors.vat}
                />

                {/* <MRTextField
                  id="country"
                  name="country"
                  placeholder={t("Country")}
                  data-testid="country-input"
                  onChange={formikStep2.handleChange}
                  value={formikStep2.values.country}
                  error={formikStep2.touched.country && Boolean(formikStep2.errors.country)}
                  helperText={formikStep2.touched.country && formikStep2.errors.country}
                /> */}

                <div>
                  <Autocomplete
                    disableClearable={true}
                    value={formikStep2.values.country}
                    onChange={(e, value) => {
                      formikStep2.setFieldValue(
                        "country",
                        countryWOFlag(value)
                      );
                    }}
                    options={jsonCountries.map(
                      (country) => country.emoji + " " + country.name
                    )}
                    isOptionEqualToValue={(option: any, value: string) => {
                      return countryWOFlag(option) === value;
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params: any) => (
                      <MRTextField
                        {...params}
                        error={
                          formikStep2.touched.country &&
                          Boolean(formikStep2.errors.country)
                        }
                      />
                    )}
                  />
                  <p style={{ margin: "0px" }}>
                    {formikStep2.touched.country &&
                      formikStep2.errors.country && (
                        <p style={{ color: "#f44336" }}>Country is required</p>
                      )}
                  </p>
                </div>

                <MRTextField
                  id="phone"
                  name="phone"
                  placeholder={t("Phone")}
                  data-testid="phone-input"
                  onChange={formikStep2.handleChange}
                  value={formikStep2.values.phone}
                  error={
                    formikStep2.touched.phone &&
                    Boolean(formikStep2.errors.phone)
                  }
                  helperText={
                    formikStep2.touched.phone && formikStep2.errors.phone
                  }
                />

                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  type="file"
                  ref={companyLogoInput}
                  onChange={(e) => uploadPic(e, "companyLogo")}
                />
                {!companyLogo ? (
                  <MRButtonSecondary
                    onClick={() => companyLogoInput?.current?.click()}
                    sx={{
                      borderColor: `${colors.green} !important`,
                      padding: "13px",
                    }}
                  >
                    {t("Upload company logo")}
                  </MRButtonSecondary>
                ) : (
                  <Stack
                    alignItems={"center"}
                    position={"relative"}
                    width={"fit-content"}
                    marginX={"auto"}
                    marginTop={"10px"}
                  >
                    <img
                      style={{
                        height: "100px",
                        width: "auto",
                        maxWidth: "300px",
                      }}
                      src={companyLogo}
                      alt={t("Company logo")}
                    />
                    <DeleteLogo
                      width={"20px"}
                      height={"20px"}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "-10px",
                        top: "-10px",
                      }}
                      onClick={handleDeleteCompanyLogo}
                    />
                  </Stack>
                )}

                <MRButtonPrimary
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="submit-button"
                  data-testid="submit-button"
                  loading={signupLoading}
                  sx={{
                    marginTop: "30px",
                    bgcolor: `${colors.green} !important`,
                  }}
                >
                  {t("Complete account")}
                </MRButtonPrimary>

                <MRButtonSecondary
                  variant="contained"
                  className="back-button"
                  data-testid="back-button"
                  onClick={() => setStep(0)}
                  sx={{ borderColor: `${colors.green} !important` }}
                >
                  <ArrowBackIcon /> {t("Back")}
                </MRButtonSecondary>
              </Stack>
            </Slide>
          </form>
        )}
      </Stack>
    </SignupFormWrapper>
  );
};

export default SignupForm;
