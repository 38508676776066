import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { Collection } from "types/collection";

interface CreateCollectionInfo {
  name: string;
  owner: string;
  blocks?: string[];
}

export default function useCreateCollection() {
  const queryClient = useQueryClient();

  const createCollection = (data: CreateCollectionInfo) => {
    const request: Request = {
      url: ENDPOINTS.collections.all,
      config: {
        method: "post",
        data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<Collection>,
    Error,
    CreateCollectionInfo
  >(createCollection, {
    onSettled: (data, error, id) => {
      queryClient.invalidateQueries([ENDPOINTS.collections.all]);
    },
  });

  return { data, mutate, ...rest };
}
