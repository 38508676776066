import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery } from "react-query";
import { Request } from "types/request";
import { Response } from "types/response";
import Error from "types/error";
import { AcquisitionUploadInfo } from "types/acquisition";
import { generatePath } from "react-router-dom";

export default function useAcquisition(id: string | undefined, parts: number) {
  const request: Request = {
    url: generatePath(ENDPOINTS.acquisitions.uploadInfo, { id }),
    config: {
      method: "GET",
      params: { parts },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.acquisitions.uploadInfo, id, parts],
    queryFn: () => client(request),
    enabled: !!id,
  };

  const { data, ...rest } = useQuery<Response<AcquisitionUploadInfo>, Error>(
    requestConfig
  );

  return { data, ...rest };
}

export const getAcqusitionUploadInfo = (id: string, parts: number) => {
  const request: Request = {
    url: generatePath(ENDPOINTS.acquisitions.uploadInfo, { id }),
    config: {
      method: "GET",
      params: { parts },
    },
  };

  return client(request);
};

export const getAcqusitionPhotosUploadInfo = (id: string, parts: number) => {
  const request: Request = {
    url: generatePath(ENDPOINTS.acquisitions.photosUploadInfo, { id }),
    config: {
      method: "GET",
      params: { parts },
    },
  };

  return client(request);
};
