import { FC, useState } from "react";
import { ContactTableProps } from "./types";
import { useTranslation } from "react-i18next";
import { TableWrapper } from "../Tables/styles";
import { MRTable } from "components/material";
import { TableCell, TableHead, TableRow, TableBody, Avatar, Dialog, IconButton } from "@mui/material";
import ContactRowActions from "./ContactRowActions";
import { stringAvatar } from "helpers";
import { colors } from "styles/theme";
import { Contact } from "types/user";
import { ButtonDialog } from "pages/profile/styles";
import ClearIcon from "@mui/icons-material/Clear";
import EditAliasForm from "./editAliasForm";
import jsonCountries from "assets/countries.json";

const ContactTable: FC<ContactTableProps> = ({ contacts, rowActionProps }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);

  return (
    <TableWrapper>
      <MRTable>
        <TableHead>
          <TableRow>
            <TableCell align="center">{}</TableCell>
            <TableCell align="center">{t("Alias")}</TableCell>
            <TableCell align="center">{t("E-mail")}</TableCell>
            <TableCell align="center">{t("Company name")}</TableCell>
            <TableCell align="center">{t("Country")}</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact) => (
            <TableRow key={contact.user}>
              <TableCell align="center">
                <Avatar src={contact.propicUrl} sx={{ margin: "auto" }}>
                  {contact.alias && stringAvatar(contact.alias)}
                </Avatar>
              </TableCell>
              <TableCell align="center">{contact.alias === "" || !contact.alias ? "-" : contact.alias}</TableCell>
              <TableCell align="center">{contact.email}</TableCell>
              <TableCell align="center">{contact.companyName}</TableCell>
              <TableCell align="center">
                {contact.country + " " + jsonCountries.find((el) => el.name === contact.country)?.emoji}
              </TableCell>
              <TableCell align="center">
                <ContactRowActions
                  contact={contact}
                  {...rowActionProps}
                  setOpenModal={setOpenModal}
                  setSelectedContact={setSelectedContact}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MRTable>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
          style: {
            background: colors.lightBlack,
            backgroundImage: "none",
            width: "100%",
            padding: "20px",
            border: `1px solid ${colors.white}`,
            borderRadius: "20px",
          },
        }}
      >
        <ButtonDialog>
          <div className="close-button-container">
            <IconButton onClick={() => setOpenModal(false)}>
              <ClearIcon />
            </IconButton>
          </div>
        </ButtonDialog>
        {selectedContact && <EditAliasForm contact={selectedContact} setOpenModal={setOpenModal} />}
      </Dialog>
    </TableWrapper>
  );
};

export default ContactTable;
