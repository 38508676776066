import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery } from "react-query";
import { Request } from "types/request";
import { Response } from "types/response";
import Error from "types/error";
import { AcquisitionMonthly } from "types/acquisition";

export default function useAcquisitionsMonthlyCount(search?: Record<string, string>) {
  const request: Request = {
    url: ENDPOINTS.acquisitions.monthlyCount,
    config: {
      method: "GET",
      params: { ...search },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.acquisitions.monthlyCount, search && JSON.stringify(search)],
    queryFn: () => client(request).then((res) => res.data),
  };

  const { data, ...rest } = useQuery<AcquisitionMonthly[], Error>(requestConfig);

  return { data, ...rest };
}
