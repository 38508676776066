import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery } from "react-query";
import { Request } from "types/request";
import { PaginatedResponse } from "types/response";
import Error from "types/error";
import { Transfer } from "types/transfer";

export default function useTransfers(page?: number, size?: number, search?: Record<string, string>) {
  const request: Request = {
    url: ENDPOINTS.transfers.all,
    config: {
      method: "GET",
      params: { page, size, ...search },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.transfers.all, page, size, search && JSON.stringify(search)],
    queryFn: () => client(request).then((res) => res.data),
  };

  const { data, ...rest } = useQuery<PaginatedResponse<Transfer[]>, Error>(requestConfig);

  return { data, ...rest };
}
