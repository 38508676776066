import { MRCard } from "components/material";
import { FC } from "react";
import { FallbackProps } from "react-error-boundary";
import { ErrorPage } from "components/ErrorFallbacks/styles";
import { ReactComponent as Logo } from "assets/icons/logo-white.svg";
import { Stack } from "@mui/material";
import { MRButtonPrimary } from "components/material/Button";
import { colors } from "styles/theme";
import i18n from "i18n";

const LinkFallBack: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  let message;
  switch (error.name) {
    case "LinkExpired":
      message = i18n.t("Sorry, the link is expired.");
      break;
    case "LinkNotFound":
      message = i18n.t("Sorry, the link does not appear to exist!");
      break;
    default:
      message = i18n.t("Ops, something went wrong!");
  }
  return (
    <ErrorPage>
      <MRCard className="card">
        <Stack alignItems="center">
          <Logo width="70px" height="70px" />
          <h3>{message}</h3>
          <MRButtonPrimary
            type="submit"
            sx={{
              bgcolor: `${colors.green} !important`,
              color: `${colors.black} !important`,
            }}
            onClick={resetErrorBoundary}
          >
            {i18n.t("Navigate to homepage")}
          </MRButtonPrimary>
        </Stack>
      </MRCard>
    </ErrorPage>
  );
};

export default LinkFallBack;
