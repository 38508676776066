import { FC } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { MRCheckbox, MRTable } from "components/material";
import AcquisitionStatusCard from "./AcquisitionStatusCard";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AcquisitionTableProps } from "./types";
import { TableWrapper } from "../Tables/styles";
import AcquisitionAdminRowActions from "./AcquisitionAdminRowActions";
import AcquisitionUserRowActions from "./AcquisitionUserRowActions";
import ROLE from "types/role";
import { Link, generatePath } from "react-router-dom";
import { AcquisitionStatus, Priority } from "types/acquisition";
import { TableColumn } from "../Tables/types";
import useEditAcquisitionInfo from "hooks/services/acquisitions/useEditAcquisitionInfo";
import { AcquisitionTableWrapper } from "./styles";

const AcquisitionTable: FC<AcquisitionTableProps> = ({
  acquisitions,
  selected,
  toggleAll,
  toggleSelection,
  userRole,
  order,
  orderBy,
  sortHandler,
}) => {
  const { t } = useTranslation();

  const {
    mutate: editAcquisitionInfo,
    isLoading: loadingEditAcquisitionInfo,
    error: editAcquisitionInfoError,
    isSuccess: isSuccessEditAcquisitionInfo,
  } = useEditAcquisitionInfo();

  const adminToggledAll =
    selected.length === acquisitions.length && selected.length !== 0;

  const userToggledAll =
    selected.length ===
      acquisitions.filter(
        (acquisition) =>
          acquisition.status === AcquisitionStatus.IDLE ||
          acquisition.status === AcquisitionStatus.PENDING_PROCESSING
      ).length && selected.length !== 0;

  const columns: TableColumn[] = [
    {
      name: "title",
      label: t("Title"),
      sortable: true,
    },
    {
      name: "_id",
      label: t("Date"),
      sortable: true,
    },
    ...(userRole === ROLE.ADMIN || userRole === ROLE.OPERATOR
      ? [{ name: "ownerName", label: t("Owner"), sortable: true }]
      : []),
    {
      name: "placeOfAcquisition",
      label: t("Place of acquisition"),
      sortable: true,
    },
    {
      name: "dimension",
      label: t("Dimension"),
    },
    {
      name: "numberOfPhotos",
      label: t("Num of photos"),
      sortable: true,
    },
    {
      name: "priority",
      label: t("Priority"),
      sortable: true,
    },
    {
      name: "status",
      label: t("Status"),
      sortable: true,
    },
  ];

  return (
    <AcquisitionTableWrapper>
      <TableWrapper>
        <MRTable>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <MRCheckbox
                  checked={
                    userRole === ROLE.ADMIN || userRole === ROLE.OPERATOR
                      ? adminToggledAll
                      : userToggledAll
                  }
                  onChange={() => {
                    if (
                      userRole &&
                      (userRole === ROLE.ADMIN || userRole === ROLE.OPERATOR)
                    ) {
                      toggleAll(
                        acquisitions.map((acquisition) => acquisition._id) || []
                      );
                      return;
                    }
                    toggleAll(
                      acquisitions
                        .filter(
                          (acquisition) =>
                            acquisition.status === AcquisitionStatus.IDLE ||
                            acquisition.status ===
                              AcquisitionStatus.PENDING_PROCESSING
                        )
                        .map((acquisition) => acquisition._id) || []
                    );
                  }}
                />
              </TableCell>
              {columns.map((column) =>
                column.sortable ? (
                  <TableCell
                    key={column.name}
                    sortDirection={orderBy === column.name ? order : false}
                    align="center"
                  >
                    <TableSortLabel
                      active={orderBy === column.name}
                      direction={orderBy === column.name ? order : "asc"}
                      onClick={() => sortHandler(column.name)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell key={column.name} align="center">
                    {column.label}
                  </TableCell>
                )
              )}
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {acquisitions.map((acquisition, idx) => (
              <TableRow key={acquisition._id}>
                <TableCell align="center">
                  {((userRole &&
                    (userRole === ROLE.ADMIN || userRole === ROLE.OPERATOR)) ||
                    acquisition.status === AcquisitionStatus.IDLE ||
                    acquisition.status ===
                      AcquisitionStatus.PENDING_PROCESSING) && (
                    <MRCheckbox
                      checked={selected.includes(acquisition._id)}
                      onChange={() => toggleSelection(acquisition._id)}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  <Link
                    style={{ cursor: "pointer" }}
                    to={`/models-to-process/${acquisition._id}`}
                  >
                    {acquisition.title}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  {moment(acquisition.createdAt).format("D MMM YYYY")}
                </TableCell>
                {userRole === ROLE.ADMIN && (
                  <TableCell align="center">
                    <Link
                      style={{ cursor: "pointer" }}
                      to={generatePath("/users/:id/profile", {
                        id: acquisition.owner,
                      })}
                    >
                      {acquisition.ownerName}
                    </Link>
                  </TableCell>
                )}
                <TableCell align="center">
                  {acquisition.placeOfAcquisition}
                </TableCell>
                <TableCell align="center">
                  {acquisition.length +
                    "x" +
                    acquisition.height +
                    "x" +
                    acquisition.depth}
                </TableCell>
                <TableCell align="center">
                  {acquisition.numberOfPhotos}{" "}
                </TableCell>
                <TableCell align="center">
                  <Select
                    labelId="priority"
                    id="priority"
                    className="priority-select"
                    disabled={
                      acquisition.status !== AcquisitionStatus.IDLE &&
                      acquisition.status !==
                        AcquisitionStatus.PENDING_PROCESSING
                    }
                    onChange={(e) => {
                      editAcquisitionInfo({
                        id: acquisition._id,
                        priority: e.target.value as Priority,
                      });
                    }}
                    value={acquisition.priority || 1}
                  >
                    <MenuItem value={Priority.LOW}>{t("Low")}</MenuItem>
                    <MenuItem value={Priority.MEDIUM}>{t("Medium")}</MenuItem>
                    <MenuItem value={Priority.HIGH}>{t("High")}</MenuItem>
                  </Select>
                </TableCell>
                <TableCell align="center">
                  <AcquisitionStatusCard status={acquisition.status} />
                </TableCell>
                <TableCell align="center" className="table-icon-container">
                  {userRole === ROLE.ADMIN ? (
                    <AcquisitionAdminRowActions id={acquisition._id} />
                  ) : (
                    (acquisition.status === AcquisitionStatus.IDLE ||
                      acquisition.status ===
                        AcquisitionStatus.PENDING_PROCESSING) && (
                      <AcquisitionUserRowActions id={acquisition._id} />
                    )
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MRTable>
      </TableWrapper>
    </AcquisitionTableWrapper>
  );
};

export default AcquisitionTable;
