import { Stack } from "@mui/material";
import useAuth from "hooks/useAuth";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import ROLE from "types/role";
import { UserCreditsWrapper } from "./styles";
import { useNavigate } from "react-router-dom";

const UserCredits: FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!user || user.roles !== ROLE.SELLER) return <></>;

  return (
    <UserCreditsWrapper>
      <Stack direction="column" className="cta-credits">
        {user?.credits} {t("Credits")}
        <span
          className="buy-credits-container"
          onClick={() => navigate("/contact-us")}
        >
          {t("Buy credits")}
        </span>
      </Stack>
    </UserCreditsWrapper>
  );
};

export default UserCredits;
