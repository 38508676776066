import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";

export default function useRemoveShare() {
  const queryClient = useQueryClient();

  const removeShare = (id: string) => {
    const request: Request = {
      url: generatePath(ENDPOINTS.shares.remove, { id }),
      config: {
        method: "patch",
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<null>, Error, string>(removeShare, {
    onSettled: (data, error, id) => {
      queryClient.invalidateQueries([ENDPOINTS.shares.all]);
      queryClient.invalidateQueries([ENDPOINTS.shares.block]);
      queryClient.invalidateQueries([ENDPOINTS.shares.one, id]);
    },
  });

  return { data, mutate, ...rest };
}
