import ForgotPassword from "./views/forgot";
import ResetPassword from "./views/reset";

const routes = [
  {
    path: "forgot",
    name: "Forgot Password",
    element: <ForgotPassword />,
  },
  {
    path: "reset",
    name: "Reset Password",
    element: <ResetPassword />,
  },
];

export default routes;
