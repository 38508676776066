import { Stack } from "@mui/material";
import styled from "styled-components";

export const LockedWrapper = styled(Stack)`
  width: 100%;
  height: 100%;

  min-height: 200px;
  border-radius: 5px;

  padding: 40px 20px;

  h2 {
    margin-bottom: 0px !important;
  }
`;
