import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { Request } from "types/request";

export interface CompleteUploadPayload {
  Key: string;
  MultipartUpload: {
    Parts: { ETag: string; PartNumber: number }[];
  };
  UploadId: string;
}

export const completeUpload = (data: CompleteUploadPayload) => {
  const request: Request = {
    url: ENDPOINTS.s3.completeUpload,
    config: {
      method: "PUT",
      data,
    },
  };

  return client(request);
};

export const completeUploadPhotos = (data: CompleteUploadPayload) => {
  const request: Request = {
    url: ENDPOINTS.s3.completeUploadPhotos,
    config: {
      method: "PUT",
      data,
    },
  };

  return client(request);
};
