import { NavigationItem } from "../types";
import i18n from "i18n";
import PATH from "routing/path";

import HomeIcon from "@mui/icons-material/Home";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

export const mobileBottomNavigation: NavigationItem[] = [
  {
    name: "home",
    label: i18n.t("Home"),
    path: PATH.DASHBOARD,
    icon: <HomeIcon />,
  },
  {
    name: "models",
    label: i18n.t("Models"),
    path: PATH.MODELS,
    icon: <ViewInArIcon />,
  },
  {
    name: "collections",
    label: i18n.t("Catalogues"),
    path: PATH.CATALOGUES,
    icon: <FolderOpenIcon />,
  },
  {
    name: "Profile",
    label: i18n.t("Profile"),
    path: PATH.PROFILE,
    icon: <PermIdentityIcon />,
  },
];
