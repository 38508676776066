import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Stack, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Dialog } from "components/material/Dialog";
import { EditCollectionModalWrapper } from "./styles";
import { AddBlocksModalProps } from "./types";
import { MRButtonquaternary } from "components/material/Button";
import useEditCollection from "hooks/services/collections/useEditCollection";
import toast from "react-hot-toast";
import CollectionBlocksList from "./CollectionBlocksList";
import { colors } from "styles/theme";

const EditCollectionModal: FC<AddBlocksModalProps> = ({
  openModal,
  setOpenModal,
  collection,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>(collection.name);
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    if (openModal) setSelected(collection.blocks.map((b) => b._id));
  }, [collection, openModal]);

  const { mutateAsync: editCollection, isLoading: editCollectionIsLoading } =
    useEditCollection();

  const handleClose = (e: object, reason: string) => {
    if (reason === "escapeKeyDown") setOpenModal(false);
  };

  const handleUpdateCollection = () => {
    editCollection({
      id: collection._id,
      name,
      blocks: selected,
    })
      .then(() => {
        toast(t("Collection updated successfully"));
      })
      .catch(() => {
        toast(
          t(
            "An error occured while updating the collection, please try again later."
          )
        );
      })
      .finally(() => {
        setOpenModal(false);
      });
  };

  return (
    <EditCollectionModalWrapper>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="create-collection-modal"
        PaperProps={{
          style: {
            background: colors.lightBlack,
            backgroundImage: "none",
            width: "100%",
            padding: "20px",
            border: `1px solid ${colors.white}`,
            borderRadius: "20px",
          },
        }}
      >
        <Stack direction="row" justifyContent={"space-between"}>
          <h2>{t("Update your catalogue")}</h2>

          <div className="close-button-container">
            <IconButton onClick={(e) => handleClose(e, "escapeKeyDown")}>
              <ClearIcon sx={{ color: colors.green }} />
            </IconButton>
          </div>
        </Stack>

        <Stack direction="column" gap={4}>
          <TextField
            placeholder={t("Insert your catalog name")}
            value={name}
            onChange={(e: any) => setName(e.target.value)}
          />

          <CollectionBlocksList selected={selected} setSelected={setSelected} />

          <MRButtonquaternary
            classes={{
              root: "next-button-root",
              disabled: "next-button-disabled",
            }}
            loading={editCollectionIsLoading}
            onClick={handleUpdateCollection}
          >
            {t("Update Catalogue")}
          </MRButtonquaternary>
        </Stack>
      </Dialog>
    </EditCollectionModalWrapper>
  );
};

export default EditCollectionModal;
