import styled from "styled-components";
import { colors } from "styles/theme";

export const UserStatusCardWrapper = styled.div`
  .active-card {
    color: ${colors.green};
  }
  .invited-card {
    color: ${colors.primary};
  }
  .blocked-card {
    color: ${colors.red};
  }

  .active-card,
  .invited-card,
  .blocked-card {
    background: ${colors.lighterBlack};
    padding: 5px 10px;
    border-radius: 15px;
  }
`;

export const UserTableWrapper = styled.div`
  .pro-tag {
    color: ${colors.green};
  }

  .admin-tag {
    color: ${colors.primary};
  }
`;
