import { FC, useState } from "react";
import { CSSObject, IconButton, styled, Theme } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import Sidenav from "components/Navs/Sidenav";
import { MiniDrawerProps } from "./types";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const MiniDrawer: FC<MiniDrawerProps> = ({ drawerWidth, mini, handleMiniClose, handleMiniOpen }) => {
  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    position: "fixed",
    zIndex: 99999,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    //width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": {
        ...openedMixin(theme),
        boxShadow: "15px 1px 26px -21px rgba(45,181,199,0.75)",
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  return (
    <Drawer
      // Mini drawer (collapsable)
      variant="permanent"
      sx={{
        display: { xs: "none", sm: "block", md: "block", lg: "none" },
        "& .MuiDrawer-paper": { boxSizing: "border-box" },
      }}
      open={mini}
    >
      {mini === true ? (
        <IconButton
          onClick={handleMiniClose}
          sx={{
            width: "none",
            alignSelf: "start",
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      ) : (
        <IconButton
          onClick={handleMiniOpen}
          sx={{
            width: "none",
            alignSelf: "center",
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      )}

      <Sidenav open={mini} />
    </Drawer>
  );
};

export default MiniDrawer;
