import { FC, useState } from "react";
import MainNavigation from "../Navigation/MainNavigation";
import { SidenavWrapper } from "./styles";
import Logo from "assets/icons/logo.svg";
import useAuth from "hooks/useAuth";
import BottomNavigation from "../Navigation/BottomNavigation";
import { SidenavProps } from "./types";

const Sidenav: FC<SidenavProps> = ({ open }) => {
  const { user, logout } = useAuth();
  const [active, setActive] = useState<string>("");

  return (
    <SidenavWrapper>
      <div className="sidenav">
        <div className="sidenav-main">
          <div className={open === false ? "logo mini-logo" : "logo"}>
            <img src={Logo} alt="Marmoreal Logo" />
          </div>
          <MainNavigation active={active} setActive={setActive} open={open} />
        </div>
        <div className="sidenav-bottom">
          <BottomNavigation active={active} setActive={setActive} open={open} />
        </div>
      </div>
    </SidenavWrapper>
  );
};

export default Sidenav;
