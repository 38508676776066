import { Grid, Stack, Tab, Tabs } from "@mui/material";
import useMultiSelect from "hooks/useMultiSelect";
import { FC, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import BlockUserTable from "components/TablesComponents/Block/BlockUserTable";
import { MRPagination, MRDrawer, MRTextField } from "components/material";
import { PAGE_SIZE } from "config/pagination";
import ROLE from "types/role";
import useSharedBlocks from "hooks/services/blocks/useSharedBlocks";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import { Inputs as BlockFilters } from "components/Block/BlockFilterForm/model";
import BlockFilterForm from "components/Block/BlockFilterForm";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";
import useInfiniteSharedBlocks from "hooks/services/shares/useInfiniteSharedBlocks";
import useToasts from "hooks/useToasts";
import { colors } from "styles/theme";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import { flattenPages } from "helpers";
import React from "react";
import BlockGridElem from "components/Block/BlockGridElem";
import BlockListElem from "components/Block/BlockListElem";
import Loader from "components/Loader";
import { useDebounce } from "use-debounce";
import { search } from "assets/icons";

const SharedModels: FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState<BlockFilters | null>(null);

  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("_id");

  const [view, setView] = useState<"list" | "grid">("grid");

  const [text, setText] = useState<string>("");
  const [debouncedText] = useDebounce(text, 500);

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data: blocks,
    isLoading: blocksLoading,
    isError: blocksError,
  } = useSharedBlocks(page, PAGE_SIZE, {
    text: debouncedText,
    ...(formattedFilters && formattedFilters),
  });

  const sortHandler = (orderBy: string) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(orderBy);
  };

  if (blocksError) throw new Error("Cannot get block");

  const { selected, toggleSelection, toggleAll, resetSelection } =
    useMultiSelect();

  const {
    data: pages,
    isLoading: isLoadingBlocks,
    error: blocksError2,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteSharedBlocks({
    query: { text: debouncedText },
    size: PAGE_SIZE,
  });

  const handleScroll = (e: any) => {
    if (
      window.innerHeight + window.scrollY >= document.body.scrollHeight - 2 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  };

  window.addEventListener("scroll", handleScroll, true);

  useToasts([
    {
      severity: "error",
      message: t("There was an error. Cannot fetch shared models."),
      condition: !!blocksError,
    },
  ]);

  return (
    <>
      <MRDrawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={toggleDrawer(false, setFilterDrawerOpen)}
      >
        <BlockFilterForm
          hideStatus
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </MRDrawer>
      <Stack
        direction="row"
        gap={2}
        mb={2}
        justifyContent="space-between"
        alignItems="center"
        alignContent={"center"}
      >
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          alignContent={"center"}
        >
          <FilterButton
            onClick={() => setFilterDrawerOpen(true)}
            activeFilters={
              formattedFilters ? Object.keys(formattedFilters).length : 0
            }
          />

          <MRTextField
            placeholder={t("Type your block name")}
            className="search-blocks"
            value={text}
            onChange={(e) => setText(e.target.value)}
            InputProps={{
              endAdornment: <img src={search} alt="search" />,
            }}
            sx={{
              background: colors.lightBlack,
            }}
          />
        </Stack>

        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          alignContent={"center"}
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          <Tabs
            value={view}
            onChange={(e, value) => setView(value)}
            variant="scrollable"
            scrollButtons={false}
            className="view-selector"
            TabIndicatorProps={{ style: { background: colors.green } }}
          >
            <Tab icon={<GridViewIcon />} value="grid" />
            <Tab icon={<ViewListIcon />} value="list" />
          </Tabs>
        </Stack>
      </Stack>

      {view === "grid" && (
        <>
          <Grid container spacing={2}>
            {flattenPages(pages).map((block, idx) => (
              <React.Fragment key={block._id}>
                <Grid
                  item
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <BlockGridElem block={block} variant="shared" />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ display: { xs: "block", sm: "none" } }}
                >
                  <BlockListElem block={block} variant="shared" />
                </Grid>
              </React.Fragment>
            ))}
            {isFetchingNextPage && <Loader />}
          </Grid>

          {flattenPages(pages).length === 0 && (
            <Stack
              direction="row"
              justifyContent={"center"}
              alignItems="center"
            >
              <p>{t("There are no shared blocks")}</p>
            </Stack>
          )}
        </>
      )}

      {view === "list" && (
        <>
          <BlockUserTable
            blocks={blocks?.data || []}
            selected={selected}
            toggleSelection={toggleSelection}
            toggleAll={toggleAll}
            variant="shared"
            userRole={ROLE.SELLER}
            order={order}
            orderBy={orderBy}
            sortHandler={sortHandler}
          />
          <Stack justifyContent="center" alignItems="center">
            {blocks?.data && blocks.data.length ? (
              <MRPagination
                count={
                  blocks
                    ? Math.ceil(blocks?.pagination[0]?.total / PAGE_SIZE) || 1
                    : 1
                }
                page={page}
                onChange={(e, page) => setPage(page)}
              />
            ) : (
              <NoDataRow>{t("No Data")}</NoDataRow>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export default SharedModels;
