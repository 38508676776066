import { styled, TextField } from "@mui/material";
import { colors } from "styles/theme";

const MRTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    background: ${colors.black};
    border-radius: 5px;
  }

  Fieldset {
    border: none;
  }
`;

export default MRTextField;
