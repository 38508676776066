import LinkFallBack from "./LinkFallBack";
import Page from "components/Layout/Page";
import Loader from "components/Loader";
import useBlockFromLinkCode from "hooks/services/links/useBlockFromLinkCode";
import { FC } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router-dom";
import PATH from "routing/path";
import SingleBlock from "components/Block/SingleBlock";
import { windowReplace } from "utils/helpers";
import { Container } from "@mui/material";

const Link: FC = () => {
  const { code } = useParams<{ code: string }>();

  if (!code) throw new Error("Invalid or missing code");

  const {
    data: block,
    isLoading: isLoadingBlock,
    error: blockError,
  } = useBlockFromLinkCode(code);

  if (blockError) throw (blockError as any)?.response.data;

  if (isLoadingBlock) return <Loader />;

  return (
    <Container>
      <Page>{block && <SingleBlock block={block.data} fromLink={code} />}</Page>
    </Container>
  );
};

const ErrorBoundedLink: FC = () => {
  return (
    <ErrorBoundary
      FallbackComponent={LinkFallBack}
      onReset={() => windowReplace(PATH.HOME)}
    >
      <Link />
    </ErrorBoundary>
  );
};

export default ErrorBoundedLink;
