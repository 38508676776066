export interface Link {
  _id: string;
  user: string;
  block: string;
  expireDate: Date;
  code: string;
  status: LinkStatus;
  username: string;
  blockTitle: string;
}

export enum LinkStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum Expiry {
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}
