import * as Yup from "yup";

export const loginValidationSchema = Yup.object({
  username: Yup.string().email("Enter a valid email").required("Email is required"),
  password: Yup.string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const loginInitialValues = {
  username: "",
  password: "",
};
