import { FC, useMemo } from "react";
import { RowActionProps, RowAction } from "../RowActions/types";
import useDeleteShare from "hooks/services/shares/useDeleteShare";
import RowActionsBase from "../RowActions/RowActionsBase";
import { trash2 } from "assets/icons";
import { useTranslation } from "react-i18next";

const ShareRowActions: FC<RowActionProps> = ({ id }) => {
  const {
    mutate: deleteShare,
    isLoading: isDeletingShare,
    isSuccess: isDeletedShare,
    error: deleteShareError,
  } = useDeleteShare();

  const { t } = useTranslation();

  const actions: RowAction[] = useMemo(
    () => [
      {
        icon: trash2,
        action: () => deleteShare(id),

        confirmMessage: t("Are you sure you want to delete this share?"),
        actionState: {
          isLoading: isDeletingShare,
          isSuccess: isDeletedShare,
          successMessage: t("Share deleted successfully"),
          isError: !!deleteShareError,
          errorMessage: t(
            "An error occurred while deleting the share, please try again"
          ),
        },
      },
    ],
    [deleteShare, isDeletingShare, isDeletedShare, deleteShareError]
  );

  return <RowActionsBase variant="icons" actions={actions} />;
};

export default ShareRowActions;
