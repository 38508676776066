import { NavigationItem } from "../types";
import i18n from "i18n";
import PATH from "routing/path";

import HomeIcon from "@mui/icons-material/Home";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

export const mobileAdminNavigation: NavigationItem[] = [
  {
    name: "home",
    label: i18n.t("Home"),
    path: PATH.DASHBOARD,
    icon: <HomeIcon />,
  },
  {
    name: "users",
    label: i18n.t("Users"),
    path: PATH.USERS,
    icon: <PermIdentityIcon />,
  },
  {
    name: "collections",
    label: i18n.t("Catalogues"),
    path: PATH.CATALOGUES,
    icon: <FolderOpenIcon />,
  },
  {
    name: "models",
    label: i18n.t("Acquisitions"),
    path: PATH.MODELS_TO_PROCESS,
    icon: <ViewInArIcon />,
  },
  {
    name: "models",
    label: i18n.t("Models"),
    path: PATH.PROCESSED_MODELS,
    icon: <ViewInArIcon />,
  },
];
