import { FC, useMemo } from "react";
import { RowActionProps, RowAction } from "../RowActions/types";
import useRemoveTransfer from "hooks/services/transfers/useRemoveTransfer";
import RowActionsBase from "../RowActions/RowActionsBase";
import { trash2 } from "assets/icons";
import { useTranslation } from "react-i18next";

const TransferRowActions: FC<RowActionProps> = ({ id }) => {
  const {
    mutate: deleteTransfer,
    isLoading: isDeletingTransfer,
    isSuccess: isDeletedTransfer,
    error: deleteTransferError,
  } = useRemoveTransfer();

  const { t } = useTranslation();

  const actions: RowAction[] = useMemo(
    () => [
      {
        icon: trash2,
        action: () => deleteTransfer(id),

        confirmMessage: t("Are you sure you want to delete this transfer?"),
        actionState: {
          isLoading: isDeletingTransfer,
          isSuccess: isDeletedTransfer,
          successMessage: t("Transfer deleted successfully"),
          isError: !!deleteTransferError,
          errorMessage: t(
            "An error occurred while deleting the transfer, please try again"
          ),
        },
      },
    ],
    [deleteTransfer, isDeletingTransfer, isDeletedTransfer, deleteTransferError]
  );

  return <RowActionsBase variant="icons" actions={actions} />;
};

export default TransferRowActions;
