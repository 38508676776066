import Loader from "components/Loader";
import { FC, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Viewer3D from "components/Viewer3D";
import useBlock from "hooks/services/blocks/useBlock";
import useBlockOwnerInfo from "hooks/services/blocks/useBlockOwnerInfo";
import useBlockFromLinkCode from "hooks/services/links/useBlockFromLinkCode";
import { Block } from "types/block";

const BlockViewer: FC = () => {
  const { id } = useParams<{ id: string }>();

  const [block, setBlock] = useState<Block | null | undefined>(null);
  const [searchParams] = useSearchParams();
  const linkCode = searchParams.get("linkCode") ?? "";

  if (!id) throw new Error("This component require param :id");

  const {
    isLoading: blockOwnerInfoLoading,
    refetch: getBlockOwnerInfo,
    error: blockOwnerInfoError,
  } = useBlockOwnerInfo(id, false);

  const {
    isLoading: blockFromLinkLoading,
    refetch: getBlockFromLink,
    error: blockFromLinkError,
  } = useBlockFromLinkCode(linkCode, false);

  useEffect(() => {
    const getBlock = async () => {
      if (linkCode) {
        getBlockFromLink().then((res) => {
          setBlock(res.data?.data);
        });
      } else {
        getBlockOwnerInfo().then((res) => {
          setBlock(res.data?.data);
        });
      }
    };

    getBlock();
  }, []);

  if (blockOwnerInfoError || blockFromLinkError) throw new Error();

  if (blockOwnerInfoLoading || blockFromLinkLoading) return <Loader />;

  return (
    <>
      {block ? (
        <Viewer3D
          modelSrc={block.model.url}
          height={window.innerHeight}
          width={window.innerWidth}
          companyLogo={block.ownerProfile?.companyLogoUrl}
          modelName={block.title}
          material={block.material}
          dimension={block.height.toString()+"cm x "+block.length.toString()+"cm x "+block.depth.toString()+"cm"}
        />
      ) : (
        <></>
      )}
      ;
    </>
  );
};

export default BlockViewer;
