import Contacts from "./views/all";
import Requests from "./views/requests";

const routes = [
  {
    index: true,
    name: "Contacts",
    element: <Contacts />,
  },
  {
    path: ":id/*",
    name: "Catalogue",
    element: <Requests />,
  },
];

export default routes;
