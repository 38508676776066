import { FC } from "react";
import { Navigate, useParams } from "react-router-dom";
import PATH from "routing/path";

const AcceptTransfer: FC = () => {
  const { id } = useParams();

  if (id) return <Navigate to={PATH.DASHBOARD} state={{ transferId: id }} replace />;

  return <Navigate to={PATH.DASHBOARD} replace />;
};

export default AcceptTransfer;
