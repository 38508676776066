import { colors } from "styles/theme";
import styled from "styled-components";
import { Stack } from "@mui/material";
import { hexOpacity } from "helpers";

export const UsersWrapper = styled.div``;

export const InviteUserWrapper = styled(Stack)`
  background: ${colors.lightBlack};
  padding: 10px 35px;

  label {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .bandwidth-wrapper {
    margin: 10px auto;
    gap: 10px;
  }

  .bandwidth-wrapper .MuiOutlinedInput-root {
    height: 30px;
    border-radius: 3px;
  }

  .close-drawer {
    display: flex;
    padding: 7px;
    background: ${hexOpacity(colors.blue, 0.2)};
    color: ${hexOpacity(colors.green, 0.8)};
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const StatusCardWrapper = styled.div`
  .active-card {
    color: ${colors.green};
  }
  .invited-card {
    color: ${colors.primary};
  }
  .blocked-card {
    color: ${colors.red};
  }

  .active-card,
  .invited-card,
  .blocked-card {
    background: ${colors.lighterBlack};
    padding: 5px 10px;
    border-radius: 15px;
  }
`;
