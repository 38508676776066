import BlockViewer from "./views/block";

const routes = [
  {
    index: true,
    element: <>Hello</>,
  },
  {
    path: "block/:id",
    name: "Block Viewer",
    element: <BlockViewer />,
  },
];

export default routes;
