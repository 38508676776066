import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { UserInfoInputs } from "types/user";
import useAuth from "hooks/useAuth";

export default function useSellerEditUserProfile() {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const sellerEditUserProfile = (updateProfile: UserInfoInputs) => {
    const { status, roles, ...profile } = updateProfile;
    const request: Request = {
      url: ENDPOINTS.users.profile,
      config: {
        method: "patch",
        data: {
          ...profile,
        },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<null>, Error, UserInfoInputs>(sellerEditUserProfile, {
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.users.all]);
      queryClient.invalidateQueries([ENDPOINTS.users.one, user?._id]);
    },
  });

  return { data, mutate, ...rest };
}
