import { FC, useMemo, useState } from "react";
import useInfiniteCollection from "hooks/services/collections/useInfiniteCollections";
import { PAGE_SIZE } from "config/pagination";
import CollectionsGrid from "./CollectionsGrid";
import { Stack, Tab, Tabs } from "@mui/material";
import { Inputs as CollectionFilters } from "components/Collection/CollectionFilterForm/model";
import CollectionFilterForm from "components/Collection/CollectionFilterForm";
import { MRDrawer, MRPagination, MRTextField } from "components/material";
import { useTranslation } from "react-i18next";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import { colors } from "styles/theme";
import MRCollectionTable from "components/TablesComponents/Collection/CollectionsTable";
import useAuth from "hooks/useAuth";
import useMultiSelect from "hooks/useMultiSelect";
import useCollections from "hooks/services/collections/useCollections";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import { useDebounce } from "use-debounce";
import { search } from "assets/icons";
import CollectionBulkActionsControl from "components/TablesComponents/Collection/CollectionBulkActionsControl";

const MyCatalogues: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState<CollectionFilters | null>(null);
  const [page, setPage] = useState(1);

  const [view, setView] = useState<"list" | "grid">("grid");

  const { selected, toggleSelection, toggleAll, resetSelection } =
    useMultiSelect();

  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("_id");

  const [text, setText] = useState<string>("");
  const [debouncedText] = useDebounce(text, 500);

  const formattedFilters = useMemo(() => {
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data,
    isLoading,
    isSuccess,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteCollection({
    size: PAGE_SIZE,
    ...((formattedFilters || debouncedText) && {
      query: { ...formattedFilters, name: debouncedText },
    }),
  });

  const sortHandler = (orderBy: string) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(orderBy);
  };

  const {
    data: collections,
    isLoading: loadingCollections,
    isError: collectionsError,
  } = useCollections({
    page,
    size: PAGE_SIZE,
    order,
    sort: orderBy,
    owner: user?._id,
    name: debouncedText,
    ...(formattedFilters && formattedFilters),
  });

  return (
    <>
      <MRDrawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={toggleDrawer(false, setFilterDrawerOpen)}
      >
        <CollectionFilterForm
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </MRDrawer>

      <Stack
        direction="row"
        gap={2}
        mt={1}
        mb={4}
        justifyContent="space-between"
        alignItems="center"
        alignContent={"center"}
      >
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          alignContent={"center"}
        >
          <FilterButton
            onClick={() => setFilterDrawerOpen(true)}
            activeFilters={
              formattedFilters ? Object.keys(formattedFilters).length : 0
            }
          />

          <MRTextField
            placeholder={t("Type your collection name")}
            className="search-collections"
            value={text}
            onChange={(e) => setText(e.target.value)}
            InputProps={{
              endAdornment: <img src={search} alt="search" />,
            }}
            sx={{
              "& .MuiInputBase-root": {
                height: "42px",
              },
              background: colors.lightBlack,
            }}
          />
        </Stack>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          alignContent={"center"}
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          {view === "list" && (
            <CollectionBulkActionsControl
              selectedIds={selected}
              resetSelection={resetSelection}
              userRole={user?.roles}
            />
          )}
          <Tabs
            value={view}
            onChange={(e, value) => setView(value)}
            variant="scrollable"
            scrollButtons={false}
            className="view-selector"
            TabIndicatorProps={{ style: { background: colors.green } }}
          >
            <Tab icon={<GridViewIcon />} value="grid" />
            <Tab icon={<ViewListIcon />} value="list" />
          </Tabs>
        </Stack>
      </Stack>

      {view === "grid" && (
        <CollectionsGrid
          data={data}
          isLoading={isLoading}
          isSuccess={isSuccess}
          error={error}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          variant="owned"
        />
      )}

      {view === "list" && user && (
        <>
          <MRCollectionTable
            collections={collections?.data || []}
            selected={selected}
            toggleSelection={toggleSelection}
            toggleAll={toggleAll}
            order={order}
            orderBy={orderBy}
            sortHandler={sortHandler}
          />
          <Stack direction="row" justifyContent="center">
            {collections?.data && collections.data.length ? (
              <MRPagination
                count={
                  collections
                    ? Math.ceil(
                        collections?.pagination[0]?.total / PAGE_SIZE
                      ) || 1
                    : 1
                }
                page={page}
                onChange={(e, page) => setPage(page)}
              />
            ) : (
              <NoDataRow>{t("No Data")}</NoDataRow>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export default MyCatalogues;
