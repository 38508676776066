import styled from "styled-components";
import { colors } from "styles/theme";

export const FilterButtonWrapper = styled.div`
  position: relative;

  .filter-conter {
    position: absolute;
    border-radius: 10px;
    height: 20px;
    width: 20px;
    background-color: ${colors.green};
    top: -5px;
    right: -5px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.black};
  }
`;
