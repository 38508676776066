import { Box } from '@mui/material'

export default function NavBar(props: any) {

  return (
    <Box
      component={"div"}
      sx={{
        display: "flex",
        position: "fixed",
        top: 0,
        zIndex: "1200",
        alignContents: "center"
      }}
      width={"100%"}
      height={props.height}
      style={{backgroundColor: "#060B0C"}}
    >
      {props.children}
    </Box>
  )
}