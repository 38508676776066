import AuthGuard from "routing/AuthGuard";
import ROLE from "types/role";
import ProcessedModels from "./views/all";
import ProcessedModel from "./views/edit";

const routes = [
  {
    index: true,
    name: "Processed Models",
    element: (
      <AuthGuard roles={[ROLE.ADMIN, ROLE.OPERATOR]}>
        <ProcessedModels />
      </AuthGuard>
    ),
  },
  {
    path: ":id",
    name: "Model Detail",
    element: <ProcessedModel />,
  },
];

export default routes;
