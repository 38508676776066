import { Grid, Stack } from "@mui/material";
import useInfiniteBlocks from "hooks/services/blocks/useInfiniteBlocks";
import useToasts from "hooks/useToasts";
import React, { useMemo } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import BlockGridElem from "components/Block/BlockGridElem";
import BlockListElem from "components/Block/BlockListElem";
import Loader from "components/Loader";
import { flattenPages } from "helpers";
import { PAGE_SIZE } from "config/pagination";
import { Inputs as BlockFilters } from "components/Block/BlockFilterForm/model";
import { removeEmptyEntries } from "utils/object";

interface BlocksGridProps {
  text?: string;
  toggleSelection?: (id: string) => void;
  selected?: string[];
  filters: BlockFilters | null;
}

const BlocksGrid: FC<BlocksGridProps> = ({
  text,
  toggleSelection,
  selected,
  filters,
}) => {
  const { t } = useTranslation();

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data: pages,
    isLoading: isLoadingBlocks,
    error: blocksError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteBlocks({
    query: { text, ...formattedFilters },
    size: PAGE_SIZE,
  });

  const handleScroll = (e: any) => {
    if (
      window.innerHeight + window.scrollY >= document.body.scrollHeight - 2 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  };

  window.addEventListener("scroll", handleScroll, true);

  useToasts([
    {
      severity: "error",
      message: t("There was an error. Cannot fetch models."),
      condition: !!blocksError,
    },
  ]);

  return (
    <>
      {isLoadingBlocks ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={2}>
            {flattenPages(pages).map((block, idx) => (
              <React.Fragment key={block._id}>
                <Grid
                  item
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <BlockGridElem
                    block={block}
                    variant="owned"
                    toggleSelection={toggleSelection}
                    selected={selected?.includes(block._id)}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ display: { xs: "block", sm: "none" } }}
                >
                  <BlockListElem block={block} variant="owned" />
                </Grid>
              </React.Fragment>
            ))}
            {isFetchingNextPage && <Loader />}
          </Grid>

          {flattenPages(pages).length === 0 && (
            <Stack
              direction="row"
              justifyContent={"center"}
              alignItems="center"
            >
              <p>{t("There are not blocks")}</p>
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export default BlocksGrid;
