import { TabContext } from "@mui/lab";
import TabPanel from "components/material/TabPanel";
import { Stack, Tab } from "@mui/material";
import Page from "components/Layout/Page";
import useAuth from "hooks/useAuth";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserDashboardWrapper } from "./styles";
import MRTabList from "components/material/TabList";
import HorizontalCollections from "components/Collection/HorizontalCollections";
import HorizontalSharedCollections from "components/Collection/HorizontalSharedCollections";
import { HorizontalScrollerContainer } from "components/HorizontalScroller/styles";
import ROLE from "types/role";
import Locked from "components/Locked";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";
import PATH from "routing/path";
import PageHeader from "components/Layout/PageHeader";
import SellerCounters from "./SellerCounters";

//import { TabList } from "@mui/lab";

const UserDashboard: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [text, setText] = useState<string>("");
  const [debouncedText] = useDebounce(text, 500);

  const [collectionsTab, setCollectionsTab] = useState<string>(
    user && user.roles === ROLE.BUYER ? "sharedCollections" : "collections"
  );

  const handleCollectionsTabChange = (
    event: React.SyntheticEvent,
    newTab: string
  ) => {
    setCollectionsTab(newTab);
  };

  return (
    <Page>
      <UserDashboardWrapper>
        <PageHeader>
          <h1>
            {t("Hello")},{" "}
            <span
              className="profile-name"
              onClick={() => navigate(PATH.PROFILE)}
            >
              {user?.profile?.firstname}
            </span>
          </h1>
        </PageHeader>

        {user?.roles === ROLE.SELLER && (
          <Stack mb={5}>
            <SellerCounters />
          </Stack>
        )}

        <Stack mb={8}>
          <HorizontalScrollerContainer>
            <Stack padding={2}>
              <TabContext value={collectionsTab}>
                <Stack direction="row" justifyContent={"space-between"} gap={2}>
                  <TabContext value={collectionsTab} >
                  <MRTabList
                    /*value={collectionsTab}*/
                    onChange={handleCollectionsTabChange}
                    className="collections-tabs"
                  >
                    <Tab label={t("Collections")} value="collections" />
                    <Tab
                      label={t("Collections shared with me")}
                      value="sharedCollections"
                    />
                  </MRTabList>
                  </TabContext>
                  <Stack
                    onClick={() => navigate(PATH.CATALOGUES)}
                    sx={{
                      display: { xs: "none", sm: "flex" },
                      cursor: "pointer",
                    }}
                  >
                    {t("See all catalogues")}
                  </Stack>
                </Stack>

                <TabPanel
                  active={collectionsTab}
                  value="collections"
                  sx={{ padding: "10px" }}
                >
                  {user && user.roles === ROLE.BUYER ? (
                    <Locked
                      description={t(
                        "You will be able to create catalogues and share them with your clients and partners"
                      )}
                      card={false}
                    />
                  ) : (
                    <HorizontalCollections header={false} />
                  )}
                </TabPanel>
                <TabPanel
                  active={collectionsTab}
                  value="sharedCollections"
                  sx={{ padding: "10px" }}
                >
                  <HorizontalSharedCollections header={false} />
                </TabPanel>
              </TabContext>
            </Stack>
          </HorizontalScrollerContainer>
        </Stack>
      </UserDashboardWrapper>
    </Page>
  );
};

export default UserDashboard;
