import { FC, useState, useMemo } from "react";
import { CollectionTableProps } from "./types";
import { Stack } from "@mui/material";
import { MRPagination, MRDrawer } from "components/material";
import useCollections from "hooks/services/collections/useCollections";
import { useTranslation } from "react-i18next";
import useMultiSelect from "hooks/useMultiSelect";
import CollectionBulkActionsControl from "components/TablesComponents/Collection/CollectionBulkActionsControl";
import MRCollectionTable from "components/TablesComponents/Collection/CollectionsTable";
import { PAGE_SIZE } from "config/pagination";
import TitleCounter from "components/TablesComponents/TitleCounter";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import { Inputs as CollectionFilters } from "components/Collection/CollectionFilterForm/model";
import CollectionFilterForm from "components/Collection/CollectionFilterForm";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";

const CollectionTable: FC<CollectionTableProps> = ({ ownerId }) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState<CollectionFilters | null>(null);

  const { selected, toggleSelection, toggleAll, resetSelection } = useMultiSelect();

  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("_id");

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data: collections,
    isLoading: loadingCollections,
    isError: collectionsError,
  } = useCollections({
    page,
    size: PAGE_SIZE,
    order,
    sort: orderBy,
    owner: ownerId,
    ...(formattedFilters && formattedFilters),
  });

  const sortHandler = (orderBy: string) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(orderBy);
  };

  // TODO: Handle error with toasts
  if (collectionsError) throw new Error("Cannot get collections");

  return (
    <>
      <MRDrawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={toggleDrawer(false, setFilterDrawerOpen)}
      >
        <CollectionFilterForm
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </MRDrawer>
      <Stack
        direction="row"
        gap={3}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "5px", sm: 3 },
        }}
      >
        <TitleCounter title={t("Collections")} count={collections?.pagination[0]?.total || 0} />
        <Stack direction="row" gap="10px" alignItems="center">
          <FilterButton
            onClick={() => setFilterDrawerOpen(true)}
            activeFilters={formattedFilters ? Object.keys(formattedFilters).length : 0}
          />
          <CollectionBulkActionsControl selectedIds={selected} resetSelection={resetSelection} />
        </Stack>
      </Stack>
      <MRCollectionTable
        collections={collections?.data || []}
        selected={selected}
        toggleSelection={toggleSelection}
        toggleAll={toggleAll}
        order={order}
        orderBy={orderBy}
        sortHandler={sortHandler}
      />
      <Stack direction="row" justifyContent="center">
        {collections?.data && collections.data.length ? (
          <MRPagination
            count={collections ? Math.ceil(collections?.pagination[0]?.total / PAGE_SIZE) || 1 : 1}
            page={page}
            onChange={(e, page) => setPage(page)}
          />
        ) : (
          <NoDataRow>{t("No Data")}</NoDataRow>
        )}
      </Stack>
    </>
  );
};

export default CollectionTable;
