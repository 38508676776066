import { FC, useMemo, useState } from "react";
import { RowActionProps, RowAction } from "../RowActions/types";
import RowActionsBase from "../RowActions/RowActionsBase";
import { edit } from "assets/icons";
import AcceptTransferModal from "components/Transfer/AcceptTransferModal";

const TransferRecipientRowActions: FC<RowActionProps> = ({ id }) => {
  const [openTransferModal, setOpenTransferModal] = useState(false);

  const actions: RowAction[] = useMemo(
    () => [
      {
        icon: edit,
        action: () => setOpenTransferModal(true),
      },
    ],
    []
  );

  return (
    <>
      <AcceptTransferModal
        open={openTransferModal}
        setOpen={setOpenTransferModal}
        transferId={id}
      />
      <RowActionsBase variant="icons" actions={actions} />
    </>
  );
};

export default TransferRecipientRowActions;
