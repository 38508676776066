import { Grid, Stack } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useParams,
  useNavigate,
  generatePath,
  useLocation,
  Link,
} from "react-router-dom";
import { ProcessedModelWrapper } from "./styles";
import useBlock from "hooks/services/blocks/useBlock";
import useUser from "hooks/services/users/useUser";
import { colors } from "styles/theme";
import { MRButtonPrimary, MRButtonSecondary } from "components/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loader from "components/Loader";
import { MRCard } from "components/material";
import {
  shareCircle,
  transferCircle,
  object3d,
  download2,
  chevronsRight,
} from "assets/icons";
import ShareChart from "components/Charts/shareChart";
import ChartYearSelector from "components/Charts/chartYearSelector";
import CollectionTable from "./tables/CollectionTable";
import TransferTable from "./tables/TransferTable";
import ShareTable from "./tables/ShareTable";
import LinkTable from "./tables/LinkTable";
import Page from "components/Layout/Page";
import EditBlockInfo from "./components/EditBlockInfo";
import CounterCard from "./components/CounterCard";
import { useConfirmModal } from "context/confirmModal";
import useDeleteBlock from "hooks/services/blocks/useDeleteBlock";
import useToasts from "hooks/useToasts";
import BlockStatusCard from "components/TablesComponents/Block/BlockStatusCard";
import { BlockStatus } from "types/block";
import useHideBlock from "hooks/services/blocks/useHideBlock";
import useShowBlock from "hooks/services/blocks/useShowBlock";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PATH from "routing/path";
import CreateDirectTransferModal from "components/Transfer/CreateDirectTransferModal";
import defaultThumbnail from "assets/images/default-thumb.png";
import toast from "react-hot-toast";
import useBlockShares from "hooks/services/shares/useBlockSahres";
import useTransfers from "hooks/services/transfers/useTransfers";
import { windowReplace } from "utils/helpers";

const ProcessedModel: FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("Missing param : id");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: block,
    isLoading: isLoadingBlock,
    error: fetchBlockError,
  } = useBlock(id);

  const {
    data: owner,
    isLoading: isLoadingOwner,
    error: fetchOwnerError,
  } = useUser(block?.data.owner);

  const { mutate: deleteBlock, error: deleteBlockError } = useDeleteBlock();

  const {
    mutateAsync: hideBlock,
    isSuccess: hideBlockSuccess,
    error: hideBlockError,
  } = useHideBlock();
  const {
    mutateAsync: showBlock,
    isSuccess: showBlockSuccess,
    error: showBlockError,
  } = useShowBlock();

  if (fetchBlockError) throw new Error();
  if (fetchOwnerError) throw new Error();

  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );

  const [transferModalOpen, setTransferModalOpen] = useState<boolean>(false);

  const { openModal: openDeletePermanentlyModal } = useConfirmModal();

  const handleDeletePermanently = () => {
    if (block)
      openDeletePermanentlyModal({
        title: "Are you sure to delete this block permanently?",
        text: "The operation is not reversible and will delete all the data related to this block",
        confirmFunction: () => deleteBlock(block.data._id),
      });
  };

  const {
    data: shares,
    isLoading: isLoadingShares,
    error: fetchSharesError,
  } = useBlockShares(id);

  const {
    data: transfers,
    isLoading: isLoadingTransfers,
    isError: fetchTransfersError,
  } = useTransfers(1, 1, { block: id });

  const handleHide = () => {
    if (block)
      hideBlock(block?.data._id).then(() =>
        toast(t("Block hided successfully"))
      );
  };

  const handleShow = () => {
    if (block)
      showBlock(block?.data._id).then(() =>
        toast(t("Block status changed successfully to available"))
      );
  };

  useToasts([
    {
      severity: "error",
      message: t("Error while deleting block"),
      condition: !!deleteBlockError,
    },
    {
      severity: "error",
      message: t("Error while hiding block"),
      condition: !!hideBlockError,
    },
    {
      severity: "error",
      message: t("Error while setting block as available"),
      condition: !!showBlockError,
    },
  ]);

  if (isLoadingBlock || isLoadingOwner) return <Loader />;

  return (
    <Page>
      <ProcessedModelWrapper>
        <Stack direction="column" gap={4}>
          <Link to={"/processed-models"}>
            <MRButtonPrimary
              sx={{
                bgcolor: `${colors.lightBlack}!important`,
                width: "fit-content",
              }}
              variant="contained"
            >
              <ArrowBackIcon />
              {t("Models")}
            </MRButtonPrimary>
          </Link>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            gap={2}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            <Stack sx={{ textAlign: { xs: "center", sm: "left" } }}>
              <Stack
                direction="row"
                flexWrap={"wrap"}
                gap={3}
                alignItems="center"
              >
                <h1>{block?.data.title}</h1>
                <BlockStatusCard
                  status={block?.data.status || BlockStatus.AVAILABLE}
                />
              </Stack>

              <h3 className="secondary-title">
                {`${t("Owner")}: `}
                <span>{owner?.data.username}</span>
              </h3>
            </Stack>
            <Stack direction="row" gap={2}>
              {block?.data.status === BlockStatus.HIDDEN && (
                <MRButtonPrimary variant="contained" onClick={handleShow}>
                  <RemoveRedEyeIcon sx={{ marginRight: 1 }} /> {t("Show")}
                </MRButtonPrimary>
              )}

              {block?.data.status === BlockStatus.AVAILABLE && (
                <MRButtonPrimary variant="contained" onClick={handleHide}>
                  <VisibilityOffIcon sx={{ marginRight: 1 }} /> {t("Hide")}
                </MRButtonPrimary>
              )}

              <MRButtonSecondary
                variant="contained"
                onClick={handleDeletePermanently}
              >
                {t("Delete permanently")}
              </MRButtonSecondary>
            </Stack>
          </Stack>
          <Stack
            alignItems="center"
            gap={2}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Stack flexGrow={4} className="thumbnail-container">
              <img
                src={block?.data.model.thumbnail || defaultThumbnail}
                alt="Block thumbnail"
              />
            </Stack>

            <Stack flexGrow={6}>
              <Stack
                direction="column"
                gap={3}
                sx={{ alignItems: { xs: "center", md: "end" } }}
              >
                <Stack direction="row" flexWrap="wrap" gap={2}>
                  <CounterCard
                    icon={shareCircle}
                    count={
                      isLoadingShares
                        ? t("Loading...")
                        : fetchSharesError
                        ? t("Error")
                        : shares?.pagination[0]?.total || 0
                    }
                    text={t("Shares")}
                  />
                  <CounterCard
                    icon={transferCircle}
                    count={
                      isLoadingTransfers
                        ? t("Loading...")
                        : fetchTransfersError
                        ? t("Error")
                        : transfers?.pagination[0]?.total || 0
                    }
                    text={t("Transfer")}
                  />
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="end"
                  gap={2}
                  flexWrap="wrap"
                  sx={{ justifyContent: { xs: "center", md: "flex-end" } }}
                >
                  <Link
                    to={
                      generatePath(`/viewer/block/:id`, { id }) +
                      `?returnUrl=${location.pathname}`
                    }
                  >
                    <MRButtonPrimary variant="contained">
                      <Stack direction="row" alignItems="center" gap={1}>
                        <img src={object3d} alt="" />
                        {t("View in 3D")}
                      </Stack>
                    </MRButtonPrimary>
                  </Link>

                  <MRButtonPrimary
                    variant="contained"
                    className="download-photos-btn"
                    onClick={() =>
                      navigate(
                        `${PATH.MODELS_TO_PROCESS}/${block?.data.acquisition}`
                      )
                    }
                  >
                    <Stack direction="row" alignItems="center" gap={1}>
                      <img src={download2} />
                      <span>{t("Download photos")}</span>
                    </Stack>
                  </MRButtonPrimary>

                  <MRButtonPrimary
                    variant="contained"
                    onClick={() => setTransferModalOpen(true)}
                  >
                    <Stack direction="row" alignItems="center" gap={1}>
                      <img src={chevronsRight} />
                      {t("Transfer")}
                    </Stack>
                  </MRButtonPrimary>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Grid container columnSpacing={3} rowSpacing={3} marginBottom="20px">
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <MRCard>{block && <EditBlockInfo block={block?.data} />}</MRCard>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <MRCard>
                <div className="share-chart-card-header">
                  <h2>
                    {t("Shares")} {selectedYear}
                  </h2>
                  <ChartYearSelector setSelectedYear={setSelectedYear} />
                </div>
                <ShareChart
                  {...{
                    year: selectedYear.toString(),
                    blockId: id,
                    maxHeight: 400,
                  }}
                />
              </MRCard>
            </Grid>
          </Grid>
          <CollectionTable blockId={id} />
          <TransferTable blockId={id} />
          <ShareTable blockId={id} />
          <LinkTable blockId={id} />
        </Stack>

        {block && (
          <CreateDirectTransferModal
            openModal={transferModalOpen}
            setOpenModal={setTransferModalOpen}
            blockId={block?.data._id}
          />
        )}
      </ProcessedModelWrapper>
    </Page>
  );
};

export default ProcessedModel;
