import { Box, Grid, InputAdornment, Stack } from "@mui/material";
import { FC } from "react";
import { ModelToProcessWrapper } from "./styles";
import { useTranslation } from "react-i18next";
import { MRButtonPrimary } from "components/material/Button";
import { colors } from "styles/theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useAcquisition from "hooks/services/acquisitions/useAcquisition";
import useUser from "hooks/services/users/useUser";
import useEditAcquisitionInfo from "hooks/services/acquisitions/useEditAcquisitionInfo";
import { Link, useParams } from "react-router-dom";
import DownloadPhotos from "./DownloadPhotos";
import { MRCard } from "components/material";
import { useFormik } from "formik";
import { AcquisitionInfoInputs, AcquisitionStatus } from "types/acquisition";
import {
  validationSchema,
  initialValues,
  formatAcquisitionInfo,
} from "./model";
import MRTextField from "components/material/TextField";
import UploadModel from "./UploadModel";
import useToasts from "hooks/useToasts";
import UploadThumbnail from "./UploadThumbnail";
import Page from "components/Layout/Page";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { displayName } from "helpers";
import AcquisitionStatusCard from "components/TablesComponents/Acquisition/AcquisitionStatusCard";
import AcquisitionForm from "./AcquisitionForm";

const ModelToProcess: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  if (!id) throw new Error("This component requires an id in the url params");

  const navigate = useNavigate();

  const {
    data: acquisition,
    isLoading: isLoadingAcquisition,
    error: acquisitionError,
  } = useAcquisition(id);

  if (acquisitionError) throw new Error();

  const {
    data: owner,
    isLoading: isLoadingOwner,
    error: ownerError,
  } = useUser(acquisition?.data.owner);

  if (ownerError) throw new Error();

  return (
    <Page>
      <ModelToProcessWrapper>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          width="100%"
          marginBottom={2}
        >
          <Link to={"/models-to-process"}>
            <MRButtonPrimary
              sx={{ bgcolor: `${colors.lightBlack}!important` }}
              variant="contained"
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <ArrowBackIcon />
                {t("Models to process")}
              </Stack>
            </MRButtonPrimary>
          </Link>
        </Stack>
        <Stack
          direction="row"
          justifyContent={"start"}
          alignItems="center"
          gap="10px"
        >
          <Stack
            marginBottom={4}
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap="5px"
            width="100%"
          >
            <h1 className="no-margin-text">
              {acquisition?.data.title}{" "}
              <span style={{ fontSize: "18px", fontWeight: "normal" }}>
                {moment(acquisition?.data.createdAt).format("lll")}
              </span>
            </h1>
            <h4 className="no-margin-text secondary-title">
              {t("Owner")}
              {": "}
              {owner && displayName(owner?.data)}
            </h4>
          </Stack>

          {acquisition && (
            <div className="acquisition-status-card-container">
              <AcquisitionStatusCard status={acquisition.data.status} />
            </div>
          )}
        </Stack>
        {acquisition &&
          acquisition.data.status === AcquisitionStatus.REMOVED && (
            <div className="deleted-warning-container">
              {t(
                "This acquisition was deleted, be careful not to edit and or insert any further information"
              )}
            </div>
          )}
        <Box marginBottom={2}>
          {acquisition?.data && (
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={5}>
                <DownloadPhotos acquisition={acquisition.data} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={7}>
                {acquisition.data.status === AcquisitionStatus.PROCESSING && (
                  <UploadModel acquisition={acquisition.data} />
                )}
                {acquisition.data.status === AcquisitionStatus.PROCESSED && (
                  <UploadThumbnail acquisition={acquisition.data} />
                )}
              </Grid>
            </Grid>
          )}
        </Box>
        <Stack>
          <MRCard className="acquisition-info-card">
            <h4 className="no-margin-text">{t("Acquisition Information")}</h4>

            <AcquisitionForm
              owner={owner?.data}
              acquisition={acquisition?.data}
            />
            {/* <form onSubmit={formik.handleSubmit}>
              <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <p>{t("Owner")} </p>
                  <MRTextField
                    className="owner-input"
                    fullWidth
                    id="owner"
                    name="owner"
                    placeholder={t("Owner")}
                    disabled
                    value={
                      owner
                        ? `${owner?.data.profile?.firstname} ${owner?.data.profile?.lastname}`
                        : ""
                    }
                  />

                  <p>{t("Place of acquisition")} </p>
                  <MRTextField
                    fullWidth
                    className="text-field"
                    id="placeOfAcquisition"
                    name="placeOfAcquisition"
                    placeholder={t("Place of acquisition")}
                    value={formik.values.placeOfAcquisition}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.placeOfAcquisition &&
                      Boolean(formik.errors.placeOfAcquisition)
                    }
                    helperText={
                      formik.touched.placeOfAcquisition &&
                      formik.errors.placeOfAcquisition
                    }
                  />

                  <p>{t("Notes")} </p>
                  <MRTextField
                    fullWidth
                    className="text-field"
                    id="notes"
                    name="notes"
                    placeholder={t("Notes")}
                    value={formik.values.notes}
                    onChange={formik.handleChange}
                    error={formik.touched.notes && Boolean(formik.errors.notes)}
                    helperText={formik.touched.notes && formik.errors.notes}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <p>{t("Title")} </p>
                  <MRTextField
                    fullWidth
                    className="text-field"
                    id="title"
                    name="title"
                    placeholder={t("Title")}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                  />

                  <p>{t("Material")} </p>
                  <MRTextField
                    fullWidth
                    className="text-field"
                    id="material"
                    name="material"
                    placeholder={t("Material")}
                    value={formik.values.material}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.material && Boolean(formik.errors.material)
                    }
                    helperText={
                      formik.touched.material && formik.errors.material
                    }
                  />

                  <p>{t("Geological Family")} </p>
                  <MRTextField
                    fullWidth
                    className="text-field"
                    id="geologicalFamily"
                    name="geologicalFamily"
                    placeholder={t("Geological Family")}
                    value={formik.values.geologicalFamily}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.geologicalFamily &&
                      Boolean(formik.errors.geologicalFamily)
                    }
                    helperText={
                      formik.touched.geologicalFamily &&
                      formik.errors.geologicalFamily
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} marginBottom={2}>
                  <Stack gap={2} direction="row">
                    <Stack>
                      <p>{t("Lenght")} </p>
                      <MRTextField
                        fullWidth
                        className="text-field"
                        id="length"
                        name="length"
                        placeholder={t("Length")}
                        value={formik.values.length}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.length && Boolean(formik.errors.length)
                        }
                        helperText={
                          formik.touched.length && formik.errors.length
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">cm</InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack>
                      <p>{t("Height")} </p>
                      <MRTextField
                        fullWidth
                        className="text-field"
                        id="height"
                        name="height"
                        placeholder={t("Height")}
                        value={formik.values.height}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.height && Boolean(formik.errors.height)
                        }
                        helperText={
                          formik.touched.height && formik.errors.height
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">cm</InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack gap={2} direction="row">
                    <Stack>
                      <p>{t("Depth")} </p>
                      <MRTextField
                        fullWidth
                        className="text-field"
                        id="depth"
                        name="depth"
                        placeholder={t("Depth")}
                        value={formik.values.depth}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.depth && Boolean(formik.errors.depth)
                        }
                        helperText={formik.touched.depth && formik.errors.depth}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">cm</InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <Stack>
                      <p>{t("Weight")} </p>
                      <MRTextField
                        fullWidth
                        className="text-field"
                        id="weight"
                        name="weight"
                        placeholder={t("Weitgh")}
                        value={formik.values.weight}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.weight && Boolean(formik.errors.weight)
                        }
                        helperText={
                          formik.touched.weight && formik.errors.weight
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <MRButtonPrimary
                  type="submit"
                  loading={loadingEditAcquisitionInfo}
                >
                  {t("Confirm change")}
                </MRButtonPrimary>
              </Stack>
            </form> */}
          </MRCard>
        </Stack>
      </ModelToProcessWrapper>
    </Page>
  );
};

export default ModelToProcess;
