import { FC } from "react";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { useTranslation } from "react-i18next";
import { NoNotificationContainer } from "./styles";

const NoNotification: FC = () => {
  const { t } = useTranslation();

  return (
    <NoNotificationContainer>
      <NotificationsOffIcon style={{ fontSize: "80px" }} />
      <h4>{t("There are no notification yet!")}</h4>
      <p>{t("When you get notifications, they'll show up here")}</p>
    </NoNotificationContainer>
  );
};

export default NoNotification;
