import { IconButton, Stack } from "@mui/material";
import { Dialog } from "components/material/Dialog";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "styles/theme";
import { AddBlockToCollectionModalProps } from "./types";
import ClearIcon from "@mui/icons-material/Clear";
import { Collection } from "types/collection";
import SelectCollection from "components/Collection/SelectCollection";
import { MRButtonPrimary } from "components/material/Button";
import useAddBlockToCollectionCollection from "hooks/services/collections/useAddBlockToCollection";
import useToasts from "hooks/useToasts";

const AddBlockToCollectionModal: FC<AddBlockToCollectionModalProps> = ({
  openModal,
  setOpenModal,
  blockId,
}) => {
  const { t } = useTranslation();
  const [selectedCollection, setSelectedCollection] = useState<string | null>(null);
  const handleClose = (e: object, reason: string) => {
    if (reason === "escapeKeyDown") setOpenModal(false);
  };
  const {
    mutate: addBlockToCollection,
    isLoading: isLoadingAddBlockToCollection,
    isSuccess: isSuccessAddBlockToCollection,
    error: addBlockToCollectionError,
  } = useAddBlockToCollectionCollection();

  const addToCollection = () => {
    if (!selectedCollection) return;
    if (Array.isArray(blockId)) {
      blockId.forEach((block) => {
        addBlockToCollection({ collectionId: selectedCollection, blockId: block });
      });
    } else {
      addBlockToCollection({ collectionId: selectedCollection, blockId });
    }

    setOpenModal(false);
  };

  useToasts([
    {
      condition: !!addBlockToCollectionError,
      severity: "error",
      message: t("An error occured while adding the model to the catalogue, please try again"),
    },
    {
      condition: isSuccessAddBlockToCollection,
      severity: "success",
      message: Array.isArray(blockId) ? t("Models added correctly") : t("Model added correctly"),
    },
  ]);
  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="create-collection-modal"
      PaperProps={{
        style: {
          background: colors.lightBlack,
          backgroundImage: "none",
          width: "100%",
          padding: "20px",
          border: `1px solid ${colors.white}`,
          borderRadius: "20px",
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h2>{t("Select the catalogue")}</h2>

        <IconButton onClick={(e) => handleClose(e, "escapeKeyDown")}>
          <ClearIcon />
        </IconButton>
      </Stack>
      <SelectCollection
        selectedCollection={selectedCollection}
        setSelectedCollection={setSelectedCollection}
      />
      {selectedCollection && (
        <MRButtonPrimary
          onClick={() => addToCollection()}
          loading={isLoadingAddBlockToCollection}
          sx={{
            bgcolor: `${colors.green} !important`,
            color: `${colors.black} !important`,
          }}
        >
          {t("Add")}
        </MRButtonPrimary>
      )}
    </Dialog>
  );
};

export default AddBlockToCollectionModal;
