import Page from "components/Layout/Page";
import styled from "styled-components";
import SignupBackground from "assets/images/bg-2.jpg";
import { colors } from "styles/theme";

export const ForgotPasswordPage = styled(Page)`
  background: url("${SignupBackground}");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  .signup-card {
    background: ${colors.black};
    padding: 50px;
    height: unset;
  }

  .MuiOutlinedInput-root {
    border: 1px solid ${colors.green};
    min-width: 300px;
  }
`;
