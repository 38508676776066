import { MRTextField } from "components/material";
import { MRButtonQuinary } from "components/material/Button";
import useEditContact from "hooks/services/users/useEditContact";
import useToasts from "hooks/useToasts";
import { FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Contact } from "types/user";

const EditAliasForm: FC<{ contact: Contact; setOpenModal: React.Dispatch<SetStateAction<boolean>> }> = ({
  contact,
  setOpenModal,
}) => {
  const [alias, setAlias] = useState<string>(contact.alias ?? "");
  const { t } = useTranslation();

  const {
    mutate: editContact,
    isLoading: loadingEditContact,
    error: errorEditContact,
    isSuccess: isSuccessEditContact,
  } = useEditContact();

  const editAlias = () => {
    editContact({ email: contact.email, alias: alias });
    setOpenModal(false);
  };

  useToasts([
    {
      condition: !!errorEditContact,
      severity: "error",
      message: t("An error occured while editing alias"),
    },
    {
      condition: isSuccessEditContact,
      severity: "success",
      message: t("Alias changed successfully"),
    },
  ]);

  return (
    <>
      <h3>Edit alias</h3>
      <MRTextField value={alias} onChange={(e) => setAlias(e.target.value)} />
      <MRButtonQuinary onClick={editAlias} style={{ padding: "10px 30px", margin: "30px auto 10px" }}>
        {t("Save")}
      </MRButtonQuinary>
    </>
  );
};

export default EditAliasForm;
