import { Stack } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useParams,
  useNavigate,
  generatePath,
  useLocation,
  Link,
} from "react-router-dom";
import { EditModelSellerWrapper } from "./styles";
import useBlock from "hooks/services/blocks/useBlock";
import {
  ActionButtonContained,
  ActionButtonOutlined,
  MRButtonPrimary,
} from "components/material/Button";
import Loader from "components/Loader";
import { MRCard } from "components/material";
import {
  shareCircle,
  object3d4,
  share2Green,
  usersGreen,
  download3,
} from "assets/icons";
import CollectionTable from "../../../processed-models/views/edit/tables/CollectionTable";
import ShareTable from "../../../processed-models/views/edit/tables/ShareTable";
import LinkTable from "../../../processed-models/views/edit/tables/LinkTable";
import Page from "components/Layout/Page";
import EditBlockInfo from "../../../processed-models/views/edit/components/EditBlockInfo";
import CounterCard from "../../../processed-models/views/edit/components/CounterCard";
import defaultThumbnail from "assets/images/default-thumb.png";
import CreateTransferModal from "components/Transfer/CreateTransferModal";
import ShareModal from "components/Share/ShareModal";
import { useConfirmModal } from "context/confirmModal";
import useTrashBlock from "hooks/services/blocks/useTrashBlock";
import PATH from "routing/path";
import useBlockShares from "hooks/services/shares/useBlockSahres";
import ROLE from "types/role";
import { colors } from "styles/theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useAuth from "hooks/useAuth";

const EditModelSeller: FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("Missing param : id");

  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [transferModalOpen, setTransferModalOpen] = useState<boolean>(false);
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);

  const {
    data: block,
    isLoading: isLoadingBlock,
    error: fetchBlockError,
  } = useBlock(id);

  const {
    data: shares,
    isLoading: isLoadingShares,
    error: fetchSharesError,
  } = useBlockShares(id);

  const {
    mutateAsync: trashBlock,
    isLoading: trashBlockLoading,
    isSuccess: trashBlockSuccess,
    error: trashBlockError,
  } = useTrashBlock();

  const { openModal: openTrashModal } = useConfirmModal();

  const downloadFile = (url: string) => {
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "model.glb";
    anchor.style.display = "none";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleTrashModal = () => {
    openTrashModal({
      title: t("Are you sure you want to move this block to trash?"),
      text: t(
        "All the pending transfers related to this block will be deleted, you can recover this block within 30 days from your profile page"
      ),
      confirmFunction: () => trashBlock(id).then(() => navigate(PATH.MODELS)),
    });
  };

  if (fetchBlockError) throw new Error();

  if (isLoadingBlock) return <Loader />;

  return (
    <Page>
      <EditModelSellerWrapper>
        <Link to={"/models"}>
          <MRButtonPrimary
            sx={{ bgcolor: `${colors.lightBlack}!important` }}
            variant="contained"
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <ArrowBackIcon />
              {t("Models")}
            </Stack>
          </MRButtonPrimary>
        </Link>

        <Stack direction="column" gap={4}>
          <Stack sx={{ textAlign: { xs: "center", sm: "left" } }}>
            <h1>{block?.data.title}</h1>
          </Stack>
          <Stack
            alignItems="center"
            gap={2}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Stack flexGrow={4} className="thumbnail-container">
              <img
                src={block?.data.model.thumbnail || defaultThumbnail}
                alt="Block thumbnail"
              />
            </Stack>

            <Stack flexGrow={6}>
              <Stack
                direction="column"
                gap={3}
                sx={{ alignItems: { xs: "center", md: "end" } }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="end"
                  gap={2}
                  flexWrap="wrap"
                  sx={{ justifyContent: { xs: "center", md: "flex-end" } }}
                >
                  {user?.downloadEnabled && (
                    <ActionButtonContained
                      onClick={() => downloadFile(block?.data.model.url || "")}
                      icon={download3}
                    >
                      {t("Download model")}
                    </ActionButtonContained>
                  )}

                  <Link
                    to={
                      generatePath(`/viewer/block/:id`, { id }) +
                      `?returnUrl=${location.pathname}`
                    }
                  >
                    <ActionButtonContained onClick={() => {}} icon={object3d4}>
                      {t("View in 3D")}
                    </ActionButtonContained>
                  </Link>

                  <ActionButtonOutlined
                    onClick={() => setShareModalOpen(true)}
                    icon={share2Green}
                  >
                    {t("Share")}
                  </ActionButtonOutlined>

                  <ActionButtonOutlined
                    onClick={() => setTransferModalOpen(true)}
                    icon={usersGreen}
                  >
                    {t("Transfer")}
                  </ActionButtonOutlined>

                  <ActionButtonOutlined onClick={handleTrashModal}>
                    {t("Delete")}
                  </ActionButtonOutlined>
                </Stack>

                <Stack direction="row" flexWrap="wrap" gap={2}>
                  <CounterCard
                    icon={shareCircle}
                    count={
                      isLoadingShares
                        ? t("Loading...")
                        : fetchSharesError
                        ? t("Error")
                        : shares?.pagination[0]?.total || 0
                    }
                    text={t("Shares")}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <MRCard>{block && <EditBlockInfo block={block?.data} />}</MRCard>
          <CollectionTable blockId={id} collapsible={true} />
          <ShareTable blockId={id} collapsible={true} userRole={ROLE.SELLER} />
          <LinkTable blockId={id} collapsible={true} />
        </Stack>

        {block && (
          <>
            <CreateTransferModal
              open={transferModalOpen}
              setOpen={setTransferModalOpen}
              blockId={block?.data._id}
            />
            <ShareModal
              open={shareModalOpen}
              setOpen={setShareModalOpen}
              blockId={block?.data._id}
            />
          </>
        )}
      </EditModelSellerWrapper>
    </Page>
  );
};

export default EditModelSeller;
