import { Stack } from "@mui/material";
import useUserStorage from "hooks/services/users/useUserStorage";
import useAuth from "hooks/useAuth";
import i18n from "i18n";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ROLE from "types/role";
import { UserStorageWrapper } from "./style";

const UserStorage: FC = () => {
  const { user, me } = useAuth();
  const { data: storage, refetch: getStorage } = useUserStorage(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) getStorage();
  }, []);

  useEffect(() => {
    me();
  }, [storage]);

  if (!user || user.roles !== ROLE.SELLER) return <></>;

  return (
    <UserStorageWrapper>
      <Stack direction="row" className="cta-storage" gap={1}>
        <span>
          {storage?.data &&
            user?.storage &&
            user.storage - Number(storage.data)}
        </span>
        {"/ " + user?.storage} models
      </Stack>
      <Stack className="cta-storage">
        <span
          style={{ cursor: "pointer" }}
          className="buy-storage-container"
          onClick={() => navigate("/contact-us")}
        >
          {i18n.t("Buy Storage") as string}
        </span>
      </Stack>
    </UserStorageWrapper>
  );
};

export default UserStorage;
