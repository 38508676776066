import { FC, ReactNode, useLayoutEffect, useState } from "react";
import { Router } from "react-router-dom";
import type { BrowserHistory } from "history";

const AppRouter: FC<{ history: BrowserHistory; children: ReactNode }> = ({
  history,
  children,
}) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  let basename = process.env.REACT_APP_BASE_PATH ?? "/client"

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  );
};

export default AppRouter;
