import { Avatar } from "@mui/material";
import { contactDisplayName, stringAvatar } from "helpers";
import { FC } from "react";
import { ContactRowWrapper } from "./styles";
import { ContactRowProps } from "./types";

const ContactRow: FC<ContactRowProps> = ({
  contact,
  bgColor,
  setSelectedContact,
  selectedContact,
}) => {
  return (
    <ContactRowWrapper>
      <div
        className={`contact-row ${selectedContact?.user === contact.user ? "selected" : ""}`}
        onClick={() => setSelectedContact(contact)}
      >
        <Avatar src={contact.propicUrl} sx={{ bgcolor: bgColor }} className="contact-avatar">
          {contactDisplayName(contact) && stringAvatar(contactDisplayName(contact))}
        </Avatar>
        <span className="contact-name">{contactDisplayName(contact)}</span>
      </div>
    </ContactRowWrapper>
  );
};

export default ContactRow;
