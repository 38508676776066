import { NavigationItem } from "../types";
import GridViewIcon from "@mui/icons-material/GridView";
import PATH from "routing/path";
import { box, folder, user } from "assets/icons";
import i18n from "i18n";

export const adminNavigation: NavigationItem[] = [
  {
    name: "dashboard",
    label: i18n.t("Dashboard"),
    path: PATH.DASHBOARD,
    icon: <GridViewIcon />,
  },
  {
    name: "users",
    label: i18n.t("Users"),
    path: PATH.USERS,
    icon: <img src={user} alt="User" />,
  },
  {
    name: "catalogs",
    label: i18n.t("Catalogues"),
    path: PATH.CATALOGUES,
    icon: <img src={folder} alt="Folder" />,
  },
  {
    name: "models-to-process",
    label: i18n.t("Models to process"),
    path: PATH.MODELS_TO_PROCESS,
    icon: <img src={box} alt="Box" />,
  },
  {
    name: "processed-models",
    label: i18n.t("Processed models"),
    path: PATH.PROCESSED_MODELS,
    icon: <img src={box} alt="Box" />,
  },
];
