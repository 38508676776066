import { useMutation, useQueryClient } from "react-query";
import { ENDPOINTS } from "config/endpoints";
import { Request } from "types/request";
import { Response } from "types/response";
import client from "utils/client";
import { Share } from "types/share";
import { generatePath } from "react-router-dom";

export interface ForwardShareInputCollection {
  collectionId: string;
  user: string;
}

export default function useCreateShare() {
  const queryClient = useQueryClient();

  const createShare = (forwardShareInput: ForwardShareInputCollection) => {
    const { collectionId, user } = forwardShareInput;

    const request: Request = {
      url: generatePath(ENDPOINTS.shares.forwardCollection, { id: collectionId }),
      config: {
        method: "POST",
        data: { user },
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<
    Response<Share>,
    Error,
    ForwardShareInputCollection
  >(createShare, {
    onSettled: () => queryClient.invalidateQueries([ENDPOINTS.shares.all]),
  });

  return { data, mutateAsync, ...rest };
}
