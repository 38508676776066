import { User, UserInfoInputs } from "types/user";
import * as Yup from "yup";

export const adminValidationSchema = Yup.object({
  firstname: Yup.string(),
  lastname: Yup.string(),
  phone: Yup.string(),
  companyName: Yup.string(),
  companyField: Yup.string(),
  country: Yup.string(),
  vat: Yup.string(),
  status: Yup.string(),
  roles: Yup.string(),
});

export const validationSchema = Yup.object({
  firstname: Yup.string().required("Firstname is required"),
  lastname: Yup.string().required("Lastname is required"),
  phone: Yup.string().required("Phone is required"),
  companyName: Yup.string().required("Company name is required"),
  companyField: Yup.string().required("Company field is required"),
  country: Yup.string().required("Country is required"),
  vat: Yup.string().required("Vat is required"),
  status: Yup.string().required("Status is required"),
  roles: Yup.string().required("Plan is required"),
});

export const initialValues = {
  firstname: "",
  lastname: "",
  phone: "",
  companyName: "",
  companyField: "",
  country: "",
  vat: "",
  status: "",
  roles: "buyer",
  downloadEnabled: false,
};

export const formatResponse = (user: User) => {
  const formatted: UserInfoInputs = {
    firstname: user.profile?.firstname || "",
    lastname: user.profile?.lastname || "",
    phone: user.profile?.phone || "",
    companyName: user.profile?.companyName || "",
    companyField: user.profile?.companyField || "",
    country: user.profile?.country || "",
    vat: user.profile?.vat || "",
    status: user.status,
    roles: user.roles,
    downloadEnabled: user.downloadEnabled || false,
  };

  return formatted;
};
