import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery } from "react-query";
import { Request } from "types/request";
import { PaginatedResponse } from "types/response";
import Error from "types/error";
import { Acquisition } from "types/acquisition";

export default function useAcquisitions(params: Record<string, any>) {
  const request: Request = {
    url: ENDPOINTS.acquisitions.all,
    config: {
      method: "GET",
      params,
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.acquisitions.all, params && JSON.stringify(params)],
    queryFn: () => client(request).then((res) => res.data),
  };

  const { data, ...rest } = useQuery<PaginatedResponse<Acquisition[]>, Error>(requestConfig);

  return { data, ...rest };
}
