import styled from "styled-components";
import { colors } from "styles/theme";

export const SidenavWrapper = styled.div`
  height: 100%;

  .active {
    background: rgba(45, 181, 199, 0.2);
  }

  .active,
  .active .MuiListItemIcon-root {
    color: ${colors.primary};
  }

  .MuiListItemIcon-root {
    min-width: 36px;
  }

  .logo {
    width: 100%;
    margin: 20px 0px;
    text-align: center;
  }

  .logo.mini-logo img {
    width: 65px;
    padding: 5px;
  }

  .logo img {
    width: 80px;
  }

  .sidenav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`;
