import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";
import { User } from "types/user";

export default function useRestore() {
  const queryClient = useQueryClient();

  const restoreUser = (id: string) => {
    const request: Request = {
      url: generatePath(ENDPOINTS.users.restore, { id }),
      config: {
        method: "PATCH",
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<User>, Error, string>(
    restoreUser,
    {
      onSettled: (data, error, id) => {
        queryClient.invalidateQueries([ENDPOINTS.users.one, id]);
        queryClient.invalidateQueries([ENDPOINTS.users.all]);
      },
    }
  );

  return { data, mutate, ...rest };
}
