import styled from "styled-components";
import { colors } from "styles/theme";

export const SelectCollectionWrapper = styled.div`
  .contacts-dialog-box {
    background-color: ${colors.black};
    min-height: 200px;
  }

  .collection-row {
    background-color: ${colors.lightBlack};
    border-radius: 5px;
  }

  .collection-row-selected {
    box-shadow: inset 0px 0px 0px 2px ${colors.green};
  }

  .folder-img {
    height: 70px;
    width: 70px;
    object-fit: contain;
  }
`;
