import { FC, useMemo } from "react";
import { RowActionProps, RowAction } from "../RowActions/types";
import { useNavigate, generatePath } from "react-router-dom";
import RowActionsBase from "../RowActions/RowActionsBase";
import { trash2 } from "assets/icons";
import useRemoveAcquisition from "hooks/services/acquisitions/useRemoveAcquisition";
import { useTranslation } from "react-i18next";

const AcquisitionUserRowActions: FC<RowActionProps> = ({ id }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    mutate: removeAcquisitions,
    isLoading: isRemovingAcquisition,
    isSuccess: isRemovedAcquisition,
    error: removeAcquisitionError,
  } = useRemoveAcquisition();

  const actions: RowAction[] = useMemo(
    () => [
      {
        icon: trash2,
        action: () => removeAcquisitions(id),

        confirmMessage: t("Are you sure you want to delete this acquisition?"),
        actionState: {
          isLoading: isRemovingAcquisition,
          isSuccess: isRemovedAcquisition,
          successMessage: t("Acquisition deleted successfully"),
          isError: !!removeAcquisitionError,
          errorMessage: t(
            "An error occurred while deleting the acquisition, please try again"
          ),
        },
      },
    ],
    [
      navigate,
      generatePath,
      removeAcquisitions,
      isRemovingAcquisition,
      isRemovedAcquisition,
      removeAcquisitionError,
    ]
  );

  return <RowActionsBase variant="icons" actions={actions} />;
};

export default AcquisitionUserRowActions;
