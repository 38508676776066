import { FC } from "react";
import {
  Cell,
  PieChart,
  Pie,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { colors } from "styles/theme";
import { UsersCountProps } from "types/user";
import { PieChartWrapper } from "./styles";

const UsersChart: FC<UsersCountProps> = ({ data }) => {
  const COLORS = [colors.primary, colors.green];

  return (
    <PieChartWrapper>
      <ResponsiveContainer width="100%" maxHeight={400} aspect={1}>
        <PieChart key={JSON.stringify(data)}>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={"50%"}
            outerRadius={"75%"}
            cornerRadius={20}
            paddingAngle={-15}
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell
                fill={COLORS[index % COLORS.length]}
                key={`cell-${index}`}
              />
            ))}
            <Label
              value={data[0].value + data[1].value}
              position="center"
              color="white"
              style={{
                textAnchor: "middle",
                fontSize: "1.5em",
                fill: "white",
                fontWeight: "bold",
              }}
            />
          </Pie>

          <Legend
            payload={data.map((item, index) => ({
              id: item.name,
              type: "square",
              value: `${item.name} :${item.value}`,
              color: COLORS[index % COLORS.length],
            }))}
          />
        </PieChart>
      </ResponsiveContainer>
    </PieChartWrapper>
  );
};

export default UsersChart;
