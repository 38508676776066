import { Stack } from "@mui/material";
import useToasts from "hooks/useToasts";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Collection from "components/Collection/Single";
import useInfiniteSharedCollection from "hooks/services/collections/useInfiniteSharedCollections";
import { PAGE_SIZE } from "config/pagination";
import HorizontalScroller from "components/HorizontalScroller";
import {
  HorizontalScrollerContainer,
  ScrollerHeader,
} from "components/HorizontalScroller/styles";
import { flattenPages } from "helpers";
import { HorizontalSharedCollectionsProps } from "./types";
import PATH from "routing/path";
import { useNavigate } from "react-router-dom";

const HorizontalSharedCollections: FC<HorizontalSharedCollectionsProps> = ({
  header = true,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: pages,
    isLoading: isLoadingCollections,
    error: CollectionsError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteSharedCollection({ size: PAGE_SIZE });

  useToasts([
    {
      severity: "error",
      message: t("There was an error. Cannot fetch collections."),
      condition: !!CollectionsError,
    },
  ]);

  return (
    <HorizontalScrollerContainer>
      {header && (
        <ScrollerHeader>
          <h4>{t("Catalogues shared with me")}</h4>
          <span
            onClick={() =>
              navigate(`${PATH.CATALOGUES}?activeTab=shared_catalogues`)
            }
          >
            {t("View all")}
          </span>
        </ScrollerHeader>
      )}

      <HorizontalScroller
        isLoading={isLoadingCollections}
        hasNextPage={hasNextPage}
        fetchNext={fetchNextPage}
        isLoadingNextPage={isFetchingNextPage}
      >
        {flattenPages(pages).map((sharedCollection, idx) => {
          return (
            <Collection
              key={sharedCollection.collection._id}
              collection={sharedCollection.collection}
              forwardable={sharedCollection.forwardable}
              variant="shared"
            />
          );
        })}
        {flattenPages(pages).length === 0 && (
          <Stack sx={{ paddingLeft: "20px" }}>
            {t("There are no shared collections yet")}
          </Stack>
        )}
      </HorizontalScroller>
    </HorizontalScrollerContainer>
  );
};

export default HorizontalSharedCollections;
