import { FC } from "react";
import { TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { MRCheckbox, MRTable } from "components/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ShareRowActions from "./ShareRowAction";
import { Share, ShareStatus } from "types/share";
import ShareStatusCard from "./ShareStatusCard";
import { TableWrapper } from "../Tables/styles";
import { ShareTableProps } from "./types";
import UserShareRowActions from "./UserShareRowAction";
import ROLE from "types/role";
import { formatShareContent } from "helpers";

const SharesTable: FC<ShareTableProps> = ({
  shares,
  selected,
  toggleAll,
  toggleSelection,
  userRole,
  filter,
}) => {
  const { t } = useTranslation();

  const adminToggledAll = selected.length === shares.length && selected.length !== 0;

  const userToggledAll =
    selected.length === shares.filter((share) => share.status !== ShareStatus.REMOVED).length &&
    selected.length !== 0;

  return (
    <TableWrapper>
      <MRTable>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <MRCheckbox
                checked={userRole === ROLE.ADMIN ? adminToggledAll : userToggledAll}
                onChange={() => {
                  if (userRole && userRole === ROLE.ADMIN) {
                    toggleAll(shares.map((share) => share._id) || []);
                    return;
                  }
                  toggleAll(
                    shares
                      .filter((share) => share.status !== ShareStatus.REMOVED)
                      .map((share) => share._id) || []
                  );
                }}
              />
            </TableCell>
            <TableCell align="center">{t("Date")}</TableCell>
            <TableCell align="center">{t("Share content")}</TableCell>
            <TableCell align="center">{t("Name")}</TableCell>
            <TableCell align="center">{t("Shared with")}</TableCell>
            <TableCell align="center">{t("Status")}</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shares
            .filter((s) => (filter ? formatShareContent(s, "type") === filter : true))
            .map((share) => (
              <TableRow key={share._id}>
                <TableCell align="center">
                  {(userRole === ROLE.ADMIN || share.status !== ShareStatus.REMOVED) && (
                    <MRCheckbox
                      checked={selected.includes(share._id)}
                      onChange={() => toggleSelection(share._id)}
                    />
                  )}
                </TableCell>
                <TableCell align="center">{moment(share.createdAt).format("D MMM YYYY")}</TableCell>
                <TableCell align="center">{formatShareContent(share, "type")}</TableCell>
                <TableCell align="center">{formatShareContent(share, "name")}</TableCell>
                <TableCell align="center">{share.recipient}</TableCell>
                <TableCell align="center">{<ShareStatusCard status={share.status} />}</TableCell>
                <TableCell align="center" className="table-icon-container">
                  {userRole === ROLE.ADMIN ? (
                    <ShareRowActions id={share._id} />
                  ) : (
                    <>
                      {share.status !== ShareStatus.REMOVED && (
                        <UserShareRowActions id={share._id} />
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </MRTable>
    </TableWrapper>
  );
};

export default SharesTable;
