import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery, useInfiniteQuery } from "react-query";
import { Request } from "types/request";
import { PaginatedResponse } from "types/response";
import Error from "types/error";
import { SharedCollection } from "types/collection";

export default function useInfiniteSharedCollection({ size = 10 }) {
  const requestConfig = {
    queryKey: [ENDPOINTS.shares.collection],
    queryFn: ({ pageParam = 1 }) => {
      const request: Request = {
        url: ENDPOINTS.shares.collection,
        config: {
          method: "GET",
          params: { page: pageParam, size },
        },
      };
      return client(request).then((res) => res.data);
    },
  };

  const { data, ...rest } = useInfiniteQuery<PaginatedResponse<SharedCollection[]>, Error>({
    ...requestConfig,
    getNextPageParam: (lastPage) => {
      if (lastPage.pagination.length) {
        return lastPage.pagination[0].size * lastPage.pagination[0].page >=
          lastPage.pagination[0].total
          ? undefined
          : lastPage.pagination[0].page + 1;
      }
      return undefined;
    },
  });

  return { data, ...rest };
}
