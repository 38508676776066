import { AxiosError } from "axios";
import Error from "types/error";
import i18n from "../i18n";

export const parseError = (
  error: Error | null | undefined
): { status: number; name: string; message: string } => ({
  status: (error as AxiosError)?.response?.status || 0,
  name: (error as AxiosError)?.response?.data?.name || "",
  message: (error as AxiosError)?.response?.data?.message || "",
});

export const GENERIC_ERROR = i18n.t(
  "There was en error with your request. Please try again or contact the administrator"
);
