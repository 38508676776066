import { IconButton, Stack } from "@mui/material";
import { FC } from "react";
import { BlockFilterFormWrapper } from "./styles";
import { Inputs, initialValues, validationSchema } from "./model";
import { useFormik } from "formik";
import { MRCheckbox, MRTextField } from "components/material";
import { useTranslation } from "react-i18next";
import { BlockStatus } from "types/block";
import { MRButtonPrimary, MRButtonSecondary } from "components/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { BlockFilterFromProps } from "./types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ROLE from "types/role";
import useAuth from "hooks/useAuth";

const BlockFilterForm: FC<BlockFilterFromProps> = ({
  onApplyFilters,
  onClearFilters,
  onClose,
  appliedFilters,
  hideStatus,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const formik = useFormik<Inputs>({
    enableReinitialize: true,
    initialValues: appliedFilters
      ? { ...initialValues, ...appliedFilters }
      : initialValues,
    validationSchema,
    onSubmit: (blockFilterInfo) => {
      onApplyFilters(blockFilterInfo);
    },
  });
  return (
    <BlockFilterFormWrapper>
      <Stack height="100%" alignItems="start" justifyContent="center">
        <form className="filter-form" onSubmit={formik.handleSubmit}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton onClick={(e) => onClose()}>
              <ClearIcon />
            </IconButton>
            <MRButtonSecondary
              onClick={() => {
                onClearFilters();
                formik.resetForm();
              }}
            >
              {t("Clear Filters")}
            </MRButtonSecondary>
          </Stack>
          <p>{t("Owner email")} </p>
          <MRTextField
            fullWidth
            id="username"
            name="username"
            placeholder={t("Username")}
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />

          <p>{t("Material")} </p>
          <MRTextField
            fullWidth
            id="material"
            name="material"
            placeholder={t("Material")}
            value={formik.values.material}
            onChange={formik.handleChange}
            error={formik.touched.material && Boolean(formik.errors.material)}
            helperText={formik.touched.material && formik.errors.material}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <p>{t("From date")} </p>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={formik.values.startDate}
              onChange={(value) => formik.setFieldValue("startDate", value)}
              renderInput={(params) => (
                <MRTextField
                  error={
                    formik.touched.startDate && Boolean(formik.errors.startDate)
                  }
                  helperText={
                    formik.touched.startDate && Boolean(formik.errors.startDate)
                  }
                  name="startDate"
                  fullWidth
                  {...params}
                />
              )}
            />

            <p>{t("To date")} </p>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={formik.values.endDate}
              onChange={(value) => formik.setFieldValue("endDate", value)}
              renderInput={(params) => (
                <MRTextField
                  error={
                    formik.touched.startDate && Boolean(formik.errors.endDate)
                  }
                  helperText={formik.touched.endDate && Boolean(formik.errors.endDate)}
                  name="endDate"
                  fullWidth
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          {!hideStatus && (
            <>
              <p>{t("Status")} </p>
              <Stack direction="row" gap="5px" flexWrap="wrap">
                <Stack direction="row" alignItems="center">
                  <MRCheckbox
                    checked={formik.values.status.includes(
                      BlockStatus.AVAILABLE
                    )}
                    onChange={() => {
                      if (
                        !formik.values.status.includes(BlockStatus.AVAILABLE)
                      ) {
                        formik.setFieldValue("status", [
                          ...formik.values.status,
                          BlockStatus.AVAILABLE,
                        ]);
                        return;
                      }
                      formik.setFieldValue(
                        "status",
                        formik.values.status.filter(
                          (s) => s !== BlockStatus.AVAILABLE
                        )
                      );
                    }}
                  />
                  <p>{t("Available")}</p>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <MRCheckbox
                    checked={formik.values.status.includes(BlockStatus.TRASHED)}
                    onChange={() => {
                      if (!formik.values.status.includes(BlockStatus.TRASHED)) {
                        formik.setFieldValue("status", [
                          ...formik.values.status,
                          BlockStatus.TRASHED,
                        ]);
                        return;
                      }
                      formik.setFieldValue(
                        "status",
                        formik.values.status.filter(
                          (s) => s !== BlockStatus.TRASHED
                        )
                      );
                    }}
                  />
                  <p>{t("Trashed")}</p>
                </Stack>

                {(user?.roles === ROLE.ADMIN ||
                  user?.roles === ROLE.OPERATOR) && (
                  <>
                    <Stack direction="row" alignItems="center">
                      <MRCheckbox
                        checked={formik.values.status.includes(
                          BlockStatus.REMOVED
                        )}
                        onChange={() => {
                          if (
                            !formik.values.status.includes(BlockStatus.REMOVED)
                          ) {
                            formik.setFieldValue("status", [
                              ...formik.values.status,
                              BlockStatus.REMOVED,
                            ]);
                            return;
                          }
                          formik.setFieldValue(
                            "status",
                            formik.values.status.filter(
                              (s) => s !== BlockStatus.REMOVED
                            )
                          );
                        }}
                      />
                      <p>{t("Removed")}</p>
                    </Stack>

                    <Stack direction="row" alignItems="center">
                      <MRCheckbox
                        checked={formik.values.status.includes(
                          BlockStatus.HIDDEN
                        )}
                        onChange={() => {
                          if (
                            !formik.values.status.includes(BlockStatus.HIDDEN)
                          ) {
                            formik.setFieldValue("status", [
                              ...formik.values.status,
                              BlockStatus.HIDDEN,
                            ]);
                            return;
                          }
                          formik.setFieldValue(
                            "status",
                            formik.values.status.filter(
                              (s) => s !== BlockStatus.HIDDEN
                            )
                          );
                        }}
                      />
                      <p>{t("Hidden")}</p>
                    </Stack>

                    <Stack direction="row" alignItems="center">
                      <MRCheckbox
                        checked={formik.values.status.includes(
                          BlockStatus.SOLD
                        )}
                        onChange={() => {
                          if (
                            !formik.values.status.includes(BlockStatus.SOLD)
                          ) {
                            formik.setFieldValue("status", [
                              ...formik.values.status,
                              BlockStatus.SOLD,
                            ]);
                            return;
                          }
                          formik.setFieldValue(
                            "status",
                            formik.values.status.filter(
                              (s) => s !== BlockStatus.SOLD
                            )
                          );
                        }}
                      />
                      <p>{t("Sold")}</p>
                    </Stack>
                  </>
                )}
              </Stack>
            </>
          )}
          <Stack
            marginTop="15px"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <MRButtonPrimary type="submit">{t("Apply")}</MRButtonPrimary>
          </Stack>
        </form>
      </Stack>
    </BlockFilterFormWrapper>
  );
};

export default BlockFilterForm;
