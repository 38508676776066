import { Stack } from "@mui/material";
import { MRCard } from "components/material";
import { MRButtonPrimary } from "components/material/Button";
import useToasts from "hooks/useToasts";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UploadThumbnailWrapper } from "./styles";
import { UploadThumbnailProps } from "./types";
import useGetThumbnail from "hooks/services/acquisitions/useGetThumbnail";
import useSetThumbnail from "hooks/services/acquisitions/useSetThumbnail";
import defaultThumbnail from "assets/images/default-thumb.png";
// import useAcquisitionUploadInfo from "hooks/services/acquisitions/useAcquisitionUploadInfo";
import useAuth from "hooks/useAuth";
//import useS3 from "hooks/useS3";
import { CHUNK_SIZE, MAX_ATTEMPTS_PER_CHUNK } from "config/s3upload";
import { S3Upload } from "utils/s3upload/s3Upload";
import toast from "react-hot-toast";
import Loader from "components/Loader";
import { getAcqusitionUploadInfo } from "hooks/services/acquisitions/useAcquisitionUploadInfo";
import { completeUpload } from "hooks/services/acquisitions/useCompleteUpload";

const UploadThumbnail: FC<UploadThumbnailProps> = ({ acquisition }) => {
  const { t } = useTranslation();
  const modelInputRef = useRef<HTMLInputElement>(null);
  const thumbnailInputRef = useRef<HTMLInputElement>(null);

  const [totChunks, setTotChunks] = useState(0);
  const [chunksUploaded, setChunksUploaded] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const { data: thumbnail, isLoading: getThumbnailLoading } = useGetThumbnail(
    acquisition._id
  );

  const { user } = useAuth();
  //useS3(user);

  // const {
  //   data: uploadInfo,
  //   isLoading: isLoadingUploadInfo,
  //   error: uploadInfoError,
  // } = useAcquisitionUploadInfo(acquisition._id);

  const {
    mutateAsync: setThumbnail,
    isError: setThumbnailError,
    isLoading: setThumbnailLoading,
  } = useSetThumbnail(acquisition._id);

  const thumbnailUploadHandler = (e: any) => {
    const file = e.target.files[0];
    const maxSize = 1024 * 1024; // Maximum file size in bytes (1MB)
    if (file) {
      if (file.size > maxSize) {
        e.target.value = "";
        toast(t("ERROR: Image is too big!"));
        return;
      }

      const formData = new FormData();
      formData.append("thumbnail", file);
      setThumbnail(formData);
    }
  };

  const modelUploadHandler = async (e: any) => {
    if (e.target.files[0]) {
      const partsNum = Math.ceil(e.target.files[0].size / CHUNK_SIZE);
      setTotChunks(partsNum);
      setChunksUploaded(0);
      setUploadError(false);
      setUploading(true);
      const uploadInfo = await getAcqusitionUploadInfo(
        acquisition._id,
        partsNum
      );
      S3Upload(
        e.target.files[0],
        "eu-central-1",
        uploadInfo.data.Bucket,
        uploadInfo.data.UploadId,
        uploadInfo.data.Key,
        uploadInfo.data.signedUrls,
        CHUNK_SIZE,
        MAX_ATTEMPTS_PER_CHUNK,
        completeUpload,
        () => setChunksUploaded((prev) => prev + 1),
        () => {
          setUploading(false);
          toast(t("Model replaced successfully"));
        },
        () => setUploadError(true)
      );
    }
  };
  useToasts([
    {
      severity: "error",
      message: t(
        "There was an error setting the acquisition as 'processing'. Please contact the administrator."
      ),
      condition: !!setThumbnailError,
    },
  ]);

  return (
    <UploadThumbnailWrapper>
      <MRCard className={"mr-card-green"}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          gap={1}
          padding={1}
        >
          <Stack direction="column" alignItems="flex-start" ml={2} gap={1}>
            {!uploading ? (
              <>
                <h2>{t("Upload completed")}</h2>
                {acquisition.numberOfPhotos} {t("photos")} - SIZE
                <input
                  style={{ display: "none" }}
                  ref={modelInputRef}
                  type="file"
                  id="model-file"
                  onChange={modelUploadHandler}
                />
                <MRButtonPrimary
                  className="download-button"
                  onClick={() => modelInputRef?.current?.click()}
                >
                  {t("REPLACE MODEL")}
                </MRButtonPrimary>
              </>
            ) : (
              <>
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  padding={2}
                >
                  <Loader />
                  <span className="percentage">
                    {Math.round((chunksUploaded / totChunks) * 100)}%
                  </span>
                </Stack>
              </>
            )}
          </Stack>
          <Stack
            direction="column"
            justifyContent={"center"}
            alignItems="center"
            gap={2}
          >
            <img
              src={thumbnail?.data || defaultThumbnail}
              alt="Model thumbnail"
              className="thumbnail"
            />

            <input
              style={{ display: "none" }}
              ref={thumbnailInputRef}
              type="file"
              id="thumbnail-file"
              onChange={thumbnailUploadHandler}
            />

            <MRButtonPrimary
              href="#"
              onClick={(e: any) => {
                e.preventDefault();
                thumbnailInputRef.current?.click();
              }}
              loading={setThumbnailLoading}
              className="change-thumbnail"
            >
              {t("Change thumbnail")}
            </MRButtonPrimary>
          </Stack>
        </Stack>
      </MRCard>
    </UploadThumbnailWrapper>
  );
};

export default UploadThumbnail;
