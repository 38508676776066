import styled from "styled-components";
import { colors } from "styles/theme";

export const BottomNavigationWrapper = styled.div`
  .user-controls {
    padding: 15px 10px;
    max-width: 240px;
  }

  .user-controls .MuiListItem-root {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
  }

  .user-controls .MuiAvatar-root {
    border-radius: 10px;
  }

  .user-controls .user-name {
    flex-grow: 4;
    cursor: pointer;
  }

  .user-controls .user-info {
    font-size: 0.9rem;
  }

  .user-controls .user-account {
    font-size: 0.8rem;
  }

  .user-controls .user-logout {
    cursor: pointer;
  }
`;
