import { ENDPOINTS } from "config/endpoints";
import { USER_TOKEN_KEY } from "config/localStorage";
import useAuth from "hooks/useAuth";
import { FC, useEffect } from "react";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { io } from "socket.io-client";

const SocketIOProvider: FC <{children: React.ReactNode}> = ({ children }) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (user) {
      const socket = io(process.env.REACT_APP_BE_HOST || "localhost:8080", {
        extraHeaders: {
          Authorization: `Bearer ${localStorage.getItem(USER_TOKEN_KEY)}`,
        },
      });

      socket.on("connect", () => {});

      socket.on("notification", () => {
        queryClient.invalidateQueries([ENDPOINTS.notifications.all]);
        toast("There is a new notification");
      });

      socket.on("disconnect", () => {});

      return () => {
        socket.off("connect");
        socket.off("disconnect");
        socket.off("notification");
      };
    }
  }, [user]);

  return <>{children}</>;
};

export default SocketIOProvider;
