import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { Expiry, Link } from "types/link";
import { Request } from "types/request";
import { Response } from "types/response";
import client from "utils/client";

export interface CreateShareInput {
  user: string;
  forwardable: boolean;
}

export interface CreateShareInputBlock extends CreateShareInput {
  blocks: string[];
  blockCollection?: never;
}

export interface CreateShareInputCollection extends CreateShareInput {
  blocks?: never;
  blockCollection: string;
}

export default function useCreateShare() {
  const queryClient = useQueryClient();

  const createShare = (createShareInput: CreateShareInputBlock | CreateShareInputCollection) => {
    const request: Request = {
      url: ENDPOINTS.shares.all,
      config: {
        method: "POST",
        data: { ...createShareInput },
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<
    Response<Link>,
    Error,
    CreateShareInputBlock | CreateShareInputCollection
  >(createShare, {
    onSettled: () => queryClient.invalidateQueries([ENDPOINTS.shares.all]),
  });

  return { data, mutateAsync, ...rest };
}
