import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useInfiniteQuery } from "react-query";
import { Request } from "types/request";
import { PaginatedResponse } from "types/response";
import { Contact } from "types/user";
import Error from "types/error";

export default function useInfiniteContacts({ size = 5, query = {} }) {
  const requestConfig = {
    queryKey: [ENDPOINTS.users.contacts, query && JSON.stringify(query)],
    queryFn: ({ pageParam = 1 }) => {
      const request: Request = {
        url: ENDPOINTS.users.contacts,
        config: {
          method: "GET",
          params: { page: pageParam, size, ...query },
        },
      };
      return client(request).then((res) => res.data);
    },
  };

  const { data, ...rest } = useInfiniteQuery<PaginatedResponse<Contact[]>, Error>({
    ...requestConfig,
    getNextPageParam: (lastPage) => {
      if (lastPage.pagination.length) {
        return lastPage.pagination[0].size * lastPage.pagination[0].page >= lastPage.pagination[0].total
          ? undefined
          : lastPage.pagination[0].page + 1;
      }
      return undefined;
    },
  });

  return { data, ...rest };
}
