import { FC, useMemo } from "react";
import useDeleteShare from "hooks/services/shares/useDeleteShare";
import useBulkAction from "hooks/useBulkAction";
import { BulkAction, BulkActionProps } from "../BulkActionsControls/types";
import { trash2 } from "assets/icons";
import BulkActionsControlBase from "../BulkActionsControls/BulkActionsControlBase";
import { useTranslation } from "react-i18next";

const BlockBulkActionControl: FC<BulkActionProps> = (props) => {
  const { mutateAsync: deleteSharesAsync } = useDeleteShare();

  const { t } = useTranslation();

  const {
    bulkAction: deleteShareBulk,
    isLoading: isDeletingShareBulk,
    bulkResult: deleteShareBulkResult,
  } = useBulkAction(deleteSharesAsync);

  const actions: BulkAction[] = useMemo(
    () => [
      {
        name: t("Remove"),
        icon: trash2,
        confirmMessage: t("Are you sure you want to delete these shares?"),
        bulkAction: deleteShareBulk,
        bulkActionLoading: isDeletingShareBulk,
        bulkActionResult: deleteShareBulkResult,
      },
    ],
    [deleteShareBulk, isDeletingShareBulk, deleteShareBulkResult]
  );
  return <BulkActionsControlBase {...props} actions={actions} />;
};

export default BlockBulkActionControl;
