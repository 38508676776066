import { useState } from "react"
import { ButtonBase, SvgIcon, Popover, Typography, Box, Divider } from '@mui/material'
import InfoCube from '../ui-icons/InfoCube'

import MRDivider from "../ui-components/MRDivider"


interface InfoBlockProps {
  modelName?: string,
  material?: string,
  dimension?: string
}

export default function InfoBlockButton(props: InfoBlockProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box component={"div"}  sx={{display: "flex", height:"100%"}}>
      <ButtonBase disableRipple aria-describedby={id} onClick={handleClick} title={"Info block"}>
        <SvgIcon viewBox={"0 0 25 27"}  style={{strokeWidth: 0.05, stroke: "#4BBECF"}}>
          <InfoCube />
        </SvgIcon>
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        elevation={0}
        slotProps={{
          paper: {
            style: {backgroundColor: "transparent"}
          }
        }}
        marginThreshold={0}
      >
        <Box
          component={"div"}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            backgroundColor: "rgba(6,11,12,0.75)",
            justifyContent: "center"
          }}
          padding={1}
        >
          <MRDivider text={"BLOCK INFO"} colorDivider={"#A3D8E7"} />
        
          <Typography color={"#00A4B7"} sx={{display: "flex", justifyContent: "center"}}>Model name</Typography>
          <Typography color={"#A3D8E7"} sx={{display: "flex", justifyContent: "center", backgroundColor:"#060B0C"}}>{props.modelName}</Typography>
          <Typography color={"#00A4B7"} sx={{display: "flex", justifyContent: "center"}}>Material</Typography>
          <Typography color={"#A3D8E7"} sx={{display: "flex", justifyContent: "center", backgroundColor:"#060B0C"}}>{props.material}</Typography>
          <Typography color={"#00A4B7"} sx={{display: "flex", justifyContent: "center"}}>Dimensions</Typography>
          <Typography color={"#A3D8E7"} sx={{display: "flex", justifyContent: "center", backgroundColor:"#060B0C"}} >{props.dimension}</Typography>
          
        </Box>
      </Popover>
    </Box>
  )

}