import Catalogues from "./views/all";
import Catalogue from "./views/single";

const routes = [
  {
    index: true,
    name: "Catalogues",
    element: <Catalogues />,
  },
  {
    path: ":id/*",
    name: "Catalogue",
    element: <Catalogue />,
  },
];

export default routes;
