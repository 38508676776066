import { FC, createRef, useState } from "react";
import { UploadModelWrapper } from "./styles";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { Stack } from "@mui/material";
import { MRButtonPrimary } from "components/material/Button";
import { uploadWhite } from "assets/icons";
import { useTranslation } from "react-i18next";
//mport useS3 from "hooks/useS3";
import { UploadModelProps } from "./types";
//import useAcquisitionUploadInfo from "hooks/services/acquisitions/useAcquisitionUploadInfo";
import Loader from "components/Loader";
import { S3Upload } from "utils/s3upload/s3Upload";
import { CHUNK_SIZE, MAX_ATTEMPTS_PER_CHUNK } from "config/s3upload";
import useToasts from "hooks/useToasts";
import useNotifyModelUpload from "hooks/services/acquisitions/useNotifyModelUpload";
import { getAcqusitionUploadInfo } from "hooks/services/acquisitions/useAcquisitionUploadInfo";
import { completeUpload } from "hooks/services/acquisitions/useCompleteUpload";

const UploadModel: FC<UploadModelProps> = ({ acquisition }) => {
  const dropzoneRef = createRef<DropzoneRef>();

  const [totChunks, setTotChunks] = useState(0);
  const [chunksUploaded, setChunksUploaded] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const { t } = useTranslation();

  const {
    mutate: notifyModelUpload,
    isLoading: isLoadingNotifyModelUpload,
    error: notifyModelUploadError,
    isSuccess: isNotifyModelUploadSuccess,
  } = useNotifyModelUpload();

  const handleUpload = async (files: File[]) => {
    const partsNum = Math.ceil(files[0].size / CHUNK_SIZE);
    setTotChunks(partsNum);
    setChunksUploaded(0);
    setUploadError(false);
    setUploading(true);
    const uploadInfo = await getAcqusitionUploadInfo(acquisition._id, partsNum);
    S3Upload(
      files[0],
      "eu-central-1",
      uploadInfo.data.Bucket,
      uploadInfo.data.UploadId,
      uploadInfo.data.Key,
      uploadInfo.data.signedUrls,
      CHUNK_SIZE,
      MAX_ATTEMPTS_PER_CHUNK,
      completeUpload,
      () => setChunksUploaded((prev) => prev + 1),
      () => {
        setUploading(false);
        notifyModelUpload(acquisition._id);
      },
      () => setUploadError(true)
    );
  };

  useToasts([
    {
      message: t(
        "An error occured while uploading the model, please try again"
      ),
      severity: "error",
      condition: !!uploadError,
    },
    {
      message: t("Model uploaded successfully"),
      severity: "success",
      condition: isNotifyModelUploadSuccess,
    },
    {
      message: t(
        "The model was uploaded correcty, but I was unable to notify the success of the operation"
      ),
      severity: "error",
      condition: !!notifyModelUploadError,
    },
  ]);

  if (uploading)
    return (
      <UploadModelWrapper
        percentage={Math.round((chunksUploaded / totChunks) * 100)}
        style={{ position: "relative" }}
      >
        <Stack
          height="100%"
          alignItems="center"
          justifyContent="center"
          padding={2}
        >
          <Loader />
          <span className="percentage">
            {Math.round((chunksUploaded / totChunks) * 100)}%
          </span>
        </Stack>
        <div className="cover-div"></div>
      </UploadModelWrapper>
    );

  return (
    <UploadModelWrapper>
      <Dropzone ref={dropzoneRef} multiple={false} onDrop={handleUpload}>
        {({ getRootProps, getInputProps }) => (
          <Stack
            {...getRootProps()}
            className="dropzone-container"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <span>{t("Drag your model here")}</span>
            <MRButtonPrimary>
              <Stack direction="row" alignItems="center" gap={1}>
                <img src={uploadWhite} />
                {t("Upload")}
              </Stack>
            </MRButtonPrimary>
            <input {...getInputProps()} />
          </Stack>
        )}
      </Dropzone>
    </UploadModelWrapper>
  );
};

export default UploadModel;
