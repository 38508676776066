import styled from "styled-components";
import { TabList } from "@mui/lab";
import { colors } from "styles/theme";


const MRTabList = styled<typeof TabList>(TabList)`
  .MuiButtonBase-root.MuiTab-root {
    text-transform: none;
    font-size: 1.05em;
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: ${colors.white};
  }

  .MuiTabs-indicator {
    background-color: ${colors.green};
  }

  .MuiTabs-scroller {
    overflow-x: auto !important;
  }
`;

export default MRTabList;
