import Drawer from "@mui/material/Drawer";
import Sidenav from "components/Navs/Sidenav";
import { FC } from "react";
import { PersistentDrawerProps } from "./types";

const PersistentDrawer: FC<PersistentDrawerProps> = ({ drawerWidth }) => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        display: { xs: "none", sm: "none", md: "none", lg: "block" },
        "& .MuiDrawer-paper": { boxSizing: "border-box" },
      }}
      open={true}
    >
      <Sidenav open={true} />
    </Drawer>
  );
};

export default PersistentDrawer;
