import { FormControlLabel, IconButton, Radio, RadioGroup, Stack } from "@mui/material";
import { FC } from "react";
import { Inputs, initialValues, validationSchema } from "./model";
import { useFormik } from "formik";
import { MRTextField } from "components/material";
import { useTranslation } from "react-i18next";
import { LinkStatus } from "types/link";
import { MRButtonPrimary, MRButtonSecondary } from "components/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LinkFilterFromProps } from "./types";
import { LinkFilterFormWrapper } from "./styles";

const LinkFilterForm: FC<LinkFilterFromProps> = ({ onApplyFilters, onClearFilters, onClose, appliedFilters }) => {
  const { t } = useTranslation();
  const formik = useFormik<Inputs>({
    enableReinitialize: true,
    initialValues: appliedFilters ? { ...initialValues, ...appliedFilters } : initialValues,
    validationSchema,
    onSubmit: (acquisitionFilterInfo) => {
      onApplyFilters(acquisitionFilterInfo);
    },
  });
  return (
    <LinkFilterFormWrapper>
      <Stack height="100%" alignItems="start" justifyContent="center">
        <form className="filter-form" onSubmit={formik.handleSubmit}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <IconButton onClick={(e) => onClose()}>
              <ClearIcon />
            </IconButton>
            <MRButtonSecondary
              onClick={() => {
                onClearFilters();
                formik.resetForm();
              }}
            >
              {t("Clear Filters")}
            </MRButtonSecondary>
          </Stack>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <p>{t("Expiry")} </p>
            <p>{t("From date")} </p>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={formik.values.startDate}
              onChange={(value) => formik.setFieldValue("startDate", value)}
              renderInput={(params) => (
                <MRTextField
                  error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                  helperText={formik.touched.startDate && Boolean(formik.errors.startDate)}
                  name="startDate"
                  fullWidth
                  {...params}
                />
              )}
            />

            <p>{t("To date")} </p>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={formik.values.endDate}
              onChange={(value) => formik.setFieldValue("endDate", value)}
              renderInput={(params) => (
                <MRTextField
                  error={formik.touched.startDate && Boolean(formik.errors.endDate)}
                  helperText={formik.touched.endDate && Boolean(formik.errors.endDate)}
                  name="endDate"
                  fullWidth
                  {...params}
                />
              )}
            />
          </LocalizationProvider>

          <p>{t("Status")} </p>
          <Stack direction="row">
            <RadioGroup
              aria-label="Status"
              name="status"
              style={{
                flexDirection: "row",
              }}
              onChange={formik.handleChange}
              value={formik.values.status}
            >
              <FormControlLabel control={<Radio value={LinkStatus.ACTIVE} />} label="Active" />
              <FormControlLabel control={<Radio value={LinkStatus.INACTIVE} />} label="Inactive" />
            </RadioGroup>
          </Stack>
          <Stack marginTop="15px" direction="row" justifyContent="center" alignItems="center">
            <MRButtonPrimary type="submit">{t("Apply")}</MRButtonPrimary>
          </Stack>
        </form>
      </Stack>
    </LinkFilterFormWrapper>
  );
};

export default LinkFilterForm;
