import { MRCard } from "components/material";
import { FC } from "react";
import { FallbackProps } from "react-error-boundary";
import { ReactComponent as Logo } from "assets/icons/logo-white.svg";
import { Stack } from "@mui/material";
import { MRButtonPrimary } from "components/material/Button";
import { colors } from "styles/theme";
import i18n from "i18n";

const NoAcquisitionErrorFallback: FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <Stack height={"97vh"} justifyContent={"center"} alignItems={"center"}>
      <MRCard
        className="card"
        style={{ height: "fit-content", padding: "50px" }}
      >
        <Stack alignItems="center">
          <Logo width="70px" height="70px" />
          <h3 style={{ textAlign: "center" }}>
            {i18n.t("The acquisition model is no longer available!")}
          </h3>
          <span>{error.message}</span>
          <MRButtonPrimary
            type="submit"
            sx={{
              bgcolor: `${colors.green} !important`,
              color: `${colors.black} !important`,
            }}
            onClick={resetErrorBoundary}
          >
            {i18n.t("Navigate to homepage")}
          </MRButtonPrimary>
        </Stack>
      </MRCard>
    </Stack>
  );
};

export default NoAcquisitionErrorFallback;
