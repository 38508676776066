import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";

interface IEditUserSellerInfo {
  id: string;
  credits?: number;
  storage?: number;
  bandwidth?: number;
}

export default function useEditUserSellerInfo() {
  const queryClient = useQueryClient();

  const editUserSellerInfo = (updateProfile: IEditUserSellerInfo) => {
    const { id, ...data } = updateProfile;
    const request: Request = {
      url: generatePath(ENDPOINTS.users.sellerInfo, { id }),
      config: {
        method: "patch",
        data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    Error,
    IEditUserSellerInfo
  >(editUserSellerInfo, {
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.users.all]);
      queryClient.invalidateQueries([ENDPOINTS.users.one, variables.id]);
    },
  });

  return { data, mutate, ...rest };
}
