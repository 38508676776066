import styled from "styled-components";
import { colors } from "styles/theme";

export const SellerCataloguesWrapper = styled.div`
  .cta-credits span {
    color: ${colors.green};
    font-weight: bold;
  }

  .collections-grid-item {
    display: flex;
    justify-content: center;
  }

  .view-selector .MuiTab-root {
    min-width: unset;

    &.Mui-selected {
      color: ${colors.green};
    }
  }
`;
