export interface Share {
  _id: string;
  owner: string;
  user: string;
  recipient: string;
  blocks?: string[];
  blockName?: string;
  blocksCollection?: string;
  collectionName?: string;
  status: ShareStatus;
  forwardable: boolean;
  createdAt: string;
}

export enum ShareStatus {
  ACTIVE = "active",
  REMOVED = "removed",
}

export interface SharesMonthly {
  _id: number;
  sharesNumber: number;
}

export interface ShareChartsProps {
  year: string;
  userId?: string;
  blockId?: string;
  maxHeight?: number;
  children?: React.ReactNode;
}
