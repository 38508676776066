import { FC } from "react";
import { IncrementDecrementButtonWrapper } from "./styles";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

interface IncDecProps {
  setState: React.Dispatch<React.SetStateAction<number>>;
}

const IncrementDecrementButton: FC<IncDecProps> = ({ setState }) => {
  return (
    <IncrementDecrementButtonWrapper>
      <a onClick={() => setState((prev) => prev - 1)}>
        <RemoveCircleOutlineRoundedIcon className="icon" />
      </a>
      <HorizontalRuleIcon className="icon divider" />
      <a onClick={() => setState((prev) => prev + 1)}>
        <AddCircleOutlineRoundedIcon className="icon" />
      </a>
    </IncrementDecrementButtonWrapper>
  );
};
export default IncrementDecrementButton;
