import styled from "styled-components";
import { TextField, TextFieldProps } from "@mui/material";
import { colors } from "styles/theme";
import { search } from "assets/icons";

const StyledSearchbar = styled(TextField)`
  .MuiOutlinedInput-root {
    height: 40px;
    border-radius: 10px;
    background-color: ${colors.lightBlack};
  }

  fieldset {
    border: none;
  }
`;

const MRSearchbar = (props: TextFieldProps) => (
  <StyledSearchbar
    {...props}
    placeholder="Search"
    variant="outlined"
    InputProps={{ endAdornment: <img src={search} /> }}
  />
);

export default MRSearchbar;
