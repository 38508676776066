import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";

interface AddBlockToCollectionInfo {
  collectionId: string;
  blockId: string;
}

export default function useAddBlockToCollectionCollection() {
  const queryClient = useQueryClient();

  const addBlockToCollectionCollection = (data: AddBlockToCollectionInfo) => {
    const { collectionId, blockId } = data;
    const request: Request = {
      url: generatePath(ENDPOINTS.collections.addBlock, {
        collectionId,
        blockId,
      }),
      config: {
        method: "patch",
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    Error,
    AddBlockToCollectionInfo
  >(addBlockToCollectionCollection, {
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries([
        ENDPOINTS.collections.one,
        variables.collectionId,
      ]);
      queryClient.invalidateQueries([ENDPOINTS.collections.all]);
    },
  });

  return { data, mutate, ...rest };
}
