import { Dialog as MuiDialog } from "@mui/material";
import styled from "styled-components";
import { colors } from "styles/theme";

export const Dialog = styled(MuiDialog)`
  .dialog {
    background: ${colors.lightBlack};
    border: solid 1px;
    border-radius: 20px;
    padding: 26px 32px 55px;
  }
`;
