import styled from "styled-components";
import { colors } from "styles/theme";

export const MRLayoutWrapper = styled.div`
  height: 100%;

  main.layout-basic {
    min-height: 100%;
    height: 100%;
  }

  .MuiDrawer-root > .MuiPaper-root {
    border-right: 0px;
  }
`;

export const MobileNavigationWrapper = styled.div`
  .MuiBottomNavigation-root {
    background: ${colors.black};
    height: 75px;
  }

  .MuiBottomNavigation-root .MuiBottomNavigationAction-label.Mui-selected {
    color: ${colors.white};
  }

  .MuiBottomNavigation-root .MuiButtonBase-root {
    gap: 5px;
  }

  .MuiBottomNavigation-root .MuiSvgIcon-root {
    color: ${colors.green};
    width: 1.2em;
    height: 1.2em;
  }
`;
