import { FC } from "react";
import { BlockStatus } from "types/block";
import { BlockStatusCardWrapper } from "./styles";

const BlockStatusCard: FC<{ status: BlockStatus }> = ({ status }) => {
  const classNameByStatus = {
    [BlockStatus.AVAILABLE]: "available-card",
    [BlockStatus.SOLD]: "sold-card",
    [BlockStatus.HIDDEN]: "hidden-card",
    [BlockStatus.TRASHED]: "trashed-card",
    [BlockStatus.REMOVED]: "removed-card",
  };

  return (
    <BlockStatusCardWrapper>
      <div className={"block-card " + classNameByStatus[status]}>
        {((status?.charAt(0).toUpperCase() || "") + status?.slice(1)).replace("_", " ")}
      </div>
    </BlockStatusCardWrapper>
  );
};

export default BlockStatusCard;
