import styled from "styled-components";
import { Checkbox } from "@mui/material";
import { colors } from "styles/theme";

const MRCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: ${colors.green}!important;
  }
`;

export default MRCheckbox;
