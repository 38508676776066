import { createTheme, PaletteOptions } from "@mui/material";

export const colors = {
  white: "rgba(255,255,255,0.87)",
  primary: "#2DB5C7",
  black: "#000",
  lightBlack: "#0E131F",
  darkBlack: "#020402",
  green: "#2FE5A7",
  red: "#EF452F",
  lighterBlack: "#192530",
  blue: "#3772FF",
  blueVariant: "#3A36DB",
  yellow: "#F4D35E",
  gray: "#A9A9A9",
  mediumGray: "#ffffff33",
};

declare module "@mui/material/styles" {
  interface Theme {
    palette: PaletteOptions | undefined;
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: "#07C8C0",
    },
    text: {
      primary: colors.white,
    },
    background: {
      default: colors.black,
      paper: colors.lightBlack,
    },
  },
  typography: {
    fontFamily: "Barlow, sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
});
