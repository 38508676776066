import { Acquisition, AcquisitionInfoInputs } from "types/acquisition";
import * as Yup from "yup";

export const initialValues: AcquisitionInfoInputs = {
  length: 0,
  height: 0,
  depth: 0,
  weight: 0,
  material: "",
  geologicalFamily: "",
  placeOfAcquisition: "",
  createdAt: "",
  title: "",
  notes: "",
  owner: "",
  numberOfPhotos: 0,
};

export const validationSchema = Yup.object({
  length: Yup.number().required("Required"),
  height: Yup.number().required("Required"),
  depth: Yup.number().required("Required"),
  weight: Yup.number().required("Required"),
  material: Yup.string().required("Required"),
  geologicalFamily: Yup.string(),
  placeOfAcquisition: Yup.string().required("Required"),
  createdAt: Yup.date().typeError("Invalid Date"),
  title: Yup.string().required("Required"),
});

export const validationSchemaOwner = Yup.object({
  length: Yup.number().required("Required"),
  height: Yup.number().required("Required"),
  depth: Yup.number().required("Required"),
  weight: Yup.number().required("Required"),
  material: Yup.string().required("Required"),
  geologicalFamily: Yup.string(),
  placeOfAcquisition: Yup.string().required("Required"),
  createdAt: Yup.date().typeError("Invalid Date"),
  title: Yup.string().required("Required"),
  owner: Yup.string().required("Required"),
});

export const formatAcquisitionInfo = (acquisition: Acquisition) => {
  const formatted: AcquisitionInfoInputs = {
    length: acquisition.length,
    height: acquisition.height,
    depth: acquisition.depth,
    weight: acquisition.weight,
    material: acquisition.material,
    geologicalFamily: acquisition.geologicalFamily,
    placeOfAcquisition: acquisition.placeOfAcquisition,
    createdAt: acquisition.createdAt,
    title: acquisition.title,
    notes: acquisition.notes,
    status: acquisition.status,
  };

  return formatted;
};
