import { FC, useMemo, useState } from "react";
import { RowActionBaseProps, RowActionViewProps } from "./types";
import useToasts from "hooks/useToasts";
import Loader from "components/Loader";
import { useConfirmModal } from "context/confirmModal";
import { RowActionWrapper, ActionMenuWrapper } from "./styles";
import { MRButtonTertiary } from "components/material/Button";
import { MenuItem, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { MRMenu } from "components/material";
import { colors } from "styles/theme";
import { useTranslation } from "react-i18next";

const RowActionIcons: FC<RowActionViewProps> = ({ actions, askConfirm }) => {
  return (
    <>
      {actions.map((action, index) => (
        <img
          key={index}
          src={action.icon}
          onClick={(e) => {
            e.stopPropagation();
            action.confirmMessage
              ? askConfirm(action.confirmMessage, action.action)
              : action.action();
          }}
        />
      ))}
    </>
  );
};

const RowActionMenu: FC<RowActionViewProps> = ({ actions, askConfirm }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon
          fontSize="large"
          className="more-horizon-icon"
          sx={{
            cursor: "pointer",
            color: colors.green,
          }}
        />
      </IconButton>

      <MRMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ActionMenuWrapper>
          {actions.map((action, index) => (
            <MenuItem onClick={handleClose} key={index}>
              <MRButtonTertiary
                className="menu-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  action.confirmMessage
                    ? askConfirm(action.confirmMessage, action.action)
                    : action.action();
                  handleClose();
                }}
              >
                <img src={action.icon} alt="" className="button-icon" />
                {action.name}
              </MRButtonTertiary>
            </MenuItem>
          ))}
        </ActionMenuWrapper>
      </MRMenu>
    </>
  );
};

const RowActionsBase: FC<RowActionBaseProps> = ({ actions, variant }) => {
  const { openModal } = useConfirmModal();
  const { t } = useTranslation();

  const askConfirm = (message: string, action: () => void) => {
    openModal({
      title: message,
      text: t("The operation is not reversible"),
      confirmFunction: () => action(),
    });
  };

  const toasts = useMemo(
    () =>
      actions
        .map((action) =>
          action.actionState
            ? [
                {
                  severity: "success" as "success",
                  message: action.actionState.successMessage,
                  condition: !!action.actionState.isSuccess,
                },
                {
                  severity: "error" as "error",
                  message: action.actionState.errorMessage,
                  condition: !!action.actionState.isError,
                },
              ]
            : []
        )
        .reduce((acc, curr) => [...acc, ...curr], []),
    [actions]
  );

  const isLoading = useMemo(
    () =>
      actions.reduce(
        (acc, curr) =>
          acc || (curr.actionState ? curr.actionState.isLoading : false),
        false
      ),
    [actions]
  );

  useToasts(toasts);

  if (isLoading) return <Loader />;

  return (
    <RowActionWrapper>
      {variant === "icons" ? (
        <RowActionIcons actions={actions} askConfirm={askConfirm} />
      ) : (
        <RowActionMenu actions={actions} askConfirm={askConfirm} />
      )}
    </RowActionWrapper>
  );
};

export default RowActionsBase;
