import { Profile } from "./user";

export interface Block {
  _id: string;
  title: string;
  owner: string;
  ownerProfile?: Profile;
  status: BlockStatus;
  acquisition: string;
  placeOfAcquisition: string;
  dateOfAcquisition: string;
  ownerName: string;
  ownerUsername: string;
  createdAt: string;
  depth: number;
  height: number;
  length: number;
  weight: number;
  material: string;
  geologicalFamily: string;
  sharesCount: number;
  model: Model;
  trashedDate?: string;
}

export interface BlockInfoInputs {
  title: string;
  depth: number;
  height: number;
  length: number;
  weight: number;
  material: string;
  geologicalFamily: string;
}

export interface Model {
  size: number;
  contentType: string;
  url: string;
  thumbnail?: string;
  _id: string;
}

export enum BlockStatus {
  AVAILABLE = "available",
  HIDDEN = "hidden",
  TRASHED = "trashed",
  SOLD = "sold",
  REMOVED = "removed",
}
