import { FC, useMemo } from "react";
import { BulkActionProps, BulkAction } from "../BulkActionsControls/types";
import useRemoveTransfer from "hooks/services/transfers/useRemoveTransfer";
import useBulkAction from "hooks/useBulkAction";
import { trash2 } from "assets/icons";
import BulkActionsControlBase from "../BulkActionsControls/BulkActionsControlBase";
import { useTranslation } from "react-i18next";

const TransferBulkActionsControl: FC<BulkActionProps> = (props) => {
  const { mutateAsync: removeTransferAsync } = useRemoveTransfer();

  const { t } = useTranslation();

  const {
    bulkAction: removeTransferBulk,
    isLoading: isRemovingTransferBulk,
    bulkResult: removeTransferBulkResult,
  } = useBulkAction(removeTransferAsync);

  const actions: BulkAction[] = useMemo(
    () => [
      {
        name: t("Remove"),
        icon: trash2,
        confirmMessage: t("Are you sure you want to delete these transfers?"),
        bulkAction: removeTransferBulk,
        bulkActionLoading: isRemovingTransferBulk,
        bulkActionResult: removeTransferBulkResult,
      },
    ],
    [removeTransferBulk, isRemovingTransferBulk, removeTransferBulkResult]
  );
  return <BulkActionsControlBase {...props} actions={actions} />;
};

export default TransferBulkActionsControl;
