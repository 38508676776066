import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useInfiniteQuery } from "react-query";
import { Request } from "types/request";
import { PaginatedResponse } from "types/response";
import Error from "types/error";
import { Notification } from "types/notification";
import { NotificationPaginatedResponse } from "./types";

export default function useInfiniteNotifications(size?: number, search?: Record<string, string>) {
  const requestConfig = {
    queryKey: [ENDPOINTS.notifications.all, search && JSON.stringify(search)],
    queryFn: ({ pageParam = 1 }) => {
      const request: Request = {
        url: ENDPOINTS.notifications.all,
        config: {
          method: "GET",
          params: { page: pageParam, size, ...search },
        },
      };
      return client(request).then((res) => res.data);
    },
  };

  const { data, ...rest } = useInfiniteQuery<NotificationPaginatedResponse, Error>({
    ...requestConfig,
    getNextPageParam: (lastPage) => {
      if (lastPage.pagination.length) {
        return lastPage.pagination[0].size * lastPage.pagination[0].page >=
          lastPage.pagination[0].total
          ? undefined
          : lastPage.pagination[0].page + 1;
      }
      return undefined;
    },
  });

  return { data, ...rest };
}
