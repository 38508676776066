import Profile from ".";

const routes = [
  {
    index: true,
    name: "Profile",
    element: <Profile />,
  },
];

export default routes;
