import styled from "styled-components";
import Folder from "assets/images/folder.png";
import SharedFolder from "assets/images/shared_folder.png";
import { colors } from "styles/theme";
import CreateCollectionFolder from "assets/images/create-collection-folder.png";

export const CollectionWrapper = styled.div`
  width: 180px;
  margin: 0px 20px;

  .folder {
    background: url(${Folder});
    background-repeat: no-repeat;
    background-size: cover;
    width: 180px;
    height: 162px;
    flex-wrap: wrap;
  }

  .shared-folder {
    background: url(${SharedFolder});
    background-repeat: no-repeat;
    background-size: cover;
    width: 180px;
    height: 162px;
    flex-wrap: wrap;
  }

  .folder img {
    max-width: 60px;
    height: 40px;
  }

  .collection-blocks-thumbs {
    height: 120px;
    width: 180px;
    padding: 5px 10px;
  }

  .more-horizon-icon {
    color: ${colors.green};
  }

  .shared-more-horizon-icon {
    color: ${colors.primary};
  }

  .owned-more-horizon-icon {
    color: ${colors.green};
  }

  .adder {
    cursor: pointer;
  }

  .folder-col {
    height: 30px;
    width: 30px;
  }

  .thumbanil-container {
    height: 30px;
    width: 30px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .more-options-button-container {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    margin-bottom: -20px;
  }

  .collection-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const CollectionAdderWrapper = styled.div`
  width: 180px;

  .folder {
    background: url(${CreateCollectionFolder});
    background-repeat: no-repeat;
    background-size: cover;
    width: 180px;
    height: 162px;
    flex-wrap: wrap;
    cursor: pointer;
    margin-top: 20px;
  }
`;
