import useAcquisitionsMonthlyCount from "hooks/services/acquisitions/useAcquisitionsMonthlyCount";
import { FC, useMemo } from "react";
import { AreaChart, Area, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, TooltipProps } from "recharts";
import { colors } from "styles/theme";
import { AcquisitionChartWrapper } from "./styles";

const AcquisitionChart: FC<{ year: number }> = ({ year }) => {
  const COLORS = [colors.primary, colors.green];

  const {
    data: acquisitionsMonthly,
    isLoading: loadingAcquisitions,
    isError: acquisitionsError,
  } = useAcquisitionsMonthlyCount({ year: year.toString() });

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="tooltip-label">{`(${label})`}</p>
          <p className="tooltip-label">{`ACQUISITIONS`}</p>
          <p className="tooltip-value">{payload?.[0].value}</p>
        </div>
      );
    }

    return null;
  };

  const getData = useMemo(() => {
    if (!acquisitionsMonthly) return;
    const myData = months.map((month, idx) => {
      return {
        timeRange: month,
        acquisitionNumber: acquisitionsMonthly.find((el) => el._id === idx + 1)?.acquisitionNumber ?? 0,
      };
    });
    return myData;
  }, [acquisitionsMonthly]);

  return (
    <AcquisitionChartWrapper>
      <ResponsiveContainer width="100%" maxHeight={400} aspect={1}>
        <AreaChart data={getData}>
          <defs>
            <linearGradient id="colorUv" x1="0" x2="1" y1="0" y2="0">
              <stop offset="25%" stopColor="#4a47ffd5" stopOpacity={0.7} />
              <stop offset="75%" stopColor="#ff7dbed5" stopOpacity={0.9} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#9c9fa328" vertical={false} />
          <Tooltip content={<CustomTooltip />} position={{ y: 0 }} />
          <Area
            type="monotone"
            dataKey="acquisitionNumber"
            fill="url(#colorUv)"
            stroke="none"
            dot={{ stroke: "#FF69B4", strokeWidth: 4, r: 8, fill: "black" }}
          />
          <XAxis dataKey="timeRange" />
          <YAxis />
        </AreaChart>
      </ResponsiveContainer>
    </AcquisitionChartWrapper>
  );
};

export default AcquisitionChart;
