import { Stack } from "@mui/material";
import useUserBandwidth from "hooks/services/users/useUserBandwidth";
import useAuth from "hooks/useAuth";
import { FC, useEffect } from "react";
import ROLE from "types/role";
import { UserBandwidthWrapper } from "./style";

const UserBandwidth: FC = () => {
  const { user, me, isError, isSuccess } = useAuth();

  if (!user || user.roles !== ROLE.SELLER) return <></>;

  return (
    <UserBandwidthWrapper>
      <Stack direction="row" className="cta-bandwidth" gap={1}>
        <span>{Math.floor(Number(user?.bandwidth) * 100) / 100}</span> GB
      </Stack>
    </UserBandwidthWrapper>
  );
};

export default UserBandwidth;
