import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { User } from "types/user";
import { Response } from "types/response";

export default function useDeleteCompanyLogo() {
  const queryClient = useQueryClient();

  const deleteLogo = () => {
    const request: Request = {
      url: ENDPOINTS.users.deleteLogo,
      config: {
        method: "patch",
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<Response<User>, Error>(
    deleteLogo,
    {
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([]);
      },
    }
  );

  return { data, mutateAsync, ...rest };
}
