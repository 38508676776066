import { ButtonBase } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export default function GoBackButton(props:any) {

    return (
        <ButtonBase disableRipple onClick={props.onClick()}>
            <KeyboardArrowLeftIcon fontSize={"large"} sx={{color: "#4BBECF"}}/>
        </ButtonBase>
    )
}