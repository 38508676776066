import { FC } from "react";
import { ShareStatus } from "types/share";
import { ShareStatusCardWrapper } from "./styles";

const ShareStatusCard: FC<{ status: ShareStatus }> = ({ status }) => {
  const classNameByStatus = {
    [ShareStatus.ACTIVE]: "active-card",
    [ShareStatus.REMOVED]: "removed-card",
  };

  return (
    <ShareStatusCardWrapper>
      <div className={"block-card " + classNameByStatus[status]}>
        {(status.charAt(0).toUpperCase() + status.slice(1)).replace("_", " ")}
      </div>
    </ShareStatusCardWrapper>
  );
};

export default ShareStatusCard;
