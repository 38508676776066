import { FC, useMemo } from "react";
import { RowActionProps, RowAction } from "../RowActions/types";
import { useNavigate, generatePath } from "react-router-dom";
import RowActionsBase from "../RowActions/RowActionsBase";
import { edit, trash2 } from "assets/icons";
import useDeleteUser from "hooks/services/users/useDeleteUser";
import { useTranslation } from "react-i18next";
import useAuth from "hooks/useAuth";

const UserRowActions: FC<RowActionProps> = ({ id }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const {
    mutate: deleteUser,
    isLoading: isDeletingUser,
    isSuccess: isDeletedUser,
    error: deleteUserError,
  } = useDeleteUser();

  const { t } = useTranslation();

  const actions: RowAction[] = useMemo(
    () => [
      {
        icon: edit,
        name: "Edit",
        action: () => navigate(generatePath("/users/:id/profile", { id })),
      },
      ...(user?._id !== id
        ? [
            {
              icon: trash2,
              action: () => deleteUser(id),
              name: t("Remove"),
              confirmMessage: t("Are you sure you want to delete this user?"),
              actionState: {
                isLoading: isDeletingUser,
                isSuccess: isDeletedUser,
                successMessage: t("User deleted successfully"),
                isError: !!deleteUserError,
                errorMessage: t(
                  "An error occurred while deleting the block, please try again"
                ),
              },
            },
          ]
        : []),
    ],
    [
      navigate,
      generatePath,
      deleteUser,
      isDeletingUser,
      isDeletedUser,
      deleteUserError,
    ]
  );

  return <RowActionsBase variant="menu" actions={actions} />;
};

export default UserRowActions;
