import { Stack } from "@mui/material";
import { MRPagination, MRDrawer } from "components/material";
import { FC, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useMultiSelect from "hooks/useMultiSelect";
import useAcquisitions from "hooks/services/acquisitions/useAcquisitions";
import { PAGE_SIZE } from "config/pagination";
import ROLE from "types/role";
import { AcquisitionTableProps } from "./types";
import TitleCounter from "components/TablesComponents/TitleCounter";
import MRAcquisitionTable from "components/TablesComponents/Acquisition/AcquistionTable";
import AcquisitionAdminBulkActionControl from "components/TablesComponents/Acquisition/AcquisitionAdminBulkActionsControl";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import AcquisitionFilterForm from "components/Acquisition/AcquisitionFilterForm";
import { Inputs as AcquisitionFilters } from "components/Acquisition/AcquisitionFilterForm/model";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";

const AcquisitionTable: FC<AcquisitionTableProps> = ({ ownerId }) => {
  const [page, setPage] = useState(1);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<AcquisitionFilters | null>(null);

  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("_id");

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data: acquisitions,
    isLoading: loadingAcquisitions,
    isError: acquisitionError,
  } = useAcquisitions({
    page,
    size: PAGE_SIZE,
    order,
    sort: orderBy,
    owner: ownerId,
    ...(formattedFilters && formattedFilters),
  });

  const sortHandler = (orderBy: string) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(orderBy);
  };

  if (acquisitionError) throw new Error("Cannot get acquisition");

  const { selected, toggleSelection, toggleAll, resetSelection } = useMultiSelect();
  const { t } = useTranslation();

  return (
    <>
      <MRDrawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={toggleDrawer(false, setFilterDrawerOpen)}
      >
        <AcquisitionFilterForm
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </MRDrawer>
      <Stack
        direction="row"
        gap={3}
        flexWrap="wrap"
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "5px", sm: 3 },
        }}
      >
        <TitleCounter
          title={t("Models to process")}
          count={acquisitions?.pagination[0]?.total || 0}
        />

        <Stack direction="row" alignItems="center" gap="10px">
          <FilterButton
            onClick={() => setFilterDrawerOpen(true)}
            activeFilters={formattedFilters ? Object.keys(formattedFilters).length : 0}
          />
          <AcquisitionAdminBulkActionControl
            selectedIds={selected}
            resetSelection={resetSelection}
          />
        </Stack>
      </Stack>
      <MRAcquisitionTable
        acquisitions={acquisitions?.data || []}
        selected={selected}
        toggleSelection={toggleSelection}
        toggleAll={toggleAll}
        userRole={ROLE.ADMIN}
        order={order}
        orderBy={orderBy}
        sortHandler={sortHandler}
      />
      <Stack direction="row" justifyContent="center">
        {acquisitions?.data && acquisitions.data.length ? (
          <MRPagination
            count={
              acquisitions ? Math.ceil(acquisitions?.pagination[0]?.total / PAGE_SIZE) || 1 : 1
            }
            page={page}
            onChange={(e, page) => setPage(page)}
          />
        ) : (
          <NoDataRow>{t("No Data")}</NoDataRow>
        )}
      </Stack>
    </>
  );
};

export default AcquisitionTable;
