import { FC } from "react";
import { useRoutes } from "react-router-dom";
import { routes } from "./routes";
import Title from "./Title";

const AppRoutes: FC = () => {
  return useRoutes(
    routes.map((route) => ({
      ...route,
      element: <Title title={route.name}>{route.element}</Title>,
    }))
  );
};

export default AppRoutes;
