import { FC, useState } from "react";
import { InputAdornment, Stack, TextField } from "@mui/material";
import Loader from "components/Loader";
import { colors } from "styles/theme";

import { flattenPages } from "helpers";

import { CollectionBlocksListProps } from "./types";
import { CollectionBlocksListWrapper } from "./styles";

import useInfiniteBlocks from "hooks/services/blocks/useInfiniteBlocks";

import defaultThumbnail from "assets/images/default-thumb.png";
import { useDebounce } from "use-debounce";
import { Block } from "types/block";

import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

const CollectionBlocksList: FC<CollectionBlocksListProps> = ({
  selected,
  setSelected,
}) => {
  const [text, setText] = useState<string>("");
  const [debouncedText] = useDebounce(text, 500);

  const { t } = useTranslation();

  const {
    data: blocks,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteBlocks({ size: 6, query: { text: debouncedText } });

  const handleScroll = (e: any) => {
    if (
      e.target.clientHeight + e.target.scrollTop === e.target.scrollHeight &&
      hasNextPage
    ) {
      fetchNextPage();
    }
  };

  const pluralize = (count: number) => {
    return {
      block: count === 1 ? t("model") : t("models"),
      selected: count === 1 ? t("selected") : t("selected"),
    };
  };

  const toggleSelected = (block: Block) => {
    if (isSelected(block))
      setSelected(selected.filter((selectedId) => selectedId !== block._id));
    else setSelected([...selected, block._id]);
  };

  const isSelected = (block: Block) => {
    return selected.includes(block._id);
  };

  const handleSearchChange = (e: any) => {
    setText(e.target.value);
  };

  return (
    <CollectionBlocksListWrapper>
      <Stack direction="column" gap={1}>
        <Stack direction="row" gap={2} alignItems="center">
          <TextField
            placeholder={t("Type your block name")}
            value={text}
            onChange={handleSearchChange}
            sx={{ flexGrow: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Stack direction="row" justifyContent="flex-end">
            <div
              style={{
                fontWeight: "600",
                marginBottom: "5px",
                visibility: selected.length > 0 ? "visible" : "hidden",
              }}
            >
              {selected.length} {pluralize(selected.length).block}{" "}
              {pluralize(selected.length).selected}
            </div>
          </Stack>
        </Stack>

        <Stack
          direction="column"
          className="blocks-container"
          gap={2}
          onScroll={handleScroll}
        >
          {isLoading ? (
            <Loader />
          ) : (
            flattenPages(blocks).map((block) => (
              <Stack
                key={block._id}
                direction="row"
                className="block-row"
                alignItems="center"
                justifyContent={"space-between"}
                gap={2}
                sx={{
                  ...(isSelected(block) && {
                    border: `1px solid ${colors.green}`,
                  }),
                }}
                onClick={() => toggleSelected(block)}
              >
                <Stack flexGrow={1}>
                  <img
                    src={block.model.thumbnail || defaultThumbnail}
                    alt="block thumbnail"
                    width="60"
                  />
                </Stack>

                <Stack sx={{ maxWidth: "140px" }}>{block.title}</Stack>
                <Stack flexGrow={1}>{block.material}</Stack>
                <Stack
                  flexGrow={1}
                >{`${block.height} x ${block.length} x ${block.depth} cm`}</Stack>
              </Stack>
            ))
          )}
          {isFetchingNextPage ? <Loader /> : <></>}
        </Stack>
      </Stack>
    </CollectionBlocksListWrapper>
  );
};

export default CollectionBlocksList;
