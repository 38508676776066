import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { BlockProps } from "./types";
import { BlockCardLabel, BlockCardText, BlockGridElemWrapper } from "./styles";
import { Stack } from "@mui/material";
import { eyeOff, share } from "assets/icons/";
import { threeD } from "assets/icons/";
import ShareModal from "components/Share/ShareModal";
import BlockActions from "./BlockActions";
import BlockCard from "./BlockCard";
import PATH from "routing/path";
import { Link } from "react-router-dom";
import { colors } from "styles/theme";
import moment from "moment";
import config from "config";
import defaultThumbnail from "assets/images/default-thumb.png";
import { MRCheckbox } from "components/material";
import ThumbDisplayer from "components/ThumbDisplayer";

const Block: FC<BlockProps> = ({
  block,
  variant,
  viewOnly,
  collection,
  toggleSelection,
  selected,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const getLink = (variant: string) => {
    if (variant === "owned") return `${PATH.MODELS}/edit/${block._id}`;
    if (variant === "shared") return `${PATH.MODELS}/${block._id}`;
    return "#";
  };

  return (
    <BlockGridElemWrapper>
      <ShareModal open={open} setOpen={setOpen} blockId={block._id} />
      <Stack className={selected ? "selected" : ""}>
        <BlockCard variant={variant} status={block.status}>
          {!viewOnly && (
            <Stack direction="row" justifyContent="space-between">
              {variant !== "shared" && variant !== "trashed" && (
                <MRCheckbox
                  checked={selected}
                  onChange={() => toggleSelection && toggleSelection(block._id)}
                />
              )}
              <BlockActions
                variant={variant}
                block={block}
                collection={collection}
              />
            </Stack>
          )}

          <Link to={getLink(variant)}>
            <Stack alignItems="center" className="thumbnail-container">
              <ThumbDisplayer
                imgProps={{
                  src: block.model?.thumbnail || defaultThumbnail,
                  style:
                    variant === "trashed" ? { filter: "brightness(50%)" } : {},
                  alt: "Block Thumbnail",
                }}
              />

              {variant === "trashed" && (
                <img src={eyeOff} alt="eye-off" className="eye-off" />
              )}
            </Stack>
          </Link>

          <Stack direction="column" gap={2}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="column" className="flex-grow-elem">
                {variant === "owned" && (
                  <>
                    <BlockCardLabel>{t("Model name")}</BlockCardLabel>
                    <BlockCardText>{block.title}</BlockCardText>
                  </>
                )}
                {variant === "shared" && (
                  <>
                    <BlockCardLabel>{t("Owner")}</BlockCardLabel>
                    <BlockCardText
                      title={block.ownerName}
                      className="owner-name"
                      style={{ color: colors.primary }}
                    >
                      {block.ownerName}
                    </BlockCardText>
                  </>
                )}
                {variant === "trashed" && (
                  <>
                    <BlockCardLabel>{block.title}</BlockCardLabel>
                    <BlockCardText style={{ color: colors.red }}>
                      {t("Deleted on ") +
                        moment(block.trashedDate).format("D MMM YYYY")}
                      <br />
                      {t("Defenitive delete within ") +
                        config.blocks.trashExpiration +
                        " " +
                        t("days")}
                    </BlockCardText>
                  </>
                )}
              </Stack>
              {!viewOnly && variant === "owned" && (
                <img
                  src={share}
                  style={{ cursor: "pointer" }}
                  alt="Share"
                  onClick={() => setOpen(true)}
                />
              )}
              {!viewOnly && variant === "shared" && (
                <Link to={`/viewer/block/${block._id}`}>
                  <img src={threeD} style={{ cursor: "pointer" }} alt="3D" />
                </Link>
              )}
            </Stack>
            <Stack direction="column">
              <BlockCardLabel>{t("Material")}</BlockCardLabel>
              <BlockCardText>{block.material}</BlockCardText>
            </Stack>
            <Stack direction="column">
              <BlockCardLabel>{t("Sizes")}</BlockCardLabel>
              <BlockCardText>
                {`${t("A:")} ${block.height}cm x ${t("L:")} ${
                  block.length
                }cm x ${t("P:")} ${block.depth}cm`}
              </BlockCardText>
            </Stack>
          </Stack>
        </BlockCard>
      </Stack>
    </BlockGridElemWrapper>
  );
};

export default Block;
