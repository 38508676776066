import styled from "styled-components";
import { colors } from "styles/theme";

export const BlockStatusCardWrapper = styled.div`
  .available-card {
    color: ${colors.green};
  }
  .hidden-card {
    color: ${colors.yellow};
  }

  .removed-card,
  .trashed-card {
    color: ${colors.red};
  }
  .sold-sold {
    color: ${colors.blue};
  }

  .block-card {
    background: ${colors.lighterBlack};
    padding: 5px 10px;
    border-radius: 15px;
  }
`;

export const BlockUserTableWrapper = styled.div<{
  variant: "owned" | "shared";
}>`
  .share-model-cell {
    color: ${(props) => (props.variant === "owned" ? colors.green : colors.primary)};
    img {
      margin-right: 10px;
      height: 15px;
      width: 15px;
      vertical-align: middle;
    }
  }

  .thumbnail-img {
    height: 90px;
    width: 90px;
    object-fit: contain;
  }

  .model-quick-action {
    cursor: pointer;
  }
`;
