import { FC, useState } from "react";
import { TableWrapper } from "../Tables/styles";
import { MRCheckbox, MRTable } from "components/material";
import {
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import ROLE from "types/role";
import { useTranslation } from "react-i18next";
import UserRowActions from "./UserRowActions";
import UserStatusCard from "./UserStatusCard";
import { UserTableProps } from "./types";
import { UserTableWrapper } from "./styles";
import { TableColumn } from "../Tables/types";
import { Link } from "react-router-dom";

export const UserRole: FC<{ role: ROLE }> = ({ role }) => {
  if (role === ROLE.ADMIN) return <span className="admin-tag">ADMIN</span>;
  if (role === ROLE.OPERATOR)
    return <span className="admin-tag">OPERATOR</span>;
  if (role === ROLE.SELLER) return <span className="pro-tag">PR0</span>;
  return <>FREE</>;
};

const UserTable: FC<UserTableProps> = ({
  users,
  selected,
  toggleAll,
  toggleSelection,
  order,
  orderBy,
  sortHandler,
}) => {
  const { t } = useTranslation();

  const columns: Array<TableColumn> = [
    {
      name: "username",
      label: t("E-mail"),
      sortable: true,
    },
    {
      name: "profile.firstname",
      label: t("Name"),
      sortable: true,
    },
    {
      name: "roles",
      label: t("Type"),
      sortable: true,
    },
    {
      name: "profile.companyName",
      label: t("Company"),
      sortable: true,
    },
    {
      name: "phone",
      label: t("Phone"),
      sortable: false,
    },
    {
      name: "totalBlocks",
      label: t("Uploaded Models"),
      sortable: true,
    },
    {
      name: "activeBlocks",
      label: t("Active Models"),
      sortable: true,
    },
    {
      name: "status",
      label: t("Status"),
      sortable: true,
    },
  ];

  return (
    <TableWrapper>
      <UserTableWrapper>
        <MRTable>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <MRCheckbox
                  checked={
                    selected.length === users.length && selected.length !== 0
                  }
                  onChange={() =>
                    toggleAll(users.map((user) => user._id) || [])
                  }
                />
              </TableCell>
              {columns.map((column) =>
                column.sortable ? (
                  <TableCell
                    key={column.name}
                    sortDirection={orderBy === column.name ? order : false}
                    align="center"
                  >
                    <TableSortLabel
                      active={orderBy === column.name}
                      direction={orderBy === column.name ? order : "asc"}
                      onClick={() => sortHandler(column.name)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell key={column.name} align="center">
                    {column.label}
                  </TableCell>
                )
              )}
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell align="center">
                  <MRCheckbox
                    checked={selected.includes(user._id)}
                    onChange={() => toggleSelection(user._id)}
                  />
                </TableCell>
                <TableCell align="center">
                  <Link
                    style={{ cursor: "pointer" }}
                    to={`/users/${user._id}/profile`}
                  >
                    {user.username}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  {user.profile
                    ? `${user.profile.firstname} ${user.profile.lastname}`
                    : "N/D"}
                </TableCell>
                <TableCell align="center">
                  <UserRole role={user.roles} />
                </TableCell>
                <TableCell align="center">
                  {user.profile ? user.profile.companyName : "N/D"}
                </TableCell>
                <TableCell align="center">
                  {user.profile ? user.profile.phone : "N/D"}
                </TableCell>
                <TableCell align="center"> {user.totalBlocks} </TableCell>
                <TableCell align="center"> {user.activeBlocks} </TableCell>
                <TableCell align="center">
                  <UserStatusCard status={user.status} />
                </TableCell>
                <TableCell align="center">
                  <UserRowActions id={user._id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MRTable>
      </UserTableWrapper>
    </TableWrapper>
  );
};

export default UserTable;
