import NoAcquisitionErrorFallback from "components/ErrorFallbacks/NoAcquisitionErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import PATH from "routing/path";
import ModelsToProcess from "./views/all";
import ModelToProcess from "./views/single";
import { windowReplace } from "utils/helpers";
import NewModelToProcess from "pages/models-to-process/views/new";

const onResetErrorBoundary = () => {
  //refresh all state and redirect to homepage
  windowReplace(PATH.DASHBOARD);
};

const routes = [
  {
    index: true,
    name: "Acquisitions",
    element: <ModelsToProcess />,
  },
  {
    path: "create",
    name: "New Acquisition",
    element: <NewModelToProcess />,
  },
  {
    path: ":id",
    name: "Acquisition Detail",
    element: (
      <ErrorBoundary
        FallbackComponent={NoAcquisitionErrorFallback}
        onReset={onResetErrorBoundary}
      >
        <ModelToProcess />
      </ErrorBoundary>
    ),
  },
];

export default routes;
