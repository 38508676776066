import { NavigationItem } from "../types";
import i18n from "i18n";
import PATH from "routing/path";
import { box } from "assets/icons";
import { user } from "assets/icons";
import { usersPlain } from "assets/icons";
import { folder } from "assets/icons";
import HomeIcon from "@mui/icons-material/Home";

export const buyerNavigation: NavigationItem[] = [
  {
    name: "dashboard",
    label: i18n.t("Dashboard"),
    path: PATH.DASHBOARD,
    icon: <HomeIcon />,
  },
  {
    name: "models",
    label: i18n.t("Models"),
    path: PATH.MODELS,
    icon: <img src={box} alt="Box" />,
  },
  {
    name: "catalogues",
    label: i18n.t("Catalogues"),
    path: PATH.CATALOGUES,
    icon: <img src={folder} alt="Folder" />,
  },
  {
    name: "profile",
    label: i18n.t("Profile"),
    path: PATH.PROFILE,
    icon: <img src={user} alt="User" />,
  },
  {
    name: "contacts",
    label: i18n.t("Contacts"),
    path: PATH.CONTACTS,
    icon: <img src={usersPlain} alt="Users" />,
  },
];
