import { Badge, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { PAGE_SIZE } from "config/pagination";
import React from "react";
import NotificationItem from "./NotificationItem";
import Loader from "components/Loader";
import useInfiniteNotifications from "hooks/services/notifications/useInfiniteNotifications";
import { flattenPages } from "helpers";
import { PopoverContainer } from "./styles";
import NoNotification from "./NoNotification";
import useReadNotifications from "hooks/services/notifications/useReadNotifications";

interface NotificationNavProps {
  navOpen: boolean;
}

const NotificationNavItem: FC<NotificationNavProps> = ({ navOpen }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const {
    data: notifications,
    isLoading: isLoadingNofications,
    error: notificationsError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteNotifications(PAGE_SIZE);

  const { mutate: readNotifications } = useReadNotifications();

  const handleScroll = (e: any) => {
    if (e.target.clientHeight + e.target.scrollTop === e.target.scrollHeight && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (!open) return;
    const notificationIds = flattenPages(notifications)
      .filter((notification) => !notification.readed)
      .map((n) => n._id as string);
    if (!notificationIds.length) return;
    readNotifications({ notifications: notificationIds });
  }, [open, notifications]);

  if (notificationsError) throw new Error("Cannot get notifications");

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: "center",
            px: 2.5,
          }}
          onClick={handleClick}
        >
          {true ? (
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: navOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Badge badgeContent={notifications?.pages[0]?.notificationData[0]?.unread || 0} color={"primary"}>
                <NotificationsActiveIcon />
              </Badge>
            </ListItemIcon>
          ) : (
            ""
          )}
          <ListItemText primary={t("Notifications")} sx={{ opacity: navOpen ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>

      <PopoverContainer>
        <Popover
          sx={{ display: { xs: "none", sm: "block" } }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              maxHeight: "500px",
              marginLeft: "20px",
              boxShadow: "0px 7px 29px 0px #2db5c718",
            },
            onScroll: (e) => handleScroll(e),
          }}
        >
          {flattenPages(notifications).map((n) => {
            return (
              <React.Fragment key={n._id}>
                <NotificationItem notification={n} />
              </React.Fragment>
            );
          })}
          {isLoadingNofications && <Loader />}
          {notifications && flattenPages(notifications).length === 0 && <NoNotification />}
        </Popover>
      </PopoverContainer>
    </>
  );
};

export default NotificationNavItem;
