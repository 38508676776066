import { Fade, Stack } from "@mui/material";
import { TabPanel as MuiTabPanel, TabPanelProps as MuiTabPanelProps } from "@mui/lab";
import { FC } from "react";

interface TabPanelProps extends MuiTabPanelProps {
  value: string;
  active: string;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, active, ...props }) => {
  return (
    <MuiTabPanel value={value} {...props}>
      <Fade in={active === value}>
        <Stack>{children}</Stack>
      </Fade>
    </MuiTabPanel>
  );
};

export default TabPanel;
