import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";
import { BlockInfoInputs } from "types/block";

interface IEditBlockInfo extends BlockInfoInputs {
  id: string;
}

export default function useEditBlockInfo() {
  const queryClient = useQueryClient();

  const editBlockInfo = (updateBlockInfo: IEditBlockInfo) => {
    const { id, ...data } = updateBlockInfo;
    const request: Request = {
      url: generatePath(ENDPOINTS.block.one, { id }),
      config: {
        method: "patch",
        data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    Error,
    IEditBlockInfo
  >(editBlockInfo, {
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.block.all]);
      queryClient.invalidateQueries([ENDPOINTS.block.one, variables.id]);
    },
  });

  return { data, mutate, ...rest };
}
