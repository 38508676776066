import useToasts from "hooks/useToasts";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import CreateCollection from "./CreateCollection";
import Collection from "components/Collection/Single";
import useInfiniteCollection from "hooks/services/collections/useInfiniteCollections";
import { PAGE_SIZE } from "config/pagination";
import { Collection as ICollection } from "types/collection";
import HorizontalScroller from "components/HorizontalScroller";
import {
  HorizontalScrollerContainer,
  ScrollerHeader,
} from "components/HorizontalScroller/styles";
import { flattenPages } from "helpers";
import { HorizontalCollectionsProps } from "./types";
import PATH from "routing/path";
import { useNavigate } from "react-router-dom";

const HorizontalCollections: FC<HorizontalCollectionsProps> = ({
  header = true,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: pages,
    isLoading: isLoadingCollections,
    error: CollectionsError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteCollection({ size: PAGE_SIZE });

  useToasts([
    {
      severity: "error",
      message: t("There was an error. Cannot fetch collections."),
      condition: !!CollectionsError,
    },
  ]);

  return (
    <HorizontalScrollerContainer>
      {header && (
        <ScrollerHeader>
          <h4>{t("My collections")}</h4>
          <span onClick={() => navigate(PATH.CATALOGUES)}>{t("View all")}</span>
        </ScrollerHeader>
      )}

      <HorizontalScroller
        isLoading={isLoadingCollections}
        hasNextPage={hasNextPage}
        fetchNext={fetchNextPage}
        isLoadingNextPage={isFetchingNextPage}
      >
        <CreateCollection />

        {flattenPages(pages).map((collection: ICollection, idx) => {
          return (
            <Collection
              key={collection._id}
              collection={collection}
              variant="owned"
            />
          );
        })}
      </HorizontalScroller>
    </HorizontalScrollerContainer>
  );
};

export default HorizontalCollections;
