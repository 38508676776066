import useAuth from "hooks/useAuth";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PATH from "routing/path";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "assets/icons/logo-white.svg";
import MRCard from "components/material/Card";
import { colors } from "styles/theme";
import { SignupPage } from "./styles";
import SignupFreeForm from "./SignupFreeForm";
import { MRButtonPrimary } from "components/material/Button";

const SignupFree: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    if (user) {
      navigate(PATH.LOGIN);
    }
  }, [navigate, user]);

  return (
    <SignupPage>
      <Stack direction="row" justifyContent={"center"} alignItems="center">
        <Stack
          direction="column"
          justifyContent={"center"}
          alignItems={"center"}
        >
          <MRCard className="signup-card">
            <Stack sx={{ marginBottom: "30px" }} alignItems="center">
              <Logo width="70px" height="70px" />
              {(step == 0 || step == 1) && (
                <>
                  <h3 style={{ marginBottom: "0px" }}>
                    {t("Welcome to Marmoreal")}
                  </h3>
                  <span>
                    {t("Already have an account?")}{" "}
                    <a
                      href={PATH.LOGIN}
                      style={{ color: colors.green, textDecoration: "none" }}
                    >
                      {t("Log in")}
                    </a>
                  </span>
                  <SignupFreeForm step={step} setStep={setStep} />
                </>
              )}

              {step === 2 && (
                <Stack direction="column" gap={3} alignItems="center">
                  <Typography variant="h5" sx={{ textAlign: "center" }}>
                    {t("Thank you for signin up")}
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    {t(
                      "We have sent you an email, please read the instructions and click on the link to verify your account"
                    )}
                  </Typography>
                  <MRButtonPrimary
                    variant="contained"
                    onClick={() => navigate(PATH.LOGIN)}
                  >
                    GO BACK TO LOGIN
                  </MRButtonPrimary>
                </Stack>
              )}
            </Stack>
          </MRCard>
        </Stack>
      </Stack>
    </SignupPage>
  );
};

export default SignupFree;
