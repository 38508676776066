import * as Yup from "yup";
import i18n from "i18n";

export const validationSchema = Yup.object({
  newPassword: Yup.string().required(i18n.t("Password is required")),
});

export const initialValues = {
  newPassword: "",
};
