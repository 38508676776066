import { FC, useState } from "react";
import { Notification, NotificationType } from "types/notification";
import { NotificationItemProps } from "./types";
import { generatePath, useNavigate } from "react-router-dom";
import { NotifyContainer } from "./styles";
import moment from "moment";
import AcceptTransferModal from "components/Transfer/AcceptTransferModal";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const notificationMaker = (notification: Notification) => {
  switch (notification.type) {
    case NotificationType.FRIEND_REQ_ACC: {
      const Body: FC<{ width: string | undefined }> = ({ width }) => {
        const navigate = useNavigate();
        const { t } = useTranslation();
        return (
          <NotifyContainer
            className="notify-container"
            style={{ width: width ?? "350px" }}
            onClick={() => navigate("/contacts")}
          >
            <Stack>
              <div className="notify-Body">
                <span style={{ fontWeight: "bold" }}>
                  {notification.data.userFullname}
                </span>{" "}
                (
                <span className="notify-user-name">
                  {notification.data.userEmail}
                </span>
                ) {t("accepted your contact request.")}
              </div>
              <p className="from-creation-to-now">
                {moment(notification.createdAt).fromNow()}
              </p>
            </Stack>
          </NotifyContainer>
        );
      };

      return Body;
    }
    case NotificationType.FRIEND_REQ_REC: {
      const Body: FC<{ width: string | undefined }> = ({ width }) => {
        const navigate = useNavigate();
        const { t } = useTranslation();
        return (
          <NotifyContainer
            className="notify-container"
            style={{ width: width ?? "350px" }}
            onClick={() => navigate("/contacts/requests")}
          >
            <Stack>
              <div className="notify-Body">
                <span style={{ fontWeight: "bold" }}>
                  {notification.data.userFullname}
                </span>{" "}
                (
                <span className="notify-user-name">
                  {notification.data.userEmail}
                </span>
                ) {t("sent you a contact request.")}
              </div>
              <p className="from-creation-to-now">
                {moment(notification.createdAt).fromNow()}
              </p>
            </Stack>
          </NotifyContainer>
        );
      };

      return Body;
    }

    case NotificationType.ACQ_PHOTOS_UPL: {
      const Body: FC<{ width: string | undefined }> = ({ width }) => {
        const navigate = useNavigate();
        const { t } = useTranslation();
        return (
          <NotifyContainer
            className="notify-container"
            style={{ width: width ?? "350px" }}
            onClick={() =>
              navigate(
                generatePath("/models-to-process/:id", {
                  id: notification.data.acquisitionId,
                })
              )
            }
          >
            <Stack>
              <div className="notify-Body">
                <span style={{ fontWeight: "bold" }}>
                  {notification.data.userFullname}
                </span>{" "}
                (
                <span className="notify-user-name">
                  {notification.data.userEmail}
                </span>
                ) {t("uploaded photos for model")}{" "}
                <span
                  style={{ fontWeight: "bold" }}
                >{` ${notification.data.acquisitionTitle}`}</span>
              </div>
              <p className="from-creation-to-now">
                {moment(notification.createdAt).fromNow()}
              </p>
            </Stack>
          </NotifyContainer>
        );
      };

      return Body;
    }
    case NotificationType.MODEL_UPL: {
      const Body: FC<{ width: string | undefined }> = ({ width }) => {
        const navigate = useNavigate();
        const { t } = useTranslation();
        return (
          <NotifyContainer
            className="notify-container"
            style={{ width: width ?? "350px" }}
            onClick={() =>
              navigate(
                generatePath("/models/:id", { id: notification.data.modelId })
              )
            }
          >
            <Stack>
              <div className="notify-Body">
                {t("Your model:")}
                <span
                  style={{ fontWeight: "bold" }}
                >{` ${notification.data.modelTitle} `}</span>
                {t("was uploaded.")}
              </div>
              <p className="from-creation-to-now">
                {moment(notification.createdAt).fromNow()}
              </p>
            </Stack>
          </NotifyContainer>
        );
      };

      return Body;
    }
    case NotificationType.SHARE_REC: {
      const Body: FC<{ width: string | undefined }> = ({ width }) => {
        const navigate = useNavigate();
        const { t } = useTranslation();
        return (
          <NotifyContainer
            className="notify-container"
            style={{ width: width ?? "350px" }}
            onClick={() => {
              if (notification.data.collectionId) {
                navigate(
                  generatePath("catalogues/:id", {
                    id: notification.data.collectionId,
                  })
                );
                return;
              }
              if (notification.data.blockId) {
                navigate(
                  generatePath("/models/:id", { id: notification.data.blockId })
                );
                return;
              }
            }}
          >
            <Stack>
              <div className="notify-Body">
                <span style={{ fontWeight: "bold" }}>
                  {notification.data.userFullname}
                </span>{" "}
                (
                <span className="notify-user-name">
                  {notification.data.userEmail}
                </span>
                ) {t("shared with you a")}
                <span>{`${
                  notification.data.collectionId ? " collection" : " model"
                }`}</span>
              </div>
              <p className="from-creation-to-now">
                {moment(notification.createdAt).fromNow()}
              </p>
            </Stack>
          </NotifyContainer>
        );
      };

      return Body;
    }
    case NotificationType.SHARE_RMV: {
      const Body: FC<{ width: string | undefined }> = ({ width }) => {
        const { t } = useTranslation();
        return (
          <NotifyContainer
            className="notify-container"
            style={{ width: width ?? "350px" }}
          >
            <Stack>
              <div className="notify-Body">
                <span style={{ fontWeight: "bold" }}>
                  {notification.data.userFullname}
                </span>{" "}
                (
                <span className="notify-user-name">
                  {notification.data.userEmail}
                </span>
                ) {t("removed the share of ")}
                <span>{`${
                  notification.data.collectionId ? " collection" : " model"
                } ${notification.data.title}`}</span>
              </div>
              <p className="from-creation-to-now">
                {moment(notification.createdAt).fromNow()}
              </p>
            </Stack>
          </NotifyContainer>
        );
      };

      return Body;
    }
    case NotificationType.TRANSFER_ACCEPTED: {
      const Body: FC<{ width: string | undefined }> = ({ width }) => {
        const navigate = useNavigate();
        const { t } = useTranslation();
        return (
          <NotifyContainer
            className="notify-container"
            style={{ width: width ?? "350px" }}
            onClick={() => navigate("/models")}
          >
            <Stack>
              <div className="notify-Body">
                <span style={{ fontWeight: "bold" }}>
                  {notification.data.userFullname}
                </span>{" "}
                (
                <span className="notify-user-name">
                  {notification.data.userEmail}
                </span>
                ) {t("accepted the transfer.")}
              </div>
              <p className="from-creation-to-now">
                {moment(notification.createdAt).fromNow()}
              </p>
            </Stack>
          </NotifyContainer>
        );
      };

      return Body;
    }
    case NotificationType.TRANSFER_RECEIVED: {
      const Body: FC<{ width: string | undefined }> = ({ width }) => {
        const [openTransferModal, setOpenTransferModal] = useState(false);
        const { t } = useTranslation();
        return (
          <NotifyContainer
            className="notify-container"
            style={{ width: width ?? "350px" }}
            onClick={() => {
              setOpenTransferModal(true);
            }}
          >
            <Stack>
              <div className="notify-Body">
                <div>
                  <AcceptTransferModal
                    transferId={notification.data.transferId}
                    open={openTransferModal}
                    setOpen={setOpenTransferModal}
                  />
                  <span style={{ fontWeight: "bold" }}>
                    {notification.data.userFullname}
                  </span>{" "}
                  (
                  <span className="notify-user-name">
                    {notification.data.userEmail}
                  </span>
                  ) {t("sent you a transfer request.")}
                </div>
              </div>
              <p className="from-creation-to-now">
                {moment(notification.createdAt).fromNow()}
              </p>
            </Stack>
          </NotifyContainer>
        );
      };

      return Body;
    }
    case NotificationType.ALERT_BENDWIDTH: {
      const Body: FC<{ width: string | undefined }> = ({ width }) => {
        const { t } = useTranslation();
        return (
          <NotifyContainer
            className="notify-container"
            style={{ width: width ?? "350px" }}
          >
            <Stack>
              <div className="notify-Body">
                <div>
                  <span>
                    {t("You have almost exhausted the available bandwidth.")}
                  </span>
                  <br />
                  <span>{t("Available bandwidth: ")}</span>
                  <span style={{ fontWeight: "bold" }}>
                    {(
                      Math.floor(Number(notification.data?.bandwidth) * 100) /
                      100
                    ).toString() + " GB"}
                  </span>
                </div>
              </div>
              <p className="from-creation-to-now">
                {moment(notification.createdAt).fromNow()}
              </p>
            </Stack>
          </NotifyContainer>
        );
      };

      return Body;
    }
  }
};

const NotificationItem: FC<NotificationItemProps> = ({
  notification,
  handleClose,
  width,
}) => {
  const Body = notificationMaker(notification);
  return <Body width={width} />;
};

export default NotificationItem;
