import { useCallback } from "react";
import { useAsync } from "./useAsync";

export interface BulkResult {
  successCount: number;
  errorCount: number;
}

const useBulkAction = (action: (...args: any) => Promise<unknown>) => {
  const { data: bulkResult, isLoading, run, setData } = useAsync<BulkResult>();

  const bulkAction = useCallback(
    (args: any[]) => {
      setData(null);
      run(
        Promise.all(
          args.map((arg: any) =>
            action(arg)
              .then(() => ({ successCount: 1, errorCount: 0 }))
              .catch(() => ({ successCount: 0, errorCount: 1 }))
          )
        ).then((result) => {
          return result.reduce(
            (acc, singleRes) => ({
              successCount: acc.successCount + singleRes.successCount,
              errorCount: acc.errorCount + singleRes.errorCount,
            }),
            { successCount: 0, errorCount: 0 }
          );
        })
      );
    },
    [action]
  );
  return { bulkAction, bulkResult, isLoading };
};

export default useBulkAction;
