import useAcquisitions from "hooks/services/acquisitions/useAcquisitions";
import { FC, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useMultiSelect from "hooks/useMultiSelect";
import { PAGE_SIZE } from "config/pagination";
import ROLE from "types/role";
import { Stack } from "@mui/material";
import { MRSearchbar, MRPagination, MRDrawer } from "components/material";
import AcquisitionTable from "components/TablesComponents/Acquisition/AcquistionTable";
import AcquisitionAdminBulkActionControl from "components/TablesComponents/Acquisition/AcquisitionAdminBulkActionsControl";
import Page from "components/Layout/Page";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import Loader from "components/Loader";
import { useDebounce } from "use-debounce";
import AcquisitionFilterForm from "components/Acquisition/AcquisitionFilterForm";
import { Inputs as AcquisitionFilters } from "components/Acquisition/AcquisitionFilterForm/model";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";
import { AcquisitionStatus } from "types/acquisition";
import { MRButtonPrimary } from "components/material/Button";
import { plus } from "assets/icons";
import { useNavigate } from "react-router-dom";
import PATH from "routing/path";

const ModelsToProcess: FC = () => {
  const { selected, toggleSelection, toggleAll, resetSelection } =
    useMultiSelect();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchbarText, setSearchbarText] = useState<string>("");
  const [debouncedSearchbarText] = useDebounce(searchbarText, 500);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState<AcquisitionFilters | null>({
    status: [
      AcquisitionStatus.IDLE,
      AcquisitionStatus.PENDING_PROCESSING,
      AcquisitionStatus.PROCESSING,
    ],
  } as AcquisitionFilters);

  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("_id");

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data: acquisitions,
    isLoading: isLoadingAcquisitions,
    error: acquisitionsError,
  } = useAcquisitions({
    page,
    size: PAGE_SIZE,
    order,
    sort: orderBy,
    text: debouncedSearchbarText,
    ...(formattedFilters && formattedFilters),
  });

  const sortHandler = (orderBy: string) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(orderBy);
  };

  if (acquisitionsError) throw new Error("Cannot get acquisition");

  return (
    <Page>
      <MRDrawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={toggleDrawer(false, setFilterDrawerOpen)}
      >
        <AcquisitionFilterForm
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values: any) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </MRDrawer>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <h1>{t("Models to process")}</h1>
        <FilterButton
          onClick={() => setFilterDrawerOpen(true)}
          activeFilters={
            formattedFilters ? Object.keys(formattedFilters).length : 0
          }
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        justifyContent={"space-between"}
      >
        <Stack>
          <MRButtonPrimary
            variant="contained"
            onClick={() => navigate(`${PATH.MODELS_TO_PROCESS}/create`)}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <img src={plus} />
              {t("Add new")}
            </Stack>
          </MRButtonPrimary>
        </Stack>
        <Stack direction="row" gap={3} alignItems="center">
          <MRSearchbar
            onChange={(event) => setSearchbarText(event.target.value)}
          />
          <AcquisitionAdminBulkActionControl
            selectedIds={selected}
            resetSelection={resetSelection}
          />
        </Stack>
      </Stack>
      {isLoadingAcquisitions ? (
        <Loader />
      ) : (
        <>
          <AcquisitionTable
            acquisitions={acquisitions?.data || []}
            selected={selected}
            userRole={ROLE.ADMIN}
            toggleSelection={toggleSelection}
            toggleAll={toggleAll}
            order={order}
            orderBy={orderBy}
            sortHandler={sortHandler}
          />
          <Stack direction="row" justifyContent="center">
            {acquisitions?.data && acquisitions.data.length ? (
              <MRPagination
                count={
                  acquisitions
                    ? Math.ceil(
                        acquisitions?.pagination[0]?.total / PAGE_SIZE
                      ) || 1
                    : 1
                }
                page={page}
                onChange={(e, page) => setPage(page)}
              />
            ) : (
              <NoDataRow>{t("No Data")}</NoDataRow>
            )}
          </Stack>
        </>
      )}
    </Page>
  );
};

export default ModelsToProcess;
