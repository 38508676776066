import { InfiniteData } from "react-query";
import { colors } from "styles/theme";
import { BlockStatus } from "types/block";
import { PaginatedResponse } from "types/response";
import ROLE from "types/role";
import { Share } from "types/share";
import { Contact, User } from "types/user";

const matched = (x: any) => ({
  on: () => matched(x),
  otherwise: () => x,
});

export const match = (x: any) => ({
  on: (pred: any, fn: any) => (pred(x) ? matched(fn(x)) : match(x)),
  otherwise: (fn: any) => fn(x),
});

export const stringAvatar = (name: string) =>
  name
    .split(" ")
    .map((word) => word[0])
    .slice(0, 2)
    .join("");

export const displayName = (user: User) => {
  if (user.profile?.firstname || user.profile?.lastname) {
    return `${user.profile?.firstname || ""} ${user.profile?.lastname || ""}`;
  }
  if (user.profile?.companyName) return user.profile?.companyName;
  return user.username;
};

export const contactDisplayName = (contact: Contact) => {
  if (contact.alias && contact.alias !== "") return contact.alias;
  if (contact.companyName) return contact.companyName;
  return contact.email;
};

export const transferDisplayName = (obj: {
  email?: string;
  firstname: string;
  lastname: string;
}) => {
  if (obj?.firstname || obj?.lastname) {
    return `${obj.firstname || ""} ${obj.lastname || ""}`;
  }
  if (obj.email) return obj.email;
  return "";
};

export const getBlockColor = (variant: string, status: BlockStatus) => {
  if (status === BlockStatus.TRASHED || status === BlockStatus.REMOVED)
    return colors.red;
  if (variant === "shared") return colors.primary;
  if (variant === "owned") return colors.green;
};

export const flattenPages = <T>(pages?: InfiniteData<PaginatedResponse<T>>) => {
  return pages?.pages.map((page) => page.data).flat(1) || [];
};

export const accountType = (user: User) => {
  if (!user.roles) return "";
  if (user.roles === ROLE.ADMIN) return "Admin";
  if (user.roles === ROLE.OPERATOR) return "Operator";
  if (user.roles === ROLE.SELLER) return "PRO Account";
  if (user.roles === ROLE.BUYER) return "Free account";
};

export const hexOpacity = (hex: string, opacity: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const formatShareContent = (share: Share, field: string) => {
  if (field === "type") {
    if (share.blockName) return "Block";
    if (share.collectionName) return "Collection";
    return "N.D.";
  }
  if (field === "name") {
    return share.blockName ?? share.collectionName;
  }
};
