import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={37}
    fill="none"
    {...props}
  >
    <path
      fill="#00A4B7"
      d="M45.455 0H4.545A4.559 4.559 0 0 0 0 4.545v27.273c0 2.5 2.045 4.546 4.545 4.546h40.91c2.5 0 4.545-2.046 4.545-4.546V4.545C50 2.045 47.955 0 45.455 0Zm0 31.818H4.545V4.545h40.91v27.273Z"
    />
    <path
      fill="#00A4B7"
      d="M14.615 17.692v1.539h3.847v-1.539h-3.847ZM35.385 19.23v-1.538h-3.846v1.539h3.846ZM11.784 11.822l1.813 1.813 2.72-2.72-1.814-1.812-2.72 2.72ZM38.216 25.1l-1.813-1.812-2.72 2.72 1.814 1.812 2.72-2.72ZM11.784 25.095l2.72 2.72L16.317 26l-2.72-2.72-1.813 1.814ZM38.216 11.829l-2.72-2.72-1.813 1.813 2.72 2.72 1.813-1.813ZM6.923 26.154V30h2.308v-3.846H6.923ZM43.077 10.769V6.923h-2.308v3.846h2.308ZM6.923 6.923v3.846h2.308V6.923H6.923ZM43.077 30v-3.846h-2.308V30h2.308ZM13.077 18.461a6.156 6.156 0 0 0-6.154-6.153v2.461a3.703 3.703 0 0 1 3.692 3.692 3.703 3.703 0 0 1-3.692 3.693v2.461a6.156 6.156 0 0 0 6.154-6.154ZM36.923 18.462a6.156 6.156 0 0 0 6.154 6.153v-2.461a3.703 3.703 0 0 1-3.692-3.692 3.703 3.703 0 0 1 3.692-3.693v-2.461a6.156 6.156 0 0 0-6.154 6.154Z"
    />
  </svg>
)
export default SvgComponent