import Loader from "components/Loader";
import { USER_REFRESH_TOKEN_KEY, USER_TOKEN_KEY } from "config/localStorage";
import useVerifyEmail from "hooks/services/signup/useVerifyEmail";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import PATH from "routing/path";

const SignupFreeVerify: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (!searchParams.get("verify")) throw new Error("Invalid code");
  const { data: user, isError, isSuccess, isLoading } = useVerifyEmail(searchParams.get("verify"));

  useEffect(() => {
    if (isError) {
      throw new Error("Invalid code");
    }
  }, [isError]);

  useEffect(() => {
    if (!user) return;
    localStorage.setItem(USER_TOKEN_KEY, user.data.accessToken);
    localStorage.setItem(USER_REFRESH_TOKEN_KEY, user.data.refreshToken);
    navigate(PATH.DASHBOARD);
  }, [user]);

  return (
    <>
      <Loader></Loader>
    </>
  );
};

export default SignupFreeVerify;
