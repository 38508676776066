import { useQuery } from "react-query";
import { generatePath } from "react-router-dom";
import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { Block } from "types/block";
import Error from "types/error";

export default function useBlock(id: string, search?: Record<string, string>) {
  const request: Request = {
    url: generatePath(ENDPOINTS.block.one, { id }),
    config: {
      method: "GET",
      params: search,
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.block.one, id, search && JSON.stringify(search)],
    queryFn: () => client(request),
    enabled: !!id,
  };

  const { data, ...rest } = useQuery<Response<Block>, Error>(requestConfig);

  return { data, ...rest };
}
