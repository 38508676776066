import { FC, useMemo } from "react";
import { RowActionProps, RowAction } from "../RowActions/types";
import { useNavigate, generatePath } from "react-router-dom";
import useDeleteCollection from "hooks/services/collections/useDeleteCollection";
import RowActionsBase from "../RowActions/RowActionsBase";
import { edit, trash2 } from "assets/icons";
import usePullBlockFromCollection from "hooks/services/collections/usePullBlockFromCollection";
import { useTranslation } from "react-i18next";

const BlockRowActions: FC<RowActionProps & { blockId?: string }> = ({
  id,
  blockId,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const {
    mutate: deleteCollection,
    isLoading: isDeletingCollection,
    isSuccess: isDeletedCollection,
    error: deleteCollectionError,
  } = useDeleteCollection();

  const {
    mutate: pullBlock,
    isLoading: pullBlockLoading,
    isSuccess: pullBlockSuccess,
    error: pullBlockError,
  } = usePullBlockFromCollection();

  const actions: RowAction[] = useMemo(
    () => [
      {
        icon: edit,
        action: () => navigate(`/catalogues/${id}`),
      },
      ...(blockId
        ? [
            {
              icon: trash2,
              action: () => pullBlock({ blockId, collectionId: id }),

              confirmMessage: t(
                "Are you sure you want to remove the block from this collection?"
              ),
              actionState: {
                isLoading: pullBlockLoading,
                isSuccess: pullBlockSuccess,
                successMessage: t("Block removed from collection successfully"),
                isError: !!pullBlockError,
                errorMessage: t(
                  "An error occurred while deleting this block from the collection, please try again later."
                ),
              },
            },
          ]
        : []),
      ...(!blockId
        ? [
            {
              icon: trash2,
              action: () => deleteCollection(id),

              confirmMessage: t(
                "Are you sure you want to delete this collection?"
              ),
              actionState: {
                isLoading: isDeletingCollection,
                isSuccess: isDeletedCollection,
                successMessage: t("Collection deleted successfully"),
                isError: !!deleteCollectionError,
                errorMessage: t(
                  "An error occurred while deleting the collection, please try again"
                ),
              },
            },
          ]
        : []),
    ],
    [
      navigate,
      generatePath,
      deleteCollection,
      isDeletingCollection,
      isDeletedCollection,
      deleteCollectionError,
    ]
  );

  return <RowActionsBase variant="icons" actions={actions} />;
};

export default BlockRowActions;
