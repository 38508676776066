import styled from "styled-components";
import { colors } from "styles/theme";

export const RequestsWrapper = styled.div``;

export const RequestItemWrapper = styled.div`
  background-color: ${colors.lightBlack};
  border-radius: 10px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;

  .email-container {
    margin-left: 10px;
    width: 100%;
    min-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .delete-icon {
    height: 24px;
    width: 24px;
  }

  .date-container {
    width: 100%;
  }
`;

export const RequestsContainerWrapper = styled.div`
  .requests-container {
    flex-direction: column;
    display: flex;
    row-gap: 10px;
    column-gap: 10px;
  }

  .placeholder {
    background-color: ${colors.lightBlack};
    border-radius: 10px;
    padding: 20px 20px;
    text-align: center;
  }
`;
