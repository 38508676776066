import { FC } from "react";
import { UserStatus } from "types/user";
import { UserStatusCardWrapper } from "./styles";

const UserStatusCard: FC<{ status: UserStatus }> = ({ status }) => {
  const classNameByStatus = {
    [UserStatus.ACTIVE]: "active-card",
    [UserStatus.INVITED]: "invited-card",
    [UserStatus.BLOCKED]: "blocked-card",
    [UserStatus.DELETED]: "blocked-card",
  };

  return (
    <UserStatusCardWrapper>
      <div className={classNameByStatus[status]}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </div>
    </UserStatusCardWrapper>
  );
};

export default UserStatusCard;
