import { FC, useState, useMemo } from "react";
import { caretDown } from "assets/icons";
import { MenuItem } from "@mui/material";
import { MRMenu } from "components/material";
import {
  MRButtonquaternary,
  MRButtonTertiary,
} from "components/material/Button";
import useToasts from "hooks/useToasts";
import { useConfirmModal } from "context/confirmModal";
import { BulkActionBaseProps } from "./types";
import { BulkActionsWrapper, ActionMenuWrapper } from "./styles";
import { useTranslation } from "react-i18next";

const BulkActionsControlBase: FC<BulkActionBaseProps> = ({
  selectedIds,
  resetSelection,
  actions,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const askConfirm = (message: string, action: (ids: string[]) => void) => {
    if (!selectedIds.length) return;
    openModal({
      title: message,
      text: t("The operation is not reversible"),
      confirmFunction: () => {
        action(selectedIds);
        resetSelection();
      },
    });
  };

  const toasts = useMemo(
    () =>
      actions
        .filter((action) => action.bulkActionResult)
        .map((action) => [
          {
            severity: "success" as "success",
            message:
              action.bulkActionResult?.errorCount === 0
                ? t("The action was applied successfully")
                : t("Some errors occurred, the action was applied partially"),
            condition: !!action.bulkActionResult?.successCount,
          },
        ])
        .reduce((acc, curr) => [...acc, ...curr], []),
    [actions]
  );

  const isLoading = useMemo(
    () =>
      actions.reduce(
        (acc, curr) => acc || Boolean(curr.bulkActionLoading),
        false
      ),
    [actions]
  );

  const { openModal } = useConfirmModal();

  useToasts(toasts);

  return (
    <BulkActionsWrapper>
      <MRButtonquaternary
        loading={isLoading}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Bulk Actions{!isLoading && <img src={caretDown} />}
      </MRButtonquaternary>

      <MRMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ActionMenuWrapper>
          {actions.map((action, index) => (
            <MenuItem key={index} onClick={handleClose}>
              <MRButtonTertiary
                className="menu-btn"
                onClick={() => {
                  if (action.confirmMessage) {
                    askConfirm(action.confirmMessage, action.bulkAction);
                    return;
                  }
                  action.bulkAction(selectedIds);
                }}
              >
                <img src={action.icon} alt="thrash2" className="button-icon" />
                {action.name}
              </MRButtonTertiary>
            </MenuItem>
          ))}
        </ActionMenuWrapper>
      </MRMenu>
    </BulkActionsWrapper>
  );
};
export default BulkActionsControlBase;
