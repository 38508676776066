import ROLE from "types/role";
import { UserStatus } from "types/user";
import * as Yup from "yup";

export interface Inputs {
  firstname: string;
  lastname: string;
  companyName: string;
  status: UserStatus | string;
  roles: ROLE | string;
}

export const validationSchema = Yup.object({
  firstname: Yup.string(),
  lastname: Yup.string(),
  companyName: Yup.string(),
  status: Yup.mixed().oneOf(Object.values(UserStatus)),
  roles: Yup.mixed().oneOf(Object.values(ROLE)),
});

export const initialValues = {
  firstname: "",
  lastname: "",
  companyName: "",
  status: "",
  roles: "",
};

export const formatFilters = (filters: Inputs) => {
  const { firstname, lastname, companyName, ...rest } = filters;
  return {
    ...rest,
    "profile.firstname": firstname,
    "profile.lastname": lastname,
    "profile.companyName": companyName,
  };
};
