import InfoIcon from "@mui/icons-material/Info";
import PATH from "routing/path";
import { NavigationItem } from "../types";
import i18n from "i18next";

export const bottomNavigation: NavigationItem[] = [
  {
    name: "support",
    label: i18n.t("Support"),
    path: PATH.CONTACT_US,
    icon: <InfoIcon />,
  },
];
