import { useFormik } from "formik";
import { PasswordInput } from "types/user";
import { FC, useEffect } from "react";
import { validationSchema, initialValues } from "./model";
import { Grid, Stack, Zoom } from "@mui/material";
import { MRTextField } from "components/material";
import { MRButtonPrimary } from "components/material/Button";
import useChangePassword from "hooks/services/users/useChangePassword";
import useToasts from "hooks/useToasts";
import i18n from "i18n";
import { ChangePasswordFormProps } from "./types";

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({ closeDialog }) => {
  const {
    mutate: changePassword,
    error: changePasswordError,
    isSuccess,
  } = useChangePassword();

  const formik = useFormik<PasswordInput>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (passwords) => {
      changePassword(passwords);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      formik.resetForm();
      closeDialog();
    }
  }, [isSuccess]);

  useToasts([
    {
      condition: !!changePasswordError,
      severity: "error",
      message: changePasswordError
        ? i18n.t((changePasswordError as any).response.data.message)
        : i18n.t("An error occurred while changing password"),
    },
    {
      condition: !!isSuccess,
      severity: "success",
      message: i18n.t("Password changed successfully"),
    },
  ]);

  return (
    <form onSubmit={formik.handleSubmit} className="user-info-form">
      <Grid container spacing={3} rowSpacing={5}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>{i18n.t("Current password") as string}</p>
          <MRTextField
            type={"password"}
            fullWidth
            className="text-field"
            id="currentPassword"
            name="currentPassword"
            placeholder="Current password"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.currentPassword &&
              Boolean(formik.errors.currentPassword)
            }
            helperText={
              formik.touched.currentPassword && formik.errors.currentPassword
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>{i18n.t("New password") as string}</p>
          <MRTextField
            type={"password"}
            fullWidth
            className="text-field"
            id="newPassword"
            name="newPassword"
            placeholder="New password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>{i18n.t("Confirm new password") as string}</p>
          <MRTextField
            type={"password"}
            fullWidth
            className="text-field"
            id="confirmNewPassword"
            name="confirmNewPassword"
            placeholder="Confirm new password"
            value={formik.values.confirmNewPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmNewPassword &&
              Boolean(formik.errors.confirmNewPassword)
            }
            helperText={
              formik.touched.confirmNewPassword &&
              formik.errors.confirmNewPassword
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Zoom in={formik.dirty}>
            <Stack mt={"10px"} alignItems={"center"} justifyContent={"center"}>
              <MRButtonPrimary type="submit">
                {i18n.t("Confirm change password")}
              </MRButtonPrimary>
            </Stack>
          </Zoom>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePasswordForm;
