type PathObject = {
  [key: string]: string;
};

const PATH: PathObject = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  FREESIGNUP: "/free-signup",
  FREESIGNUPVERIFY: "/free-signup-verify",
  HOME: "/home",
  USERS: "/users",
  CATALOGUES: "/catalogues",
  DASHBOARD: "/",
  MODELS_TO_PROCESS: "/models-to-process",
  PROCESSED_MODELS: "/processed-models",
  VIEWER_3D: "/viewer",
  CONTACTS: "/contacts",
  TRANSFER: "/transfer",
  MODELS: "/models",
  PROFILE: "/profile",
  PASSWORD: "/password",
  LINK: "link/:code",
  CONTACT_US: "/contact-us",
};

export default PATH;
