import styled from "styled-components";
import { colors } from "styles/theme";

export const EditModelSellerWrapper = styled.div`
  h1,
  h3 {
    margin-bottom: 5px;
  }
  .secondary-title {
    color: ${colors.green};
  }

  .block-top-card {
    h2,
    p {
      margin: 0;
    }
  }

  .thumbnail-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .thumbnail-container img {
    height: 256px;
    width: 256px;
    object-fit: contain;
  }

  .download-photos-btn {
    min-width: 160px;
  }

  .share-chart-card-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
`;
