import { FC, useState } from "react";
import { Dialog } from "components/material/Dialog";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  IconButton,
  Slide,
  Stack,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { AcceptTransferModalWrapper } from "./styles";
import { colors } from "styles/theme";
import {
  MRButtonPrimary,
  MRButtonquaternary,
  MRButtonSecondary,
} from "components/material/Button";
import useToasts from "hooks/useToasts";
import { AcceptTransferModalProps } from "./types";
import useTransfer from "hooks/services/transfers/useTransfer";
import Loader from "components/Loader";
import Block from "components/Block/BlockGridElem";
import { Block as IBlock } from "types/block";
import useAcceptTransfer from "hooks/services/transfers/useAcceptTransfer";
import useDeclineTransfer from "hooks/services/transfers/useDeclineTransfer";
import toast from "react-hot-toast";
import { MRCard, MRCheckbox } from "components/material";
import { ReactComponent as Logo } from "assets/icons/logo-white.svg";
import { TransferStatus } from "types/transfer";
import { stringAvatar, transferDisplayName } from "helpers";

const AcceptTransferModal: FC<AcceptTransferModalProps> = ({
  open,
  setOpen,
  transferId,
}) => {
  const { t } = useTranslation();
  const [keepShares, setKeepShares] = useState<boolean>(false);

  const {
    data: transfer,
    isLoading: transferLoading,
    error: transferError,
  } = useTransfer(transferId);

  const {
    mutateAsync: acceptTransfer,
    isLoading: acceptTransferLoading,
    error: acceptTransferError,
    isSuccess: acceptTransferSuccess,
  } = useAcceptTransfer();

  const {
    mutateAsync: declineTransfer,
    isLoading: declineTransferLoading,
    error: declineTransferError,
    isSuccess: declineTransferSuccess,
  } = useDeclineTransfer();

  const handleClose = (e: object, reason: string) => {
    if (reason === "escapeKeyDown") {
      setOpen(false);
    }
  };

  const handleAcceptTransfer = () => {
    acceptTransfer({ transferId, keepShares })
      .then((response) => {
        setOpen(false);
      })
      .catch((error) => {
        if (
          error.response.status === 403 &&
          error.response.data.name === "NotEnoughStorage"
        ) {
          toast(
            t(
              "You don't have enough storage to accept this transfer. Please increase your storage or remove other blocks."
            ),
            { duration: 5000 }
          );
          return;
        }

        toast(
          t("There was an error with your request. Please try again later.")
        );
      });
  };

  const handleDeclineTransfer = () => {
    declineTransfer(transferId).then(() => setOpen(false));
  };

  useToasts([
    {
      severity: "success",
      message: t(
        "Transfer request accepted. You can now see this block in your dashboard."
      ),
      condition: acceptTransferSuccess,
    },
    {
      severity: "success",
      message: t("Transfer declined successfully"),
      condition: declineTransferSuccess,
    },
    {
      severity: "error",
      message: t("There was an error. Please try again later."),
      condition: !!declineTransferError,
    },
  ]);

  if (transferLoading) return <Loader />;

  // if (transferError && transferError.response.data.name === "UserNotSeller")
  // if (transfer && transfer.status !== "pending")

  return (
    <AcceptTransferModalWrapper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="dialog"
        PaperProps={{
          style: {
            background: colors.lightBlack,
            backgroundImage: "none",
            width: "100%",
            padding: "40px",
            border: `1px solid ${colors.white}`,
            borderRadius: "20px",
          },
        }}
      >
        {!transferError && transfer?.data.status === TransferStatus.PENDING ? (
          <Stack direction="column" gap={2}>
            <Stack direction="row" justifyContent={"flex-end"}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose(e, "escapeKeyDown");
                }}
              >
                <ClearIcon sx={{ color: colors.green }} />
              </IconButton>
            </Stack>

            <Stack direction="row" alignItems="center" gap={2}>
              <Avatar className="contact-avatar">
                {transferDisplayName(transfer?.data.sender) &&
                  stringAvatar(transferDisplayName(transfer?.data.sender))}
              </Avatar>

              <h2>
                {transfer?.data.sender.firstname}{" "}
                {transfer?.data.sender.lastname}
              </h2>
            </Stack>

            <h3>{t("Wants to transfer the ownership of this model to you")}</h3>

            {transfer && (
              <div className="block-view">
                <Block
                  block={transfer?.data.block as IBlock}
                  variant="owned"
                  viewOnly={true}
                />
              </div>
            )}

            <Stack direction="row" alignItems="center" gap={1}>
              <FormControlLabel
                control={
                  <MRCheckbox
                    value={keepShares}
                    onChange={(e) => setKeepShares(e.target.checked)}
                  />
                }
                label={`${t("Keep shares of")} ${
                  transfer?.data.sender.firstname
                } ${transfer?.data.sender.lastname}`}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: "20px" }}
            >
              <MRButtonSecondary
                onClick={handleDeclineTransfer}
                loading={declineTransferLoading}
              >
                {t("Decline")}
              </MRButtonSecondary>
              <MRButtonquaternary
                onClick={handleAcceptTransfer}
                loading={acceptTransferLoading}
              >
                {t("Accept")}
              </MRButtonquaternary>
            </Stack>
          </Stack>
        ) : (
          <MRCard className="card">
            <Stack alignItems="center">
              <Logo width="70px" height="70px" />
              <h3 style={{ textAlign: "center" }}>
                {t("This transfer is no longer available!")}
              </h3>
              <MRButtonPrimary
                type="submit"
                sx={{
                  bgcolor: `${colors.green} !important`,
                  color: `${colors.black} !important`,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose(e, "escapeKeyDown");
                }}
              >
                {t("Close")}
              </MRButtonPrimary>
            </Stack>
          </MRCard>
        )}
      </Dialog>
    </AcceptTransferModalWrapper>
  );
};

export default AcceptTransferModal;
