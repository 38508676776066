import { createContext, FC, useEffect } from "react";
import { AuthContextType, Credentials } from "./types";
import client from "utils/client";
import { ENDPOINTS } from "config/endpoints";
import { Request } from "types/request";
import { User } from "types/user";
import { useAsync } from "hooks/useAsync";
import { USER_REFRESH_TOKEN_KEY, USER_TOKEN_KEY } from "config/localStorage";
import PATH from "routing/path";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);
AuthContext.displayName = "UserContext";

const AuthProvider: FC<{children: React.ReactNode}> = ({ children }) => {
  const {
    data: user,
    error,
    isLoading,
    isError,
    setData,
    run,
    reset,
    isSuccess,
    setError,
  } = useAsync<User>();

  const navigate = useNavigate();

  const token = localStorage.getItem(USER_TOKEN_KEY);

  useEffect(() => {
    if (token) {
      const request: Request = {
        url: ENDPOINTS.auth.me,
        config: {
          method: "get",
        },
      };
      run(
        client(request).then((res) => {
          return res.data;
        })
      );
    }
  }, [run, token, reset]);

  const login = ({ username, password }: Credentials) => {
    localStorage.clear();
    setError(null);

    const request: Request = {
      url: ENDPOINTS.auth.login,
      config: {
        method: "post",
        data: { username, password },
      },
    };

    run(
      client(request).then((result) => {
        localStorage.setItem(USER_TOKEN_KEY, result.data.accessToken);
        localStorage.setItem(USER_REFRESH_TOKEN_KEY, result.data.refreshToken);
        return result.data;
      })
    );
  };

  const me = () => {
    if (token) {
      const request: Request = {
        url: ENDPOINTS.auth.me,
        config: {
          method: "get",
        },
      };
      return run(
        client(request).then((res) => {
          return res.data;
        })
      );
    }
  };

  const logout = () => {
    clearData();
    navigate(PATH.LOGIN);
    //queryClient.clear();
  };

  const clearData = () => {
    setData(null);
    localStorage.removeItem(USER_TOKEN_KEY);
  };

  const value = {
    user,
    error,
    isLoading,
    isError,
    isSuccess,
    login,
    logout,
    me,
    clearData,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
