import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";
import { PasswordInput } from "types/user";

export default function useChangePassword() {
  const queryClient = useQueryClient();

  const changePassword = (passwords: PasswordInput) => {
    const { confirmNewPassword, ...oldAndNewPasswords } = passwords;
    const request: Request = {
      url: ENDPOINTS.auth.changePassword,
      config: {
        method: "POST",
        data: { ...oldAndNewPasswords },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<null>, Error, PasswordInput>(changePassword, {
    onSettled: (data, error) => {},
  });

  return { data, mutate, ...rest };
}
