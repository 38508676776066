import { FC } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
} from "@mui/material";
import { MRCheckbox, MRTable } from "components/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CollectionRowActions from "./CollectionRowActions";
import { CollectionsTableProps } from "./types";
import { TableWrapper } from "../Tables/styles";
import { Link } from "react-router-dom";
import useAuth from "hooks/useAuth";
import ROLE from "types/role";

const CollectionsTable: FC<CollectionsTableProps> = ({
  collections,
  selected,
  toggleAll,
  toggleSelection,
  order,
  orderBy,
  sortHandler,
  blockId,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const columns = [
    {
      name: "name",
      label: "Name",
      sortable: true,
    },
    ...(user?.roles === ROLE.ADMIN || user?.roles === ROLE.OPERATOR
      ? [
          {
            name: "ownerName",
            label: t("Owner"),
            sortable: true,
          },
        ]
      : []),
    {
      name: "_id",
      label: t("Date"),
      sortable: true,
    },
    {
      name: "numOfBlocks",
      label: t("Num of blocks"),
      sortable: false,
    },
    {
      name: "sharesCount",
      label: t("Num of shares"),
      sortable: true,
    },
  ];

  return (
    <TableWrapper>
      <MRTable>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <MRCheckbox
                checked={
                  selected.length === collections.length &&
                  selected.length !== 0
                }
                onChange={() =>
                  toggleAll(
                    collections.map((collection) => collection._id) || []
                  )
                }
              />
            </TableCell>
            {columns.map((column) =>
              column.sortable ? (
                <TableCell
                  key={column.name}
                  sortDirection={orderBy === column.name ? order : false}
                  align="center"
                >
                  <TableSortLabel
                    active={orderBy === column.name}
                    direction={orderBy === column.name ? order : "asc"}
                    onClick={() => sortHandler(column.name)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell key={column.name} align="center">
                  {column.label}
                </TableCell>
              )
            )}
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collections.map((collection) => (
            <TableRow key={collection._id}>
              <TableCell align="center">
                <MRCheckbox
                  checked={selected.includes(collection._id)}
                  onChange={() => toggleSelection(collection._id)}
                />
              </TableCell>
              <TableCell align="center">
                <Link
                  style={{ cursor: "pointer" }}
                  to={`/catalogues/${collection._id}`}
                >
                  {collection.name}
                </Link>
              </TableCell>

              {user?.roles === ROLE.ADMIN && (
                <TableCell align="center">{collection.ownerName}</TableCell>
              )}
              <TableCell align="center">
                {moment(collection.createdAt).format("D MMM YYYY")}
              </TableCell>
              <TableCell align="center">{collection.blocks.length}</TableCell>
              <TableCell align="center">{collection.sharesCount}</TableCell>
              <TableCell align="center" className="table-icon-container">
                <CollectionRowActions id={collection._id} blockId={blockId} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MRTable>
    </TableWrapper>
  );
};

export default CollectionsTable;
