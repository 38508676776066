import { useState, useRef } from 'react'
import { ButtonBase, Box, Popover, Divider, Stack, Typography, SvgIcon } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import StraightenIcon from '@mui/icons-material/Straighten';
import ModeIcon from '@mui/icons-material/Mode';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import EnvironmentLight from '../ui-icons/EnvironmentLight'
import StudioLight from '../ui-icons/StudioLight'
import DiffuseLight from '../ui-icons/DiffuseLight'


import MRDivider from '../ui-components/MRDivider';
import MRButtonWithIconText from '../ui-components/MRButtonWithIconText';


export default function ToolsButtonMobile(props: any) {
  const refAnchor = useRef<HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);


  const handleClose = () => {
    setAnchorEl(null);
  };

  const alternativeAnchor = () => {
    setAnchorEl(props.anchor.current);
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box component={"div"} ref={refAnchor} sx={{ display: "flex", height: "100%" }}>
      <ButtonBase disableRipple aria-describedby={id} onClick={alternativeAnchor}>
        {open ? <CloseIcon sx={{ color: "#4BBECF" }} /> : <MenuIcon sx={{ color: "#4BBECF" }} />}
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        elevation={0}
        slotProps={{
          paper: {
            style: { backgroundColor: "transparent", borderRadius: 0}
          }
        }}
        marginThreshold={0}
      >
        <Box
          component={"div"}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            backgroundColor: "rgba(6,11,12,0.75)",
            justifyContent: "center"
          }}
          padding={2}
        >
          <MRDivider text={"TOOLS"} colorDivider={"#A3D8E7"} />
          <Stack
            display={"flex"}
            flexDirection={"row"}
            gap={2}
          >
            
            <MRButtonWithIconText
              icon={<StraightenIcon fontSize='medium' />}
              text={"Ruler"}
              onClickAction={()=>{props.onClickMeasure(); handleClose()}}
            />

            <MRButtonWithIconText
              icon={<ModeIcon fontSize='medium' />}
              text={"Pencil"}
              onClickAction={() => {props.onClickFreeDraw(); handleClose()}}
            />

            <MRButtonWithIconText
              icon={<VisibilityOffOutlinedIcon fontSize='medium' />}
              text={"Lines"}
              onClickAction={() => {props.onClickToggleVisibility(); handleClose()}}
            />

            <MRButtonWithIconText
              icon={<RestartAltIcon fontSize='medium' />}
              text={"Reset"}
              onClickAction={() => {props.onClickReset(); handleClose()}}
            />
          </Stack>

          <MRDivider text={"LIGHTS"} colorDivider={"#A3D8E7"} />
          <Box
            component={"div"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >

            <MRButtonWithIconText
              icon={<SvgIcon viewBox={"0 0 50 37"} fontSize={"medium"}>
                <EnvironmentLight />
              </SvgIcon>}
              text={"Natural"}
              onClickAction={() => {props.setEnvLight(); handleClose()}}
            />

            <MRButtonWithIconText
              icon={<SvgIcon viewBox={"0 0 50 37"} fontSize={"medium"}>
                <StudioLight />
              </SvgIcon>}
              text={"Studio"}
              onClickAction={() => {props.setEnvStudioLight(); handleClose()}}
            />

            <MRButtonWithIconText
              icon={<SvgIcon viewBox={"0 0 50 37"} fontSize={"medium"}>
                <DiffuseLight />
              </SvgIcon>}
              text={"Diffuse"}
              onClickAction={() => {props.setEnvDiffLight(); handleClose()}}
            />

          </Box>
        </Box>
      </Popover>
    </Box>
  )
}