import { ENDPOINTS } from "config/endpoints";
import { useMutation } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";

interface AskResetPasswordInfo {
  email: string;
}

export default function useAskResetPassword() {
  const askResetPassword = (data: AskResetPasswordInfo) => {
    const request: Request = {
      url: ENDPOINTS.auth.passwordForgot,
      config: {
        method: "post",
        data: data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    Error,
    AskResetPasswordInfo
  >(askResetPassword);

  return { data, mutate, ...rest };
}
