import { Avatar, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import Loader from "components/Loader";
import useFriendshpiRequestSent from "hooks/services/friendship-request/useFriendshpiRequestSent";
import React, { FC } from "react";
import { FriendshipRequest } from "types/friendship-request";
import { RequestItemWrapper, RequestsContainerWrapper } from "./styles";
import { trash2 } from "assets/icons";
import { useConfirmModal } from "context/confirmModal";
import { useTranslation } from "react-i18next";
import useDeleteFriendshipRequest from "hooks/services/friendship-request/useDeleteFriendshipRequest";
import useToasts from "hooks/useToasts";
import moment from "moment";

const RequestsSentItem: FC<{ req: FriendshipRequest }> = ({ req }) => {
  const { openModal } = useConfirmModal();
  const { t } = useTranslation();

  const {
    mutate: deleteRequest,
    error,
    isSuccess,
  } = useDeleteFriendshipRequest();

  useToasts([
    {
      severity: "error",
      message: t("Something went wrong, please try again later."),
      condition: !!error,
    },
    {
      severity: "success",
      message: t("Request deleted successfully."),
      condition: !!isSuccess,
    },
  ]);

  return (
    <RequestItemWrapper>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Avatar></Avatar>
      </Box>
      <span className="email-container">{req.recipientEmail}</span>
      <Box
        sx={{ display: { xs: "none", md: "block" } }}
        className="date-container"
      >
        {moment(req.createdAt).format("D MMM YYYY")}
      </Box>
      <span>
        <IconButton
          onClick={() => {
            openModal({
              title: t("Are you sure to delete this contact request?"),
              text: t("The operation is not reversible"),
              confirmFunction: () => deleteRequest({ requestId: req._id }),
            });
          }}
        >
          <img className="delete-icon" src={trash2} />
        </IconButton>
      </span>
    </RequestItemWrapper>
  );
};

const RequestsSent: FC = () => {
  const { t } = useTranslation();

  // TODO: Handle pagination

  const { data: requests, isLoading, error } = useFriendshpiRequestSent();
  if (error) throw new Error("Cannot get requests");

  return (
    <RequestsContainerWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="requests-container">
          {requests?.data.map((r) => (
            <React.Fragment key={r._id}>
              <RequestsSentItem req={r} />
            </React.Fragment>
          ))}
          {requests?.data.length === 0 && (
            <div className="placeholder">
              {t("You have not sent any request yet")}
            </div>
          )}
        </div>
      )}
    </RequestsContainerWrapper>
  );
};

export default RequestsSent;
