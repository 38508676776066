import * as Yup from "yup";

export const validationSchema = Yup.object({
  email: Yup.string().email("Enter a valid email").required("Email is required"),
  alias: Yup.string(),
});

export const initialValues = {
  email: "",
  alias: "",
};
