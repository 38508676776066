import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { RowAction } from "../RowActions/types";
import { useNavigate } from "react-router-dom";
import RowActionsBase from "../RowActions/RowActionsBase";
import { edit, trash2 } from "assets/icons";
import { CollectionRowActionProps } from "./types";
import useDeleteContact from "hooks/services/users/useDeleteContact";
import { Contact } from "types/user";
import { useTranslation } from "react-i18next";

const ContactRowActions: FC<
  CollectionRowActionProps & {
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    setSelectedContact: Dispatch<SetStateAction<Contact | null>>;
  }
> = ({ editAction, contact, setOpenModal, setSelectedContact }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    mutate: deleteContact,
    isLoading: isLoadingDeleteContact,
    isError: isErrorDeleteContact,
    isSuccess: isSuccessDeleteContact,
  } = useDeleteContact();

  const actions: RowAction[] = useMemo(
    () => [
      {
        icon: edit,
        action: () => {
          setOpenModal(true);
          setSelectedContact(contact);
        },
        name: t("Edit"),
      },
      {
        icon: trash2,
        action: () => deleteContact({ contactId: contact.user }), // TODO add endpoint ro remove contact
        name: t("Remove"),
        confirmMessage: t("Are you sure you want to delete this contact?"),
        actionState: {
          isLoading: isLoadingDeleteContact,
          isSuccess: isSuccessDeleteContact,
          successMessage: t("Contact deleted successfully"),
          isError: !!isErrorDeleteContact,
          errorMessage: t(
            "An error occurred while deleting the Contact, please try again"
          ),
        },
      },
    ],
    [navigate, contact]
  );

  return <RowActionsBase variant="menu" actions={actions} />;
};

export default ContactRowActions;
