import { Environment } from '@react-three/drei';
import { useState, useEffect } from 'react'
import { RGBELoader } from 'three-stdlib'
import { EXRLoader } from 'three-stdlib'
import { useLoader } from '@react-three/fiber'

//useLoader.preload(EXRLoader, "Bianco_2k.exr");
//useLoader.preload(EXRLoader, "carroponte.exr");
//useLoader.preload(EXRLoader, "studio2k.exr");

interface EnvManagerProps {
  enabledBackground: boolean,
  files: string[],
  indexMapToUse: number
}

export default function EnvManager(props) {
  
  useEffect(() => {
    props.files.forEach(file => {
      useLoader.preload(EXRLoader, file);
    })
  },[]) 

  const envs = ["carroponte.exr", "studio2k.exr", "Bianco_2k.exr"];
  const [currentEnvIndex, setCurrentEnvsIndex] = useState<number>(1);

  /*return (
    <Environment files={envs[currentEnvIndex]} background={props.enabledBackground}/>
  ) */
  return (
    <></>
  )
}