import useAuth from "hooks/useAuth";
import { FC, useEffect } from "react";
import { LoginPage } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import PATH from "routing/path";
import LoginForm from "./LoginForm";
import LoginBackground from "../../assets/images/login_background.png";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Page from "components/Layout/Page";
import Loader from "components/Loader";

const validationSchema = Yup.object({
  username: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Login: FC = () => {
  const { user, error, login, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation(); // @ https://github.com/remix-run/react-router/issues/7670

  const { t } = useTranslation();

  let from = (location.state as any)?.from?.pathname || PATH.DASHBOARD;

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [navigate, user]);

  if (isLoading)
    return (
      <Page>
        <Loader />
      </Page>
    );

  return (
    <LoginPage>
      <Stack direction="row" height="100%">
        <Stack flex="0 0 350px" width="350px">
          <LoginForm />
        </Stack>

        <div className="background-image">
          <img
            src={LoginBackground}
            alt="background"
            width={"100%"}
            height={"100%"}
          />
        </div>
      </Stack>
    </LoginPage>
  );
};

export default Login;
