import { Grid, InputAdornment, MenuItem, Select, Stack } from "@mui/material";
import { MRTextField } from "components/material";
import { MRButtonPrimary } from "components/material/Button";
import SelectUserModal from "components/User/SelectUserModal";
import { useFormik } from "formik";
import useCreateAcquisition from "hooks/services/acquisitions/useCreateAcquisition";
import useEditAcquisitionInfo from "hooks/services/acquisitions/useEditAcquisitionInfo";
import useToasts from "hooks/useToasts";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PATH from "routing/path";
import {
  Acquisition,
  AcquisitionInfoInputs,
  AcquisitionStatus,
} from "types/acquisition";
import { User } from "types/user";
import {
  formatAcquisitionInfo,
  initialValues,
  validationSchema,
  validationSchemaOwner,
} from "./model";

interface AcquisitionFormProps {
  acquisition?: Acquisition;
  owner?: User;
}

const AcquisitionForm: FC<AcquisitionFormProps> = ({ acquisition, owner }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    mutate: editAcquisitionInfo,
    isLoading: loadingEditAcquisitionInfo,
    error: editAcquisitionInfoError,
    isSuccess: isSuccessEditAcquisitionInfo,
  } = useEditAcquisitionInfo();

  const {
    mutateAsync: createAcquisition,
    isLoading: loadingCreateAcquisition,
    error: createAcquisitionError,
    isSuccess: isSuccessCreateAcquisition,
  } = useCreateAcquisition();

  useToasts([
    {
      condition: isSuccessEditAcquisitionInfo,
      message: t("Acquisition updated successfully"),
      severity: "success",
    },
    {
      condition: !!editAcquisitionInfoError,
      message: t("An error occured when updating acquistion"),
      severity: "error",
    },
    {
      condition: isSuccessCreateAcquisition,
      message: t("Acquisition created successfully"),
      severity: "success",
    },
    {
      condition: !!createAcquisitionError,
      message: t("An error occured when create acquistion"),
      severity: "error",
    },
  ]);

  const formik = useFormik<AcquisitionInfoInputs>({
    enableReinitialize: true,
    initialValues: acquisition
      ? formatAcquisitionInfo(acquisition)
      : initialValues,
    validationSchema: acquisition ? validationSchema : validationSchemaOwner,
    onSubmit: (acquistionInfo) => {
      const { createdAt, ...payload } = acquistionInfo;
      if (acquisition) {
        editAcquisitionInfo({ id: acquisition._id, ...payload });
      } else {
        createAcquisition({
          ...payload,
          owner: selectedUser!._id,
        }).then((res) => navigate(`${PATH.MODELS_TO_PROCESS}/${res.data._id}`));
      }
    },
  });

  const handleSelectedUser = (user: User) => {
    setSelectedUser(user);
    formik.setFieldValue(
      "owner",
      user.profile?.firstname + " " + user.profile?.lastname
    );
  };

  return (
    <>
      <SelectUserModal
        open={open}
        setOpen={setOpen}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        handleSelectedUser={handleSelectedUser}
      />
      <form onSubmit={formik.handleSubmit}>
        <Grid container columnSpacing={3} rowSpacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <p>{t("Owner")} </p>
            <MRTextField
              className="owner-input"
              fullWidth
              id="owner"
              name="owner"
              placeholder={t("Owner")}
              disabled={Boolean(acquisition)}
              sx={{
                input: { cursor: acquisition ? "not-allowed" : "pointer" },
              }}
              onClick={acquisition ? () => null : () => setOpen(true)}
              value={
                acquisition
                  ? `${owner?.profile?.firstname} ${owner?.profile?.lastname}`
                  : formik.values.owner
              }
              error={formik.touched.owner && Boolean(formik.errors.owner)}
              helperText={formik.touched.owner && formik.errors.owner}
            />

            <p>{t("Place of acquisition")} </p>
            <MRTextField
              fullWidth
              className="text-field"
              id="placeOfAcquisition"
              name="placeOfAcquisition"
              placeholder={t("Place of acquisition")}
              value={formik.values.placeOfAcquisition}
              onChange={formik.handleChange}
              error={
                formik.touched.placeOfAcquisition &&
                Boolean(formik.errors.placeOfAcquisition)
              }
              helperText={
                formik.touched.placeOfAcquisition &&
                formik.errors.placeOfAcquisition
              }
            />

            <p>{t("Notes")} </p>
            <MRTextField
              fullWidth
              className="text-field"
              id="notes"
              name="notes"
              placeholder={t("Notes")}
              value={formik.values.notes}
              onChange={formik.handleChange}
              error={formik.touched.notes && Boolean(formik.errors.notes)}
              helperText={formik.touched.notes && formik.errors.notes}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <p>{t("Title")} </p>
            <MRTextField
              fullWidth
              className="text-field"
              id="title"
              name="title"
              placeholder={t("Title")}
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />

            <p>{t("Material")} </p>
            <MRTextField
              fullWidth
              className="text-field"
              id="material"
              name="material"
              placeholder={t("Material")}
              value={formik.values.material}
              onChange={formik.handleChange}
              error={formik.touched.material && Boolean(formik.errors.material)}
              helperText={formik.touched.material && formik.errors.material}
            />

            <p>{t("Geological Family")} </p>
            <MRTextField
              fullWidth
              className="text-field"
              id="geologicalFamily"
              name="geologicalFamily"
              placeholder={t("Geological Family")}
              value={formik.values.geologicalFamily}
              onChange={formik.handleChange}
              error={
                formik.touched.geologicalFamily &&
                Boolean(formik.errors.geologicalFamily)
              }
              helperText={
                formik.touched.geologicalFamily &&
                formik.errors.geologicalFamily
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} marginBottom={2}>
            <Stack gap={2} direction="row">
              <Stack>
                <p>{t("Lenght")} </p>
                <MRTextField
                  fullWidth
                  className="text-field"
                  id="length"
                  name="length"
                  placeholder={t("Length")}
                  value={formik.values.length}
                  onChange={formik.handleChange}
                  error={formik.touched.length && Boolean(formik.errors.length)}
                  helperText={formik.touched.length && formik.errors.length}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack>
                <p>{t("Height")} </p>
                <MRTextField
                  fullWidth
                  className="text-field"
                  id="height"
                  name="height"
                  placeholder={t("Height")}
                  value={formik.values.height}
                  onChange={formik.handleChange}
                  error={formik.touched.height && Boolean(formik.errors.height)}
                  helperText={formik.touched.height && formik.errors.height}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
            <Stack gap={2} direction="row">
              <Stack>
                <p>{t("Depth")} </p>
                <MRTextField
                  fullWidth
                  className="text-field"
                  id="depth"
                  name="depth"
                  placeholder={t("Depth")}
                  value={formik.values.depth}
                  onChange={formik.handleChange}
                  error={formik.touched.depth && Boolean(formik.errors.depth)}
                  helperText={formik.touched.depth && formik.errors.depth}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack>
                <p>{t("Weight")} </p>
                <MRTextField
                  fullWidth
                  className="text-field"
                  id="weight"
                  name="weight"
                  placeholder={t("Weitgh")}
                  value={formik.values.weight}
                  onChange={formik.handleChange}
                  error={formik.touched.weight && Boolean(formik.errors.weight)}
                  helperText={formik.touched.weight && formik.errors.weight}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>

            {!acquisition && (
              <>
                <p>{t("Number of photos")} </p>
                <MRTextField
                  fullWidth
                  className="text-field"
                  id="numberOfPhotos"
                  name="numberOfPhotos"
                  placeholder={t("Number of photos")}
                  value={formik.values.numberOfPhotos}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.numberOfPhotos &&
                    Boolean(formik.errors.numberOfPhotos)
                  }
                  helperText={
                    formik.touched.numberOfPhotos &&
                    formik.errors.numberOfPhotos
                  }
                />
              </>
            )}

            {acquisition && formik.values.status && (
              <>
                <p>{t("Status")} </p>
                <Select
                  fullWidth
                  name="status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={AcquisitionStatus.IDLE}>{"Idle"}</MenuItem>

                  <MenuItem value={AcquisitionStatus.PENDING_PROCESSING}>
                    {"Pending Processing"}
                  </MenuItem>

                  <MenuItem value={AcquisitionStatus.PROCESSING}>
                    {"Processing"}
                  </MenuItem>

                  <MenuItem value={AcquisitionStatus.PROCESSED}>
                    {"Processed"}
                  </MenuItem>

                  <MenuItem value={AcquisitionStatus.REMOVED}>
                    {"Removed"}
                  </MenuItem>

                  <MenuItem value={AcquisitionStatus.SOLD}>{"Sold"}</MenuItem>
                </Select>
              </>
            )}
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="end" alignItems="center">
          <MRButtonPrimary
            type="submit"
            loading={
              acquisition
                ? loadingEditAcquisitionInfo
                : loadingCreateAcquisition
            }
          >
            {t("Confirm change")}
          </MRButtonPrimary>
        </Stack>
      </form>
    </>
  );
};

export default AcquisitionForm;
