import styled from "styled-components";
import { colors } from "styles/theme";

export const CreateCollectionModalWrapper = styled.div`
  .next-button-disabled {
    opacity: 0.8 !important;
    color: white;
  }

  .filter-button {
    color: ${colors.gray};
    cursor: pointer;
  }
`;

export const SetCollectionBlocksWrapper = styled.div`
  .blocks-container {
    overflow-y: scroll;
    height: 500px;
    background: ${colors.black};
    padding: 20px 5px 20px 20px;
  }

  .block-row {
    flex-wrap: wrap;
    background: ${colors.lightBlack};
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
  }

  .block-row > * {
    max-width: 140px;
  }

  .blocks-container::-webkit-scrollbar {
    width: 20px;
  }

  .blocks-container::-webkit-scrollbar-thumb {
    height: 100px;
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${colors.lightBlack};
  }

  .close-button-container {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
