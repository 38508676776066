import ROLE from "./role";

export enum UserStatus {
  INVITED = "invited",
  ACTIVE = "active",
  BLOCKED = "blocked",
  DELETED = "deleted",
}

export interface UserInvite {
  username: string;
  roles: string;
  credits?: number;
  storage?: number;
  bandwidth?: number;
}

export interface Profile {
  firstname: string;
  lastname: string;
  companyName: string;
  country: string;
  phone: string;
  companyField: string;
  vat: string;
  propic?: string;
  propicUrl?: string;
  companyLogo?: string;
  companyLogoUrl?: string;
}

export interface User {
  _id: string;
  username: string;
  roles: ROLE;
  status: UserStatus;
  profile?: Profile;
  credits: number;
  storage: number;
  bandwidth: number;
  apiKey?: string;
  downloadEnabled: boolean;
}

export interface UserInfoInputs {
  firstname: string;
  lastname: string;
  companyName: string;
  companyField: string;
  country: string;
  status: string;
  phone: string;
  vat: string;
  roles: string;
  downloadEnabled: boolean;
}

export interface PasswordInput {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface UsersCountProps {
  data: {
    name: string;
    value: number;
  }[];
}

export interface Contact {
  alias?: string;
  email: string;
  user: string;
  companyName: string;
  country: string;
  propicUrl?: string;
}
