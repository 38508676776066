import { FC, useState, useMemo } from "react";
import { TransferTableProps } from "./types";
import { Collapse, Stack } from "@mui/material";
import { MRPagination, MRDrawer } from "components/material";
import useTransfers from "hooks/services/transfers/useTransfers";
import { useTranslation } from "react-i18next";
import useMultiSelect from "hooks/useMultiSelect";
import TransferBulkActionsControl from "components/TablesComponents/Transfer/TransferBulkActionsControl";
import MRTransfersTable from "components/TablesComponents/Transfer/TransfersTable";
import { PAGE_SIZE } from "config/pagination";
import TitleCounter from "components/TablesComponents/TitleCounter";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import ROLE from "types/role";
import { Inputs as TransferFilters } from "components/Transfer/TransferFilterForm/model";
import TransferFilterFrom from "components/Transfer/TransferFilterForm";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const TransferTable: FC<TransferTableProps> = ({ blockId }) => {
  const [page, setPage] = useState(1);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<TransferFilters | null>(null);
  const [openTransfer, setOpenTransfer] = useState<boolean>(true);

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data: transfers,
    isLoading: loadingTransfers,
    isError: transfersError,
  } = useTransfers(page, PAGE_SIZE, {
    block: blockId,
    ...(formattedFilters && formattedFilters),
  });

  if (transfersError) throw new Error("Cannot get transfers");

  const { selected, toggleSelection, toggleAll, resetSelection } = useMultiSelect();
  const { t } = useTranslation();

  const toggleCollapse = () => {
    setOpenTransfer((prev) => !prev);
  };

  return (
    <>
      <MRDrawer anchor="right" open={filterDrawerOpen} onClose={toggleDrawer(false, setFilterDrawerOpen)}>
        <TransferFilterFrom
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </MRDrawer>
      <Stack
        direction="row"
        gap={3}
        alignItems="center"
        justifyContent={"space-between"}
        flexWrap="wrap"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "5px", sm: 3 },
          height: { sm: "76px" },
        }}
      >
        <span style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
          <TitleCounter title={t("Transfers")} count={transfers?.pagination[0]?.total || 0} />

          <span onClick={toggleCollapse} style={{ cursor: "pointer" }}>
            {openTransfer ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </span>
        </span>

        <Collapse in={openTransfer}>
          <Stack direction="row" alignItems="center" gap="10px">
            <FilterButton
              onClick={() => setFilterDrawerOpen(true)}
              activeFilters={formattedFilters ? Object.keys(formattedFilters).length : 0}
            />
            <TransferBulkActionsControl selectedIds={selected} resetSelection={resetSelection} />
          </Stack>
        </Collapse>
      </Stack>

      <Collapse in={openTransfer}>
        <MRTransfersTable
          userRole={ROLE.ADMIN}
          transfers={transfers?.data || []}
          selected={selected}
          toggleSelection={toggleSelection}
          toggleAll={toggleAll}
        />
        <Stack direction="row" justifyContent="center">
          {transfers?.data && transfers.data.length ? (
            <MRPagination
              count={transfers ? Math.ceil(transfers?.pagination[0]?.total / PAGE_SIZE) || 1 : 1}
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          ) : (
            <NoDataRow>{t("No Data")}</NoDataRow>
          )}
        </Stack>
      </Collapse>
    </>
  );
};

export default TransferTable;
