import { Popover, PopoverOrigin } from "@mui/material";

interface MRPopoverProps {
  id: string|undefined,
  open: boolean,
  anchor: HTMLElement|null,
  handleClose: () => void,
  anchorOrigin?: PopoverOrigin,
  transformOrigin?: PopoverOrigin,
  children?: any
}

export default function MRPopover(props: MRPopoverProps) {

  return (
    <Popover
        id={props.id}
        open={props.open}
        anchorEl={props.anchor}
        onClose={props.handleClose}
        anchorOrigin={props.anchorOrigin}
        transformOrigin={props.transformOrigin}
        elevation={0}
        slotProps={{
          paper: {
            style: { backgroundColor: "transparent", borderRadius: 0 }
          }
        }}
        marginThreshold={0}
      >
        {props.children}
      </Popover>
  )

}