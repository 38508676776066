import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { Contact } from "types/user";

interface EditContactInfo {
  email: string;
  alias: string;
}

export default function useEditContact() {
  const queryClient = useQueryClient();

  const editContact = (data: EditContactInfo) => {
    const request: Request = {
      url: ENDPOINTS.users.contact,
      config: {
        method: "patch",
        data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<Contact>, Error, EditContactInfo>(
    editContact,
    {
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([ENDPOINTS.users.contacts]);
      },
    }
  );

  return { data, mutate, ...rest };
}
