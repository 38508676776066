import styled from "styled-components";
import { colors } from "styles/theme";

const Page = styled.div`
  padding: 20px;
  height: 100%;
  width: 100%;

  animation: fadeIn 0.3s;
  -webkit-animation: fadeIn 0.3s;
  -moz-animation: fadeIn 0.3s;
  -o-animation: fadeIn 0.3s;
  -ms-animation: fadeIn 0.3s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 600px) {
    & {
      padding: 10px;
      padding-bottom: 100px;
    }
  }
`;

export default Page;
