import styled from "styled-components";
import { colors } from "styles/theme";

export const SelectUserModalWrapper = styled.div`
  .dialog {
    padding: 26px 32px 30px;
  }

  .modal-title > h2 {
    margin-bottom: 0px;
  }

  .modal-title > span {
    color: ${colors.green};
  }
`;
