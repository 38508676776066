import { Stack, Tab } from "@mui/material";
import Page from "components/Layout/Page";
import { FC, useState } from "react";
import { ModelsWrapper } from "./styles";
import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import { TabContext } from "@mui/lab";
import TabPanel from "components/material/TabPanel";
import { MRTabList } from "components/material";
import AcquisitionSellerTable from "components/Acquisition/AcquisitionSellerTable";
import MyModels from "./MyModels";
import SharedModels from "./SharedModels";
import ROLE from "types/role";
import Locked from "components/Locked";
import PageHeader from "components/Layout/PageHeader";
import { useSearchParams } from "react-router-dom";

const Models: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const [activeTab] = useState<string | null | undefined>(
    searchParams.get("activeTab")
  );
  const [tab, setTab] = useState<string>(
    activeTab ??
      (user && user.roles === ROLE.BUYER ? "models_shared_with_me" : "models")
  );

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  return (
    <Page>
      <ModelsWrapper>
        <PageHeader>
          <h1>{t("Models")}</h1>
        </PageHeader>
        <TabContext value={tab}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <TabContext value={tab} >
            <MRTabList /*value={tab}*/ onChange={handleTabChange} >
              <Tab label={t("My Models")} value="models" />
              <Tab
                label={t("Models shared with me")}
                value="models_shared_with_me"
              />
              <Tab label={t("Models to process")} value="models_to_process" />
            </MRTabList>
            </TabContext>
          </Stack>
          <TabPanel
            active={tab}
            value="models"
            sx={{ padding: 0, paddingTop: 2 }}
          >
            {user && user.roles === ROLE.SELLER && <MyModels />}
            {user && user.roles === ROLE.BUYER && <Locked />}
          </TabPanel>
          <TabPanel
            active={tab}
            value="models_to_process"
            sx={{ padding: 0, paddingTop: 2 }}
          >
            {user && user.roles === ROLE.SELLER && <AcquisitionSellerTable />}
            {user && user.roles === ROLE.BUYER && <Locked />}
          </TabPanel>
          <TabPanel
            active={tab}
            value="models_shared_with_me"
            sx={{ padding: 0, paddingTop: 2 }}
          >
            <SharedModels />
          </TabPanel>
        </TabContext>
      </ModelsWrapper>
    </Page>
  );
};

export default Models;
