import styled from "styled-components";
import { colors } from "styles/theme";

export const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  .table-icon-container img {
    cursor: pointer;
    width: 20px;
  }

  .link-text {
    cursor: pointer;
  }

  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const NoDataRow = styled.div`
  background-color: ${colors.lightBlack};
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
`;
