import styled from "styled-components";
import { colors } from "styles/theme";

export const ModelsWrapper = styled.div`
  .cta-credits {
    font-size: 1.05em;
  }

  .cta-credits span {
    color: ${colors.green};
    font-weight: bold;
  }

  .view-selector .MuiTab-root {
    min-width: unset;

    &.Mui-selected {
      color: ${colors.green};
    }
  }

  .search-blocks .Mui-root {
    background: ${colors.white};
  }
`;
