import { useState, useRef, useEffect } from 'react'
import { ButtonBase, Box,  Stack, SvgIcon } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import StraightenIcon from '@mui/icons-material/Straighten';
import ModeIcon from '@mui/icons-material/Mode';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import EnvironmentLight from '../ui-icons/EnvironmentLight'
import StudioLight from '../ui-icons/StudioLight'
import DiffuseLight from '../ui-icons/DiffuseLight'

import MRPopover from '../ui-components/MRPopover';
import MRDivider from '../ui-components/MRDivider';
import MRButtonWithIconText from '../ui-components/MRButtonWithIconText';

// measure
// ruler
// reset
// visibility 

// env
// studio
// diffuse

export default function ToolsButton(props: any) {
  const refAnchor = useRef<HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  
  const alternativeAnchor = () => {
    setAnchorEl(props.anchor.current);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box component={"div"}  ref={refAnchor} sx={{ display: "flex", height: "100%" }}>
      <ButtonBase disableRipple  onClick={alternativeAnchor} title={"Tools"}>
        {open ? <CloseIcon sx={{ color: "#4BBECF" }} /> : <MenuIcon sx={{ color: "#4BBECF" }} />}
      </ButtonBase>
      <MRPopover
        id={id}
        open={open}
        anchor={anchorEl}
        handleClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          component={"div"}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            backgroundColor: "rgba(6,11,12,0.75)",
            justifyContent: "center"
          }}
          padding={1}
        >
          <MRDivider text={"TOOLS"} colorDivider={"#A3D8E7"} />
          <Stack
            display={"flex"}
            flexDirection={"row"}
            gap={2}
            marginBottom={1}
          >

            <MRButtonWithIconText
              icon={<StraightenIcon  />}
              text={"Ruler"}
              onClickAction={()=>{props.onClickMeasure(); handleClose()}}
            />

            <MRButtonWithIconText
              icon={<ModeIcon  />}
              text={"Pencil"}
              onClickAction={() => {props.onClickFreeDraw(); handleClose()}}
            />

            <MRButtonWithIconText
              icon={<VisibilityOffOutlinedIcon  />}
              text={"Lines"}
              onClickAction={() => {props.onClickToggleVisibility(); handleClose()}}
            />

            <MRButtonWithIconText
              icon={<RestartAltIcon  />}
              text={"Reset"}
              onClickAction={() => {props.onClickReset(); handleClose()}}
            />
            
          </Stack>

          <MRDivider text={"LIGHTS"} colorDivider={"#A3D8E7"} />
          <Box
            component={"div"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            marginTop={"1%"}
          >
            <MRButtonWithIconText
              icon={<SvgIcon viewBox={"0 0 50 37"} >
                <EnvironmentLight />
              </SvgIcon>}
              onClickAction={() => {props.setEnvLight(); handleClose()}}
              text={"Natural"}
            />

            <MRButtonWithIconText
              icon={<SvgIcon viewBox={"0 0 50 37"} >
                <StudioLight />
              </SvgIcon>}
              text={"Studio"}
              onClickAction={() => {props.setEnvStudioLight(); handleClose()}}
            />

            <MRButtonWithIconText
              icon={<SvgIcon viewBox={"0 0 50 37"} >
                <DiffuseLight />
              </SvgIcon>}
              text={"Diffuse"}
              onClickAction={() => {props.setEnvDiffLight(); handleClose()}}
            />
          </Box>
        </Box>
      </MRPopover>
    </Box>
  )
}