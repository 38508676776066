import { MRCard } from "components/material";
import { FC } from "react";
import { FallbackProps } from "react-error-boundary";
import { ErrorPage } from "./styles";
import { ReactComponent as Logo } from "assets/icons/logo-white.svg";
import { Stack } from "@mui/material";
import { MRButtonPrimary } from "components/material/Button";
import { colors } from "styles/theme";
import i18n from "i18n";

const FullpageErroFallBack: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <ErrorPage>
      <MRCard className="card">
        <Stack alignItems="center">
          <Logo width="70px" height="70px" />
          <h3>{i18n.t("Ops, something went wrong!")}</h3>
          <span>{error.message}</span>
          <MRButtonPrimary
            type="submit"
            sx={{
              bgcolor: `${colors.green} !important`,
              color: `${colors.black} !important`,
            }}
            onClick={resetErrorBoundary}
          >
            {i18n.t("Navigate to homepage")}
          </MRButtonPrimary>
        </Stack>
      </MRCard>
    </ErrorPage>
  );
};

export default FullpageErroFallBack;
