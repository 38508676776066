import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";

export default function useRemoveUser() {
  const queryClient = useQueryClient();

  const removeUser = () => {
    const request: Request = {
      url: ENDPOINTS.users.remove,
      config: {
        method: "patch",
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<null>, Error>(
    removeUser,
    {
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([ENDPOINTS.auth.me]);
      },
    }
  );

  return { data, mutate, ...rest };
}
