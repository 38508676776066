import { IconButton, Menu, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import { colors } from "styles/theme";
import { BlockActionsProps } from "./types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ShareModal from "components/Share/ShareModal";
import { useTranslation } from "react-i18next";
import CreateTransferModal from "components/Transfer/CreateTransferModal";
import AddBlockToCollectionModal from "./AddBlockToCollectionModal";
import useRecoverBlock from "hooks/services/blocks/useRecoverBlock";
import useToasts from "hooks/useToasts";
import { BlockStatus } from "types/block";
import { getBlockColor } from "helpers";
import useTrashBlock from "hooks/services/blocks/useTrashBlock";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import PATH from "routing/path";
import useRemoveBlock from "hooks/services/blocks/useRemoveBlock";
import { useConfirmModal } from "context/confirmModal";
import usePullBlockFromCollection from "hooks/services/collections/usePullBlockFromCollection";
import toast from "react-hot-toast";

const BlockActions: FC<BlockActionsProps> = ({
  variant,
  block,
  collection,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const moreOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);
  const [openAddToCollectionModal, setOpenAddToCollectionModal] =
    useState<boolean>(false);

  const {
    mutate: recoverBlock,
    isLoading: recoverBlockLoading,
    isSuccess: recoverBlockSuccess,
    error: recoverBlockError,
  } = useRecoverBlock(block._id);

  const {
    mutateAsync: trashBlock,
    isLoading: trashBlockLoading,
    isSuccess: trashBlockSuccess,
    error: trashBlockError,
  } = useTrashBlock();

  const {
    mutateAsync: removeBlock,
    isLoading: removeBlockLoading,
    isSuccess: removeBlockSuccess,
    error: removeBlockError,
  } = useRemoveBlock();

  const {
    mutate: pullBlock,
    isLoading: pullBlockLoading,
    isSuccess: pullBlockSuccess,
    error: pullBlockError,
  } = usePullBlockFromCollection();

  const { openModal: openDeletePermanentlyModal } = useConfirmModal();
  const { openModal: openTrashModal } = useConfirmModal();

  const handleDeletePermanently = () => {
    openDeletePermanentlyModal({
      title: t("Are you sure you want to delete this block?"),
      text: t("The operation is not reversible"),
      confirmFunction: () =>
        removeBlock(block._id).then(() =>
          navigate(generatePath(PATH.PROCESSED_MODELS))
        ),
    });
  };

  const handleTrashModal = () => {
    openTrashModal({
      title: t("Are you sure you want to move this block to trash?"),
      text: t(
        "All the pending transfers related to this block will be deleted, you can recover this block within 30 days from your profile page"
      ),
      confirmFunction: () =>
        trashBlock(block._id).then(() =>
          toast(t("Block moved to trash successfully."))
        ),
    });
  };

  useToasts([
    {
      severity: "error",
      message: t(
        "Cannot recover this block. Please try again later or contact the administrator."
      ),
      condition: !!recoverBlockError,
    },
    {
      severity: "success",
      message: t("Block recovered successfully."),
      condition: !!recoverBlockSuccess,
    },
    {
      severity: "error",
      message: t(
        "Cannot move this block to trash. Please try again later or contact the administrator."
      ),
      condition: !!trashBlockError,
    },
    {
      severity: "error",
      message: t(
        "Cannot remove permanently this block. Please try again later or contact the administrator."
      ),
      condition: !!removeBlockError,
    },
    {
      severity: "success",
      message: t("Block removed successfully."),
      condition: !!removeBlockSuccess,
    },
    {
      severity: "error",
      message: t(
        "Cannot remove this block from. Please try again later or contact the administrator."
      ),
      condition: !!pullBlockError,
    },
    {
      severity: "success",
      message: t("Block deleted from collection successfully."),
      condition: !!pullBlockSuccess,
    },
  ]);

  //TODO Finish block functionalities
  const actions = {
    owned: [
      {
        name: t("View"),
        action: () => navigate(`${PATH.MODELS}/edit/${block._id}`),
      },
      {
        name: t("View in 3D"),
        action: () =>
          navigate(
            generatePath(`/viewer/block/:id`, {
              id: block._id,
            }) + `?returnUrl=${location.pathname}`
          ),
      },
      {
        name: t("Share"),
        action: () => setOpenShareModal(true),
      },
      {
        name: t("Transfer ownership"),
        action: () => setOpenTransferModal(true),
      },
      {
        name: collection
          ? t("Add to another catalogue")
          : t("Add to catalogue"),
        action: () => setOpenAddToCollectionModal(true),
      },
      ...(collection
        ? [
            {
              name: t("Remove from catalogue"),
              action: () =>
                pullBlock({ blockId: block._id, collectionId: collection }),
            },
          ]
        : []),
      {
        name: collection ? t("Move to trashbin") : t("Remove"),
        action: () => handleTrashModal(),
      },
    ],

    shared: [
      {
        name: t("View"),
        action: () => navigate(`${PATH.MODELS}/${block._id}`),
      },
      {
        name: t("View in 3D"),
        action: () =>
          navigate(
            generatePath(`/viewer/block/:id`, {
              id: block._id,
            }) + `?returnUrl=${location.pathname}`
          ),
      },
    ],

    trashed: [
      {
        name: t("Recover"),
        action: () => recoverBlock(),
      },
      {
        name: t("Delete permanently"),
        action: () => handleDeletePermanently(),
      },
    ],
  };

  const paperProps = (variant: string, status: BlockStatus) => {
    if (status === BlockStatus.TRASHED)
      return {
        className: "more-options-menu",
        style: {
          backgroundColor: colors.white,
          color: colors.black,
          borderRadius: "10px",
          border: `1px solid ${colors.white}`,
        },
      };

    switch (variant) {
      case "owned":
        return {
          className: "more-options-menu",
          style: {
            backgroundColor: colors.green,
            color: colors.black,
            borderRadius: "10px",
            border: `1px solid ${colors.white}`,
          },
        };
      case "shared":
        return {
          className: "more-options-menu",
          style: {
            backgroundColor: colors.primary,
            color: colors.black,
            borderRadius: "10px",
            border: `1px solid ${colors.white}`,
          },
        };
      default:
        return {};
    }
  };

  return (
    <>
      <ShareModal
        open={openShareModal}
        setOpen={setOpenShareModal}
        blockId={block._id}
      />
      <CreateTransferModal
        open={openTransferModal}
        setOpen={setOpenTransferModal}
        blockId={block._id}
      />
      <AddBlockToCollectionModal
        openModal={openAddToCollectionModal}
        setOpenModal={setOpenAddToCollectionModal}
        blockId={block._id}
      />
      <IconButton onClick={moreOptions}>
        <MoreHorizIcon
          fontSize="large"
          className="more-horizon-icon"
          sx={{
            cursor: "pointer",
            color: getBlockColor(variant, block.status),
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={paperProps(variant, block.status)}
      >
        {(block.status === BlockStatus.TRASHED
          ? actions["trashed"]
          : actions[variant]
        ).map((action, idx) => (
          <MenuItem key={idx} onClick={() => action.action()}>
            {action.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default BlockActions;
