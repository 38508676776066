import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery, useInfiniteQuery } from "react-query";
import { Request } from "types/request";
import { PaginatedResponse } from "types/response";
import { Block } from "types/block";
import Error from "types/error";

export default function useInfiniteSharedBlocks({ size = 5, query = {} }) {
  const requestConfig = {
    queryKey: [ENDPOINTS.shares.sharedBlocks, query && JSON.stringify(query)],
    queryFn: ({ pageParam = 1 }) => {
      const request: Request = {
        url: ENDPOINTS.shares.sharedBlocks,
        config: {
          method: "GET",
          params: { page: pageParam, size, ...query },
        },
      };
      return client(request).then((res) => res.data);
    },
  };

  const { data, ...rest } = useInfiniteQuery<PaginatedResponse<Block[]>, Error>({
    ...requestConfig,
    getNextPageParam: (lastPage) => {
      if (lastPage.pagination.length) {
        return lastPage.pagination[0].size * lastPage.pagination[0].page >=
          lastPage.pagination[0].total
          ? undefined
          : lastPage.pagination[0].page + 1;
      }
      return undefined;
    },
  });

  return { data, ...rest };
}
