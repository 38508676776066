import useAuth from "hooks/useAuth";
import { FC } from "react";
import { LoginFormWrapper } from "./styles";
import { useFormik } from "formik";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Stack,
  Button,
} from "@mui/material";
import { MRButtonPrimary } from "components/material/Button";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { useTranslation } from "react-i18next";
import { loginInitialValues, loginValidationSchema } from "./model";
import useToasts from "hooks/useToasts";
import { getErrorMessage } from "./errors";
import { parseError } from "utils/errors";
import PATH from "routing/path";
import { AxiosError } from "axios";

const LoginForm: FC = () => {
  const { error, login, isLoading } = useAuth();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      login(values);
    },
  });

  const genericError = !!(
    error &&
    !(
      parseError(error).name === "UnauthorizedError" &&
      parseError(error).message === "jwt expired"
    )
  );

  const userNotVerifiedError =
    !!error && (error as AxiosError).response?.data.name === "UserNotVerified";

  useToasts([
    {
      severity: "error",
      message: getErrorMessage(error),
      condition: genericError && !userNotVerifiedError,
    },
    {
      severity: "error",
      message: t("Please verify your email address before logging in."),
      condition: userNotVerifiedError,
    },
  ]);

  return (
    <LoginFormWrapper>
      <Logo width="100px" height="100px" />
      <h3 style={{ margin: "1rem" }}>{t("Login")}</h3>
      <Stack style={{ height: "fit-content" }}>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-field">
            <h3>{t("Email Address")}</h3>
            <TextField
              fullWidth
              className="text-field"
              id="username"
              name="username"
              placeholder={t("Email")}
              data-testid="username-input"
              InputProps={{
                className: "text-field",
              }}
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </div>
          <div className="form-field">
            <h3>{t("password")}</h3>
            <TextField
              fullWidth
              className="text-field"
              id="password"
              name="password"
              type="password"
              placeholder={t("password")}
              data-testid="password-input"
              InputProps={{
                className: "text-field",
              }}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </div>

          <Stack>
            <FormControlLabel control={<Checkbox />} label={t("Remember me")} />
            <Button
              className="reset-password-button"
              onClick={(event) => {
                event.preventDefault();
                navigate("/password/forgot");
              }}
            >
              {t("Reset password")}
            </Button>
          </Stack>

          <MRButtonPrimary
            type="submit"
            variant="contained"
            color="primary"
            loading={isLoading}
            className="submit-button"
            data-testid="submit-button"
          >
            {t("Login")}
          </MRButtonPrimary>
          <h3 style={{ display: "flex", alignItems: "center" }}>
            {t("No account yet")}
            <span>
              <Button
                className="reset-password-button"
                onClick={() => navigate(PATH.FREESIGNUP)}
              >
                {t("New account")}
              </Button>
            </span>
          </h3>
        </form>
      </Stack>
    </LoginFormWrapper>
  );
};

export default LoginForm;
