import Page from "components/Layout/Page";
import styled from "styled-components";
import { colors } from "styles/theme";

export const ProfilePage = styled.div`
  .name-container h1 {
    margin: 0px;
    margin-bottom: 10px;
  }

  .contacts-button {
    color: ${colors.black};
    border-radius: 10px;
    width: fit-content;
    padding: 10px;
    text-transform: none;
  }

  .contacts-button img {
    margin-right: 10px;
    filter: brightness(0) saturate(100%);
  }
`;

export const InfoChip = styled.div`
  padding: 5px 10px;
  border: 1px solid ${colors.mediumGray};
  border-radius: 10px;

  & img {
    margin-top: 3px;
    color: ${colors.green};
  }
`;

export const ButtonDialog = styled.div`
  .close-button-container {
    width: 50px;
    height: 50px;
    border: solid 1px;
    border-radius: 30px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    float: right;
    background: ${colors.lightBlack};
  }

  .close-button-container Button {
    font-size: 20px;
    color: ${colors.green};
  }
`;
