import { Request } from "types/request";
import axios, { AxiosPromise } from "axios";
import { USER_REFRESH_TOKEN_KEY, USER_TOKEN_KEY } from "config/localStorage";
import { ENDPOINTS } from "config/endpoints";
import PATH from "routing/path";

const client = (req: Request) => {
  const token = localStorage.getItem(USER_TOKEN_KEY);
  const { url, config } = req;

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BE_URL,
    ...(token && { headers: { Authorization: `Bearer ${token}` } }),
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        error.response.status === 401 &&
        error.response.data.name === "UnauthorizedError" &&
        error.response.data.message === "jwt expired"
      ) {
        // Request needs to be retried with a new access token
        const refreshToken = localStorage.getItem(USER_REFRESH_TOKEN_KEY);
        if (!refreshToken) {
          localStorage.clear();
          window.location.href = PATH.LOGIN;
          return Promise.reject(error);
        }

        const refreshEndpoint = `${process.env.REACT_APP_BE_URL || "http://localhost:8080/api"}/${
          ENDPOINTS.auth.refresh
        }`;

        return axios
          .post(refreshEndpoint, { token: refreshToken })
          .then((response) => {
            // Set new tokens
            localStorage.setItem(USER_TOKEN_KEY, response.data.accessToken);
            localStorage.setItem(USER_REFRESH_TOKEN_KEY, response.data.refreshToken);

            return axios({
              ...error.config,
              headers: {
                ...error.config.headers,
                Authorization: `Bearer ${response.data.accessToken}`,
              },
            });
          })
          .catch((error) => {
            // Refresh token may be expired too, clear local storage and redirect to login page
            localStorage.clear();
            window.location.href = PATH.LOGIN;
            return Promise.reject(error);
          });
      }

      if (error.response.status === 401 && error.response.data.name === "UnauthorizedError") {
        window.location.href = PATH.LOGIN;
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance(url, config);
};

export default client;
