import { Dialog } from "components/material/Dialog";
import styled from "styled-components";
import { colors } from "styles/theme";

export const StyledDialog = styled(Dialog)`
  .close-button-container {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
