import Page from "components/Layout/Page";
import useAuth from "hooks/useAuth";
import { FC } from "react";
import ROLE from "types/role";
import CollectionTable from "./admin";
import SellerCatalogues from "./user";

const Catalogues: FC = () => {
  const { user } = useAuth();

  if (user?.roles === ROLE.ADMIN || user?.roles === ROLE.OPERATOR)
    return (
      <Page>
        <CollectionTable />
      </Page>
    );

  if (user?.roles === ROLE.SELLER || user?.roles === ROLE.BUYER) return <SellerCatalogues />;

  return <></>; //TODO: Redirect to ?
};

export default Catalogues;
