import styled from "styled-components";
import { colors } from "styles/theme";

export const HorizontalScrollerWrapper = styled.div`
  overflow-x: scroll;
  padding: 10px 0px 20px;

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.gray};
  }
`;

export const ScrollerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px;

  span {
    cursor: pointer;
  }
`;

export const HorizontalScrollerContainer = styled.div`
  background-color: ${colors.lightBlack};
  padding-bottom: 0px;
  border-radius: 10px;

  @media screen and (max-width: 600px) {
    & {
      width: calc(100% + 20px);
      margin-left: -10px;
      margin-right: -10px;
    }
  }
`;
