import { IconButton } from "@mui/material";
import { FC } from "react";
import { ShareModalWrapper } from "./style";
import ClearIcon from "@mui/icons-material/Clear";
import { ShareModalProps } from "./types";
import ContactsDialog from "./contactsDialog";
import LinkDialog from "./linkDialog";

const ShareModal: FC<ShareModalProps> = ({ open, setOpen, blockId, collectionId, forward }) => {
  if (!blockId && !collectionId) throw new Error("Please specify blockId or collectionId");

  if (blockId && collectionId) throw new Error("Please choose one between blockId or collectionId");

  const handleClose = (e: object, reason: string) => {
    if (reason === "escapeKeyDown") {
      setOpen(false);
    }
  };

  return (
    <>
      <div>
        <ShareModalWrapper
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="MR-ShareModal"
          PaperProps={{
            style: {
              backgroundImage: "none",
              backgroundColor: "transparent",
              boxShadow: "none",
              width: "100%",
              padding: "20px",
            },
            className: "share-modal-paper",
          }}
        >
          <div className="button-dialog">
            <div className="close-button-container">
              <IconButton onClick={(e) => handleClose(e, "escapeKeyDown")}>
                <ClearIcon />
              </IconButton>
            </div>
          </div>

          <ContactsDialog
            blockId={blockId}
            collectionId={collectionId}
            setOpen={setOpen}
            forward={forward}
          />

          {blockId && !forward ? <LinkDialog blockId={blockId} /> : <></>}
        </ShareModalWrapper>
      </div>
    </>
  );
};

export default ShareModal;
