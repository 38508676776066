export const ENDPOINTS = {
  auth: {
    invite: "auth/invite",
    checkInvite: "/auth/check-invite",
    signup: "/auth/signup",
    freesignup: "/auth/free-signup",
    verifyEmail: "/auth/verify-email",
    login: "auth/login",
    refresh: "auth/refresh",
    me: "auth/me",
    forbidden: "auth/forbidden",
    passwordForgot: "auth/reset-password-request",
    passwordReset: "auth/reset-password",
    changePassword: "auth/change-password",
    checkEmail: "auth/check-email",
  },
  s3: {
    credentials: "s3/credentials",
    completeUpload: "s3/complete-multipart-upload-model",
    completeUploadPhotos: "s3/complete-multipart-upload-photos",
  },

  block: {
    all: "block",
    one: "block/:id",
    ownerInfo: "block/:id/owner-info",
    recover: "block/:id/recover",
    trash: "block/:id/trash",
    hide: "block/:id/hide",
    show: "block/:id/show",
    remove: "block/:id/remove",
  },
  acquisitions: {
    all: "acquisition",
    one: "acquisition/:id",
    remove: "acquisition/:id/remove",
    setProcessing: "acquisition/:id/processing",
    uploadInfo: "acquisition/:id/model-upload-info",
    photosUploadInfo: "acquisition/:id/photos-upload-info",
    notifyModelUpload: "/acquisition/:id/model-upload-completed",
    notifyPhotosUpload: "/acquisition/:id/photos-upload-completed",
    thumbnail: "acquisition/:id/thumbnail",
    monthlyCount: "acquisition/monthlycount",
  },
  users: {
    all: "user",
    one: "user/:id/profile",
    user: "user/:id",
    restore: "user/:id/restore",
    profile: "user/profile",
    sellerInfo: "user/:id/seller-info",
    contacts: "user/contacts",
    contact: "user/contact",
    storage: "user/storage",
    contactDelete: "user/contact/:id",
    propic: "user/propic",
    companyLogo: "user/company-logo",
    deleteLogo: "user/delete/company-logo",
    remove: "user/remove",
    apiKey: "user/:id/api-key",
  },
  shares: {
    all: "share",
    one: "share/:id/",
    collection: "share/collection",
    block: "share/block/:id",
    monthlyCount: "share/monthlycount",
    sharedBlocks: "share/shared-blocks",
    canForward: "share/block/:id/can-forward",
    forwardBlock: "share/block/:id/forward",
    canForwardCollection: "share/collection/:id/can-forward",
    forwardCollection: "share/collection/:id/forward",
    remove: "share/:id/remove",
  },
  links: {
    block: "link/block/:id",
    revoke: "link/:id/revoke",
    blockFromCode: "link/code/:code",
  },
  collections: {
    one: "collection/:id/",
    all: "collection",
    addBlock: "/collection/:collectionId/block/:blockId",
    pullBlock: "/collection/:collectionId/block/:blockId",
  },
  transfers: {
    all: "transfer",
    one: "transfer/:id",
    create: "transfer/block/:blockId",
    direct: "transfer/block/:blockId/direct",
    remove: "transfer/:id/remove",
    accept: "transfer/:id/accept",
    decline: "transfer/:id/decline",
  },
  bandwidth: {
    bandwidth: "bandwidth/",
  },
  friendshipRequest: {
    create: "/friendship-request",
    sent: "/friendship-request/sent",
    delete: "/friendship-request/:id/remove",
    accept: "/friendship-request/:id/accept",
    received: "/friendship-request/received",
    decline: "/friendship-request/:id/decline",
  },
  notifications: {
    all: "/notification",
    read: "notification/read",
  },
};
