import { ListItem, ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, matchPath, useNavigate } from "react-router-dom";
import { NavItemProps } from "./types";

const NavItem: FC<NavItemProps> = ({ item, open }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const active = useMemo(() => {
    return !!matchPath({ path: item.path, end: true }, location.pathname);
  }, [location.pathname, matchPath]);

  return (
    <ListItem className={active ? "active" : ""} key={item.path} disablePadding onClick={() => navigate(item.path)}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        {item.icon ? (
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {item.icon}
          </ListItemIcon>
        ) : (
          ""
        )}
        <ListItemText primary={t(item.label)} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;
