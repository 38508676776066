import MRCard from "components/material/Card";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DownloadPhotosWrapper } from "./styles";
import { DownloadPhotosProps } from "./types";
import DownloadFileIcon from "assets/icons/download-file.svg";
import { Stack } from "@mui/material";
import { MRButtonPrimary } from "components/material/Button";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { AcquisitionStatus } from "types/acquisition";
import useSetProcessing from "hooks/services/acquisitions/useSetProcessing";
import useToasts from "hooks/useToasts";
import UploadPhotos from "./UploadPhotos";

const DownloadPhotos: FC<DownloadPhotosProps> = ({ acquisition }) => {
  const { t } = useTranslation();

  const {
    data: setProcessingResponse,
    mutate: setProcessing,
    isLoading: setProcessingLoading,
    error: setProcessingError,
  } = useSetProcessing(acquisition._id);

  const isPhotoDownloaded = (status: AcquisitionStatus) => {
    return (
      acquisition.status !== AcquisitionStatus.PENDING_PROCESSING &&
      acquisition.status !== AcquisitionStatus.IDLE
    );
  };

  const handleDownloadClick = () => {
    if (!isPhotoDownloaded(acquisition.status)) setProcessing();
  };

  function humanFileSize(size: number) {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (
      Number((size / Math.pow(1024, i)).toFixed(2)) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  }

  useToasts([
    {
      severity: "error",
      message: t(
        "There was an error setting the acquisition as 'processing'. Please contact the administrator."
      ),
      condition: !!setProcessingError,
    },
  ]);

  return (
    <DownloadPhotosWrapper>
      <MRCard
        className={isPhotoDownloaded(acquisition.status) ? "mr-card-green" : ""}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          padding={2}
        >
          <Stack direction="column" alignItems="flex-start" ml={2}>
            {isPhotoDownloaded(acquisition.status) && (
              <Stack>
                <h2>{t("Download completed")}</h2>
              </Stack>
            )}

            <Stack gap={1}>
              {acquisition.status !== AcquisitionStatus.IDLE &&
              acquisition.photos ? (
                <>
                  <span>
                    {acquisition.numberOfPhotos} {t("photos")} -{" "}
                    {acquisition.size
                      ? humanFileSize(acquisition.size * 1024 * 1024 * 1024)
                      : "0 B"}
                  </span>
                  <a
                    href={acquisition.photos}
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <MRButtonPrimary
                      className="download-button"
                      onClick={handleDownloadClick}
                    >
                      <SystemUpdateAltIcon
                        fontSize="small"
                        sx={{ marginRight: "10px" }}
                      />{" "}
                      {t("Download")}
                    </MRButtonPrimary>
                  </a>
                </>
              ) : (
                // <span>{t("Photos not yet uploaded")}</span>
                <UploadPhotos acquisition={acquisition} />
              )}
            </Stack>
          </Stack>
          <img
            src={DownloadFileIcon}
            alt="Download file"
            className="download-file-icon"
          />
        </Stack>
      </MRCard>
    </DownloadPhotosWrapper>
  );
};

export default DownloadPhotos;
