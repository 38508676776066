import { IconButton, Slide, Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { BlockShareModalProps } from "./types";
import ClearIcon from "@mui/icons-material/Clear";
import { ShareModalWrapper } from "../ShareModal/style";
import moment from "moment";
import { MRCheckbox, MRTextField } from "components/material";
import { useTranslation } from "react-i18next";
import { MRButtonPrimary } from "components/material/Button";
import { colors } from "styles/theme";
import SelectContact from "components/Contact/SelectContact";
import { Contact } from "types/user";
import useCreateCollection from "hooks/services/collections/useCreateCollection";
import useCreateShare from "hooks/services/shares/useCreateShares";
import useAuth from "hooks/useAuth";
import toast from "react-hot-toast";
import useToasts from "hooks/useToasts";

const BulkShareModal: FC<BlockShareModalProps> = ({ open, setOpen, blocksIds }) => {
  const { user } = useAuth();
  const [step, setStep] = useState<number>(0);
  const [collectionName, setCollectionName] = useState("");
  const [isForwardable, setIsForwardable] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);

  const {
    mutateAsync: createCollectionAsync,
    isLoading: isLoadingCrteateCollection,
    isError: isErrorCreateCollection,
  } = useCreateCollection();
  const {
    mutateAsync: createShareAsync,
    isLoading: isLoadingCreateShare,
    isError: isErrorCreateShare,
  } = useCreateShare();

  useEffect(() => {
    if (open) {
      setStep(0);
      setCollectionName(`Share (${moment().format("DD MMMM YYYY, HH:mm")})`);
      setSelectedContact(null);
      setIsForwardable(false);
    }
  }, [open]);

  const { t } = useTranslation();

  const handleClose = (e: object, reason: string) => {
    if (reason === "escapeKeyDown") {
      setOpen(false);
    }
  };

  const shareModels = () => {
    //should share model
    if (!user?._id || !selectedContact) return;
    createCollectionAsync({
      owner: user._id,
      name: collectionName,
      blocks: blocksIds,
    })
      .then((collection) => {
        createShareAsync({
          user: selectedContact.user,
          forwardable: false,
          blockCollection: collection.data._id,
        });
      })
      .then(() => {
        setOpen(false);
        toast(t("Blocks successfully shared"));
      });
  };

  useToasts([
    {
      severity: "error",
      message: t("Cannot create the collection for this share. Please try again later or contact the administrator."),
      condition: !!isErrorCreateCollection,
    },
    {
      severity: "error",
      message: t("Cannot share the newly created collection. Please try again later or contact the administrator."),
      condition: !!isErrorCreateShare,
    },
  ]);

  return (
    <ShareModalWrapper
      open={open}
      PaperProps={{
        style: {
          backgroundImage: "none",
          backgroundColor: "transparent",
          boxShadow: "none",
          width: "100%",
          padding: "20px",
        },
        className: "share-modal-paper",
      }}
    >
      <div className="button-dialog">
        <div className="close-button-container">
          <IconButton onClick={(e) => handleClose(e, "escapeKeyDown")}>
            <ClearIcon />
          </IconButton>
        </div>
      </div>

      {step === 0 && (
        <Slide direction="right" in={step === 0}>
          <div className="dialog">
            <h3>{t(`Sharing a group of models: Create a collection first`)}</h3>
            <p>{t("Collection name")}</p>
            <MRTextField
              fullWidth
              id="collection-name-input"
              name="collection-name-input"
              value={collectionName}
              onChange={(e) => setCollectionName(e.target.value)}
              error={!collectionName}
              helperText={!collectionName && t("Provide a name for this collection")}
            />
            <Stack justifyContent="center" alignItems="center" direction="row" marginTop="20px">
              <MRButtonPrimary
                sx={{ bgcolor: `${colors.green} !important` }}
                disabled={!collectionName}
                onClick={() => setStep(1)}
              >
                {t("Next")}
              </MRButtonPrimary>
            </Stack>
          </div>
        </Slide>
      )}
      {step === 1 && (
        <Slide direction="right" in={step === 1}>
          <div className="dialog">
            <h3>{t(`Sharing a group of models: Select the recipient user`)}</h3>
            <SelectContact selectedContact={selectedContact} setSelectedContact={setSelectedContact} />
            <Stack direction="row" justifyContent="start" alignItems="center">
              <MRCheckbox
                size="small"
                className="forwardable-checkbox"
                checked={isForwardable}
                onChange={(e) => setIsForwardable(e.target.checked)}
              />
              <p>{t("Allow re-share")}</p>
            </Stack>
            <Stack justifyContent="center" alignItems="center" direction="row" marginTop="20px" gap="5px">
              <MRButtonPrimary disabled={!collectionName} onClick={() => setStep(0)}>
                {t("Prev")}
              </MRButtonPrimary>
              <MRButtonPrimary
                sx={{ bgcolor: `${colors.green} !important` }}
                disabled={!selectedContact}
                loading={isLoadingCreateShare || isLoadingCrteateCollection}
                onClick={() => shareModels()}
              >
                {t("Share")}
              </MRButtonPrimary>
            </Stack>
          </div>
        </Slide>
      )}
    </ShareModalWrapper>
  );
};

export default BulkShareModal;
