import { MRButtonquaternary } from "components/material/Button";
import { MRCheckbox } from "components/material";
import { useTranslation } from "react-i18next";
import useCreateShare from "hooks/services/shares/useCreateShares";
import { Dispatch, FC, SetStateAction, useState } from "react";
import toast from "react-hot-toast";
import SelectContact from "components/Contact/SelectContact";
import { Contact } from "types/user";
import useToasts from "hooks/useToasts";
import { AxiosError } from "axios";
import useForwardBlockShare from "hooks/services/shares/useForwardBlockShare";
import useForwardCollectionShare from "hooks/services/shares/useForwardCollectionShare";

const ContactsDialog: FC<{
	blockId?: string;
	collectionId?: string;
	setOpen: Dispatch<SetStateAction<boolean>>;
	forward?: boolean;
}> = ({ blockId, collectionId, setOpen, forward }) => {
	if (!blockId && !collectionId) throw new Error("Please specify blockId or collectionId");

	if (blockId && collectionId) throw new Error("Please choose one between blockId or collectionId");

	const { t } = useTranslation();
	const [forwardable, setForwardable] = useState<boolean>(false);
	const [selectedContact, setSelectedContact] = useState<Contact | null>(null);

	const { mutateAsync: createShare, isLoading: isLoadingCreateShare, error: createShareError } = useCreateShare();

	const {
		mutateAsync: forwardBlockShare,
		isLoading: ForwardBlockShareLoading,
		error: forwardBlockShareError,
	} = useForwardBlockShare();

	const {
		mutateAsync: forwardCollectionShare,
		isLoading: ForwardCollectionShareLoading,
		error: forwardCollectionShareError,
	} = useForwardCollectionShare();

	const handleCreateShare = async () => {
		if (!selectedContact) return;

		if (forward) {
			if (blockId) {
				forwardBlockShare({ blockId, user: selectedContact.user }).then(() => {
					toast(t("Block share forwarded successfully!"));
					resetModal();
					setOpen(false);
				});
			} else if (collectionId) {
				forwardCollectionShare({ collectionId, user: selectedContact.user }).then(() => {
					toast(t("Collection share forwarded successfully!"));
					resetModal();
					setOpen(false);
				});
			}
		} else {
			if (blockId) {
				createShare({
					user: selectedContact.user,
					forwardable,
					blocks: [blockId],
				}).then(() => {
					toast(t("Block shared successfully!"));
					resetModal();
					setOpen(false);
				});
				return;
			}

			if (collectionId) {
				createShare({
					user: selectedContact.user,
					forwardable,
					blockCollection: collectionId,
				}).then(() => {
					toast(t("Collection shared successfully!"));
					resetModal();
					setOpen(false);
				});
				return;
			}
		}
	};

	const shareAlreadyExistsError =
		!!createShareError && (createShareError as AxiosError).response?.data.name === "ShareAlreadyExists";

	const sharingWithOwnerError =
		!!forwardBlockShareError && (forwardBlockShareError as AxiosError).response?.data.name === "CannotShareWithOwner";

	useToasts([
		{
			severity: "error",
			message: t("Something went wrong with this share. Please try again later."),
			condition: !!createShareError && !shareAlreadyExistsError && !forwardBlockShareError && !sharingWithOwnerError,
		},
		{
			severity: "error",
			message: t("This block is owned by this user."),
			condition: sharingWithOwnerError,
		},
		{
			severity: "error",
			message: t("This resource is already shared with this user."),
			condition: shareAlreadyExistsError,
		},
	]);

	const resetModal = () => {
		setForwardable(false);
		setSelectedContact(null);
	};

	return (
		<div className="dialog contacts-dialog">
			<div className="contacts-dialog-header">
				<h2>{t("Share with a contact")} </h2>
			</div>
			<div className="contacts-dialog-body">
				<SelectContact selectedContact={selectedContact} setSelectedContact={setSelectedContact} />

				<div className="forwardable-container">
					<MRCheckbox
						size="small"
						className="forwardable-checkbox"
						checked={forwardable}
						onChange={(e) => setForwardable(e.target.checked)}
					/>
					{t("Allow re-sharing")}
				</div>
				<MRButtonquaternary
					className={`send-share-button ${selectedContact ? "" : "hidden"}`}
					loading={isLoadingCreateShare}
					onClick={handleCreateShare}
				>
					{t("Send")}
				</MRButtonquaternary>
			</div>
		</div>
	);
};

export default ContactsDialog;
