import { MRCard, MRTextField } from "components/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResetPasswordPage } from "./styles";
import { InputLabel, Stack } from "@mui/material";
import { ReactComponent as Logo } from "assets/icons/logo-white.svg";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./model";
import { MRButtonPrimary } from "components/material/Button";
import { colors } from "styles/theme";
import useResetPassword from "hooks/services/password/useResetPassword";
import useToasts from "hooks/useToasts";
import { useNavigate, useSearchParams } from "react-router-dom";

const ResetPassword: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [code] = useState<string | null | undefined>(searchParams.get("code"));

  if (!code) throw new Error("Invalid or missing code");

  const navigate = useNavigate();

  const {
    mutate: resetPassword,
    isLoading: isLoadingResetPassword,
    error: resetPasswordError,
    isSuccess: isSuccessResetPassword,
  } = useResetPassword();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      resetPassword({ ...values, code });
    },
  });

  useEffect(() => {
    if (isSuccessResetPassword) navigate("/login");
  }, [isSuccessResetPassword]);

  useToasts([
    {
      severity: "success",
      message: t("The password was changed successfully"),
      condition: isSuccessResetPassword,
    },
    {
      severity: "error",
      message: t("There was an error. Please try again later."),
      condition: !!resetPasswordError,
    },
  ]);

  return (
    <ResetPasswordPage>
      <MRCard className="signup-card">
        <form onSubmit={formik.handleSubmit}>
          <Stack sx={{ marginBottom: "30px" }} alignItems="center">
            <Logo width="70px" height="70px" />
            <h3 style={{ marginBottom: "0px" }}>{t("Reset you password")}</h3>
            <span>{t("Enter your new password!")} </span>

            <Stack alignItems="start" marginTop="20px" marginBottom="20px">
              <InputLabel>{t("Password")}</InputLabel>
              <MRTextField
                fullWidth
                id="newPassword"
                name="newPassword"
                type="password"
                placeholder={t("Password")}
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
              />
            </Stack>

            <MRButtonPrimary
              type="submit"
              loading={isLoadingResetPassword}
              sx={{
                bgcolor: `${colors.green} !important`,
                color: `${colors.black} !important`,
              }}
            >
              {t("Reset")}
            </MRButtonPrimary>
          </Stack>
        </form>
      </MRCard>
    </ResetPasswordPage>
  );
};

export default ResetPassword;
