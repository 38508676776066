import React, { FC } from "react";
import { BlocksProps } from "./types";
import BlockGridElem from "components/Block/BlockGridElem";
import BlockListElem from "components/Block/BlockListElem";
import { Grid } from "@mui/material";
import { Block, BlockStatus } from "types/block";
import useAuth from "hooks/useAuth";

const Blocks: FC<BlocksProps> = ({
  blocks,
  collection,
  toggleSelection,
  selected,
}) => {
  const { user } = useAuth();

  function getVariantFromStatus(block: Block) {
    if (
      block.status === BlockStatus.TRASHED ||
      block.status === BlockStatus.REMOVED
    )
      return "trashed";
    if (block.owner !== user?._id) return "shared";
    return "owned";
  }

  return (
    <Grid container spacing={2}>
      {blocks.map((block, idx) => (
        <React.Fragment key={block._id}>
          <Grid
            item
            sm={4}
            md={3}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <BlockGridElem
              block={block}
              variant={getVariantFromStatus(block)}
              collection={collection}
              toggleSelection={toggleSelection}
              selected={selected?.includes(block._id)}
            />
          </Grid>

          <Grid item xs={12} sx={{ display: { xs: "block", sm: "none" } }}>
            <BlockListElem
              block={block}
              variant={getVariantFromStatus(block)}
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default Blocks;
