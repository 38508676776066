import { FC, useState } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  TableSortLabel,
} from "@mui/material";
import { MRCheckbox, MRTable } from "components/material";
import { useTranslation } from "react-i18next";
import { BlockUserTableProps } from "./types";
import { TableWrapper } from "../Tables/styles";
import { BlockUserTableWrapper } from "./styles";
import { share, object3d2 } from "assets/icons";
import { generatePath, useNavigate, Link } from "react-router-dom";
import BlockActions from "components/Block/BlockActions";
import ShareModal from "components/Share/ShareModal";
import React from "react";
import defaultThumbnail from "assets/images/default-thumb.png";
import moment from "moment";
import { TableColumn } from "../Tables/types";
import ThumbDisplayer from "components/ThumbDisplayer";
import { windowReplace } from "utils/helpers";

const BlockTable: FC<BlockUserTableProps> = ({
  blocks,
  selected,
  toggleSelection,
  toggleAll,
  variant,
  order,
  orderBy,
  sortHandler,
}) => {
  const { t } = useTranslation();

  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const columns: TableColumn[] = [
    {
      name: "title",
      label: t("Name"),
      sortable: true,
    },
    {
      name: "dateOfAcquisition",
      label: t("Date of acquisition"),
      sortable: true,
    },
    ...(variant === "owned"
      ? [
          {
            name: "shares",
            label: t("Shares"),
            sortable: true,
          },
        ]
      : [
          {
            name: "owner",
            label: t("Owner"),
            sortable: true,
          },
        ]),
    {
      name: "dimension",
      label: t("Dimension"),
      sortable: false,
    },
    {
      name: "material",
      label: t("Material"),
      sortable: true,
    },
    {
      name: "share",
      label: t("Share"),
      sortable: false,
    },
  ];

  return (
    <TableWrapper>
      <BlockUserTableWrapper variant={variant}>
        <MRTable>
          <TableHead>
            <TableRow>
              {variant !== "shared" && (
                <TableCell align="center">
                  <MRCheckbox
                    checked={
                      selected.length === blocks.length && selected.length !== 0
                    }
                    onChange={() =>
                      toggleAll(blocks.map((block) => block._id) || [])
                    }
                  />
                </TableCell>
              )}
              <TableCell align="left">{}</TableCell>
              {columns.map((column) =>
                column.sortable ? (
                  <TableCell
                    key={column.name}
                    sortDirection={orderBy === column.name ? order : false}
                    align="center"
                  >
                    <TableSortLabel
                      active={orderBy === column.name}
                      direction={orderBy === column.name ? order : "asc"}
                      onClick={() => sortHandler(column.name)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell key={column.name} align="center">
                    {column.label}
                  </TableCell>
                )
              )}
              <TableCell align="center">{}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blocks.map((block) => (
              <React.Fragment key={block._id}>
                <ShareModal
                  open={openShareModal}
                  setOpen={setOpenShareModal}
                  blockId={block._id}
                />

                <TableRow key={block._id}>
                  {variant !== "shared" && (
                    <TableCell align="center">
                      <MRCheckbox
                        checked={selected.includes(block._id)}
                        onChange={() => toggleSelection(block._id)}
                      />
                    </TableCell>
                  )}
                  <TableCell align="left" width={"100px"}>
                    <Link
                      to={
                        variant === "owned"
                          ? `/models/edit/${block._id}`
                          : `/models/${block._id}`
                      }
                    >
                      <ThumbDisplayer
                        imgProps={{
                          className: "thumbnail-img",
                          src: block.model.thumbnail || defaultThumbnail,
                          style: { cursor: "pointer" },
                          alt: "Block Thumbnail",
                        }}
                      />
                    </Link>
                  </TableCell>
                  <TableCell align="center">{block.title}</TableCell>
                  <TableCell align="center">
                    {block.dateOfAcquisition &&
                      moment(block.dateOfAcquisition).format(
                        "DD MMM YYYY - HH:mm"
                      )}
                  </TableCell>
                  <TableCell align="center">
                    {variant === "owned" ? block.sharesCount : block.ownerName}
                  </TableCell>
                  <TableCell align="center">{`H:${block.height} x L:${block.length} x D:${block.depth}`}</TableCell>
                  <TableCell align="center">{block.material}</TableCell>
                  <TableCell align="center">
                    <Stack
                      justifyContent={"center"}
                      direction="row"
                      alignItems="center"
                      gap="10px"
                      className="share-model-cell"
                      onClick={() => {
                        if (variant === "owned") {
                          setOpenShareModal(true);
                          return;
                        }
                        navigate(
                          generatePath("/viewer/block/:id", { id: block._id })
                        );
                      }}
                    >
                      <img src={variant === "owned" ? share : object3d2} />
                      <b className="model-quick-action">
                        {variant === "owned"
                          ? t("Share Model")
                          : t("View in 3D")}
                      </b>
                    </Stack>
                  </TableCell>

                  <TableCell align="center" className="table-icon-container">
                    {/*TODO Create user actions*/}
                    <BlockActions variant={variant} block={block} />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </MRTable>
      </BlockUserTableWrapper>
    </TableWrapper>
  );
};

export default BlockTable;
