import { FC } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
} from "@mui/material";
import { MRCheckbox, MRTable } from "components/material";
import moment from "moment";
import BlockRowActions from "./BlockRowActions";
import { useTranslation } from "react-i18next";
import BlockStatusCard from "./BlockStatusCard";
import { BlockTableProps } from "./types";
import { TableWrapper } from "../Tables/styles";
import { generatePath, Link } from "react-router-dom";
import PATH from "routing/path";
import { TableColumn } from "../Tables/types";

const BlockTable: FC<BlockTableProps> = ({
  blocks,
  selected,
  toggleSelection,
  toggleAll,
  order,
  orderBy,
  sortHandler,
}) => {
  const { t } = useTranslation();

  const columns: TableColumn[] = [
    {
      name: "title",
      label: t("Title"),
      sortable: true,
    },
    {
      name: "_id",
      label: t("Date"),
      sortable: true,
    },
    {
      name: "owner",
      label: t("Owner"),
      sortable: false,
    },
    {
      name: "username",
      label: t("Owner email"),
      sortable: false,
    },
    {
      name: "placeOfAcquisition",
      label: t("Place of acquisition"),
      sortable: true,
    },
    {
      name: "dimension",
      label: t("Dimension"),
    },
    {
      name: "numberOfShares",
      label: t("Num. of Shares"),
    },
    {
      name: "status",
      label: t("Status"),
      sortable: true,
    },
  ];

  return (
    <TableWrapper>
      <MRTable>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <MRCheckbox
                checked={
                  selected.length === blocks.length && selected.length !== 0
                }
                onChange={() =>
                  toggleAll(blocks.map((block) => block._id) || [])
                }
              />
            </TableCell>
            {columns.map((column) =>
              column.sortable ? (
                <TableCell
                  key={column.name}
                  sortDirection={orderBy === column.name ? order : false}
                  align="center"
                >
                  <TableSortLabel
                    active={orderBy === column.name}
                    direction={orderBy === column.name ? order : "asc"}
                    onClick={() => sortHandler(column.name)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell key={column.name} align="center">
                  {column.label}
                </TableCell>
              )
            )}
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {blocks.map((block) => (
            <TableRow key={block._id}>
              <TableCell align="center">
                <MRCheckbox
                  checked={selected.includes(block._id)}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    toggleSelection(block._id);
                  }}
                />
              </TableCell>
              <TableCell align="center">
                <Link
                  style={{ cursor: "pointer" }}
                  to={`${PATH.PROCESSED_MODELS}/${block._id}`}
                >
                  {block.title}
                </Link>
              </TableCell>
              <TableCell align="center">
                {moment(block.createdAt).format("D MMM YYYY")}
              </TableCell>
              <TableCell align="center">
                <Link
                  style={{ cursor: "pointer" }}
                  to={generatePath("/users/:id/profile", {
                    id: block.owner,
                  })}
                >
                  {block.ownerName}
                </Link>
              </TableCell>
              <TableCell align="center">
                <Link
                  style={{ cursor: "pointer" }}
                  to={generatePath("/users/:id/profile", {
                    id: block.owner,
                  })}
                >
                  {block.ownerUsername}
                </Link>
              </TableCell>
              <TableCell align="center">{block.placeOfAcquisition}</TableCell>
              <TableCell align="center">
                {block.length + "x" + block.height + "x" + block.depth}
              </TableCell>
              <TableCell align="center">{block.sharesCount}</TableCell>
              <TableCell align="center">
                {<BlockStatusCard status={block.status} />}
              </TableCell>
              <TableCell align="center" className="table-icon-container">
                <BlockRowActions id={block._id} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MRTable>
    </TableWrapper>
  );
};

export default BlockTable;
