import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={27}
    fill="none"
    focusable="false"
    {...props}
  >
    <path
      fill="#4BBECF"
      d="M10.81.34a2.842 2.842 0 0 1 2.704 0l9.459 4.989c.428.227.76.526.997.898.236.372.354.785.354 1.24v5.266a8.387 8.387 0 0 0-1.284-.697c-.45-.197-.923-.367-1.418-.512V8.798l-4.865 2.572c-1.262.289-2.405.78-3.43 1.471a9.014 9.014 0 0 0-2.516 2.525v-2.262L2.703 8.798v8.489l6.824 3.624c.09.661.259 1.296.507 1.905.247.61.563 1.183.946 1.72a.4.4 0 0 1-.085-.047.398.398 0 0 0-.084-.046l-9.46-4.988a2.59 2.59 0 0 1-.996-.898A2.261 2.261 0 0 1 0 17.317v-9.85c0-.455.118-.868.355-1.24a2.59 2.59 0 0 1 .996-.899l9.46-4.987Zm1.352 2.138L4.155 6.723l8.007 4.244 8.007-4.244-8.007-4.245Z"
    />
    <path
      fill="#4BBECF"
      d="M18.243 13.631c1.87 0 3.463.604 4.78 1.812C24.342 16.652 25 18.113 25 19.827c0 1.714-.659 3.175-1.976 4.384-1.318 1.208-2.911 1.812-4.78 1.812-1.87 0-3.464-.604-4.781-1.812-1.318-1.209-1.976-2.67-1.976-4.384 0-1.714.658-3.175 1.976-4.384 1.317-1.208 2.91-1.812 4.78-1.812Zm0 2.478a.678.678 0 0 0-.473.186.572.572 0 0 0-.202.434c0 .165.067.31.202.434a.677.677 0 0 0 .473.186c.18 0 .338-.062.473-.186a.572.572 0 0 0 .203-.434.572.572 0 0 0-.203-.434.678.678 0 0 0-.473-.186Zm.676 2.479h-1.351v4.956h1.35v-4.956Z"
    />
  </svg>
)
export default SvgComponent