import styled from "styled-components";
import { colors } from "styles/theme";

export const LinkStatusCardWrapper = styled.div`
  .active-card {
    color: ${colors.green};
  }
  .inactive-card {
    color: ${colors.red};
  }

  .card {
    background: ${colors.lighterBlack};
    padding: 5px 10px;
    border-radius: 15px;
  }
`;
