import Loader from "components/Loader";
import { DetailedHTMLProps, FC, ImgHTMLAttributes, useState } from "react";

const ThumbDisplayer: FC<{
  imgProps: Partial<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>>;
}> = (displayerProps) => {
  const { imgProps } = displayerProps;
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {loaded ? null : <Loader />}
      <img
        {...imgProps}
        style={!loaded ? { display: "none" } : imgProps.style}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};

export default ThumbDisplayer;
