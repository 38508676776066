import styled from "styled-components";

export const RowActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;

export const ActionMenuWrapper = styled.div`
  .button-icon {
    margin-right: 5px;
  }

  .menu-btn {
    width: 90px;
    justify-content: start;
  }
`;
