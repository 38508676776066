import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={37}
    fill="none"
    {...props}
  >
    <path
      fill="#00A4B7"
      d="M45.455 0H4.545A4.559 4.559 0 0 0 0 4.545v27.273c0 2.5 2.045 4.546 4.545 4.546h40.91c2.5 0 4.545-2.046 4.545-4.546V4.545C50 2.045 47.955 0 45.455 0Zm0 31.818H4.545V4.545h40.91v27.273ZM30.682 15.91l-7.955 10.25-5.681-6.84L9.09 29.545h31.818L30.682 15.909Z"
    />
    <path
      fill="#00A4B7"
      d="M38.462 10h-1.539v2.308h1.538V10ZM41.676 8.3 40.588 9.39l1.632 1.631 1.088-1.087L41.676 8.3ZM33.712 8.301l-1.631 1.632 1.087 1.088L34.8 9.389l-1.088-1.088ZM33.077 5.385h-2.308v1.538h2.308V5.385ZM44.615 5.385h-2.307v1.538h2.307V5.385ZM37.692 9.23a3.848 3.848 0 0 0 3.846-3.845H40a2.315 2.315 0 0 1-2.308 2.307 2.315 2.315 0 0 1-2.307-2.307h-1.539a3.847 3.847 0 0 0 3.846 3.846Z"
    />
  </svg>
)
export default SvgComponent