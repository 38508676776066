import { TabContext } from "@mui/lab";
import { Stack, Tab } from "@mui/material";
import TabPanel from "components/material/TabPanel";
import Page from "components/Layout/Page";
import { MRTabList } from "components/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { RequestsWrapper } from "./styles";
import RequestsSent from "./RequestsSent";
import RequestsReceived from "./RequestReceived";

const Requests: FC = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState<string>("req_received");

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  return (
    <Page>
      <RequestsWrapper>
        <h1>{t("Requests")}</h1>
        <TabContext value={tab}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <TabContext value={tab} >
            <MRTabList /*value={tab}*/ onChange={handleTabChange} >
              <Tab label={t("Contact requests")} value="req_received" />
              <Tab label={t("Requests Sent")} value="req_sent" /> 
            </MRTabList>
            </TabContext>
          </Stack>

          <TabPanel
            active={tab}
            value="req_sent"
            sx={{ padding: 0, paddingTop: 2 }}
          >
            <RequestsSent />
          </TabPanel>
          <TabPanel
            active={tab}
            value="req_received"
            sx={{ padding: 0, paddingTop: 2 }}
          >
            <RequestsReceived />
          </TabPanel>
        </TabContext>
      </RequestsWrapper>
    </Page>
  );
};

export default Requests;
