import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "components/material/Dialog";
import { SelectUserModalProps } from "./types";
import { SelectUserModalWrapper } from "./styles";
import { colors } from "styles/theme";
import { IconButton, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { MRButtonquaternary } from "components/material/Button";
import { User } from "types/user";
import SelectUser from "../SelectUser";

const SelectUserModal: FC<SelectUserModalProps> = ({
  open,
  setOpen,
  selectedUser,
  setSelectedUser,
  handleSelectedUser,
}) => {
  const { t } = useTranslation();

  const handleClose = (e: object, reason: string) => {
    if (reason === "escapeKeyDown") {
      setOpen(false);
      setSelectedUser(null);
    }
  };

  const handleSelectUser = () => {
    setOpen(false);
    handleSelectedUser(selectedUser ?? ({} as User));
  };

  return (
    <SelectUserModalWrapper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="dialog"
        PaperProps={{
          style: {
            background: colors.lightBlack,
            backgroundImage: "none",
            width: "100%",
            padding: "20px",
            border: `1px solid ${colors.white}`,
            borderRadius: "20px",
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          sx={{ marginBottom: "10px" }}
        >
          <Stack className="modal-title">
            <h2>{t("Select Owner")}</h2>
            <span>{t("Choose the user you want assign the block to")}</span>
          </Stack>

          <div className="close-button-container">
            <IconButton onClick={(e) => handleClose(e, "escapeKeyDown")}>
              <ClearIcon sx={{ color: colors.green }} />
            </IconButton>
          </div>
        </Stack>

        <SelectUser
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />

        {selectedUser && (
          <MRButtonquaternary onClick={handleSelectUser}>
            {t("Select")}
          </MRButtonquaternary>
        )}
      </Dialog>
    </SelectUserModalWrapper>
  );
};

export default SelectUserModal;
