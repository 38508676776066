export const removeEmptyEntries = (obj: Object) => {
  const nonEmptyEntries = Object.entries(obj).filter(([k, v]) => {
    if (!!v && v.length === 0) {
      return;
    }
    return !!v;
  });

  return Object.fromEntries(nonEmptyEntries);
};
