import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import { Request } from "types/request";
import { Response } from "types/response";
import { User } from "types/user";
import client from "utils/client";

export default function useSetPropic() {
  const queryClient = useQueryClient();

  const setPropic = (data: FormData) => {
    const request: Request = {
      url: ENDPOINTS.users.propic,
      config: {
        method: "PATCH",
        data,
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<
    Response<User>,
    Error,
    FormData
  >(setPropic, {
    onSettled: () => queryClient.invalidateQueries([]),
  });

  return { data, mutateAsync, ...rest };
}
