import styled from "styled-components";
import DownloadFileIcon from "assets/icons/download-file.svg";
import { colors } from "styles/theme";
import { color } from "@mui/system";

export const ModelToProcessWrapper = styled.div`
  .no-margin-text {
    margin: 0;
  }

  .secondary-title {
    color: ${colors.green};
  }

  .acquisition-info-card {
    padding: 25px;
  }

  .mr-card-green {
    color: black !important;
  }

  .mr-card-green h2 {
    font-family: "DM Sans";
    line-height: 1.4;
  }

  .mr-card-green.MuiPaper-root {
    background: ${colors.green};
  }

  .mr-card-green .MuiButton-root {
    background: transparent !important;
    color: ${colors.black} !important;
    border: 1px solid ${colors.black};
  }

  .acquisition-status-card-container {
    font-size: 24px !important;
    white-space: nowrap;
  }

  .deleted-warning-container {
    background-color: ${colors.red};
    border-radius: 10px;
    padding: 20px 20px;
    margin-bottom: 10px;
    color: ${colors.black};
  }
`;

export const DownloadPhotosWrapper = styled.div`
  height: 215px;

  .download-button {
    padding: "5px 15px";
  }
`;

export const UploadModelWrapper = styled.div<{ percentage?: number }>`
  height: 215px;

  .cover-div {
    position: absolute;
    top: 0;
    background-color: ${colors.green};
    width: ${(props) => props.percentage + "%"};
    height: 100%;
    transition: width 1s;
    border-radius: 10px;
    z-index: -1;
  }

  .percentage {
    color: ${(props) =>
      props.percentage && props.percentage > 50 ? colors.black : colors.white};
  }

  .dropzone-container {
    height: 100%;
    border-radius: 10px;
    border: 5px dashed ${colors.blue};
  }
`;

export const UploadThumbnailWrapper = styled.div`
  height: 215px;

  img.thumbnail {
    height: 150px;
    width: 150px;
    object-fit: contain;
  }

  .change-thumbnail {
    text-align: "center";
    padding: 0px 6px;
  }

  .upload-thumbnail {
    padding: 0px 6px;
    font-size: 0.8em;
  }
`;
