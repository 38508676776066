import Page from "components/Layout/Page";
import styled from "styled-components";
import { colors } from "styles/theme";
import SignupBackground from "assets/images/bg-2.jpg";

export const SignupPage = styled(Page)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  background: url("${SignupBackground}");
  background-size: cover;

  .signup-card {
    background: ${colors.black};
    padding: 50px;
  }

  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 450px) {
    .signup-card {
      background: ${colors.black};
      padding: 0px;
    }
  }
`;

export const SignupFormWrapper = styled(Page)`
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 2rem;
  margin: auto;

  .MuiOutlinedInput-root {
    border: 1px solid ${colors.green};
    min-width: 300px;
  }

  .next-button {
    margin-top: 30px;
  }
`;
