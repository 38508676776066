import Page from "components/Layout/Page";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContactsWrapper } from "../../styles";
import { Badge, Stack } from "@mui/material";
import useAuth from "hooks/useAuth";
import { colors } from "styles/theme";
import { plus2 } from "assets/icons";
import { MRButtonPrimary, MRButtonSecondary } from "components/material/Button";
import ContactTable from "components/TablesComponents/Contact/ContactTable";
import { PAGE_SIZE } from "config/pagination";
import { MRPagination } from "components/material";
import useContacts from "hooks/services/users/useContacts";
import AddContactModal from "./ContactModal";
import { Contact } from "types/user";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import { useDebounce } from "use-debounce";
import MRTextField from "components/material/TextField";
import { useNavigate } from "react-router-dom";
import PageHeader from "components/Layout/PageHeader";
import useFriendshpiRequestReceived from "hooks/services/friendship-request/useFriendshipRequestReceived";

const Contacts: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [contactToEdit, setContactToEdit] = useState<Omit<
    Contact,
    "user"
  > | null>(null);

  const [page, setPage] = useState(1);
  const [text, setText] = useState<string>("");
  const [debouncedText] = useDebounce(text, 500);

  const {
    data: contacts,
    isLoading: isLoadingContacts,
    error: contactsError,
  } = useContacts(page, PAGE_SIZE, { text: debouncedText });

  const { data: requests } = useFriendshpiRequestReceived();

  if (contactsError) throw new Error("Cannot get contacts");

  return (
    <Page>
      <AddContactModal
        open={open}
        setOpen={setOpen}
        contact={contactToEdit}
        setContact={setContactToEdit}
      />

      <ContactsWrapper>
        <PageHeader>
          <h1>{t("Contacts")}</h1>
        </PageHeader>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap={"wrap"}
          rowGap="5px"
          width="100%"
        >
          <Stack
            direction="row"
            justifyContent="start"
            alignItems="center"
            flexWrap={"wrap"}
            columnGap="5px"
          >
            <MRButtonPrimary
              sx={{
                bgcolor: `${colors.green} !important`,
                color: `${colors.black} !important`,
              }}
              onClick={() => setOpen(true)}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
              >
                <img src={plus2} />
                <span>{t("Send contact request")}</span>
              </Stack>
            </MRButtonPrimary>

            <Badge
              {...(requests?.data.length && {
                badgeContent: requests?.data.length,
              })}
              color="primary"
            >
              <MRButtonSecondary
                sx={{ border: `solid 0.5px ${colors.green}!important` }}
                onClick={() => navigate("/contacts/requests")}
              >
                <span>{t("Contact requests")}</span>
              </MRButtonSecondary>
            </Badge>
          </Stack>

          <MRTextField
            placeholder={t("Search by alias or email..")}
            className="search-contact"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </Stack>
        <ContactTable
          contacts={contacts?.data || []}
          rowActionProps={{
            editAction: (contact: Contact) => {
              const { user, ...rest } = contact;
              setContactToEdit(rest);
              setOpen(true);
            },
          }}
        />
      </ContactsWrapper>
      <Stack direction="row" justifyContent="center">
        {contacts?.data && contacts?.data.length ? (
          <MRPagination
            count={
              contacts
                ? Math.ceil(contacts?.pagination[0]?.total / PAGE_SIZE) || 1
                : 1
            }
            page={page}
            onChange={(e, page) => setPage(page)}
          />
        ) : (
          <NoDataRow>{t("No Data")}</NoDataRow>
        )}
      </Stack>
    </Page>
  );
};

export default Contacts;
