import { FC } from "react";
import { Stack } from "@mui/material";
import { SetCollectionNameProps } from "./types";
import { useTranslation } from "react-i18next";
import { MRButtonquaternary } from "components/material/Button";
import TextField from "components/material/TextField";

const SetCollectionName: FC<SetCollectionNameProps> = ({
  name,
  setName,
  setStep,
}) => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" gap={2}>
      <TextField
        placeholder={t("Type the new catalogue name")}
        value={name}
        onChange={(e: any) => setName(e.target.value)}
      />
      <Stack direction="row" justifyContent="flex-end">
        <MRButtonquaternary
          disabled={name !== "" ? false : true}
          classes={{
            root: "next-button-root",
            disabled: "next-button-disabled",
          }}
          onClick={() => setStep(1)}
        >
          {t("Next")}
        </MRButtonquaternary>
      </Stack>
    </Stack>
  );
};

export default SetCollectionName;
