import styled from "@emotion/styled/macro";
import { Card } from "@mui/material";
import { colors } from "styles/theme";

const MRCard = styled(Card)`
  background: ${colors.lightBlack};
  border-radius: 10px;
  height: 100%;

  p {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default MRCard;
