import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={37}
    fill="none"
    {...props}
  >
    <path
      fill="#00A4B7"
      d="M26.64 26.235h-2.51V30h2.51v-3.765ZM16.6 16.194h-3.766v2.51H16.6v-2.51ZM37.935 16.194H34.17v2.51h3.765v-2.51ZM32.038 22.964l-1.766 1.767 2.662 2.662 1.766-1.766-2.662-2.663ZM18.74 22.956l-2.663 2.662 1.766 1.766 2.663-2.662-1.766-1.766ZM29.15 12.454v-6.3h-7.53v6.3c-1.52 1.155-2.51 2.95-2.51 4.995a6.278 6.278 0 0 0 6.274 6.276 6.278 6.278 0 0 0 6.276-6.276c0-2.045-.992-3.84-2.51-4.995Zm-5.02-3.79h2.51V11.3a6.863 6.863 0 0 0-1.256-.126c-.426 0-.853.05-1.255.126V8.664Zm1.254 12.55a3.776 3.776 0 0 1-3.765-3.765 3.776 3.776 0 0 1 3.765-3.765 3.776 3.776 0 0 1 3.766 3.765 3.776 3.776 0 0 1-3.766 3.765Z"
    />
    <path
      fill="#00A4B7"
      d="M45.455 0H4.545A4.559 4.559 0 0 0 0 4.545v27.273c0 2.5 2.045 4.546 4.545 4.546h40.91c2.5 0 4.545-2.046 4.545-4.546V4.545C50 2.045 47.955 0 45.455 0Zm0 31.818H4.545V4.545h40.91v27.273Z"
    />
  </svg>
)
export default SvgComponent