import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { MRLayoutWrapper } from "./styles";
import { matchPath, useLocation } from "react-router-dom";
import PersistentDrawer from "./PersistentDrawer";
import MiniDrawer from "./MiniDrawer";
import MobileDrawer from "./MobileDrawer";
import MobileNavigation from "./MobileNavigation";
import { useEffect } from "react";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const MRLayout: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [open, setOpen] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [mini, setMini] = React.useState(false);

  const handleMiniOpen = () => {
    setMini(true);
  };

  const handleMiniClose = () => {
    setMini(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const closeMenu = () => {
      setMini(false);
    };
    window.addEventListener("resize", closeMenu);
    return () => window.removeEventListener("resize", closeMenu);
  }, []);

  const location = useLocation();

  const disabledRoutes = [
    "/login",
    "/signup",
    "/free-signup",
    "/free-signup-verify",
    "/password/*",
    "link/*",
    "/contact-us",
    "viewer/block/*",
  ];

  if (disabledRoutes.some((route) => matchPath(route, location.pathname))) {
    return (
      <MRLayoutWrapper>
        <CssBaseline />
        <main className="layout-basic">{children}</main>
      </MRLayoutWrapper>
    );
  }

  return (
    <MRLayoutWrapper>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box component="nav" sx={{ flexShrink: { sm: 0 } }}>
          <MobileDrawer
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
          <MiniDrawer
            drawerWidth={drawerWidth}
            mini={mini}
            handleMiniOpen={handleMiniOpen}
            handleMiniClose={handleMiniClose}
          />
          <PersistentDrawer drawerWidth={drawerWidth} />
        </Box>
        <Main
          open={open}
          className="mains"
          sx={{
            padding: { xs: "0px", sm: "16px" },
            flexGrow: 1,
            p: 3,
            width: {
              xs: "100%",
              sm: `calc(100% - 65px)`,
              lg: `calc(100% - ${drawerWidth}px )`,
            },
            maxWidth: "1260px",
            margin: "0 auto",
            ...(mini && { marginLeft: "65px", opacity: "0.7" }),
          }}
        >
          {children}
        </Main>
        <MobileNavigation
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Box>
    </MRLayoutWrapper>
  );
};

export default MRLayout;
