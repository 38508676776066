import { Stack } from "@mui/material";
import Page from "components/Layout/Page";
import { MRButtonPrimary, MRButtonSecondary } from "components/material/Button";
import useCollection from "hooks/services/collections/useCollection";
import useAuth from "hooks/useAuth";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SellerCatalogueWrapper } from "./styles";
import { colors } from "styles/theme";
import { share3, list, trash2, edit } from "assets/icons";
import Blocks from "./Blocks";
import { Route, Routes, useNavigate } from "react-router-dom";
import Shares from "./Shares";
import { useConfirmModal } from "context/confirmModal";
import useDeleteCollection from "hooks/services/collections/useDeleteCollection";
import useToasts from "hooks/useToasts";
import ShareModal from "components/Share/ShareModal";
import PageHeader from "components/Layout/PageHeader";
import ROLE from "types/role";
import Loader from "components/Loader";
import useCanForwardCollection from "hooks/services/shares/useCanForwardColleciton";
import EditCollectionModal from "components/Collection/EditCollectionModal";
import useMultiSelect from "hooks/useMultiSelect";
import toast from "react-hot-toast";
import usePullBlockFromCollection from "hooks/services/collections/usePullBlockFromCollection";

const SellerCatalogue: FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("Missing param :id");

  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [openAddBlocksModal, setOpenAddBlocksModal] = useState(false);

  const {
    data: collection,
    isLoading: isLoadingCollection,
    error: collectionError,
  } = useCollection(id);

  const {
    data: canForward,
    isLoading: canForwardLoading,
    error: canForwardError,
  } = useCanForwardCollection(id);

  const {
    mutateAsync: deleteCollectionAsync,
    isLoading: deleteCollectionLoading,
    error: deleteCollectionError,
    isSuccess: isSuccessDeleteCollection,
  } = useDeleteCollection();

  const { openModal } = useConfirmModal();
  const { selected, toggleSelection, resetSelection } = useMultiSelect();

  const { mutate: pullBlock } = usePullBlockFromCollection();

  const handleRemoveBulk = () => {
    if (collection)
      Promise.all(
        selected.map((block) =>
          pullBlock({ blockId: block, collectionId: collection?.data._id })
        )
      )
        .then((results) => {
          toast(
            t(`Blocks removed successfully from collection`) +
              `${collection?.data.name}`
          );
          resetSelection();
        })
        .catch((err) =>
          toast(
            t(`There was an error removing blocks from collection`) +
              `${collection?.data.name}`
          )
        );
  };

  const isOwner: boolean = user?._id === collection?.data.owner;

  useToasts([
    {
      severity: "error",
      message: t(
        "There was an error while deleting the colletion, please try again later."
      ),
      condition: !!deleteCollectionError,
    },
    {
      severity: "success",
      message: t("Collection deleted successfully"),
      condition: isSuccessDeleteCollection,
    },
  ]);

  if (collectionError) throw new Error("Cannot get collection");

  if (isLoadingCollection) return <Loader />;

  return (
    <Page>
      <ShareModal
        open={shareModalOpen}
        setOpen={setShareModalOpen}
        collectionId={id}
        forward={collection?.data.owner !== user?._id && canForward?.data}
      />
      <SellerCatalogueWrapper>
        <PageHeader>
          <h1>{collection?.data.name}</h1>
        </PageHeader>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          marginBottom="20px"
        >
          <Stack direction="row" gap={2}>
            {(isOwner || canForward) && (
              <MRButtonPrimary
                sx={{
                  bgcolor: `${colors.green} !important`,
                  color: `${colors.black} !important`,
                }}
                onClick={() => setShareModalOpen(true)}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={1}
                >
                  <img src={share3} />
                  <span>{t("Share")}</span>
                </Stack>
              </MRButtonPrimary>
            )}

            {(isOwner || canForward) && (
              <MRButtonSecondary
                sx={{ border: `solid 0.5px ${colors.green}!important` }}
                onClick={() => navigate("shares")}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={1}
                >
                  <img src={list} />
                  <span>{t("Shares")}</span>
                </Stack>
              </MRButtonSecondary>
            )}

            {isOwner && collection && (
              <>
                <EditCollectionModal
                  openModal={openAddBlocksModal}
                  setOpenModal={setOpenAddBlocksModal}
                  collection={collection?.data}
                />

                <MRButtonSecondary
                  sx={{ border: `solid 0.5px ${colors.green}!important` }}
                  onClick={() => setOpenAddBlocksModal(true)}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                  >
                    <img src={edit} />
                    <span>{t("Edit catalogue")}</span>
                  </Stack>
                </MRButtonSecondary>
              </>
            )}

            {isOwner && (
              <MRButtonSecondary
                loading={deleteCollectionLoading}
                sx={{ border: `solid 0.5px ${colors.green}!important` }}
                onClick={() => {
                  openModal({
                    title: t(
                      "Are you sure you want to delete this collection?"
                    ),
                    text: t("The operation is not reversible"),
                    confirmFunction: () => {
                      deleteCollectionAsync(id).then(() =>
                        navigate("/catalogues")
                      );
                    },
                  });
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={1}
                >
                  <img src={trash2} />
                  <span>{t("Remove")}</span>
                </Stack>
              </MRButtonSecondary>
            )}
          </Stack>
          <Stack>
            {selected.length > 0 && (
              <MRButtonSecondary
                sx={{
                  borderColor: `${colors.red} !important`,
                  color: `${colors.white} !important`,
                  animation: "fadeIn 0.2s",
                  display: { xs: "none", sm: "block" },
                }}
                onClick={handleRemoveBulk}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={1}
                >
                  <img src={trash2} />
                  <span>{t("Remove from catalogue")}</span>
                </Stack>
              </MRButtonSecondary>
            )}
          </Stack>
        </Stack>

        <Routes>
          <Route
            index
            element={
              <Blocks
                blocks={
                  collection?.data.blocks.map((block) => ({
                    ...block,
                    ownerName: collection?.data.ownerName || "",
                  })) || []
                }
                collection={collection?.data._id}
                toggleSelection={toggleSelection}
                selected={selected}
              />
            }
          />
          <Route path="shares" element={<Shares />} />
        </Routes>
      </SellerCatalogueWrapper>
    </Page>
  );
};

export default SellerCatalogue;
