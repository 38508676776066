import { TextField } from "@mui/material";
import styled from "styled-components";
import { colors } from "styles/theme";

export const UserWrapper = styled.div`
  button.back-button {
    padding: 10px;
    border-radius: 10px;
    background-color: ${colors.lightBlack};
    color: ${colors.white};
    text-transform: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    gap: 10px;
  }

  .user-top-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-top-card-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    gap: 20px;

    h2 {
      margin: 0px;
    }
  }

  .check-box-card {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-container {
    background: #020402;
    border-radius: 5px;
  }

  .icon-container {
    display: flex;
    align-items: center;
  }

  .user-top-card-input {
    min-width: 50px;
    max-width: 120px;
  }

  fieldset {
    border: none;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .user-info-form {
    width: 100%;
  }

  .plan-radio-group {
    display: flex;
    flex-direction: row;
  }

  .bandwidth-settings {
    width: 100%;
    justify-content: space-between;
    height: 20px;
    font-size: 14px;

    input {
      border-radius: 0px !important;
      padding: 5px;
    }

    input[type="number"] {
      max-width: 18px;
    }
    div {
      height: 25px;
    }
  }

  .table-header {
    padding-left: 30px;
    margin-top: 50px;
    color: ${colors.green};

    h2 {
      display: inline;
    }
  }

  .table-icon-container img {
    cursor: pointer;
    width: 20px;
  }

  .share-chart-card-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  Button {
    padding: 10px;
    border-radius: 10px;
    color: ${colors.white};
    text-transform: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  .block-user {
    background-color: ${colors.blue};
  }

  .delete-user {
    border: solid 0.5px ${colors.blue};
  }
`;

export const RowActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ActionMenuWrapper = styled.div`
  .button-icon {
    margin-right: 5px;
  }

  .menu-btn {
    width: 90px;
    justify-content: start;
  }
`;

export const ShareChartWrapper = styled.div``;

export const BulkActionsWrapper = styled.div``;
