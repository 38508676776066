import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import { Request } from "types/request";
import { Response } from "types/response";
import client from "utils/client";

export default function useSignup() {
  const queryClient = useQueryClient();

  const signup = (user: FormData) => {
    const request: Request = {
      url: ENDPOINTS.auth.signup,
      config: {
        method: "POST",
        data: user,
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<Response<null>, Error, FormData>(signup, {
    onSettled: () => queryClient.invalidateQueries([ENDPOINTS.auth.me]),
  });

  return { data, mutateAsync, ...rest };
}
