import { FC, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { MRDrawer, MRPagination, MRTextField } from "components/material";
import useCollections from "hooks/services/collections/useCollections";
import { useTranslation } from "react-i18next";
import useMultiSelect from "hooks/useMultiSelect";
import CollectionBulkActionsControl from "components/TablesComponents/Collection/CollectionBulkActionsControl";
import MRCollectionTable from "components/TablesComponents/Collection/CollectionsTable";
import { PAGE_SIZE } from "config/pagination";
import TitleCounter from "components/TablesComponents/TitleCounter";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import { useDebounce } from "use-debounce";
import { colors } from "styles/theme";
import { search } from "assets/icons";
import useToasts from "hooks/useToasts";
import CollectionFilterForm from "components/Collection/CollectionFilterForm";
import { toggleDrawer } from "utils/drawer";
import { Inputs as CollectionFilters } from "components/Collection/CollectionFilterForm/model";
import FilterButton from "components/FilterButton";
import { removeEmptyEntries } from "utils/object";
import Page from "components/Layout/Page";

const CollectionTable: FC = () => {
  const { t } = useTranslation();
  const { selected, toggleSelection, toggleAll, resetSelection } =
    useMultiSelect();
  const [page, setPage] = useState(1);
  const [text, setText] = useState<string>("");
  const [debouncedText] = useDebounce(text, 500);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("_id");
  const [filters, setFilters] = useState<CollectionFilters | null>(null);

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data: collections,
    isLoading: loadingCollections,
    isError: collectionsError,
  } = useCollections({
    page,
    size: PAGE_SIZE,
    order,
    sort: orderBy,
    text: debouncedText,
    ...(formattedFilters && formattedFilters),
  });

  const sortHandler = (orderBy: string) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(orderBy);
  };

  useToasts([
    {
      severity: "error",
      message: t("Cannot get collections. Please try again later."),
      condition: !!collectionsError,
    },
  ]);

  return (
    <Page>
      <MRDrawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={toggleDrawer(false, setFilterDrawerOpen)}
      >
        <CollectionFilterForm
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </MRDrawer>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <TitleCounter
          title={t("Collections")}
          count={collections?.pagination[0]?.total || 0}
        />
        <FilterButton
          onClick={() => setFilterDrawerOpen(true)}
          activeFilters={
            formattedFilters ? Object.keys(formattedFilters).length : 0
          }
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="end"
        alignItems="center"
        width="100%"
      >
        <MRTextField
          placeholder={t("Collection / owner name")}
          className="search-blocks"
          value={text}
          onChange={(e) => setText(e.target.value)}
          InputProps={{
            endAdornment: <img src={search} alt="search" />,
          }}
          sx={{ background: colors.lightBlack }}
        />

        <CollectionBulkActionsControl
          selectedIds={selected}
          resetSelection={resetSelection}
        />
      </Stack>

      <MRCollectionTable
        collections={collections?.data || []}
        selected={selected}
        toggleSelection={toggleSelection}
        toggleAll={toggleAll}
        order={order}
        orderBy={orderBy}
        sortHandler={sortHandler}
      />
      <Stack direction="row" justifyContent="center">
        {collections?.data && collections?.data.length ? (
          <MRPagination
            count={
              collections
                ? Math.ceil(collections?.pagination[0]?.total / PAGE_SIZE) || 1
                : 1
            }
            page={page}
            onChange={(e, page) => setPage(page)}
          />
        ) : (
          <NoDataRow>{t("No Data")}</NoDataRow>
        )}
      </Stack>
    </Page>
  );
};

export default CollectionTable;
