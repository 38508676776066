import styled from "@emotion/styled/macro";
import { colors } from "styles/theme";

export const UserDashboardWrapper = styled.div`
  h2 {
    margin-bottom: 70px;
  }

  .cta-credits {
    font-size: 1.05em;
  }

  .cta-credits span {
    color: ${colors.green};
    font-weight: bold;
  }

  .search-blocks .MuiOutlinedInput-root {
    background: ${colors.lightBlack};
  }

  .search-blocks {
    border-radius: 5px;
  }

  .profile-name {
    cursor: pointer;
  }
`;

export const SellerCountersWrapper = styled.div`
  .dashboard-top-card {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 50px 25px;
    gap: 25px;
    h2 {
      margin: 0px;
    }
    p {
      margin: 0px;
    }
  }
`;
