import { FC } from "react";
import { TransferStatus } from "types/transfer";
import { TransferStatusCardWrapper } from "./styles";

const TransferStatusCard: FC<{ status: TransferStatus }> = ({ status }) => {
  const classNameByStatus = {
    [TransferStatus.ACCEPTED]: "accepted-card",
    [TransferStatus.PENDING]: "pending-card",
    [TransferStatus.DECLINED]: "declined-card",
    [TransferStatus.REMOVED]: "removed-card",
  };

  return (
    <TransferStatusCardWrapper>
      <div className={"block-card " + classNameByStatus[status]}>
        {(status.charAt(0).toUpperCase() + status.slice(1)).replace("_", " ")}
      </div>
    </TransferStatusCardWrapper>
  );
};

export default TransferStatusCard;
