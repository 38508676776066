import { Avatar } from "@mui/material";
import { displayName, stringAvatar } from "helpers";
import { FC } from "react";
import { UserRowWrapper } from "./styles";
import { UserRowProps } from "./types";

const UserRow: FC<UserRowProps> = ({
  user,
  bgColor,
  setSelectedUser,
  selectedUser,
}) => {
  return (
    <UserRowWrapper>
      <div
        className={`contact-row ${
          selectedUser?._id === user._id ? "selected" : ""
        }`}
        onClick={() => setSelectedUser(user)}
      >
        <Avatar
          src={user?.profile?.propicUrl}
          sx={{ bgcolor: bgColor }}
          className="contact-avatar"
        >
          {displayName(user) && stringAvatar(displayName(user))}
        </Avatar>
        <span className="contact-name">{displayName(user)}</span>
      </div>
    </UserRowWrapper>
  );
};

export default UserRow;
