import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import { Request } from "types/request";
import { Response } from "types/response";
import { UserInvite } from "types/user";
import client from "utils/client";

export default function useInviteUser() {
  const queryClient = useQueryClient();

  const createNote = (userInvite: UserInvite) => {
    const request: Request = {
      url: `${ENDPOINTS.auth.invite}`,
      config: {
        method: "post",
        data: userInvite,
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<Response<null>, Error, UserInvite>(
    createNote,
    {
      onSettled: () => queryClient.invalidateQueries([ENDPOINTS.users.all]),
    }
  );

  return { data, mutateAsync, ...rest };
}
