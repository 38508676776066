import { MenuItem } from "@mui/material";
import { moreHorizontal } from "assets/icons";
import { MRMenu } from "components/material";
import { MRButtonTertiary } from "components/material/Button";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { ActionMenuWrapper } from "./styles";

const ChartYearSelector: FC<{ setSelectedYear: Dispatch<SetStateAction<number>> }> = ({ setSelectedYear }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentYear = new Date().getFullYear();
  const years = Array(currentYear - 2021)
    .fill("")
    .map((v, idx) => currentYear - idx);

  return (
    <>
      <img src={moreHorizontal} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} />
      <MRMenu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <ActionMenuWrapper>
          {years.map((year, index) => (
            <MenuItem onClick={handleClose} key={index}>
              <MRButtonTertiary className="menu-btn" onClick={() => setSelectedYear(year)}>
                {year}
              </MRButtonTertiary>
            </MenuItem>
          ))}
        </ActionMenuWrapper>
      </MRMenu>
    </>
  );
};

export default ChartYearSelector;
