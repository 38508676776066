import { FC, useState } from "react";
import { Stack } from "@mui/material";
import { SetCollectionBlocksProps } from "./types";
import { useTranslation } from "react-i18next";
import {
  MRButtonquaternary,
  MRButtonSecondary,
} from "components/material/Button";
import { SetCollectionBlocksWrapper } from "./styles";
import useCreateCollection from "hooks/services/collections/useCreateCollection";
import useAuth from "hooks/useAuth";
import toast from "react-hot-toast";
import CollectionBlocksList from "../EditCollectionModal/CollectionBlocksList";

const SetCollectionBlocks: FC<SetCollectionBlocksProps> = ({
  setStep,
  name,
  resetModal,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [selected, setSelected] = useState<string[]>([]);

  const {
    mutateAsync: createCollectionAsync,
    isLoading: isLoadingCreateCollection,
  } = useCreateCollection();

  const handleCreateCollection = () => {
    if (!user) return;
    createCollectionAsync({
      owner: user._id,
      name,
      blocks: selected,
    })
      .then(() => {
        resetModal();
      })
      .catch(() => {
        toast(
          t(
            "An error occured while creating the collection, please try again later."
          )
        );
        resetModal();
      });
  };

  return (
    <SetCollectionBlocksWrapper>
      <Stack direction="column" gap={2}>
        <CollectionBlocksList {...{ selected, setSelected }} />

        <Stack direction="row" justifyContent="space-between">
          <MRButtonSecondary onClick={() => setStep(0)}>
            {t("Back")}
          </MRButtonSecondary>
          <MRButtonquaternary
            classes={{
              root: "next-button-root",
              disabled: "next-button-disabled",
            }}
            loading={isLoadingCreateCollection}
            onClick={handleCreateCollection}
          >
            {t("Create collection")}
          </MRButtonquaternary>
        </Stack>
      </Stack>
    </SetCollectionBlocksWrapper>
  );
};

export default SetCollectionBlocks;
