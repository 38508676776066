import styled from "styled-components";
import { Menu } from "@mui/material";
import { colors } from "styles/theme";

const MRMenu = styled(Menu)`
  .MuiList-root {
    background-color: ${colors.black};
  }

  .MuiMenuItem-root:hover {
    background-color: unset;
  }
`;

export default MRMenu;
