import { FC } from "react";
import { AcquisitionStatus } from "types/acquisition";
import { AcquisitionStatusCardWrapper } from "./styles";

const AcquisitionStatusCard: FC<{ status: AcquisitionStatus }> = ({
  status,
}) => {
  const classNameByStatus = {
    [AcquisitionStatus.IDLE]: "idle-card",
    [AcquisitionStatus.PENDING_PROCESSING]: "processing-card",
    [AcquisitionStatus.PROCESSING]: "processing-card",
    [AcquisitionStatus.PROCESSED]: "processed-card",
    [AcquisitionStatus.SOLD]: "sold-card",
    [AcquisitionStatus.REMOVED]: "removed-card",
  };

  return (
    <AcquisitionStatusCardWrapper>
      <div className={"acquisition-card " + classNameByStatus[status]}>
        {(status.charAt(0).toUpperCase() + status.slice(1)).replace("_", " ")}
      </div>
    </AcquisitionStatusCardWrapper>
  );
};

export default AcquisitionStatusCard;
