import styled from "styled-components";
import Page from "components/Layout/Page";
import { colors } from "styles/theme";

export const LoginPage = styled(Page)`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: ${colors.lightBlack};

  .background-image {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const LoginFormWrapper = styled.div`
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 2rem;

  h3 {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .form-field {
    margin-bottom: 1.5rem;
  }

  .text-field {
    border-radius: 10px;
    background-color: ${colors.darkBlack};
    border: none;
    outline: none;
  }

  .or {
    height: fit-content;
    margin: 1rem;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: ${colors.primary};
    margin: 1rem;
  }

  .reset-password-button {
    color: ${colors.primary};
    text-transform: capitalize;
    font-size: 1rem;
  }

  .submit-button {
    width: 100%;
    text-transform: capitalize;
    color: ${colors.white};
    border-radius: 10px;
    margin: 2rem 0;
    background-color: #2db5c7 !important;
  }
`;
