import { Avatar, IconButton, Stack, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import Loader from "components/Loader";
import React, { FC } from "react";
import { FriendshipRequest } from "types/friendship-request";
import { RequestItemWrapper, RequestsContainerWrapper } from "./styles";
import {} from "assets/icons";
import { useTranslation } from "react-i18next";
import useToasts from "hooks/useToasts";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "styles/theme";
import useAcceptFriendshipRequest from "hooks/services/friendship-request/useAcceptFriendshipRequest";
import useDeclineFriendshipRequest from "hooks/services/friendship-request/useDeclineFriendshipRequest";
import useFriendshpiRequestReceived from "hooks/services/friendship-request/useFriendshipRequestReceived";
import moment from "moment";

const RequestsReceivedItem: FC<{ req: FriendshipRequest }> = ({ req }) => {
  const { t } = useTranslation();

  const {
    mutate: acceptRequest,
    error: accetRequestError,
    isSuccess: isSuccessAcceptRequest,
  } = useAcceptFriendshipRequest();

  const {
    mutate: declineRequest,
    error: declineRequestError,
    isSuccess: isSuccesDeclineRequest,
  } = useDeclineFriendshipRequest();

  useToasts([
    {
      severity: "error",
      message: t("Something went wrong, please try again later."),
      condition: !!accetRequestError || !!declineRequestError,
    },
    {
      severity: "success",
      message: t("Request accepted."),
      condition: !!isSuccessAcceptRequest,
    },
    {
      severity: "success",
      message: t("Request declined."),
      condition: !!isSuccesDeclineRequest,
    },
  ]);

  return (
    <RequestItemWrapper>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Avatar src={req.propicUrl}></Avatar>
      </Box>
      <span className="email-container">{req.senderEmail}</span>
      <Box sx={{ display: { xs: "none", md: "block" } }} className="date-container">
        {moment(req.createdAt).format("D MMM YYYY")}
      </Box>
      <Stack direction="row" alignItems="center" gap="5px">
        <Tooltip title={t("Accept")}>
          <IconButton onClick={() => acceptRequest({ requestId: req._id })}>
            <CheckIcon className="check-icon" sx={{ color: `${colors.green}` }} />
          </IconButton>
        </Tooltip>

        <Tooltip title={t("Decline")}>
          <IconButton onClick={() => declineRequest({ requestId: req._id })}>
            <CloseIcon className="check-icon" sx={{ color: `${colors.red}` }} />
          </IconButton>
        </Tooltip>
      </Stack>
    </RequestItemWrapper>
  );
};

const RequestsReceived: FC = () => {
  const { t } = useTranslation();

  //TODO: Handle pagination

  const { data: requests, isLoading, error } = useFriendshpiRequestReceived();
  if (error) throw new Error("Cannot get requests");

  return (
    <RequestsContainerWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="requests-container">
          {requests?.data.map((r) => (
            <React.Fragment key={r._id}>
              <RequestsReceivedItem req={r} />
            </React.Fragment>
          ))}
          {requests?.data.length === 0 && (
            <div className="placeholder">{t("You have not received any request yet")}</div>
          )}
        </div>
      )}
    </RequestsContainerWrapper>
  );
};

export default RequestsReceived;
