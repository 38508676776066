import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import axios, { AxiosError } from "axios";
import { createBrowserHistory } from "history";
import PATH from "routing/path";
import "styles/global.css";
import "./i18n";
import { ENDPOINTS } from "config/endpoints";
import { Request } from "types/request";
import { USER_REFRESH_TOKEN_KEY, USER_TOKEN_KEY } from "config/localStorage";

export const history = createBrowserHistory();

const goToLogin = () => {
  localStorage.clear();
  history.push(PATH.LOGIN);
};

// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error: AxiosError) => {
//     if (error.response?.status === 401) {
//       if (
//         error.response?.data.name === "Unauthorized" &&
//         error.response.data.message === "jwt expired"
//       ) {
//         return goToLogin();
//       }
//     }

//     return Promise.reject(error);
//   }
// );

// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//   IdentityPoolId: 'IDENTITY_POOL_ID',
//   IdentityId: 'IDENTITY_ID_RETURNED_FROM_YOUR_PROVIDER',
//   Logins: {
//      'cognito-identity.amazonaws.com': 'TOKEN_RETURNED_FROM_YOUR_PROVIDER'
//   }
// });

// AWS.config.update({
//   region: "eu-central-1",
//   credentials: new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: "eu-central-1:009d9862-f836-4e86-bfb7-662135bf8326",
//   }),
// });

/*const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
) */

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)

root.render(
  <React.StrictMode>
    <App history={history} />
  </React.StrictMode>
)

/*ReactDOM.render(
  <React.StrictMode>
    <App history={history} />
  </React.StrictMode>,
  document.getElementById("root")
); */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
