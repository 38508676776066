import Loader from "components/Loader";
import useAuth from "hooks/useAuth";
import { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PATH from "./path";
import { AuthGuardProps } from "./types";

const AuthGuard: FC<AuthGuardProps> = ({ children, roles }) => {
  let { user } = useAuth();
  let location = useLocation();

  if (!user) return <Navigate to={PATH.LOGIN} state={{ from: location }} replace />;

  if (roles && roles.length && !roles.includes(user?.roles))
    return <Navigate to={PATH.DASHBOARD} replace />;

  return <>{children}</>;
};

export default AuthGuard;
