import { Stack } from "@mui/material";
import { FC } from "react";
import { LockedWrapper } from "./styles";
import { lock3png } from "assets/icons";
import { useTranslation } from "react-i18next";
import { LockedProps } from "./types";
import { MRButtonquaternary } from "components/material/Button";
import { useNavigate } from "react-router-dom";
import PATH from "routing/path";
import { colors } from "styles/theme";

const Locked: FC<LockedProps> = ({ title, description, buttonText, buttonAction, card = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <LockedWrapper
      sx={{
        ...(card && {
          background: colors.lightBlack,
          border: "1px solid #272727",
          boxShadow: "rgb(158 163 169 / 20%) 0px 8px 16px",
        }),
      }}
    >
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Stack direction="column" justifyContent={"center"} alignItems="center" gap={1}>
          <img src={lock3png} alt="Lock" width="80" />
          <Stack direction="column" alignItems="center">
            <h2>{title || t("Upgrade your account")}</h2>
            <p>
              {description ||
                t(
                  "You will be able to load your 3d models and share them with your clients and partners"
                )}
            </p>
          </Stack>

          <MRButtonquaternary
            onClick={() => (buttonAction ? buttonAction() : navigate(PATH.CONTACT_US))}
          >
            {buttonText || t("Contact us")}
          </MRButtonquaternary>
        </Stack>
      </Stack>
    </LockedWrapper>
  );
};

export default Locked;
