import {
  Collapse,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import { FC } from "react";
import { InviteUserWrapper, StatusCardWrapper } from "./styles";
import MRTextField from "components/material/TextField";
import { InputLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MRButtonPrimary } from "components/material/Button";
import { InviteUserProps } from "./types";
import useInviteUser from "hooks/services/users/useInviteUser";
import { RollerShades } from "@mui/icons-material";
import ROLE from "types/role";
import useToasts from "hooks/useToasts";
import { inviteUserValidationSchema } from "./model";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

const InviteUser: FC<InviteUserProps> = ({ setDrawerOpen }) => {
  const { t } = useTranslation();

  const {
    data: inviteUserResponse,
    mutateAsync: inviteUser,
    isLoading: inviteUserLoading,
    error: inviteUserError,
  } = useInviteUser();

  const formik = useFormik({
    initialValues: {
      email: "",
      credits: 0,
      storage: 0,
      roles: ROLE.BUYER,
      bandwidthSize: 0,
    },
    validationSchema: inviteUserValidationSchema,
    onSubmit: async (values) => {
      const { email, credits, storage, bandwidthSize, roles } = values;

      inviteUser({
        username: email,
        roles,
        ...(roles === ROLE.SELLER && {
          credits,
          storage,
          bandwidth: bandwidthSize,
        }),
      }).then((res) => setDrawerOpen(false));
    },
  });

  useToasts([
    {
      severity: "success",
      message: t(`User invited correctly`),
      condition: !!inviteUserResponse,
    },
    {
      severity: "error",
      message: t(`User already exists`),
      condition:
        inviteUserError !== null &&
        (inviteUserError as AxiosError).response?.data.name ===
          "UserAlreadyExists",
    },
    {
      severity: "error",
      message: t(`Cannot invite this user`),
      condition: !!inviteUserError,
    },
  ]);

  return (
    <InviteUserWrapper
      direction="row"
      justifyContent="center"
      width="450px"
      maxWidth="100vw"
      height="100%"
    >
      <Stack direction="column" justifyContent="center" width="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <h2>{t("Invite user")}</h2>
          <div className="close-drawer" onClick={() => setDrawerOpen(false)}>
            <CloseIcon />
          </div>
        </Stack>
        <form onSubmit={formik.handleSubmit}>
          <InputLabel>{t("Email Address")}</InputLabel>
          <MRTextField
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <InputLabel style={{ marginTop: "30px", marginBottom: "0px" }}>
            {t("Role")}
          </InputLabel>
          <Stack direction="row">
            <RadioGroup
              aria-label="Role"
              name="roles"
              style={{
                flexDirection: "row",
              }} /* value={plan} onChange={changePlan} */
              onChange={formik.handleChange}
              value={formik.values.roles}
            >
              <FormControlLabel
                control={<Radio value={ROLE.BUYER} />}
                label={t("Free")}
              />
              <FormControlLabel
                control={<Radio value={ROLE.SELLER} />}
                label={t("Pro")}
              />
              <FormControlLabel
                control={<Radio value={ROLE.OPERATOR} />}
                label={t("Operator")}
              />
              <FormControlLabel
                control={<Radio value={ROLE.ADMIN} />}
                label={t("Admin")}
              />
            </RadioGroup>
          </Stack>

          <Collapse
            orientation="vertical"
            in={formik.values.roles === ROLE.SELLER}
          >
            <InputLabel>{t("Credits")}</InputLabel>
            <MRTextField
              id="credits"
              name="credits"
              type="number"
              inputProps={{ min: 0 }}
              onChange={formik.handleChange}
              value={formik.values.credits}
              error={formik.touched.credits && Boolean(formik.errors.credits)}
              helperText={formik.touched.credits && formik.errors.credits}
            />
            <InputLabel>{t("Storage")}</InputLabel>
            <MRTextField
              id="storage"
              name="storage"
              type="number"
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Unità</InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              value={formik.values.storage}
              error={formik.touched.storage && Boolean(formik.errors.storage)}
              helperText={formik.touched.storage && formik.errors.storage}
            />
            <InputLabel>{t("Bandwidth")}</InputLabel>
            <MRTextField
              id="bandwidthSize"
              name="bandwidthSize"
              type="number"
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">GB</InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              value={formik.values.bandwidthSize}
              error={
                formik.touched.bandwidthSize &&
                Boolean(formik.errors.bandwidthSize)
              }
              helperText={
                formik.touched.bandwidthSize && formik.errors.bandwidthSize
              }
            />
          </Collapse>
          <Stack style={{ marginTop: "35px", marginBottom: "0px" }}>
            <MRButtonPrimary type="submit">{t("Submit")}</MRButtonPrimary>
          </Stack>
        </form>
      </Stack>
    </InviteUserWrapper>
  );
};

export default InviteUser;
