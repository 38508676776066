import { Stack } from "@mui/material";
import { MRCard } from "components/material";
import { FC } from "react";
import { CounterCardWrapper } from "./styles";
import { CounterCardProps } from "./types";

const CounterCard: FC<CounterCardProps> = ({ icon, count, text }) => {
	return (
		<CounterCardWrapper>
			<MRCard>
				<Stack
					justifyContent="center"
					alignItems="center"
					direction="row"
					gap={2}
					className="content"
					sx={{ padding: { xs: "30px 20px", md: "50px 30px" } }}
				>
					<img src={icon} alt={text} />

					<Stack direction="column">
						<h2>{count}</h2>
						<p>{text}</p>
					</Stack>
				</Stack>
			</MRCard>
		</CounterCardWrapper>
	);
};

export default CounterCard;
