import styled from "styled-components";
import { colors } from "styles/theme";

export const AcquisitionFilterFormWrapper = styled.div`
  max-width: 450px;
  background-color: ${colors.lightBlack};

  .filter-form {
    width: 100%;
    padding: 20px;
  }
`;
