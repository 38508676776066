import Dashboard from "pages/dashboard";
import Home from "pages/home";
import Login from "pages/login";
import Signup from "pages/signup";
import PATH from "./path";
import { Navigate, Outlet } from "react-router-dom";

//subroutes
import userRoutes from "pages/users/routes";
import modelsToPrecessRoutes from "pages/models-to-process/routes";
import viewer3dRoutes from "pages/viewer3d/routes";
import processedModelsRoutes from "pages/processed-models/routes";
import cataloguesRoutes from "pages/catalogues/routes";
import modelsRoutes from "pages/user-models/routes";
import profileRoutes from "pages/profile/routes";
import passwordRoutes from "pages/password/routes";
import contactsRoutes from "pages/contacts/routes";

import AuthGuard from "./AuthGuard";
import ROLE from "types/role";
import AcceptTransfer from "pages/transfer/accept";
import Profile from "pages/profile";
import Link from "pages/link";
import ContactUs from "pages/contact-us";
import SignupFree from "pages/signup-free";
import SignupFreeVerify from "pages/signup-free/verify";

export const routes = [
  {
    name: "Login",
    path: PATH.LOGIN,
    element: <Login />,
  },
  {
    name: "Signup",
    path: PATH.SIGNUP,
    element: <Signup />,
  },
  {
    name: "Free Signup",
    path: PATH.FREESIGNUP,
    element: <SignupFree />,
  },
  {
    name: "Free Signup Verify",
    path: PATH.FREESIGNUPVERIFY,
    element: <SignupFreeVerify />,
  },
  {
    name: "Dashboard",
    path: PATH.DASHBOARD,
    element: (
      <AuthGuard>
        <Dashboard />
      </AuthGuard>
    ),
  },
  {
    name: "Users",
    path: PATH.USERS,
    element: (
      <AuthGuard roles={[ROLE.ADMIN, ROLE.OPERATOR]}>
        <Outlet />
      </AuthGuard>
    ),
    children: userRoutes,
  },
  {
    name: "Models to process",
    path: PATH.MODELS_TO_PROCESS,
    element: (
      <AuthGuard roles={[ROLE.ADMIN, ROLE.OPERATOR]}>
        <Outlet />
      </AuthGuard>
    ),
    children: modelsToPrecessRoutes,
  },
  {
    name: "Models",
    path: PATH.PROCESSED_MODELS,
    element: (
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    ),
    children: processedModelsRoutes,
  },
  {
    name: "3d Viewer",
    path: PATH.VIEWER_3D,
    element: <Outlet />,
    children: viewer3dRoutes,
  },
  {
    name: "Catalogues",
    path: PATH.CATALOGUES,
    element: (
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    ),
    children: cataloguesRoutes,
  },
  {
    name: "Contacts",
    path: PATH.CONTACTS,
    element: (
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    ),
    children: contactsRoutes,
  },
  {
    name: "Transfer",
    path: `${PATH.TRANSFER}/:id/accept`,
    element: (
      <AuthGuard>
        <AcceptTransfer />
      </AuthGuard>
    ),
  },
  {
    name: "Models",
    path: PATH.MODELS,
    element: (
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    ),
    children: modelsRoutes,
  },
  {
    name: "Profile",
    path: PATH.PROFILE,
    element: (
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    ),
    children: profileRoutes,
  },
  {
    name: "Password",
    path: PATH.PASSWORD,
    element: <Outlet />,
    children: passwordRoutes,
  },
  {
    name: "Link",
    path: PATH.LINK,
    element: <Link />,
  },
  {
    name: "Contact Us",
    path: PATH.CONTACT_US,
    element: <ContactUs />,
  },
];
