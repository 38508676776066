import { FC } from "react";
import { CollectionFilterFormWrapper } from "./styles";
import { CollectionFilterFormProps } from "./types";
import { Inputs, initialValues, validationSchema } from "./model";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { FormControlLabel, IconButton, Radio, RadioGroup, Stack } from "@mui/material";
import { MRTextField } from "components/material";
import { MRButtonPrimary, MRButtonSecondary } from "components/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ClearIcon from "@mui/icons-material/Clear";

const CollectionFilterForm: FC<CollectionFilterFormProps> = ({
  onApplyFilters,
  onClearFilters,
  onClose,
  appliedFilters,
}) => {
  const { t } = useTranslation();
  const formik = useFormik<Inputs>({
    enableReinitialize: true,
    initialValues: appliedFilters ? { ...initialValues, ...appliedFilters } : initialValues,
    validationSchema,
    onSubmit: (collectionFilterInfo) => {
      onApplyFilters(collectionFilterInfo);
    },
  });

  return (
    <CollectionFilterFormWrapper>
      <Stack height="100%" alignItems="start" justifyContent="center">
        <form className="filter-form" onSubmit={formik.handleSubmit}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <IconButton onClick={(e) => onClose()}>
              <ClearIcon />
            </IconButton>
            <MRButtonSecondary
              onClick={() => {
                onClearFilters();
                formik.resetForm();
              }}
            >
              {t("Clear Filters")}
            </MRButtonSecondary>
          </Stack>
          {/* <p>{t("Title")} </p>
          <MRTextField
            fullWidth
            id="title"
            name="name"
            placeholder={t("Title")}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          /> */}

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <p>{t("From date")} </p>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={formik.values.startDate}
              onChange={(value) => formik.setFieldValue("startDate", value)}
              renderInput={(params) => (
                <MRTextField
                  error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                  helperText={formik.touched.startDate && Boolean(formik.errors.startDate)}
                  name="startDate"
                  fullWidth
                  {...params}
                />
              )}
            />

            <p>{t("To date")} </p>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={formik.values.endDate}
              onChange={(value) => formik.setFieldValue("endDate", value)}
              renderInput={(params) => (
                <MRTextField
                  error={formik.touched.startDate && Boolean(formik.errors.endDate)}
                  helperText={formik.touched.endDate && Boolean(formik.errors.endDate)}
                  name="endDate"
                  fullWidth
                  {...params}
                />
              )}
            />
          </LocalizationProvider>

          <Stack marginTop="15px" direction="row" justifyContent="center" alignItems="center">
            <MRButtonPrimary type="submit">{t("Apply")}</MRButtonPrimary>
          </Stack>
        </form>
      </Stack>
    </CollectionFilterFormWrapper>
  );
};

export default CollectionFilterForm;
