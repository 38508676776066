import { Fade } from "@mui/material";
import { useEffect, FC } from "react";
import styled from "styled-components";

interface TitleProps {
  children: React.ReactNode;
  title?: string;
}

const Title: FC<TitleProps> = ({ children, title }) => {
  useEffect(() => {
    document.title = `${title || ""} | ${process.env.REACT_APP_SITE_NAME}`;
  }, [title]);

  return <>{children}</>;
};

export default Title;
