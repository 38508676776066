import styled from "styled-components";
import { colors } from "styles/theme";

export const SelectUserWrapper = styled.div`
  .contacts-dialog-box {
    background: black;
    border-radius: 10px;
    padding: 20px 5px 20px 20px;
    height: 300px;
    overflow-y: scroll;
  }

  .contacts-dialog-box::-webkit-scrollbar {
    width: 20px;
  }

  .contacts-dialog-box::-webkit-scrollbar-thumb {
    height: 100px;
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${colors.lightBlack};
  }
`;

export const UserRowWrapper = styled.div`
  animation: fadeIn 0.3s;
  -webkit-animation: fadeIn 0.3s;
  -moz-animation: fadeIn 0.3s;
  -o-animation: fadeIn 0.3s;
  -ms-animation: fadeIn 0.3s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .contact-row {
    background: ${colors.lightBlack};
    border-radius: 16px;
    display: flex;
    align-items: center;
    margin: 15px 0px;
    padding: 14px 10px;
    cursor: pointer;
  }

  .contact-row.selected {
    border: solid 1px ${colors.green};
  }

  .contact-avatar,
  .contact-name {
    margin-right: auto;
  }

  .contact-avatar {
    margin-left: 15px;
    color: white;
    font-weight: 600;
  }

  .search-contact {
    width: 100%;
    margin-bottom: 15px;
  }
`;
