import styled from "styled-components";
import { colors } from "styles/theme";

export const UserStorageWrapper = styled.div`
  .cta-storage span {
    color: ${colors.green};
    font-weight: bold;
  }

  .buy-credits-container {
    cursor: pointer;
  }
`;
