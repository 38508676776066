import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { FC } from "react";
import { UserFilterFormWrapper } from "./styles";
import { Inputs, initialValues, validationSchema } from "./model";
import { useFormik } from "formik";
import { MRTextField } from "components/material";
import { useTranslation } from "react-i18next";
import { MRButtonPrimary, MRButtonSecondary } from "components/material/Button";
import ROLE from "types/role";
import { UserStatus } from "types/user";
import { UserFilterFormProps } from "./types";
import ClearIcon from "@mui/icons-material/Clear";

const UserFilterForm: FC<UserFilterFormProps> = ({
  onApplyFilters,
  appliedFilters,
  onClearFilters,
  onClose,
}) => {
  const formik = useFormik<Inputs>({
    enableReinitialize: true,
    initialValues: appliedFilters
      ? { ...initialValues, ...appliedFilters }
      : initialValues,
    validationSchema,
    onSubmit: (userFilterInfo) => {
      onApplyFilters(userFilterInfo);
    },
  });

  const { t } = useTranslation();

  return (
    <UserFilterFormWrapper>
      <Stack height="100%" alignItems="start" justifyContent="center">
        <form onSubmit={formik.handleSubmit} className="filter-form">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton onClick={(e) => onClose()}>
              <ClearIcon />
            </IconButton>
            <MRButtonSecondary
              onClick={() => {
                onClearFilters();
                formik.resetForm();
              }}
            >
              {t("Clear Filters")}
            </MRButtonSecondary>
          </Stack>
          <p>{t("Firstname")} </p>
          <MRTextField
            fullWidth
            id="firstname"
            name="firstname"
            placeholder={t("Firstname")}
            value={formik.values.firstname}
            onChange={formik.handleChange}
            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
            helperText={formik.touched.firstname && formik.errors.firstname}
          />
          <p>{t("Lastname")} </p>
          <MRTextField
            fullWidth
            id="lastname"
            name="lastname"
            placeholder={t("Lastname")}
            value={formik.values.lastname}
            onChange={formik.handleChange}
            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
            helperText={formik.touched.lastname && formik.errors.lastname}
          />
          <p>{t("Company")} </p>
          <MRTextField
            fullWidth
            id="companyName"
            name="companyName"
            placeholder={t("Company")}
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
          />

          <p>{t("Role")} </p>
          <Stack direction="row">
            <RadioGroup
              aria-label="Role"
              name="roles"
              style={{
                flexDirection: "row",
              }}
              onChange={formik.handleChange}
              value={formik.values.roles}
            >
              <FormControlLabel
                control={<Radio value={ROLE.BUYER} />}
                label={t("Free")}
              />
              <FormControlLabel
                control={<Radio value={ROLE.SELLER} />}
                label={t("Pro")}
              />
              <FormControlLabel
                control={<Radio value={ROLE.OPERATOR} />}
                label={t("Operator")}
              />
              <FormControlLabel
                control={<Radio value={ROLE.ADMIN} />}
                label={t("Admin")}
              />
            </RadioGroup>
          </Stack>

          <p>{t("Status")} </p>
          <Stack direction="row">
            <RadioGroup
              aria-label="Status"
              name="status"
              style={{
                flexDirection: "row",
              }}
              onChange={formik.handleChange}
              value={formik.values.status}
            >
              <FormControlLabel
                control={<Radio value={UserStatus.ACTIVE} />}
                label="Active"
              />
              <FormControlLabel
                control={<Radio value={UserStatus.INVITED} />}
                label="Invited"
              />
              <FormControlLabel
                control={<Radio value={UserStatus.BLOCKED} />}
                label="Blocked"
              />
            </RadioGroup>
          </Stack>
          <Stack
            marginTop="15px"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <MRButtonPrimary type="submit">{t("Apply")}</MRButtonPrimary>
          </Stack>
        </form>
      </Stack>
    </UserFilterFormWrapper>
  );
};

export default UserFilterForm;
