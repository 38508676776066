import * as Yup from "yup";

export const validationSchema = Yup.object({
  currentPassword: Yup.string().required("Current Password is required"),
  newPassword: Yup.string().required("New Password is required").min(8, "password must be at least 8 chars"),
  confirmNewPassword: Yup.string()
    .required("Confirm new Password is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

export const initialValues = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};
