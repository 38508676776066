import Page from "components/Layout/Page";
import styled from "styled-components";
import SignupBackground from "assets/images/bg-2.jpg";
import { colors } from "styles/theme";

export const ErrorPage = styled(Page)`
  background: url("${SignupBackground}");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  h3,
  span {
    font-family: Barlow, sans-serif;
    color: white;
  }

  .card {
    height: unset;
    padding: 50px;
    background-color: ${colors.black};
  }
`;
