import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery } from "react-query";
import { Request } from "types/request";
import { PaginatedResponse } from "types/response";
import { Link } from "types/link";
import Error from "types/error";
import { generatePath } from "react-router-dom";

export default function useBlockLinks(
  blockId: string,
  page?: number,
  size?: number,
  search?: Record<string, string>
) {
  const request: Request = {
    url: generatePath(ENDPOINTS.links.block, { id: blockId }),
    config: {
      method: "GET",
      params: { page, size, ...search },
    },
  };

  const requestConfig = {
    queryKey: [
      ENDPOINTS.links.block,
      blockId,
      page,
      size,
      search && JSON.stringify(search),
    ],
    queryFn: () => client(request).then((res) => res.data),
  };

  const { data, ...rest } = useQuery<PaginatedResponse<Link[]>, Error>(
    requestConfig
  );

  return { data, ...rest };
}
