import { Avatar, List, ListItem } from "@mui/material";
import { displayName, stringAvatar, accountType } from "helpers";
import useAuth from "hooks/useAuth";
import { FC } from "react";
import { bottomNavigation } from "./navs/bottom";
import { useNavigate } from "react-router-dom";
import { BottomNavigationWrapper } from "./styles";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavigationProps } from "./types";
import NavItem from "./NavItem";
import PATH from "routing/path";
import NotificationNavItem from "components/Notification/NotificationNavItem";

const BottomNavigation: FC<NavigationProps> = ({ active, setActive, open }) => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  return (
    <BottomNavigationWrapper>
      <List>
        {user && (
          <div className="user-controls">
            <ListItem disablePadding>
              <Avatar
                src={user.profile?.propicUrl}
                sx={{
                  height: "40px",
                  width: "40px",
                  minWidth: 0,
                  mr: open ? 0 : "auto",
                  justifyContent: "center",
                }}
              >
                {stringAvatar(displayName(user)).toUpperCase()}
              </Avatar>
              <div className="user-info" style={{ opacity: open ? 1 : 0 }}>
                <div
                  className="user-name"
                  onClick={() => navigate(PATH.PROFILE)}
                >
                  {displayName(user)}
                </div>
                <div className="user-account">{accountType(user)}</div>
              </div>
              <div className="user-logout" style={{ opacity: open ? 1 : 0 }}>
                <LogoutIcon onClick={() => logout()} />
              </div>
            </ListItem>
          </div>
        )}
        <NotificationNavItem navOpen={open} />
        {bottomNavigation.map((navItem) => (
          <NavItem key={navItem.path} item={navItem} open={open} />
        ))}
      </List>
    </BottomNavigationWrapper>
  );
};

export default BottomNavigation;
