import { Grid, Stack } from "@mui/material";
import CreateCollectionModal from "components/Collection/CreateCollectionModal";
import CollectionAdder from "components/Collection/Single/CollectionAdder";
import Loader from "components/Loader";
import {
  Collection as ICollection,
  CollectionGridProps,
  SharedCollection,
} from "types/collection";
import { FC, useEffect, useLayoutEffect, useState } from "react";
import Collection from "components/Collection/Single";
import useToasts from "hooks/useToasts";
import { useTranslation } from "react-i18next";
import { flattenPages } from "helpers";
import { MRCheckbox } from "components/material";

const CollectionsGrid: FC<CollectionGridProps> = ({
  data,
  isLoading,
  isSuccess,
  error,
  hasNextPage,
  fetchNextPage,
  variant,
}) => {
  const { t } = useTranslation();

  const [scrollY, setScrollY] = useState(0);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (!hasNextPage) return;
    if (
      !(
        document.scrollingElement?.scrollHeight &&
        document.scrollingElement.clientHeight
      )
    )
      return;
    if (
      document.scrollingElement?.scrollHeight >
      document.scrollingElement?.clientHeight + 150
    )
      return;
    fetchNextPage();
  });

  useEffect(() => {
    window.addEventListener("scroll", () => setScrollY(window.pageYOffset));
    window.addEventListener("resize", () => setScrollY(window.pageYOffset));
    return () => {
      window.removeEventListener("scroll", () =>
        setScrollY(window.pageYOffset)
      );
      window.removeEventListener("resize", () =>
        setScrollY(window.pageYOffset)
      );
    };
  });

  useLayoutEffect(() => {
    if (!hasNextPage) return;
    if (
      !(
        document.scrollingElement?.scrollHeight &&
        document.scrollingElement.clientHeight
      )
    )
      return;
    if (
      document.scrollingElement?.clientHeight +
        document.scrollingElement?.scrollTop ===
      document.scrollingElement?.scrollHeight
    ) {
      fetchNextPage();
    }
  }, [scrollY]);

  useToasts([
    {
      severity: "error",
      message: t("There was an error. Cannot fetch collections."),
      condition: !!error,
    },
  ]);

  return (
    <>
      <CreateCollectionModal
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <Grid container rowSpacing={3} columnSpacing={3}>
        {variant === "owned" && (
          <Grid
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            justifySelf={"center"}
            className="collections-grid-item"
          >
            <CollectionAdder onClick={() => setOpenModal(true)} />
          </Grid>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          flattenPages(data).map(
            (collection: ICollection | SharedCollection) => {
              return (
                <Grid
                  item
                  key={
                    variant === "owned"
                      ? (collection as ICollection)._id
                      : (collection as SharedCollection).collection._id
                  }
                  xl={3}
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  justifySelf={"center"}
                  className="collections-grid-item"
                >
                  <Collection
                    collection={
                      variant === "owned"
                        ? (collection as ICollection)
                        : (collection as SharedCollection).collection
                    }
                    variant={variant}
                    forwardable={(collection as SharedCollection).forwardable}
                  />
                </Grid>
              );
            }
          )
        )}
        {flattenPages(data).length === 0 && !isLoading && (
          <Stack
            sx={{ padding: 2, paddingTop: 4 }}
            alignItems="center"
            direction="row"
          >
            {t("There are no catalogues.")}
          </Stack>
        )}
      </Grid>
    </>
  );
};

export default CollectionsGrid;
