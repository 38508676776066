import {
    BufferGeometry, CircleGeometry,
    Intersection, LineBasicMaterial, LineSegments,
    Mesh, MeshBasicMaterial, PlaneGeometry,
    Vector3, Object3D, Vector2
  } from "three"

  import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
  import { FontLoader, Font } from "three/examples/jsm/loaders/FontLoader";
  import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
  import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
  import { LineSegments2 } from "three/examples/jsm/lines/LineSegments2";

  // res = gl.getSize(new Vector2())
export function makeLineSegments2(start: Vector3, end: Vector3, sizeLine: number, res: Vector2): LineSegments2 {
    let pos = new Float32Array(2 * 3);
    pos[0] = start.x;
    pos[1] = start.y;
    pos[2] = start.z;
    pos[3] = end.x;
    pos[4] = end.y;
    pos[5] = end.z;

    let geom = new LineGeometry();
    geom.setPositions(pos);
    //geom.setDrawRange(0,2);

    let mat = new LineMaterial({
      color: 0xFF0000,
      linewidth: sizeLine,
      resolution: res
    })
    let lineT = new LineSegments2(geom, mat);

    //lineT.geometry.setDrawRange(0,2);
    lineT.geometry.attributes.position.needsUpdate = true;
    lineT.geometry.instanceCount = 2;
    return lineT;
}

export function updateLineSegments2(lineT: LineSegments2, start: Vector3, end: Vector3, sizeLine: number, res: Vector2): LineSegments2 {
	let pos = new Float32Array(2 * 3);
	pos[0] = start.x;
	pos[1] = start.y;
	pos[2] = start.z;
	pos[3] = end.x;
	pos[4] = end.y;
	pos[5] = end.z;
	//lineT.geometry.setPositions(pos);
	//lineT.geometry.setDrawRange(0,2);
	//lineT.geometry.attributes.position.needsUpdate = true;
	//lineT.geometry.instanceCount = 2;
	lineT = makeLineSegments2(start, end, sizeLine, res);
	return lineT;
}

export function makeLineSegment(start: Vector3, end: Vector3, sizeLine: number): LineSegments {
	const points = [];
	points.push(start.clone());
	points.push(end.clone());
	const geometry = new BufferGeometry().setFromPoints(points);
	let lineT = new LineSegments(
		geometry,
		new LineBasicMaterial({
			color: 0xFF0000,
			linewidth: sizeLine,
		})
	);
	return lineT;
}

export function makeProjectionSegment(start: Vector3, end: Vector3, normal: Vector3, sizeLine: number, res: Vector2): LineSegments2 {
	let proj = computeProjection(start, end, normal);
	let lineProjection = makeLineSegments2(start, proj, sizeLine, res);
	lineProjection.name = "proiezione";
	return lineProjection;
}

export function makeCircle(radious: number): Mesh {
	let circleGeom = new CircleGeometry(radious, 128);
	let circleMaterial = new MeshBasicMaterial({
		color: 0xFF0000,
		transparent: false,
		opacity: 1
	})
	let circle = new Mesh(circleGeom, circleMaterial);
	circle.name = 'circle';
	return circle;
}

export function makePlane(l: number, h: number): Mesh {
	const geometry = new PlaneGeometry(l, h);
	const material = new MeshBasicMaterial({
		color: 0xFF0000,
		wireframe: false,
		transparent: false,
		opacity: 1
	})
	const plane = new Mesh(geometry, material);
	return plane;
}

export function getIndexCanonicalVector(v: Vector3) {
	for (let index = 0; index < 3; index++) {
		if (Math.round(v.getComponent(index)) == 1 || Math.round(v.getComponent(index)) == -1) {
			return index;
		}
	}
	return -1;
}

export function computeProjection(start: Vector3, end: Vector3, normal: Vector3 | undefined): Vector3 {
	if (normal === undefined)
		return new Vector3(0, 0, 0)
	let index = getIndexCanonicalVector(normal);
	let newNormal = new Vector3(0, 0, 0);
	newNormal.setComponent(index, 1);
	let newEnd = end.multiply(newNormal);
	for (let j = 0; j < 3; j++) {
		if (j != index)
			newEnd.setComponent(j, start.getComponent(j));
	}
	return newEnd;
}

const findMesh = (name: string, intersection: Intersection[]): boolean => {
	return intersection.findIndex((element) => element.object.name === name) == -1 ? false : true;
}

export function searchEndpoint1(obj: Object3D, points: Object3D[]): number {
    if (points.length == 0)
      return -1;

    let obj_uuid = points.findIndex((element)=> element.uuid == obj.uuid);
    //console.log("Found: ", obj_uuid);
    return obj_uuid;
  }

  // dato un array di intersection (ritornato dal Raycaster), cerco se e'
  // presente 
export function searchObjectByName(arr: Intersection[], name: string): number {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].object.name === name)
        return i;
    }
    return -1;
  }