import { FC, useState } from "react";
import { Dialog } from "components/material/Dialog";
import { CreateCollectionModalProps } from "./types";
import { Slide } from "@mui/material";

import { CreateCollectionModalWrapper } from "./styles";
import { colors } from "styles/theme";

import ModalHeader from "./ModalHeader";
import SetCollectionName from "./SetCollectionName";
import SetCollectionBlocks from "./SetCollectionBlocks";

const CreateCollectionModal: FC<CreateCollectionModalProps> = ({
  openModal,
  setOpenModal,
}) => {
  const [step, setStep] = useState<number>(0);
  const [name, setName] = useState<string>("");

  const handleClose = (e: object, reason: string) => {
    if (reason === "escapeKeyDown") setOpenModal(false);
  };

  return (
    <CreateCollectionModalWrapper>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="create-collection-modal"
        PaperProps={{
          style: {
            background: colors.lightBlack,
            backgroundImage: "none",
            width: "100%",
            padding: "20px",
            border: `1px solid ${colors.white}`,
            borderRadius: "20px",
          },
        }}
      >
        <ModalHeader step={step} handleClose={handleClose} />

        {step === 0 && (
          <Slide direction="right" in={step === 0}>
            <div>
              <SetCollectionName
                name={name}
                setName={setName}
                setStep={setStep}
              />
            </div>
          </Slide>
        )}

        {step === 1 && (
          <Slide direction="left" in={step === 1}>
            <div>
              <SetCollectionBlocks
                setStep={setStep}
                name={name}
                resetModal={() => {
                  setOpenModal(false);
                  setName("");
                  setStep(0);
                }}
              />
            </div>
          </Slide>
        )}
      </Dialog>
    </CreateCollectionModalWrapper>
  );
};

export default CreateCollectionModal;
