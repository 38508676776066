import styled from "styled-components";
import { colors } from "styles/theme";

export const ContactsWrapper = styled.div`
  .cta-credits span {
    color: ${colors.green};
    font-weight: bold;
  }

  .search-contact {
    .MuiOutlinedInput-root {
      background-color: ${colors.lightBlack};
    }
  }
`;
