import styled from "@emotion/styled";
import { colors } from "styles/theme";

export const TitleCounterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;

  h2 {
    padding-left: 30px;
    color: ${colors.green};
    display: inline;
  }

  .table-title-chip {
    background-color: rgba(55, 114, 255, 0.1);
    color: ${colors.blue};
  }
`;
