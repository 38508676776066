import styled from "styled-components";
import { colors } from "styles/theme";

export const UserCreditsWrapper = styled.div`
  .cta-credits span {
    display: inline;
    color: ${colors.green};
    font-weight: bold;
  }

  .buy-credits-container {
    cursor: pointer;
  }
`;
