import { Stack } from "@mui/material";
import styled from "styled-components";
import { colors } from "styles/theme";

export const NotifyContainer = styled.div`
  min-height: 100px;
  cursor: pointer;
  padding: 15px;
  border-bottom: solid 1px #ffffff20;

  p {
    margin: 0px;
  }

  .notify-user-name {
    color: ${colors.primary};
  }

  .from-creation-to-now {
    text-align: end;
    margin-top: 10px;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.primary};
  }

  .notify-Body {
    min-height: 45px;
  }
`;

export const PopoverContainer = styled.div``;

export const NoNotificationContainer = styled(Stack)`
  height: 400px;
  width: 350px;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h4 {
    margin-bottom: 0px;
  }
  p {
    text-align: center;
  }
`;
