import { useEffect } from "react";
import toast from "react-hot-toast";

interface Toast {
  condition: boolean;
  severity: "success" | "error" | "warning" | "info";
  message: string;
}

const useToasts = (toasts: Toast[]) => {
  const freezedToasts = JSON.stringify(toasts);

  useEffect(() => {
    const parsedToasts: Toast[] = JSON.parse(freezedToasts);
    parsedToasts.map((t) => {
      if (t.condition) {
        toast(t.message);
      }
    });
  }, [freezedToasts]);
};

export default useToasts;
