import styled from "styled-components";
import { colors } from "styles/theme";

export const AcquisitionStatusCardWrapper = styled.div`
  .idle-card {
    color: ${colors.blue};
  }
  .processing-card {
    color: ${colors.yellow};
  }
  .processed-card {
    color: ${colors.green};
  }

  .acquisition-card {
    background: ${colors.lighterBlack};
    padding: 5px 10px;
    border-radius: 15px;
  }
`;

export const AcquisitionTableWrapper = styled.div`
  .priority-select .MuiSelect-select {
    padding: 5px 32px 5px 15px;
  }
`;
