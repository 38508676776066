import styled from "styled-components";
import { Table } from "@mui/material";
import { colors } from "styles/theme";

const MRTable = styled(Table)`
	border-spacing: 0 11px !important;
	border-collapse: separate !important;

	.MuiTableCell-root {
		border-bottom: 0;
	}

	.MuiTableRow-root {
	}

	.MuiTableBody-root .MuiTableRow-root td,
	.MuiTableBody-root .MuiTableRow-root th {
		background-color: ${colors.lightBlack};
	}

	.MuiTableRow-root td:first-child,
	.MuiTableRow-root th:first-child {
		padding-left: 30px;
	}

	.MuiTableRow-root td:last-child,
	.MuiTableRow-root th:last-child {
		padding-right: 30px;
	}

	.MuiTableBody-root .MuiTableRow-root td:first-child {
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}

	.MuiTableBody-root .MuiTableRow-root td:last-child {
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}
`;

export default MRTable;
