import { Box, Stack } from "@mui/material";
import useToasts from "hooks/useToasts";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { HorizontalScrollerContainer, ScrollerHeader } from "components/HorizontalScroller/styles";
import { PAGE_SIZE } from "config/pagination";
import HorizontalScroller from "components/HorizontalScroller";
import useInfiniteBlocks from "hooks/services/blocks/useInfiniteBlocks";
import Block from "../BlockGridElem";
import { BlockStatus } from "types/block";
import { flattenPages } from "helpers";

const HorizontalTrashedBlocks: FC = () => {
  const { t } = useTranslation();

  const {
    data: pages,
    isLoading: isLoadingBlocks,
    error: blocksError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteBlocks({
    size: PAGE_SIZE,
    query: { status: [BlockStatus.TRASHED] },
  });

  useToasts([
    {
      severity: "error",
      message: t("There was an error. Cannot fetch collections."),
      condition: !!blocksError,
    },
  ]);

  return (
    <HorizontalScrollerContainer>
      <ScrollerHeader>
        <h4>{t("Trashed models")}</h4>
      </ScrollerHeader>

      <HorizontalScroller
        isLoading={isLoadingBlocks}
        hasNextPage={hasNextPage}
        fetchNext={fetchNextPage}
        isLoadingNextPage={isFetchingNextPage}
      >
        <Stack direction="row" sx={{ paddingLeft: "20px" }} gap={4}>
          {flattenPages(pages).map((block) => {
            return <Block key={block._id} block={block} variant="trashed" />;
          })}
          {flattenPages(pages).length === 0 && t("Trashbin is empty")}
        </Stack>
      </HorizontalScroller>
    </HorizontalScrollerContainer>
  );
};

export default HorizontalTrashedBlocks;
