import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import { generatePath } from "react-router-dom";
import { Acquisition } from "types/acquisition";
import { Request } from "types/request";
import { Response } from "types/response";
import client from "utils/client";

export default function useSetProcessing(id: string) {
  const queryClient = useQueryClient();

  const setProcessing = () => {
    const request: Request = {
      url: generatePath(ENDPOINTS.acquisitions.setProcessing, { id }),
      config: {
        method: "PATCH",
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<null>, Error>(setProcessing, {
    onSettled: () => queryClient.invalidateQueries([ENDPOINTS.acquisitions.one, id]),
  });

  return { data, mutate, ...rest };
}
