import {
  Autocomplete,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Zoom,
} from "@mui/material";
import { MRButtonPrimary, MRButtonQuinary } from "components/material/Button";
import MRTextField from "components/material/TextField";
import { useFormik } from "formik";
import useAdminEditUserProfile from "hooks/services/users/useAdminEditUserProfile";
import useSellerEditUserProfile from "hooks/services/users/useSellerEditUserProfile";
import useAuth from "hooks/useAuth";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ROLE from "types/role";
import { User, UserInfoInputs } from "types/user";
import {
  formatResponse,
  initialValues,
  validationSchema,
  adminValidationSchema,
} from "../../pages/users/views/single/model";
import jsonCountries from "assets/countries.json";

import useSetCompanyLogo from "hooks/services/users/useSetCompanyLogo";
import { ReactComponent as DeleteLogo } from "assets/icons/close.svg";
import { useConfirmModal } from "context/confirmModal";
import useDeleteCompanyLogo from "hooks/services/users/useDeleteCompanyLogo";
import Loader from "components/Loader";
import useToasts from "hooks/useToasts";
import { MRCheckbox } from "components/material";
import toast from "react-hot-toast";

const ProfileForm: FC<{ user: User }> = ({ user }) => {
  const { user: editer, me } = useAuth();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const companyLogo = useRef<HTMLInputElement>(null);

  const { mutate: editUserProfile } = useAdminEditUserProfile();
  const { mutate: sellerEditUserProfile, isSuccess } =
    useSellerEditUserProfile();

  const {
    mutateAsync: editCompanyLogo,
    isSuccess: editCompanyLogoSuccess,
    isError: editCompanyLogoError,
    reset: resetEditCompanyLogo,
  } = useSetCompanyLogo();

  const {
    mutateAsync: deleteLogo,
    isSuccess: deleteLogoSuccess,
    isError: deleteLogoError,
    reset: resetDeleteCompanyLogo,
  } = useDeleteCompanyLogo();

  const { openModal } = useConfirmModal();

  const isAdmin = () => {
    return editer?.roles === ROLE.ADMIN || editer?.roles === ROLE.OPERATOR;
  };

  const formik = useFormik<UserInfoInputs>({
    enableReinitialize: true,
    initialValues: user ? formatResponse(user) : initialValues,
    validationSchema: isAdmin() ? adminValidationSchema : validationSchema,
    onSubmit: (userProfile) => {
      isAdmin()
        ? editUserProfile({ id: user._id, ...userProfile })
        : sellerEditUserProfile(userProfile);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      me();
    }
  }, [isSuccess]);

  const countryWOFlag = (value: any) => {
    let [first, ...rest] = value.split(" ");
    rest = rest.join(" ");
    return rest;
  };

  const uploadCompanyLogo = async (e: any) => {
    const maxSize = 1024 * 1024; // Maximum file size in bytes (1MB)

    if (e.target.files[0]) {
      if (e.target.files[0].size > maxSize) {
        e.target.value = "";
        toast(t("ERROR: Image is too big!"));
        return;
      }

      const uploadedFile = e.target.files[0];
      const formdata = createFormData(uploadedFile);
      setIsLoading(true);
      editCompanyLogo(formdata).then(async () => {
        await me();
        setIsLoading(false);
        resetEditCompanyLogo();
      });
    }
  };

  function createFormData(uploadedFile: File) {
    let formdata = new FormData();
    formdata.set("companyLogo", uploadedFile!);
    return formdata;
  }

  const handleDeleteCompanyLogo = () => {
    openModal({
      title: t("Are you sure to delete this logo?"),
      text: t("The operation is not reversible"),
      confirmFunction: () => deleteAndRefresh(),
    });
  };

  const deleteAndRefresh = async () => {
    setIsLoading(true);
    await deleteLogo();
    await me();
    setIsLoading(false);
    resetDeleteCompanyLogo();
  };

  useToasts([
    {
      severity: "error",
      message: t("Something went wrong, please try again later."),
      condition: !!deleteLogoError || !!editCompanyLogoError,
    },
    {
      severity: "success",
      message: t("Logo updated successfully."),
      condition: !!editCompanyLogoSuccess,
    },
    {
      severity: "success",
      message: t("Logo deleted successfully."),
      condition: !!deleteLogoSuccess,
    },
  ]);

  return (
    <form
      style={{ marginBottom: "20px" }}
      onSubmit={formik.handleSubmit}
      className="user-info-form"
    >
      <Grid container spacing={3} rowSpacing={5}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <p>{t("Firstname")}</p>
          <MRTextField
            fullWidth
            className="text-field"
            id="firstname"
            name="firstname"
            placeholder={t("Name")}
            value={formik.values.firstname}
            onChange={formik.handleChange}
            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
            helperText={formik.touched.firstname && formik.errors.firstname}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <p>{t("Lastname")}</p>
          <MRTextField
            fullWidth
            className="text-field"
            id="lastname"
            name="lastname"
            placeholder={t("Lastname")}
            value={formik.values.lastname}
            onChange={formik.handleChange}
            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
            helperText={formik.touched.lastname && formik.errors.lastname}
          />
        </Grid>
        {(editer?.roles === ROLE.ADMIN || editer?.roles === ROLE.OPERATOR) && (
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <p>{t("Status")}</p>
            <MRTextField
              fullWidth
              select
              className="text-field"
              id="status"
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
            >
              <MenuItem value="invited">Invited</MenuItem>
              <MenuItem value="active">Active</MenuItem>
            </MRTextField>
          </Grid>
        )}
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <p>{t("Telephone")}</p>
          <MRTextField
            fullWidth
            className="text-field"
            id="phone"
            name="phone"
            placeholder={t("Telephone")}
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <p>{t("Country")}</p>
          <div>
            <Autocomplete
              disableClearable={true}
              value={formik.values.country}
              onChange={(e, value) => {
                formik.setFieldValue("country", countryWOFlag(value));
              }}
              options={jsonCountries.map(
                (country) => country.emoji + " " + country.name
              )}
              isOptionEqualToValue={(option: any, value: string) => {
                return countryWOFlag(option) === value;
              }}
              renderInput={(params: any) => (
                <MRTextField
                  {...params}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                />
              )}
            />
            <p style={{ margin: "0px" }}>
              {formik.touched.country && formik.errors.country && (
                <p style={{ color: "#f44336" }}>Country is required</p>
              )}
            </p>
          </div>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <p>{t("Company")}</p>
          <MRTextField
            fullWidth
            className="text-field"
            id="companyName"
            name="companyName"
            placeholder={t("Company")}
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <p>{t("Company Field")}</p>
          <MRTextField
            fullWidth
            className="text-field"
            id="companyField"
            name="companyField"
            placeholder={t("Field")}
            value={formik.values.companyField}
            onChange={formik.handleChange}
            error={
              formik.touched.companyField && Boolean(formik.errors.companyField)
            }
            helperText={
              formik.touched.companyField && formik.errors.companyField
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <p>{t("VAT")}</p>
          <MRTextField
            fullWidth
            className="text-field"
            id="vat"
            name="vat"
            placeholder={t("VAT")}
            value={formik.values.vat}
            onChange={formik.handleChange}
            error={formik.touched.vat && Boolean(formik.errors.vat)}
            helperText={formik.touched.vat && formik.errors.vat}
          />
        </Grid>
        {(editer?.roles === ROLE.ADMIN || editer?.roles === ROLE.OPERATOR) && (
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <p>{t("Plan")}</p>
            <RadioGroup
              aria-label="plan"
              value={formik.values.roles}
              onChange={formik.handleChange}
              className="plan-radio-group"
            >
              <FormControlLabel
                control={<Radio name="roles" value={ROLE.SELLER} />}
                label="Pro"
              />
              <FormControlLabel
                control={<Radio name="roles" value={ROLE.BUYER} />}
                label="Free"
              />
              <FormControlLabel
                control={<Radio name="roles" value={ROLE.OPERATOR} />}
                label="Operator"
              />
              <FormControlLabel
                control={<Radio name="roles" value={ROLE.ADMIN} />}
                label="Admin"
              />
            </RadioGroup>
          </Grid>
        )}
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <p>{t("Company logo")}</p>
          <input
            accept="image/*"
            style={{ display: "none" }}
            type="file"
            ref={companyLogo}
            onChange={uploadCompanyLogo}
          />

          <Stack justifyContent={"center"}>
            {isLoading ? (
              <Loader />
            ) : !user.profile?.companyLogoUrl ? (
              <MRButtonQuinary
                onClick={() => companyLogo?.current?.click()}
                sx={{
                  padding: "13px 30px",
                  margin: "auto",
                }}
              >
                {t("Upload")}
              </MRButtonQuinary>
            ) : (
              <Stack
                alignItems={"center"}
                position={"relative"}
                width={"fit-content"}
                margin={"auto"}
              >
                <img
                  style={{
                    height: "128px",
                    width: "128px",
                    objectFit: "contain",
                  }}
                  src={user?.profile?.companyLogoUrl}
                  alt={t("Company logo")}
                />
                <DeleteLogo
                  width={"20px"}
                  height={"20px"}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "-10px",
                    top: "-10px",
                  }}
                  onClick={handleDeleteCompanyLogo}
                />
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          {isAdmin() && formik.values.roles === "seller" && (
            <Stack mt={"10px"} height={"100%"}>
              <Stack direction={"row"} className="show-owner">
                <FormControlLabel
                  control={
                    <MRCheckbox checked={formik.values.downloadEnabled} />
                  }
                  label="Enable 3D Download"
                  name="downloadEnabled"
                  onChange={formik.handleChange}
                />
              </Stack>
            </Stack>
          )}
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Stack
            mt={"10px"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Zoom in={formik.dirty}>
              <MRButtonPrimary type="submit">
                {t("Confirm edit profile")}
              </MRButtonPrimary>
            </Zoom>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProfileForm;
