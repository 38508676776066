import { FC } from "react";
import { LinkStatus } from "types/link";
import { LinkStatusCardWrapper } from "./styles";

const AcquisitionStatusCard: FC<{ status: LinkStatus }> = ({ status }) => {
  const classNameByStatus = {
    [LinkStatus.ACTIVE]: "active-card",
    [LinkStatus.INACTIVE]: "inactive-card",
  };

  return (
    <LinkStatusCardWrapper>
      <div className={"card " + classNameByStatus[status]}>
        {(status.charAt(0).toUpperCase() + status.slice(1)).replace("_", " ")}
      </div>
    </LinkStatusCardWrapper>
  );
};

export default AcquisitionStatusCard;
