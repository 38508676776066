import styled from "@emotion/styled/macro";
import { colors } from "styles/theme";

export const BlockGridElemWrapper = styled.div`
  .block-grid-elem {
    width: 100%;
    min-width: 200px;
    padding: 20px;
  }

  .thumbnail-container {
    height: 180px;
    max-width: 100%;
    cursor: pointer;
    position: relative;

    img {
      height: 180px;
      width: 180px;
      object-fit: contain;
    }
  }

  .eye-off {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .flex-grow-elem {
    flex-grow: 1;
    overflow: hidden;
  }

  .selected {
    box-shadow: ${colors.green} 0px 0px 10px;
    border-radius: 10px;
    transition: box-shadow 0.1s ease-in-out;
  }

  /* Pre-render the bigger shadow, but hide it */
  /* .selected::after {
    box-shadow: ${colors.green} 0px 0px 10px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  } */
`;

export const BlockCardLabel = styled.span`
  color: #ffffffb3;
  font-size: 0.9em;
`;

export const BlockCardLabelList = styled.span`
  color: #ffffffb3;
  font-size: 0.8em;
`;

export const BlockCardText = styled.span`
  font-size: 1.05em;
  &.owner-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const BlockListElemWrapper = styled.span`
  .block-list-elem {
    padding: 20px 10px 10px 10px;
    font-size: 0.85em;
  }

  .block-list-elem .thumb-wrapper {
    width: 80px;
  }

  .block-list-elem .thumb-wrapper img {
    object-fit: contain;
  }
`;

export const SingleBlockWrapper = styled.div`
  h2 {
    opacity: 0.7;
  }

  button {
    min-width: 120px;

    img {
      width: 20px;
    }
  }

  .MuiGrid-item {
    text-align: center;

    p {
      opacity: 0.7;
    }
    span {
      font-size: 18px;
    }
  }
`;
