import { MenuItem, Select, Stack } from "@mui/material";
import { MRCard, MRTextField } from "components/material";
import { MRButtonPrimary } from "components/material/Button";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ContactUsPage } from "./styles";
import { ReactComponent as Logo } from "assets/icons/logo-white.svg";
import { useFormik } from "formik";
import {
  initialValuesAuth,
  initialValues,
  Inputs,
  validationSchema,
} from "./model";
import useAuth from "hooks/useAuth";

const ContactUs: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const formik = useFormik<Inputs>({
    enableReinitialize: true,
    initialValues: user ? initialValuesAuth(user) : initialValues,
    validationSchema,
    onSubmit: () => {},
  });

  return (
    <ContactUsPage>
      <MRCard className="card" sx={{ width: { xs: "100%", sm: "500px" } }}>
        <Stack alignItems="center">
          <Logo width="70px" height="70px" />
          <h3>{t("Contact us")}</h3>
          <p>{t("Please fill this form.")}</p>
          <Stack
            direction="column"
            gap={2}
            sx={{ width: "100%", padding: "10px" }}
          >
            {!user && (
              <MRTextField
                fullWidth
                id="from"
                name="from"
                placeholder={t("Your email")}
                value={formik.values.from}
                onChange={formik.handleChange}
                error={formik.touched.from && Boolean(formik.errors.from)}
                helperText={formik.touched.from && formik.errors.from}
              />
            )}
            <Select
              id="reason"
              name="reason"
              value={formik.values.reason}
              className="expire-select"
              onChange={formik.handleChange}
              error={formik.touched.reason && Boolean(formik.errors.reason)}
              placeholder={t("Reason")}
            >
              <MenuItem value={"general"}>{t("Select reason")}</MenuItem>
              <MenuItem value={"new-user"}>
                {t("Create PRO account on Marmoreal")}
              </MenuItem>
              <MenuItem value={"upgrade-user"}>
                {t("Upgrade my account to PRO")}
              </MenuItem>
              <MenuItem value={"general-info"}>
                {t("General information about Marmoreal")}
              </MenuItem>
              <MenuItem value={"report-malfunction"}>
                {t("Report issues")}
              </MenuItem>
            </Select>
            <MRTextField
              fullWidth
              id="text"
              name="text"
              placeholder={t("text")}
              multiline
              rows={4}
              value={formik.values.text}
              onChange={formik.handleChange}
              error={formik.touched.text && Boolean(formik.errors.text)}
              helperText={formik.touched.text && formik.errors.text}
            />
            <MRButtonPrimary type="submit">{t("Send")}</MRButtonPrimary>
          </Stack>
        </Stack>
      </MRCard>
    </ContactUsPage>
  );
};

export default ContactUs;
