import { FC, useMemo } from "react";
import { RowActionProps, RowAction } from "../RowActions/types";
import { useNavigate, generatePath } from "react-router-dom";
import RowActionsBase from "../RowActions/RowActionsBase";
import { edit, trash2 } from "assets/icons";
import useDeleteAcquisition from "hooks/services/acquisitions/useDeleteAcquisition";
import { useTranslation } from "react-i18next";

const AcquisitionAdminRowActions: FC<RowActionProps> = ({ id }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const {
    mutate: deleteAcquisitions,
    isLoading: isDeletingAcquisition,
    isSuccess: isDeletedAcquisition,
    error: deleteAcquisitionError,
  } = useDeleteAcquisition();

  const actions: RowAction[] = useMemo(
    () => [
      {
        icon: edit,
        action: () => navigate(generatePath("/models-to-process/:id", { id })),
      },
      {
        icon: trash2,
        action: () => deleteAcquisitions(id),

        confirmMessage: t("Are you sure you want to delete this acquisition?"),
        actionState: {
          isLoading: isDeletingAcquisition,
          isSuccess: isDeletedAcquisition,
          successMessage: "Acquisition deleted successfully",
          isError: !!deleteAcquisitionError,
          errorMessage: t(
            "An error occurred while deleting the acquisition, please try again"
          ),
        },
      },
    ],
    [
      navigate,
      generatePath,
      deleteAcquisitions,
      isDeletingAcquisition,
      isDeletedAcquisition,
      deleteAcquisitionError,
    ]
  );

  return <RowActionsBase variant="icons" actions={actions} />;
};

export default AcquisitionAdminRowActions;
