import { useState } from "react";

const useMultiSelect = () => {
  const [selected, setSelected] = useState<string[]>([]);

  const toggleSelection = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const toggleAll = (allItems: string[]) => {
    if (selected.length === allItems.length) {
      setSelected([]);
    } else {
      setSelected(allItems);
    }
  };

  const resetSelection = () => {
    setSelected([]);
  };

  return { selected, toggleSelection, toggleAll, resetSelection };
};

export default useMultiSelect;
