import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { Collection } from "types/collection";
import { generatePath } from "react-router-dom";

interface EditCollectionInfo {
  id: string;
  name: string;
  blocks?: string[];
}

export default function useEditCollection() {
  const queryClient = useQueryClient();

  const editCollection = (payload: EditCollectionInfo) => {
    const { id, ...data } = payload;
    const request: Request = {
      url: generatePath(ENDPOINTS.collections.one, { id }),
      config: {
        method: "PATCH",
        data,
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<
    Response<Collection>,
    Error,
    EditCollectionInfo
  >(editCollection, {
    onSettled: (data, error, payload) => {
      queryClient.invalidateQueries([ENDPOINTS.collections.one, payload.id]);
    },
  });

  return { data, mutateAsync, ...rest };
}
