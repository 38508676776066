import { FC, useState, useMemo } from "react";
import { LinkTableProps } from "./types";
import { Stack, Collapse } from "@mui/material";
import { MRPagination, MRDrawer } from "components/material";
import { useTranslation } from "react-i18next";
import useMultiSelect from "hooks/useMultiSelect";
import { PAGE_SIZE } from "config/pagination";
import TitleCounter from "components/TablesComponents/TitleCounter";
import LinkBulkActionsControl from "components/TablesComponents/Link/LinkBulkActionsControl";
import MRLinkTable from "components/TablesComponents/Link/LinkTable";
import useBlockLinks from "hooks/services/links/useBlockLinks";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import { Inputs as LinkFilters } from "components/Link/LinkFilterForm/model";
import LinkFilterForm from "components/Link/LinkFilterForm";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const LinkTable: FC<LinkTableProps> = ({ blockId }) => {
  const [page, setPage] = useState(1);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState<LinkFilters | null>(null);
  const [openLink, setOpenLink] = useState<boolean>(true);

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data: links,
    isLoading: isLoadingLinks,
    isError: linksError,
  } = useBlockLinks(blockId, page, PAGE_SIZE, {
    ...(formattedFilters && formattedFilters),
  });

  if (linksError) throw new Error("Cannot get shares");

  const { selected, toggleSelection, toggleAll, resetSelection } = useMultiSelect();
  const { t } = useTranslation();

  const toggleCollapse = () => {
    setOpenLink((prev) => !prev);
  };

  return (
    <>
      <MRDrawer anchor="right" open={filterDrawerOpen} onClose={toggleDrawer(false, setFilterDrawerOpen)}>
        <LinkFilterForm
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </MRDrawer>
      <Stack
        direction="row"
        gap={3}
        alignItems="center"
        justifyContent={"space-between"}
        flexWrap="wrap"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "5px", sm: 3 },
          height: { sm: "76px" },
        }}
      >
        <span style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
          <TitleCounter title={t("Links")} count={links?.pagination[0]?.total || 0} />

          <span onClick={toggleCollapse} style={{ cursor: "pointer" }}>
            {openLink ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </span>
        </span>

        <Collapse in={openLink}>
          <Stack direction="row" alignItems="center" gap="10px">
            <FilterButton
              onClick={() => setFilterDrawerOpen(true)}
              activeFilters={formattedFilters ? Object.keys(formattedFilters).length : 0}
            />
            <LinkBulkActionsControl selectedIds={selected} resetSelection={resetSelection} />
          </Stack>
        </Collapse>
      </Stack>
      <Collapse in={openLink}>
        <MRLinkTable
          links={links?.data || []}
          selected={selected}
          toggleSelection={toggleSelection}
          toggleAll={toggleAll}
        />
        <Stack direction="row" justifyContent="center">
          {links?.data && links.data.length ? (
            <MRPagination
              count={links ? Math.ceil(links?.pagination[0]?.total / PAGE_SIZE) || 1 : 1}
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          ) : (
            <NoDataRow>{t("No Data")}</NoDataRow>
          )}
        </Stack>
      </Collapse>
    </>
  );
};

export default LinkTable;
