import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { mobileBottomNavigation } from "components/Navs/Navigation/navs/mobile-bottom";
import { MobileNavigationWrapper } from "./styles";
import { MobileNavigationProps } from "./types";
import { match } from "helpers";
import useAuth from "hooks/useAuth";
import ROLE from "types/role";
import { mobileAdminNavigation } from "components/Navs/Navigation/navs/mobile-admin-bottom";
import { NavigationItem } from "components/Navs/Navigation/types";
import { useTranslation } from "react-i18next";
import NotificationMobileNavItem from "components/Notification/NotificationMobileNavItem";

const MobileNavigation: FC<MobileNavigationProps> = ({
  mobileOpen,
  handleDrawerToggle,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const navigation: NavigationItem[] = useMemo(
    () =>
      match(user?.roles)
        .on(
          (r: ROLE) => r === ROLE.ADMIN || r === ROLE.OPERATOR,
          () => mobileAdminNavigation
        )
        .on(
          (r: ROLE) => r === ROLE.SELLER || r === ROLE.BUYER,
          () => mobileBottomNavigation
        )
        .otherwise(() => []),
    [user?.roles, mobileAdminNavigation, mobileBottomNavigation]
  );

  return (
    <MobileNavigationWrapper>
      <Paper
        sx={{
          position: "fixed",
          zIndex: 999,
          bottom: 0,
          left: 0,
          right: 0,
          display: { sx: "block", sm: "none" },
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          {navigation.map((nav, idx) => (
            <BottomNavigationAction
              label={t(nav.label)}
              icon={nav.icon}
              onClick={() => navigate(nav.path)}
              key={idx}
              sx={{ minWidth: "unset" }}
            />
          ))}
          <NotificationMobileNavItem />
        </BottomNavigation>
      </Paper>
    </MobileNavigationWrapper>
  );
};

export default MobileNavigation;
