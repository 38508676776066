import { Avatar, Badge, Stack } from "@mui/material";
import { MRButtonquaternary } from "components/material/Button";
import { displayName, stringAvatar } from "helpers";
import useAuth from "hooks/useAuth";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PATH from "routing/path";
import { colors } from "styles/theme";
import { InfoChip, ProfilePage } from "./styles";
import { usersPlain } from "assets/icons";
import UserCredits from "components/User/Credits";
import { mail } from "assets/icons";
import HorizontalTrashedBlocks from "components/Block/HorizontalTrashedBlocks";
import Page from "components/Layout/Page";
import UserTransferTable from "components/Transfer/UserTransferTable";
import UserShareTable from "components/Share/UserShareTable";
import UserStorage from "components/User/Storage";
import UserBandwidth from "components/User/Bandwidth";
import ROLE from "types/role";
import LogoutIcon from "@mui/icons-material/Logout";
import camera from "assets/icons/camera.png";
import Loading_icon from "assets/icons/Loading_icon.gif";
import useSetPropic from "hooks/services/users/useSetPropic";
import EditProfile from "./EditProfile";
import toast from "react-hot-toast";

const Profile: FC = () => {
  const { user, logout, me, isLoading: isLoadingMe } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const propicInput = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    mutateAsync: editPropic,
    isLoading: editPropicLoading,
    isSuccess: editPropicSuccess,
  } = useSetPropic();

  const uploadPropic = async (e: any) => {
    const maxSize = 1024 * 1024; // Maximum file size in bytes (1MB)

    if (e.target.files[0]) {
      if (e.target.files[0].size > maxSize) {
        e.target.value = "";
        toast(t("ERROR: Image is too big!"));
        return;
      }

      const uploadedFile = e.target.files[0];
      const formdata = createFormData(uploadedFile);
      setIsLoading(true);
      editPropic(formdata).then(() => {
        me();
        setIsLoading(false);
      });
    }
  };

  function createFormData(uploadedFile: File) {
    let formdata = new FormData();
    formdata.set("propic", uploadedFile!);
    return formdata;
  }

  return (
    <Page>
      <ProfilePage>
        <Stack direction="column" spacing={8}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{ flexWrap: { xs: "wrap" }, gap: { xs: 5, sm: 3 } }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"center"}
              gap={5}
            >
              <input
                accept="image/*"
                style={{ display: "none" }}
                ref={propicInput}
                type="file"
                onChange={uploadPropic}
              />
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <Avatar
                    onClick={() => propicInput?.current?.click()}
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: colors.white,
                      cursor: "pointer",
                    }}
                    alt="Edit propic"
                    src={camera}
                  />
                }
              >
                <Avatar
                  src={isLoading ? Loading_icon : user?.profile?.propicUrl}
                  sx={{
                    bgcolor: colors.green,
                    width: 130,
                    height: 130,
                    fontSize: 48,
                    fontWeight: 600,
                    color: colors.white,
                  }}
                >
                  {user?.profile && stringAvatar(displayName(user))}
                </Avatar>
              </Badge>
              <Stack direction="column" className="name-container">
                <h1>{user && displayName(user)} </h1>
                <MRButtonquaternary
                  className="contacts-button"
                  onClick={() => navigate(PATH.CONTACTS)}
                >
                  <img src={usersPlain} alt="Users" /> {t("Your contacts")}
                </MRButtonquaternary>
              </Stack>
            </Stack>

            <Stack
              gap={4}
              sx={{
                justifyContent: {
                  xs:
                    user && user.roles !== ROLE.SELLER
                      ? "center"
                      : "space-between",
                },
                flexDirection: { xs: "row", md: "column" },
                alignItems: { xs: "center", md: "flex-end" },
              }}
              flexWrap="wrap"
            >
              {user && user.roles === ROLE.SELLER && (
                <Stack
                  gap={4}
                  sx={{
                    width: { xs: "100%" },
                    justifyContent: { xs: "space-between" },
                    flexDirection: { xs: "row", md: "row" },
                    alignItems: { xs: "center", md: "flex-end" },
                  }}
                >
                  <UserCredits />
                  <UserStorage />
                  <UserBandwidth />
                </Stack>
              )}

              <Stack
                direction="row"
                gap={2}
                sx={{
                  width: { xs: "100%" },
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "end",
                    lg: "end",
                  },
                }}
              >
                <InfoChip>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <img src={mail} alt="Mail" /> {user?.username}
                  </Stack>
                </InfoChip>
                <InfoChip
                  style={{ cursor: "pointer" }}
                  onClick={() => logout()}
                >
                  <LogoutIcon sx={{ fontSize: "16px", color: colors.green }} />
                </InfoChip>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="column" gap={4}>
            {user && <EditProfile />}
            {user && user.roles !== ROLE.BUYER && <HorizontalTrashedBlocks />}
            {user && user.roles !== ROLE.BUYER && (
              <UserTransferTable user={user} />
            )}
            {user && (
              <UserShareTable userRole={ROLE.SELLER} ownerId={user._id} />
            )}
          </Stack>
        </Stack>
      </ProfilePage>
    </Page>
  );
};

export default Profile;
