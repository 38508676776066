import { FC, useMemo } from "react";
import { RowActionProps, RowAction } from "../RowActions/types";
import RowActionsBase from "../RowActions/RowActionsBase";
import { trash2 } from "assets/icons";
import useRemoveShare from "hooks/services/shares/useRemoveShare";
import { useTranslation } from "react-i18next";

const UserShareRowActions: FC<RowActionProps> = ({ id }) => {
  const {
    mutate: removeShare,
    isLoading: isRemovingShare,
    isSuccess: isRemovedShare,
    error: removeShareError,
  } = useRemoveShare();

  const { t } = useTranslation();

  const actions: RowAction[] = useMemo(
    () => [
      {
        icon: trash2,
        action: () => removeShare(id),

        confirmMessage: t("Are you sure you want to delete this share?"),
        actionState: {
          isLoading: isRemovingShare,
          isSuccess: isRemovedShare,
          successMessage: t("Share deleted successfully"),
          isError: !!removeShareError,
          errorMessage: t(
            "An error occurred while deleting the share, please try again"
          ),
        },
      },
    ],
    [removeShare, isRemovingShare, isRemovedShare, removeShareError]
  );

  return <RowActionsBase variant="icons" actions={actions} />;
};

export default UserShareRowActions;
