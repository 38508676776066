import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery } from "react-query";
import { Request } from "types/request";
import { PaginatedResponse } from "types/response";
import { Contact } from "types/user";
import Error from "types/error";

export default function useContacts(
  page?: number,
  size?: number,
  search?: Record<string, string>
) {
  const request: Request = {
    url: ENDPOINTS.users.contacts,
    config: {
      method: "GET",
      params: { page, size, ...search },
    },
  };

  const requestConfig = {
    queryKey: [
      ENDPOINTS.users.contacts,
      page,
      size,
      search && JSON.stringify(search),
    ],
    queryFn: () => client(request).then((res) => res.data),
  };

  const { data, ...rest } = useQuery<PaginatedResponse<Contact[]>, Error>(
    requestConfig
  );

  return { data, ...rest };
}
