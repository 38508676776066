import { FC, useState } from "react";
import { TabContext } from "@mui/lab";
import TabPanel from "components/material/TabPanel";
import { Stack, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MRTabList, MRCard } from "components/material";
import useAuth from "hooks/useAuth";
import { SellerCataloguesWrapper } from "./styles";
import Page from "components/Layout/Page";
import MyCatalogues from "./MyCatalogues";
import SharedCatalogues from "./SharedCatalogues";
import ROLE from "types/role";
import Locked from "components/Locked";
import { useSearchParams } from "react-router-dom";
import PageHeader from "components/Layout/PageHeader";

const SellerCatalogues: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const [activeTab] = useState<string | null | undefined>(
    searchParams.get("activeTab")
  );

  const [tab, setTab] = useState<string>(
    activeTab ??
      (user && user.roles === ROLE.BUYER
        ? "shared_catalogues"
        : "my_catalogues")
  );

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };
  return (
    <Page>
      <SellerCataloguesWrapper>
        <PageHeader>
          <h1>{t("Catalogues")}</h1>
        </PageHeader>
        <MRCard sx={{ padding: "20px" }}>
          <TabContext value={tab}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <MRTabList
                /*value={tab} */
                onChange={handleTabChange}
                className="blocks-tabs"
              >
                <Tab label={t("Catalogues")} value="my_catalogues" />
                <Tab
                  label={t("Catalogues shared with me")}
                  value="shared_catalogues"
                />
              </MRTabList>
            </Stack>
            <TabPanel
              active={tab}
              sx={{ padding: "24px 0px" }}
              value="my_catalogues"
            >
              {user && user.roles !== ROLE.BUYER && <MyCatalogues />}
              {user && user.roles === ROLE.BUYER && (
                <Locked
                  description={t(
                    "You will be able to create catalogues and share them with your clients and partners"
                  )}
                  card={false}
                />
              )}
            </TabPanel>
            <TabPanel active={tab} value="shared_catalogues">
              <SharedCatalogues />
            </TabPanel>
          </TabContext>
        </MRCard>
      </SellerCataloguesWrapper>
    </Page>
  );
};

export default SellerCatalogues;
