import { FC, useMemo } from "react";
import { RowActionProps, RowAction } from "../RowActions/types";
import { useNavigate, generatePath } from "react-router-dom";
import useDeleteBlock from "hooks/services/blocks/useDeleteBlock";
import RowActionsBase from "../RowActions/RowActionsBase";
import { edit, trash2 } from "assets/icons";
import { useTranslation } from "react-i18next";

const BlockRowActions: FC<RowActionProps> = ({ id }) => {
  const navigate = useNavigate();

  const {
    mutate: deleteBlock,
    isLoading: isDeletingBlock,
    isSuccess: isDeletedBlock,
    error: deleteBlockError,
  } = useDeleteBlock();

  const { t } = useTranslation();

  const actions: RowAction[] = useMemo(
    () => [
      {
        icon: edit,
        action: () => navigate(generatePath("/processed-models/:id", { id })),
      },
      {
        icon: trash2,
        action: () => deleteBlock(id),

        confirmMessage: t("Are you sure you want to delete this block?"),
        actionState: {
          isLoading: isDeletingBlock,
          isSuccess: isDeletedBlock,
          successMessage: t("Block deleted successfully"),
          isError: !!deleteBlockError,
          errorMessage: t(
            "An error occurred while deleting the block, please try again"
          ),
        },
      },
    ],
    [
      navigate,
      generatePath,
      deleteBlock,
      isDeletingBlock,
      isDeletedBlock,
      deleteBlockError,
    ]
  );

  return <RowActionsBase variant="icons" actions={actions} />;
};

export default BlockRowActions;
