import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery } from "react-query";
import { Request } from "types/request";
import { PaginatedResponse } from "types/response";
import { User } from "types/user";
import Error from "types/error";

export default function useUsers(
  page?: number,
  size?: number,
  orderBy?: string,
  order?: "asc" | "desc",
  search?: Record<string, string>
) {
  const request: Request = {
    url: ENDPOINTS.users.all,
    config: {
      method: "GET",
      params: { page, size, order, sort: orderBy, ...search },
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.users.all, page, size, order, orderBy, search && JSON.stringify(search)],
    queryFn: () => client(request).then((res) => res.data),
  };

  const { data, ...rest } = useQuery<
    PaginatedResponse<(User & { activeBlocks: number; totalBlocks: number })[]>,
    Error
  >(requestConfig);

  return { data, ...rest };
}
