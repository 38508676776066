import { Dialog } from "@mui/material";
import styled from "styled-components";
import { colors } from "styles/theme";

export const ShareModalWrapper = styled(Dialog)`
  .close-button-container {
    width: 50px;
    height: 50px;
    border: solid 1px;
    border-radius: 30px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    float: right;
    background: ${colors.lightBlack};
  }

  .close-button-container Button {
    font-size: 20px;
    color: ${colors.green};
  }

  .forwardable-container {
    margin: 10px auto;
    font-size: 14px;
  }

  h2 {
    margin-top: 0px;
  }

  .dialog {
    background: #0e131f;
    border: solid 1px;
    border-radius: 20px;
    padding: 26px 32px 30px;
  }

  .contacts-dialog {
    margin-bottom: 25px;
  }

  .send-share-button {
    width: 100%;
  }

  .share-link {
    color: ${colors.green};
    margin-top: 0px;
  }

  .link-settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .expire-select {
    color: ${colors.green};
    font-weight: 600;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .expire-select svg {
    color: ${colors.green};
  }

  .hidden {
    visibility: hidden;
  }

  @media screen and (max-width: 600px) {
    .share-modal-paper {
      padding: 0px;
      margin: 3px;
    }

    .dialog {
      padding: 13px 16px 22px;
    }

    .link-expiry,
    .show-owner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
`;
