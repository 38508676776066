import { Grid, IconButton, Menu, Stack, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CollectionWrapper } from "./styles";
import { CollectionProps } from "./types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate, generatePath, Link } from "react-router-dom";
import ShareModal from "components/Share/ShareModal";
import useDeleteCollection from "hooks/services/collections/useDeleteCollection";
import { useConfirmModal } from "context/confirmModal";
import { colors } from "styles/theme";
import PATH from "routing/path";
import defaultThumbnail from "assets/images/default-thumb.png";
import ThumbDisplayer from "components/ThumbDisplayer";
import useToasts from "hooks/useToasts";

const Collection: FC<CollectionProps> = ({
  collection,
  variant,
  forwardable = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [forwardModalOpen, setForwardModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    mutate: deleteCollection,
    isLoading: isDeletingCollection,
    isSuccess: isDeletedCollection,
    error: deleteCollectionError,
  } = useDeleteCollection();

  const openShareModal = () => {
    setShareModalOpen(true);
  };

  const openForwardModal = () => {
    setForwardModalOpen(true);
  };

  const navigateToCollection = () => {
    navigate(generatePath("/catalogues/:id", { id: collection._id }));
  };

  const moreOptions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { openModal } = useConfirmModal();

  const askConfirm = () => {
    openModal({
      title: t("Are you sure to delete this collection?"),
      text: t("The operation is not reversible"),
      confirmFunction: () => deleteCollection(collection._id),
    });
  };

  useToasts([
    {
      severity: "error",
      condition: !!deleteCollectionError,
      message: t(
        "An error occured while deleting the collection, please try again later"
      ),
    },
    {
      severity: "success",
      condition: isDeletedCollection,
      message: t("Collection deleted successfully"),
    },
  ]);

  return (
    <CollectionWrapper>
      <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
        <div className="more-options-button-container">
          <IconButton onClick={moreOptions}>
            <MoreHorizIcon
              fontSize="large"
              className={`${variant + "-"}more-horizon-icon`}
              sx={{ cursor: "pointer" }}
            />
          </IconButton>
        </div>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={
            variant === "owned"
              ? {
                  className: "more-options-menu",
                  style: {
                    backgroundColor: colors.green,
                    color: colors.black,
                    borderRadius: "10px",
                    border: "1px solid #FFFFFF",
                  },
                }
              : {
                  className: "more-options-menu",
                  style: {
                    backgroundColor: colors.primary,
                    color: colors.black,
                    borderRadius: "10px",
                    border: "1px solid #FFFFFF",
                  },
                }
          }
        >
          {variant === "owned" && (
            <MenuItem onClick={openShareModal}>{t("Share catalogue")}</MenuItem>
          )}
          {forwardable && (
            <MenuItem onClick={openForwardModal}>
              {t("Share catalogue")}
            </MenuItem>
          )}
          <MenuItem onClick={navigateToCollection}>
            {t("View catalogue")}
          </MenuItem>
          {variant === "owned" && (
            <MenuItem onClick={askConfirm}>{t("Delete catalogue")}</MenuItem>
          )}
        </Menu>
      </Stack>
      <Stack direction="row" className={`${variant + "-"}folder folder`}>
        {collection.blocks.length > 0 ? (
          <Stack direction="column" justifyContent="flex-end">
            <Link
              style={{ cursor: "pointer" }}
              to={`${PATH.CATALOGUES}/${collection._id}`}
            >
              <Grid container className="collection-blocks-thumbs">
                <Grid
                  item
                  xs={4}
                  sx={{ textAlign: "center" }}
                  pt={1}
                  className="folder-col"
                >
                  {collection.blocks.length}
                </Grid>
                {collection.blocks.slice(0, 5).map((block) => (
                  <Grid
                    item
                    xs={4}
                    key={block._id}
                    className="thumbanil-container"
                  >
                    <ThumbDisplayer
                      imgProps={{
                        src: block.model?.thumbnail || defaultThumbnail,
                        alt: block.title,
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Link>
          </Stack>
        ) : (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Stack
              direction="column"
              justifyContent="center"
              sx={{ marginTop: "20px", cursor: "pointer" }}
              onClick={() => navigate(`${PATH.CATALOGUES}/${collection._id}`)}
            >
              {t("Empty")}
            </Stack>
          </Stack>
        )}
      </Stack>
      <Stack direction="column" mt={2} ml={2}>
        <span className="collection-name">{collection.name}</span>
      </Stack>
      <ShareModal
        open={shareModalOpen}
        setOpen={setShareModalOpen}
        collectionId={collection._id}
      />
      <ShareModal
        open={forwardModalOpen}
        setOpen={setForwardModalOpen}
        collectionId={collection._id}
        forward={true}
      />
    </CollectionWrapper>
  );
};

export default Collection;
