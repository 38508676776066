export type Notification = NotificationBase &
  (
    | {
        type: NotificationType.FRIEND_REQ_ACC;
        data: WUserDetail;
      }
    | {
        type: NotificationType.FRIEND_REQ_REC;
        data: WUserDetail;
      }
    | {
        type: NotificationType.ACQ_PHOTOS_UPL;
        data: WUserDetail & WAcqDetail;
      }
    | {
        type: NotificationType.MODEL_UPL;
        data: WModelDetail;
      }
    | {
        type: NotificationType.SHARE_REC;
        data: WShareDetail & WUserDetail;
      }
    | {
        type: NotificationType.SHARE_RMV;
        data: WShareDetail & WUserDetail;
      }
    | {
        type: NotificationType.TRANSFER_ACCEPTED;
        data: WTransferDetail & WUserDetail;
      }
    | {
        type: NotificationType.TRANSFER_RECEIVED;
        data: WTransferDetail & WUserDetail;
      }
    | {
        type: NotificationType.ALERT_BENDWIDTH;
        data: WBandwidthDetail;
      }
  );

interface NotificationBase {
  _id?: string;
  recipient: string;
  readed?: boolean;
  createdAt: string;
}

interface WUserDetail {
  userEmail: string;
  userFullname: string;
}

interface WAcqDetail {
  acquisitionTitle: string;
  acquisitionId: string;
}

interface WModelDetail {
  modelTitle: string;
  modelId: string;
}

interface WShareDetail {
  shareId: string;
  collectionId?: string;
  blockId?: string;
  title?: string;
}

interface WTransferDetail {
  transferId: string;
}

interface WBandwidthDetail {
  bandwidth: number;
}

export enum NotificationType {
  FRIEND_REQ_REC = "friend-request-received",
  FRIEND_REQ_ACC = "friend-request-accepted",
  ACQ_PHOTOS_UPL = "acquisition-photos-uploaded",
  MODEL_UPL = "model-uploaded",
  SHARE_REC = "share_received",
  SHARE_RMV = "share_removed",
  TRANSFER_ACCEPTED = "transfer-accepted",
  TRANSFER_RECEIVED = "transfer-received",
  ALERT_BENDWIDTH = "alert-bandwidth",
}
