import Drawer from "@mui/material/Drawer";
import Sidenav from "components/Navs/Sidenav";
import { FC, useState } from "react";
import { colors } from "styles/theme";
import { MobileDrawerProps } from "./types";

const MobileDrawer: FC<MobileDrawerProps> = ({ mobileOpen, handleDrawerToggle }) => {
  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Drawer
      // Mobile drawer
      container={container}
      variant="temporary"
      anchor="right"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": { boxSizing: "border-box" },
        background: colors.lightBlack,
      }}
    >
      <Sidenav open={true} />
    </Drawer>
  );
};

export default MobileDrawer;
