import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { Contact } from "types/user";
import { generatePath } from "react-router-dom";

interface AcceptFriendshipRequestInfo {
  requestId: string;
}

export default function useAcceptFriendshipRequest() {
  const queryClient = useQueryClient();

  const acceptFriendshipRequest = (data: AcceptFriendshipRequestInfo) => {
    const { requestId } = data;
    const request: Request = {
      url: generatePath(ENDPOINTS.friendshipRequest.accept, { id: requestId }),
      config: {
        method: "patch",
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<Contact>,
    Error,
    AcceptFriendshipRequestInfo
  >(acceptFriendshipRequest, {
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.friendshipRequest.received]);
    },
  });

  return { data, mutate, ...rest };
}
