import { ENDPOINTS } from "config/endpoints";
import client from "utils/client";
import { useQuery } from "react-query";
import { Request } from "types/request";
import { Response } from "types/response";
import Error from "types/error";
import { Collection } from "types/collection";
import { generatePath } from "react-router-dom";

export default function useCollection(id: string) {
  const request: Request = {
    url: generatePath(ENDPOINTS.collections.one, { id }),
    config: {
      method: "GET",
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.collections.one, id],
    queryFn: () => client(request),
    enabled: !!id,
  };

  const { data, ...rest } = useQuery<Response<Collection>, Error>(
    requestConfig
  );

  return { data, ...rest };
}
