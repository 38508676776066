import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";

export default function useDirectTransfer() {
  const queryClient = useQueryClient();

  const directTransfer = ({
    blockId,
    recipient,
    keepShares = false,
  }: {
    blockId: string;
    recipient: string;
    keepShares?: boolean;
  }) => {
    const request: Request = {
      url: generatePath(ENDPOINTS.transfers.direct, { blockId }),
      config: {
        method: "POST",
        data: { recipient, keepShares },
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<
    Response<null>,
    Error,
    { blockId: string; recipient: string; keepShares?: boolean }
  >(directTransfer, {
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.block.one, variables.blockId]);
    },
  });

  return { data, mutateAsync, ...rest };
}
