import { IconButton, Stack } from "@mui/material";
import { FC } from "react";
import { AcquisitionFilterFormWrapper } from "./styles";
import { Inputs, initialValues, validationSchema } from "./model";
import { useFormik } from "formik";
import { MRCheckbox, MRTextField } from "components/material";
import { useTranslation } from "react-i18next";
import { AcquisitionStatus } from "types/acquisition";
import { MRButtonPrimary, MRButtonSecondary } from "components/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { AcquisitionFilterFromProps } from "./types";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const AcquisitionFilterForm: FC<AcquisitionFilterFromProps> = ({
  onApplyFilters,
  onClearFilters,
  onClose,
  appliedFilters,
}) => {
  const { t } = useTranslation();
  const formik = useFormik<Inputs>({
    enableReinitialize: true,
    initialValues: appliedFilters ? { ...initialValues, ...appliedFilters } : initialValues,
    validationSchema,
    onSubmit: (acquisitionFilterInfo) => {
      onApplyFilters(acquisitionFilterInfo);
    },
  });
  return (
    <AcquisitionFilterFormWrapper>
      <Stack height="100%" alignItems="start" justifyContent="center">
        <form className="filter-form" onSubmit={formik.handleSubmit}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <IconButton onClick={(e) => onClose()}>
              <ClearIcon />
            </IconButton>
            <MRButtonSecondary
              onClick={() => {
                onClearFilters();
                formik.resetForm();
              }}
            >
              {t("Clear Filters")}
            </MRButtonSecondary>
          </Stack>
          <p>{t("Title")} </p>
          <MRTextField
            fullWidth
            id="title"
            name="title"
            placeholder={t("Title")}
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
          <p>{t("Material")} </p>
          <MRTextField
            fullWidth
            id="material"
            name="material"
            placeholder={t("Material")}
            value={formik.values.material}
            onChange={formik.handleChange}
            error={formik.touched.material && Boolean(formik.errors.material)}
            helperText={formik.touched.material && formik.errors.material}
          />
          <p>{t("Place of Acqisition")} </p>
          <MRTextField
            fullWidth
            id="placeOfAcquisition"
            name="placeOfAcquisition"
            placeholder={t("Place of Acqisition")}
            value={formik.values.placeOfAcquisition}
            onChange={formik.handleChange}
            error={formik.touched.placeOfAcquisition && Boolean(formik.errors.placeOfAcquisition)}
            helperText={formik.touched.placeOfAcquisition && formik.errors.placeOfAcquisition}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <p>{t("From date")} </p>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={formik.values.startDate}
              onChange={(value) => formik.setFieldValue("startDate", value)}
              renderInput={(params) => (
                <MRTextField
                  error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                  helperText={formik.touched.startDate && Boolean(formik.errors.startDate)}
                  name="startDate"
                  fullWidth
                  {...params}
                />
              )}
            />

            <p>{t("To date")} </p>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={formik.values.endDate}
              onChange={(value) => formik.setFieldValue("endDate", value)}
              renderInput={(params) => (
                <MRTextField
                  error={formik.touched.startDate && Boolean(formik.errors.endDate)}
                  helperText={formik.touched.endDate && Boolean(formik.errors.endDate)}
                  name="endDate"
                  fullWidth
                  {...params}
                />
              )}
            />
          </LocalizationProvider>

          <p>{t("Status")} </p>
          <Stack direction="row" gap="5px" flexWrap="wrap">
            <Stack direction="row" alignItems="center">
              <MRCheckbox
                checked={formik.values.status.includes(AcquisitionStatus.IDLE)}
                onChange={() => {
                  if (!formik.values.status.includes(AcquisitionStatus.IDLE)) {
                    formik.setFieldValue("status", [...formik.values.status, AcquisitionStatus.IDLE]);
                    return;
                  }
                  formik.setFieldValue(
                    "status",
                    formik.values.status.filter((s) => s !== AcquisitionStatus.IDLE)
                  );
                }}
              />
              <p>{t("Idle")}</p>
            </Stack>
            <Stack direction="row" alignItems="center">
              <MRCheckbox
                checked={formik.values.status.includes(AcquisitionStatus.PENDING_PROCESSING)}
                onChange={() => {
                  if (!formik.values.status.includes(AcquisitionStatus.PENDING_PROCESSING)) {
                    formik.setFieldValue("status", [...formik.values.status, AcquisitionStatus.PENDING_PROCESSING]);
                    return;
                  }
                  formik.setFieldValue(
                    "status",
                    formik.values.status.filter((s) => s !== AcquisitionStatus.PENDING_PROCESSING)
                  );
                }}
              />
              <p>{t("Pending Processing")}</p>
            </Stack>
            <Stack direction="row" alignItems="center">
              <MRCheckbox
                checked={formik.values.status.includes(AcquisitionStatus.PROCESSING)}
                onChange={() => {
                  if (!formik.values.status.includes(AcquisitionStatus.PROCESSING)) {
                    formik.setFieldValue("status", [...formik.values.status, AcquisitionStatus.PROCESSING]);
                    return;
                  }
                  formik.setFieldValue(
                    "status",
                    formik.values.status.filter((s) => s !== AcquisitionStatus.PROCESSING)
                  );
                }}
              />
              <p>{t("Processing")}</p>
            </Stack>
            <Stack direction="row" alignItems="center">
              <MRCheckbox
                checked={formik.values.status.includes(AcquisitionStatus.PROCESSED)}
                onChange={() => {
                  if (!formik.values.status.includes(AcquisitionStatus.PROCESSED)) {
                    formik.setFieldValue("status", [...formik.values.status, AcquisitionStatus.PROCESSED]);
                    return;
                  }
                  formik.setFieldValue(
                    "status",
                    formik.values.status.filter((s) => s !== AcquisitionStatus.PROCESSED)
                  );
                }}
              />
              <p>{t("Processed")}</p>
            </Stack>
            <Stack direction="row" alignItems="center">
              <MRCheckbox
                checked={formik.values.status.includes(AcquisitionStatus.REMOVED)}
                onChange={() => {
                  if (!formik.values.status.includes(AcquisitionStatus.REMOVED)) {
                    formik.setFieldValue("status", [...formik.values.status, AcquisitionStatus.REMOVED]);
                    return;
                  }
                  formik.setFieldValue(
                    "status",
                    formik.values.status.filter((s) => s !== AcquisitionStatus.REMOVED)
                  );
                }}
              />
              <p>{t("Removed")}</p>
            </Stack>
            <Stack direction="row" alignItems="center">
              <MRCheckbox
                checked={formik.values.status.includes(AcquisitionStatus.SOLD)}
                onChange={() => {
                  if (!formik.values.status.includes(AcquisitionStatus.SOLD)) {
                    formik.setFieldValue("status", [...formik.values.status, AcquisitionStatus.SOLD]);
                    return;
                  }
                  formik.setFieldValue(
                    "status",
                    formik.values.status.filter((s) => s !== AcquisitionStatus.SOLD)
                  );
                }}
              />
              <p>{t("Sold")}</p>
            </Stack>
          </Stack>

          <Stack marginTop="15px" direction="row" justifyContent="center" alignItems="center">
            <MRButtonPrimary type="submit">{t("Apply")}</MRButtonPrimary>
          </Stack>
        </form>
      </Stack>
    </AcquisitionFilterFormWrapper>
  );
};

export default AcquisitionFilterForm;
