import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";
import { colors } from "styles/theme";

export const MRButtonPrimary = styled(LoadingButton)`
  white-space: nowrap;
  background-color: ${colors.blue} !important;
  color: ${(props) => {
    return !props.loading ? `${colors.white} !important` : "unset";
  }};
`;

export const MRButtonSecondary = styled(LoadingButton)`
  white-space: nowrap;
  border: solid 0.5px ${colors.blue}!important;
  color: ${(props) => {
    return !props.loading ? `${colors.white} !important` : "unset";
  }};
  background-color: ${colors.black}!important;
`;

export const MRButtonTertiary = styled(LoadingButton)`
  white-space: nowrap;
  background-color: ${colors.blueVariant} !important;
  color: ${(props) => {
    return !props.loading ? `${colors.green} !important` : "unset";
  }};

  .MuiLoadingButton-loadingIndicator {
    color: ${colors.green};
  }
`;

export const MRButtonquaternary = styled(LoadingButton)`
  white-space: nowrap;
  background-color: ${colors.green} !important;
  color: ${(props) => {
    return !props.loading ? `${colors.black} !important` : "unset";
  }};

  .MuiLoadingButton-loadingIndicator {
    color: ${colors.black};
  }
`;

export const MRButtonQuinary = styled(LoadingButton)`
  white-space: nowrap;
  border: solid 0.5px ${colors.green}!important;
  color: ${(props) => {
    return !props.loading ? `${colors.white} !important` : "unset";
  }};
  background-color: ${colors.black}!important;
`;

/** ACTION BUTTON */

interface ActionButtonProps {
  onClick: () => void;
  icon?: string;
  children: React.ReactNode;
}

export const ActionButtonContained: FC<ActionButtonProps> = ({ onClick, icon, children }) => {
  return (
    <MRButtonquaternary onClick={onClick} sx={{ color: "#000" }}>
      <Stack direction="row" alignItems="center" gap={1}>
        {icon && <img src={icon} />}
        {children}
      </Stack>
    </MRButtonquaternary>
  );
};

export const ActionButtonOutlined: FC<ActionButtonProps> = ({ onClick, icon, children }) => {
  return (
    <MRButtonSecondary sx={{ border: `solid 0.5px ${colors.green}!important` }} onClick={onClick}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
        {icon && <img src={icon} />}
        {children}
      </Stack>
    </MRButtonSecondary>
  );
};
