import { colors } from "styles/theme";
import styled from "styled-components";

export const UserFilterFormWrapper = styled.div`
  max-width: 100%;
  background-color: ${colors.lightBlack};
  height: 100%;

  .filter-form {
    width: 100%;
    padding: 20px;
  }
`;
