import { Badge, BottomNavigationAction, Popover } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { PAGE_SIZE } from "config/pagination";
import useInfiniteNotifications from "hooks/services/notifications/useInfiniteNotifications";
import { flattenPages } from "helpers";
import React from "react";
import NotificationItem from "./NotificationItem";
import Loader from "components/Loader";
import NoNotification from "./NoNotification";
import useReadNotifications from "hooks/services/notifications/useReadNotifications";

const NotificationMobileNavItem: FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const {
    data: notifications,
    isLoading: isLoadingNofications,
    error: notificationsError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteNotifications(PAGE_SIZE);

  if (notificationsError) throw new Error("Cannot get notification");

  const handleScroll = (e: any) => {
    if (
      e.target.clientHeight + e.target.scrollTop === e.target.scrollHeight &&
      hasNextPage
    ) {
      fetchNextPage();
    }
  };

  const { mutate: readNotifications } = useReadNotifications();

  useEffect(() => {
    if (!open) return;
    const notificationIds = flattenPages(notifications)
      .filter((notification) => !notification.readed)
      .map((n) => n._id as string);
    if (!notificationIds.length) return;
    readNotifications({ notifications: notificationIds });
  }, [open, notifications]);

  return (
    <>
      <Badge
        badgeContent={notifications?.pages[0]?.notificationData[0]?.unread || 0}
        color={"primary"}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={"mobile-notification-badge"}
      >
        <BottomNavigationAction
          showLabel={true}
          label={t("Notifications")}
          icon={<NotificationsActiveIcon />}
          onClick={handleClick}
        />
      </Badge>

      <Popover
        sx={{ display: { xs: "block", sm: "none" } }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            height: "88%",
            width: "100%",
            boxShadow: "0px 7px 29px 0px #2db5c718",
          },
          onScroll: (e) => handleScroll(e),
        }}
      >
        {flattenPages(notifications).map((n) => {
          return (
            <React.Fragment key={n._id}>
              <NotificationItem
                notification={n}
                handleClose={handleClose}
                width="100%"
              />
            </React.Fragment>
          );
        })}
        {isLoadingNofications && <Loader />}
        {notifications && flattenPages(notifications).length === 0 && (
          <NoNotification />
        )}
      </Popover>
    </>
  );
};
export default NotificationMobileNavItem;
