import { FC, useState } from "react";
import { Dialog } from "components/material/Dialog";
import { CreateDirectTransferModalProps } from "./types";
import { useTranslation } from "react-i18next";
import { IconButton, Stack } from "@mui/material";
import { Avatar, InputAdornment } from "@mui/material";
import { MRButtonquaternary } from "components/material/Button";
import TextField from "components/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { colors } from "styles/theme";
import { SetCollectionBlocksWrapper } from "./styles";
import Loader from "components/Loader";
import { useDebounce } from "use-debounce";
import { displayName, flattenPages, stringAvatar } from "helpers";
import useInfiniteUsers from "hooks/services/users/useInfiniteUsers";
import { User } from "types/user";

import useDirectTransfer from "hooks/services/transfers/useDirectTransfer";
import useToasts from "hooks/useToasts";
import { AxiosError } from "axios";
import ROLE from "types/role";
import ClearIcon from "@mui/icons-material/Clear";

import { CreateDirectTransferModalWrapper } from "./styles";

const CreateDirectTransferModal: FC<CreateDirectTransferModalProps> = ({
  openModal,
  setOpenModal,
  blockId,
}) => {
  const { t } = useTranslation();

  const [text, setText] = useState<string>("");
  const [debouncedText] = useDebounce(text, 500);

  const [selected, setSelected] = useState<User | null>(null);
  const avatarColors = [
    "#2DB5C7",
    "#2FE5A7",
    "#3772FF",
    "#EF452F",
    "#3A36DB",
    "#F4D35E",
  ];

  const {
    data: users,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteUsers({
    size: 6,
    query: { roles: ROLE.SELLER, ...(text && { text: debouncedText }) },
  });

  const {
    mutateAsync: directTransfer,
    error: transferError,
    isSuccess: isTransferSuccess,
    isLoading: isTransferLoading,
  } = useDirectTransfer();

  const handleScroll = (e: any) => {
    if (
      e.target.clientHeight + e.target.scrollTop === e.target.scrollHeight &&
      hasNextPage
    ) {
      fetchNextPage();
    }
  };

  const handleSearchChange = (e: any) => {
    setText(e.target.value);
  };

  const toggleSelected = (user: User) => {
    if (isSelected(user)) setSelected(null);
    else setSelected(user);
  };

  const isSelected = (user: User) => {
    return selected && selected._id === user._id;
  };

  const handleTransferBlock = () => {
    if (selected)
      directTransfer({
        blockId,
        recipient: selected._id,
        keepShares: false,
      }).then(() => setOpenModal(false));
  };

  const handleClose = (e: object, reason: string) => {
    if (reason === "escapeKeyDown") setOpenModal(false);
  };

  useToasts([
    {
      severity: "error",
      message: t("There was an error transferring the block"),
      condition: !!transferError,
    },
    {
      severity: "error",
      message: t("The selected user is not a seller"),
      condition:
        (transferError as AxiosError)?.response?.data.name === "UserNotSeller",
    },
    {
      severity: "error",
      message: t("The selected user does not have enough storage"),
      condition:
        (transferError as AxiosError)?.response?.data.name ===
        "NotEnoughStorage",
    },
    {
      severity: "success",
      message: t("Block transferred successfully"),
      condition: isTransferSuccess,
    },
  ]);

  return (
    <CreateDirectTransferModalWrapper>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="create-collection-modal"
        PaperProps={{
          style: {
            background: colors.lightBlack,
            backgroundImage: "none",
            width: "100%",
            padding: "20px",
            border: `1px solid ${colors.white}`,
            borderRadius: "20px",
          },
        }}
      >
        <SetCollectionBlocksWrapper>
          <Stack direction="column" gap={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <TextField
                placeholder={t("Search")}
                value={text}
                onChange={handleSearchChange}
                sx={{ flexGrow: 1 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Stack direction="row" className="filter-button">
                <IconButton onClick={(e) => handleClose(e, "escapeKeyDown")}>
                  <ClearIcon sx={{ color: colors.green }} />
                </IconButton>
              </Stack>
            </Stack>

            <Stack>
              <Stack
                direction="column"
                className="blocks-container"
                gap={2}
                onScroll={handleScroll}
              >
                {isLoading ? (
                  <Loader />
                ) : (
                  flattenPages(users).map((user, idx) => (
                    <Stack
                      key={user._id}
                      direction="row"
                      className="block-row"
                      alignItems="center"
                      justifyContent={"space-evenly"}
                      sx={{
                        ...(isSelected(user) && {
                          border: `1px solid ${colors.green}`,
                        }),
                      }}
                      onClick={() => toggleSelected(user)}
                    >
                      <Avatar
                        className="user-avatar"
                        sx={{ bgcolor: avatarColors[idx % 5] }}
                      >
                        {user.profile && stringAvatar(displayName(user))}
                      </Avatar>
                      <div className="user-name">
                        <div>{`${user?.profile?.firstname ?? ""} ${
                          user?.profile?.lastname ?? ""
                        }`}</div>
                        <div>{user.username}</div>
                      </div>
                    </Stack>
                  ))
                )}
                {isFetchingNextPage ? <Loader /> : <></>}
              </Stack>
            </Stack>

            <Stack direction="row" justifyContent="center">
              {selected && (
                <MRButtonquaternary
                  loading={isTransferLoading}
                  onClick={handleTransferBlock}
                >
                  {t("Trasferisci blocco")}
                </MRButtonquaternary>
              )}
            </Stack>
          </Stack>
        </SetCollectionBlocksWrapper>
      </Dialog>
    </CreateDirectTransferModalWrapper>
  );
};

export default CreateDirectTransferModal;
