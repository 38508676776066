import { FC, useState, useMemo } from "react";
import { ShareTableProps } from "./types";
import { Collapse, Stack } from "@mui/material";
import { MRPagination, MRDrawer } from "components/material";
import { useTranslation } from "react-i18next";
import useMultiSelect from "hooks/useMultiSelect";
import ShareBulkActionsControl from "components/TablesComponents/Share/ShareBulkActionsControl";
import MRSharesTable from "components/TablesComponents/Share/SharesTable";
import { PAGE_SIZE } from "config/pagination";
import TitleCounter from "components/TablesComponents/TitleCounter";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import { Inputs as ShareFilters } from "components/Share/ShareFilterForm/model";
import ShareFilterForm from "components/Share/ShareFilterForm";
import useBlockShares from "hooks/services/shares/useBlockSahres";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";
import ROLE from "types/role";
import { formatShareContent } from "helpers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const ShareTable: FC<ShareTableProps> = ({ blockId, userRole = ROLE.ADMIN }) => {
  const [page, setPage] = useState(1);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<ShareFilters | null>(null);
  const [openShare, setOpenShare] = useState<boolean>(true);

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data: shares,
    isLoading: loadingShares,
    isError: sharesError,
  } = useBlockShares(blockId, page, PAGE_SIZE, {
    ...(formattedFilters && formattedFilters),
  });

  if (sharesError) throw new Error("Cannot get shares");

  const { selected, toggleSelection, toggleAll, resetSelection } = useMultiSelect();
  const { t } = useTranslation();

  const toggleCollapse = () => {
    setOpenShare((prev) => !prev);
  };

  return (
    <>
      <MRDrawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={toggleDrawer(false, setFilterDrawerOpen)}
      >
        <ShareFilterForm
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </MRDrawer>
      <Stack
        direction="row"
        gap={3}
        alignItems="center"
        justifyContent={"space-between"}
        flexWrap="wrap"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "5px", sm: 3 },
          height: { sm: "76px" },
        }}
      >
        <span style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
          <TitleCounter
            title={t("Shares")}
            count={
              shares?.data.filter((s) => formatShareContent(s, "type") === "Block").length || 0
            }
          />

          <span onClick={toggleCollapse} style={{ cursor: "pointer" }}>
            {openShare ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </span>
        </span>
        <Collapse in={openShare}>
          <Stack direction="row" alignItems="center" gap="10px">
            <FilterButton
              onClick={() => setFilterDrawerOpen(true)}
              activeFilters={formattedFilters ? Object.keys(formattedFilters).length : 0}
            />
            <ShareBulkActionsControl selectedIds={selected} resetSelection={resetSelection} />
          </Stack>
        </Collapse>
      </Stack>
      <Collapse in={openShare}>
        <MRSharesTable
          userRole={userRole}
          shares={shares?.data || []}
          selected={selected}
          toggleSelection={toggleSelection}
          toggleAll={toggleAll}
          filter="Block"
        />
        <Stack direction="row" justifyContent="center">
          {shares?.data && shares.data.length ? (
            <MRPagination
              count={shares ? Math.ceil(shares?.pagination[0]?.total / PAGE_SIZE) || 1 : 1}
              page={page}
              onChange={(e, page) => setPage(page)}
            />
          ) : (
            <NoDataRow>{t("No Data")}</NoDataRow>
          )}
        </Stack>
      </Collapse>
    </>
  );
};

export default ShareTable;
