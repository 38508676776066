import { useFrame, useThree } from "@react-three/fiber"
import { MutableRefObject, useState } from "react"
import { Vector3, Box3, Vector2 } from "three"


interface ZoomControllerProps {
  refToMesh: MutableRefObject<Box3>,
  refToControls: MutableRefObject<any>,
  initDistance: number
}

export default function ZoomController(props: ZoomControllerProps) {
  const { camera } = useThree();
  const [lastOkPosition, setLastPosition] = useState<Vector3>(() => new Vector3());
  //const upperDistance = 0.6;

  let tempLastOkPosition: Vector3 = new Vector3();

  const equal = (x: number, y: number): boolean => {
    return Math.abs(x - y) < Number.EPSILON;
  }

  const equalTolerance = (x: number, y: number, tol: number): boolean => {
    return Math.abs(x - y) < tol;
  }

  // funzione logaritmica passante nei punti a,b
  function computeZoomFactorLog(a: Vector2, b: Vector2, x:number): number {
    return a.y + (b.y - a.y)*(Math.log(x/a.x)/Math.log(b.x/a.x));
  }

  function computeZoomFactorExp(a: Vector2, b: Vector2, x: number): number {
    return a.y*Math.pow(b.y/a.y,(x-a.x)/(b.x-a.x));
  }

  // qui era 0.6
  const a: Vector2 = new Vector2(0.55,0.1);
  const b: Vector2 = new Vector2(props.initDistance,1);

  const computeNewPosition = (position: Vector3, direction: Vector3, offset: number): Vector3 => {
    let v = new Vector3();
    for (let i = 0; i < 3; i++) {
      let pi = position.getComponent(i);
      let di = direction.getComponent(i);

      // faccio il check per vedere se sono di segno discorde
      if (!equal(di, 0)) {
        if (di * pi < 0)
          v.setComponent(i, pi + (Math.sign(pi) * offset));
      }
    }
    return v
  }

  useFrame(() => {    
    if (props.refToMesh.current !== null && props.refToControls.current !== null) {
      let distance_from_box: number | undefined = props.refToMesh.current
        .distanceToPoint(camera.position);

      if (distance_from_box !== undefined) {
        if (distance_from_box > 0.5) {
          tempLastOkPosition = camera.position.clone();
        } else {
          const k = 0.05;
          let newPosition: Vector3 = computeNewPosition(camera.position,
            camera.getWorldDirection(new Vector3), k);
          camera.position.lerp(newPosition, 0.5);
          setLastPosition(newPosition);
          camera.updateMatrix();
          camera.updateMatrixWorld();
          camera.updateProjectionMatrix();
          props.refToControls.current.update();
        }
      }
      camera.updateProjectionMatrix();
      props.refToControls.current.update();
    }
  })

  return (
    <></>
  )
}