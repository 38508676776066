import { MRCard } from "components/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { BlockProps } from "./types";
import { BlockCardLabelList, BlockCardText, BlockListElemWrapper } from "./styles";
import { Checkbox, Grid, Stack } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { share } from "assets/icons/";
import { colors } from "styles/theme";
import BlockActions from "./BlockActions";
import defaultThumbnail from "assets/images/default-thumb.png";
import ThumbDisplayer from "components/ThumbDisplayer";

const BlockListElem: FC<BlockProps> = ({ block, variant }) => {
  const { t } = useTranslation();

  return (
    <BlockListElemWrapper>
      <MRCard className="block-list-elem">
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Stack direction="column" justifyContent="center" className="thumb-wrapper">
            <ThumbDisplayer
              imgProps={{
                src: block.model?.thumbnail || defaultThumbnail,
                alt: "Block Thumbnail",
              }}
            />
          </Stack>
          <Grid container spacing={1}>
            <Grid item xs={7}>
              <Stack>
                <BlockCardLabelList>{t("Model name")}</BlockCardLabelList>
                <span style={{ color: colors.green }}>{block.title}</span>
              </Stack>
            </Grid>

            <Grid item xs={5} sx={{ textAlign: "right" }}>
              <BlockActions variant={variant} block={block} />
            </Grid>

            <Grid item xs={7}>
              <Stack>
                <BlockCardLabelList>{t("Dimensions")}</BlockCardLabelList>
                <span>{`${t("A:")} ${block.height}cm x ${t("L:")} ${block.length}cm x ${t("P:")} ${
                  block.depth
                }cm`}</span>
              </Stack>
            </Grid>

            <Grid item xs={5} sx={{ textAlign: "right" }}>
              <Stack>
                <BlockCardLabelList>{t("Materials")}</BlockCardLabelList>
                <span>{block.material}</span>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </MRCard>
    </BlockListElemWrapper>
  );
};

export default BlockListElem;
