import { Stack } from "@mui/material";
import Loader from "components/Loader";
import { MRTextField } from "components/material";
import { PAGE_SIZE } from "config/pagination";
import useInfiniteCollection from "hooks/services/collections/useInfiniteCollections";
import useAuth from "hooks/useAuth";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectCollectionWrapper } from "./styles";
import { useDebounce } from "use-debounce";
import { SelectCollectionProps } from "./types";
import FolderImg from "assets/images/folder.png";
import { flattenPages } from "helpers";

const SelectCollection: FC<SelectCollectionProps> = ({
  selectedCollection,
  setSelectedCollection,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const [name, setName] = useState<string>("");
  const [debouncedName] = useDebounce(name, 500);

  const handleScroll = (e: any) => {
    if (
      e.target.clientHeight + e.target.scrollTop === e.target.scrollHeight &&
      hasNextPage
    ) {
      fetchNextPage();
    }
  };

  const {
    data: pages,
    isLoading: isLoadingCollections,
    error: collectionsError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteCollection({
    size: PAGE_SIZE,
    query: { owner: user?._id, name: debouncedName },
  });

  if (collectionsError) throw new Error("Cannot get collections");

  return (
    <SelectCollectionWrapper>
      <Stack direction="column" gap={2}>
        <MRTextField
          placeholder={t("Find by name...")}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setSelectedCollection(null);
          }}
        />

        <Stack
          padding="10px"
          gap="5px"
          className="contacts-dialog-box"
          onScroll={(e) => handleScroll(e)}
        >
          {isLoadingCollections ? (
            <Loader /> // TODO: Check why it's not showing
          ) : (
            flattenPages(pages).map((collection, idx) => {
              return (
                <Stack
                  key={collection._id}
                  onClick={() => setSelectedCollection(collection._id)}
                  direction="row"
                  className={`collection-row ${
                    selectedCollection === collection._id
                      ? "collection-row-selected"
                      : ""
                  }`}
                  padding="10px 20px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" gap="10px" alignItems="center">
                    <img src={FolderImg} className="folder-img" alt="folder" />
                    {collection.name}
                  </Stack>

                  <span>
                    {t("Models")}:{collection.blocks.length}
                  </span>
                </Stack>
              );
            })
          )}
          {isFetchingNextPage ? <Loader /> : <></>}
        </Stack>
      </Stack>
    </SelectCollectionWrapper>
  );
};

export default SelectCollection;
