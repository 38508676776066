import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";
import { BlockInfoInputs } from "types/block";

export default function useRecoverBlock(id: string) {
  const queryClient = useQueryClient();

  const recoverBlock = () => {
    const request: Request = {
      url: generatePath(ENDPOINTS.block.recover, { id }),
      config: {
        method: "patch",
        data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<null>, Error>(recoverBlock, {
    onSettled: () => {
      queryClient.invalidateQueries([ENDPOINTS.block.all]);
      queryClient.invalidateQueries([ENDPOINTS.block.one, id]);
    },
  });

  return { data, mutate, ...rest };
}
