import { FC, useState } from "react";
import { MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { colors } from "styles/theme";
import { Expiry } from "types/link";
import useCreateBlockLink from "hooks/services/links/useCreateBlockLink";
import toast from "react-hot-toast";
import { MRButtonQuinary } from "components/material/Button";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { MRCheckbox } from "components/material";

const LinkDialog: FC<{ blockId: string }> = ({ blockId }) => {
  const { t } = useTranslation();
  const [link, setLink] = useState<string>("");
  const [expiry, setExpiry] = useState<Expiry>(Expiry.WEEK);
  const [showOwner, setShowOwner] = useState<boolean>(false);

  const {
    mutateAsync: createLink,
    isLoading: isLoadingCreateLink,
    isSuccess: isSuccessCreateLink,
    error: createLinkError,
  } = useCreateBlockLink();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    toast(t("Link copied to clipboard!"));
  };

  const handleCreateLink = async () => {
    await createLink({ blockId, expiry, showOwner }).then((response) => {
      setLink(
        `${process.env.REACT_APP_FRONTEND_URL}/link/${response.data.code}`
      );
    });
  };

  const handleChange = (event: SelectChangeEvent) => {
    setExpiry(event.target.value as Expiry);
  };

  const handleShowOwner = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOwner(event.target.checked);
  };

  return (
    <div className="dialog link-dialog">
      <div className="link-dialog-header">
        <h2>{t("Or")}</h2>
      </div>

      <div className="link-dialog-body">
        {!link && (
          <div className="generate-link-container">
            <p>{t("Create a link for this model")}</p>
            <div className="link-settings">
              <div>
                <MRButtonQuinary
                  startIcon={
                    <AddCircleOutlineRoundedIcon
                      fontSize="small"
                      htmlColor={colors.green}
                    />
                  }
                  onClick={handleCreateLink}
                >
                  {t("Create link")}
                </MRButtonQuinary>
              </div>

              <div className="link-expiry">
                <span className="expire-in-label">{t("Active for")}</span>
                <Select
                  value={expiry}
                  className="expire-select"
                  onChange={handleChange}
                >
                  <MenuItem value={Expiry.HOUR}>{t("one hour")}</MenuItem>
                  <MenuItem value={Expiry.DAY}>{t("one day")}</MenuItem>
                  <MenuItem value={Expiry.WEEK}>{t("one week")}</MenuItem>
                  <MenuItem value={Expiry.MONTH}>{t("one month")}</MenuItem>
                  <MenuItem value={Expiry.YEAR}>{t("one year")}</MenuItem>
                </Select>
              </div>
              <Stack direction={"row"} className="show-owner">
                <p>{t("Show Owner")}</p>
                <MRCheckbox checked={showOwner} onChange={handleShowOwner} />
              </Stack>
            </div>
          </div>
        )}
        {link && (
          <div>
            <p>{t("Send link manually")}</p>
            <Stack flexDirection={"row"} gap={2}>
              <span
                className="share-link"
                onClick={copyToClipboard}
                style={{ cursor: "pointer" }}
              >
                {link}
              </span>
              <ContentCopyIcon
                onClick={copyToClipboard}
                style={{ cursor: "pointer" }}
              />
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkDialog;
