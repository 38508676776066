import {ButtonBase, Typography, Box } from "@mui/material"

interface MRButtonWithIconTextProps {
  text: string,
  icon: any,
  onClickAction?: () => void
}

export default function MRButtonWithIconText(props: MRButtonWithIconTextProps) {

  return (
    <ButtonBase disableRipple onClick={() => props.onClickAction()}>
    <Box component={"div"} sx={{ display: "grid", justifyItems: "center" }} color={"#00A4B7"}>
      {props.icon}
      <Typography sx={{ display: "flex", justifyContent: "center" }}>{props.text}</Typography>
    </Box>
  </ButtonBase>
  )

}