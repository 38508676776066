import { FC, useMemo } from "react";
import { BulkActionProps } from "../BulkActionsControls/types";
import useBulkAction from "hooks/useBulkAction";
import { BulkAction } from "../BulkActionsControls/types";
import { trash2 } from "assets/icons";
import BulkActionsControlBase from "../BulkActionsControls/BulkActionsControlBase";
import useRevokeLink from "hooks/services/links/useRevokeLink";
import { useTranslation } from "react-i18next";

const LinkBulkActionsControl: FC<BulkActionProps> = (props) => {
  const { mutateAsync: revokeLinkAsync } = useRevokeLink();

  const { t } = useTranslation();

  const {
    bulkAction: revokeLinkBulk,
    isLoading: isRevokingLinkBulk,
    bulkResult: revokeLinkBulkResult,
  } = useBulkAction(revokeLinkAsync);

  const actions: BulkAction[] = useMemo(
    () => [
      {
        name: t("Remove"),
        icon: trash2,
        confirmMessage: t("Are you sure you want to revoke these links?"),
        bulkAction: revokeLinkBulk,
        bulkActionLoading: isRevokingLinkBulk,
        bulkActionResult: revokeLinkBulkResult,
      },
    ],
    [revokeLinkBulk, isRevokingLinkBulk, revokeLinkBulkResult]
  );
  return <BulkActionsControlBase {...props} actions={actions} />;
};

export default LinkBulkActionsControl;
