import ROLE from "types/role";
import * as Yup from "yup";

export const inviteUserValidationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
  roles: Yup.string()
    .required()
    .oneOf([ROLE.BUYER, ROLE.SELLER, ROLE.ADMIN, ROLE.OPERATOR]),
  credits: Yup.number().when("roles", {
    is: ROLE.SELLER,
    then: Yup.number().required("Credits is required"),
  }),
  storage: Yup.number().when("roles", {
    is: ROLE.SELLER,
    then: Yup.number().required("Storage is required"),
  }),
  bandwidthSize: Yup.number().when("roles", {
    is: ROLE.SELLER,
    then: Yup.number().required("Bandwidth is required"),
  }),
});
