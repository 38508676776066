import { Stack } from "@mui/material";
import { FC } from "react";
import { FilterButtonWrapper } from "./styles";
import { FilterButtonProps } from "./types";
import { MRButtonPrimary } from "components/material/Button";
import { sliders } from "assets/icons";
import { useTranslation } from "react-i18next";

const FilterButton: FC<FilterButtonProps> = ({ onClick, activeFilters }) => {
  const { t } = useTranslation();

  return (
    <FilterButtonWrapper>
      {activeFilters ? (
        <div className="filter-conter">{activeFilters}</div>
      ) : (
        <></>
      )}
      <MRButtonPrimary variant="contained" onClick={onClick}>
        <Stack direction="row" alignItems="center" gap={1}>
          {t("Filters")}
          <img src={sliders} />
        </Stack>
      </MRButtonPrimary>
    </FilterButtonWrapper>
  );
};

export default FilterButton;
