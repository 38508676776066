import { FC } from "react";
import { TitleCounterProps } from "./types";
import { Chip } from "@mui/material";
import { TitleCounterWrapper } from "./styles";

const TitleCounter: FC<TitleCounterProps> = ({ title, count }) => {
  return (
    <TitleCounterWrapper>
      <h2>{title}</h2>
      <Chip className="table-title-chip" label={count} />
    </TitleCounterWrapper>
  );
};

export default TitleCounter;
