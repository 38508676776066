import { MRCard } from "components/material";
import useAuth from "hooks/useAuth";
import ProfileForm from "components/User/ProfileForm";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "styles/theme";
import { Dialog, IconButton, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ChangePasswordForm from "./ChangePasswordForm";
import { ButtonDialog } from "./styles";
import { MRButtonQuinary, MRButtonSecondary } from "components/material/Button";
import { useConfirmModal } from "context/confirmModal";
import toast from "react-hot-toast";
import useRemoveUser from "hooks/services/users/useRemoveUser";

const EditProfile: FC = () => {
  const { user, logout } = useAuth();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { openModal: openDeletePermanentlyModal } = useConfirmModal();
  const { mutateAsync: removeUser } = useRemoveUser();

  const handleDeletePermanently = () => {
    openDeletePermanentlyModal({
      title: "Are you sure to delete your account permanently?",
      text: "The operation is not reversible and will delete all the data related to you",
      confirmFunction: () =>
        removeUser()
          .then(() => {
            logout();
          })
          .catch(() => {
            toast(
              t(
                "An error occurred while deleting your account, please try later"
              )
            );
          }),
    });
  };

  return (
    <>
      <MRCard sx={{ padding: "10px 20px" }}>
        <h4>{t("Update personal data")}</h4>
        {user && <ProfileForm user={user} />}

        <div style={{ marginTop: "80px", width: "100%" }}>
          <h4>{t("Authentication")}</h4>
          <Stack direction="row" justifyContent="space-between">
            <MRButtonQuinary
              onClick={() => setOpenModal(true)}
              style={{ marginBottom: "20px" }}
            >
              {t("Change password")}
            </MRButtonQuinary>
            <MRButtonSecondary
              style={{
                marginLeft: "20px",
                marginBottom: "20px",
              }}
              sx={{ border: "1px solid red !important" }}
              variant="contained"
              onClick={handleDeletePermanently}
            >
              {t("Delete account")}
            </MRButtonSecondary>
          </Stack>
        </div>
      </MRCard>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
          style: {
            background: colors.lightBlack,
            backgroundImage: "none",
            width: "100%",
            padding: "20px",
            border: `1px solid ${colors.white}`,
            borderRadius: "20px",
          },
        }}
      >
        <ButtonDialog>
          <div className="close-button-container">
            <IconButton onClick={() => setOpenModal(false)}>
              <ClearIcon />
            </IconButton>
          </div>
        </ButtonDialog>

        <ChangePasswordForm closeDialog={() => setOpenModal(false)} />
      </Dialog>
    </>
  );
};

export default EditProfile;
{
}
