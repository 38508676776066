export interface Photos {
  Bucket: string;
  Key: string;
  UploadId: string;
  size?: number;
}

export enum AcquisitionStatus {
  IDLE = "idle",
  PENDING_PROCESSING = "pending_processing",
  PROCESSING = "processing",
  PROCESSED = "processed",
  SOLD = "sold",
  REMOVED = "removed",
}

export enum Priority {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}

export interface AcquisitionInfoInputs {
  length?: number;
  height?: number;
  depth?: number;
  weight?: number;
  material?: string;
  geologicalFamily?: string;
  placeOfAcquisition?: string;
  createdAt?: string;
  title?: string;
  notes?: string;
  priority?: Priority;
  numberOfPhotos?: number;
  owner?: string;
  status?: string;
}

export interface AcquisitionUploadInfo {
  Bucket: string;
  Key: string;
  UploadId: string;
  signedUrls: string[];
}

export interface Acquisition {
  _id: string;
  title: string;
  owner: string;
  status: AcquisitionStatus;
  acquisition: string;
  placeOfAcquisition: string;
  ownerName: string;
  length: number;
  height: number;
  depth: number;
  weight: number;
  material: string;
  geologicalFamily: string;
  numberOfPhotos: number;
  notes?: string;
  photos: string;
  size?: number;
  priority: Priority;
  createdAt: string;
}

export interface AcquisitionMonthly {
  _id: number;
  acquisitionNumber: number;
}
