import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";

export default function useTrashBlock() {
  const queryClient = useQueryClient();

  const trashBlock = (id: string) => {
    const request: Request = {
      url: generatePath(ENDPOINTS.block.trash, { id }),
      config: {
        method: "patch",
        data,
      },
    };
    return client(request);
  };

  const { data, mutateAsync, ...rest } = useMutation<Response<null>, Error, string>(trashBlock, {
    onSettled: (response, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.block.all]);
      queryClient.invalidateQueries([ENDPOINTS.block.one, variables]);
      queryClient.invalidateQueries([ENDPOINTS.transfers.all]);
    },
  });

  return { data, mutateAsync, ...rest };
}
