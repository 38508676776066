import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function useDeleteUserApiKey() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const deleteApiKey = (id: string) => {
    const request: Request = {
      url: generatePath(ENDPOINTS.users.apiKey, { id }),
      config: {
        method: "delete",
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<null>, Error, string>(
    deleteApiKey,
    {
      onSettled: (data, error, id) => {
        queryClient.invalidateQueries([ENDPOINTS.users.one, id]);
        queryClient.invalidateQueries([ENDPOINTS.users.all]);
      },
      onSuccess: () => {
        toast(t("Api key deleted correctly!"));
      },
    }
  );

  return { data, mutate, ...rest };
}
