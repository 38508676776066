import { Stack, Tab, Tabs } from "@mui/material";
import UserBlockBulkActionControl from "components/TablesComponents/Block/UserBlockBulkActionsControl";
import useBlocks from "hooks/services/blocks/useBlocks";
import useMultiSelect from "hooks/useMultiSelect";
import { FC, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import BlockUserTable from "components/TablesComponents/Block/BlockUserTable";
import { MRPagination, MRDrawer, MRTextField } from "components/material";
import { PAGE_SIZE } from "config/pagination";
import ROLE from "types/role";
import { NoDataRow } from "components/TablesComponents/Tables/styles";
import { Inputs as BlockFilters } from "components/Block/BlockFilterForm/model";
import BlockFilterForm from "components/Block/BlockFilterForm";
import useAuth from "hooks/useAuth";
import { removeEmptyEntries } from "utils/object";
import { toggleDrawer } from "utils/drawer";
import FilterButton from "components/FilterButton";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import { colors } from "styles/theme";
import Blocks from "./Blocks";
import { useDebounce } from "use-debounce";
import { search } from "assets/icons";

const MyModels: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState<BlockFilters | null>(null);

  const [text, setText] = useState<string>("");
  const [debouncedText] = useDebounce(text, 500);

  const [page, setPage] = useState(1);

  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("_id");

  const [view, setView] = useState<"list" | "grid">("grid");

  const formattedFilters = useMemo(() => {
    //Remove non filled values
    if (!filters) return;
    return removeEmptyEntries(filters);
  }, [filters]);

  const {
    data: blocks,
    isLoading: blocksLoading,
    isError: blocksError,
  } = useBlocks({
    page,
    size: PAGE_SIZE,
    order,
    sort: orderBy,
    owner: user?._id || "",
    text: debouncedText,
    ...(formattedFilters && formattedFilters),
  });

  const sortHandler = (orderBy: string) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(orderBy);
  };

  if (blocksError) throw new Error("Cannot get block");

  const { selected, toggleSelection, toggleAll, resetSelection } =
    useMultiSelect();

  return (
    <>
      <MRDrawer
        anchor="right"
        open={filterDrawerOpen}
        onClose={toggleDrawer(false, setFilterDrawerOpen)}
      >
        <BlockFilterForm
          appliedFilters={filters}
          onClearFilters={() => setFilters(null)}
          onApplyFilters={(values) => {
            setFilters(values);
            setFilterDrawerOpen(false);
          }}
          onClose={() => setFilterDrawerOpen(false)}
        />
      </MRDrawer>
      <Stack
        direction="row"
        gap={2}
        mb={2}
        justifyContent="space-between"
        alignItems="center"
        alignContent={"center"}
      >
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          alignContent={"center"}
        >
          <FilterButton
            onClick={() => setFilterDrawerOpen(true)}
            activeFilters={
              formattedFilters ? Object.keys(formattedFilters).length : 0
            }
          />

          <MRTextField
            placeholder={t("Type your block name")}
            className="search-blocks"
            value={text}
            onChange={(e) => setText(e.target.value)}
            InputProps={{
              endAdornment: <img src={search} alt="search" />,
            }}
            sx={{
              background: colors.lightBlack,
            }}
          />
        </Stack>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          alignContent={"center"}
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          <UserBlockBulkActionControl
            selectedIds={selected}
            resetSelection={resetSelection}
          />

          <Tabs
            value={view}
            onChange={(e, value) => setView(value)}
            variant="scrollable"
            scrollButtons={false}
            className="view-selector"
            TabIndicatorProps={{ style: { background: colors.green } }}
          >
            <Tab icon={<GridViewIcon />} value="grid" />
            <Tab icon={<ViewListIcon />} value="list" />
          </Tabs>
        </Stack>
      </Stack>

      {view === "grid" && (
        <Blocks
          toggleSelection={toggleSelection}
          selected={selected}
          text={debouncedText}
          filters={filters}
        />
      )}

      {view === "list" && (
        <>
          <BlockUserTable
            blocks={blocks?.data || []}
            selected={selected}
            toggleSelection={toggleSelection}
            toggleAll={toggleAll}
            variant="owned"
            userRole={ROLE.SELLER}
            order={order}
            orderBy={orderBy}
            sortHandler={sortHandler}
          />
          <Stack justifyContent="center" alignItems="center">
            {blocks?.data && blocks.data.length ? (
              <MRPagination
                count={
                  blocks
                    ? Math.ceil(blocks?.pagination[0]?.total / PAGE_SIZE) || 1
                    : 1
                }
                page={page}
                onChange={(e, page) => setPage(page)}
              />
            ) : (
              <NoDataRow>{t("No Data")}</NoDataRow>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export default MyModels;
