import { Stack } from "@mui/material";
import Page from "components/Layout/Page";
import { MRButtonPrimary } from "components/material/Button";
import { FC } from "react";
import { ModelToProcessWrapper } from "../single/styles";
import { colors } from "styles/theme";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { MRCard } from "components/material";
import AcquisitionForm from "../single/AcquisitionForm";

const NewModelToProcess: FC = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <ModelToProcessWrapper>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          width="100%"
          marginBottom={2}
        >
          <Link to={"/models-to-process"}>
            <MRButtonPrimary
              sx={{ bgcolor: `${colors.lightBlack}!important` }}
              variant="contained"
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <ArrowBackIcon />
                {t("Models to process")}
              </Stack>
            </MRButtonPrimary>
          </Link>
        </Stack>
        <Stack
          marginBottom={4}
          direction="column"
          alignItems="start"
          justifyContent="center"
          gap="5px"
          width="100%"
        >
          <h1 className="no-margin-text">{t("New model to process")}</h1>
        </Stack>
        <Stack>
          <MRCard className="acquisition-info-card">
            <h4 className="no-margin-text">{t("Acquisition Information")}</h4>

            <AcquisitionForm />
          </MRCard>
        </Stack>
      </ModelToProcessWrapper>
    </Page>
  );
};

export default NewModelToProcess;
