import NoCatalogueErrorFallback from "components/ErrorFallbacks/NoCatalogueErrorFallback";
import useAuth from "hooks/useAuth";
import { FC } from "react";
import { ErrorBoundary } from "react-error-boundary";
import PATH from "routing/path";
import ROLE from "types/role";
import SellerCatalogue from "./seller";
import { windowReplace } from "utils/helpers";

const Catalogue: FC = () => {
  const { user } = useAuth();
  const onResetErrorBoundary = () => {
    //refresh all state and redirect to homepage
    windowReplace(PATH.DASHBOARD);
  };

  if (user)
    return (
      <ErrorBoundary FallbackComponent={NoCatalogueErrorFallback} onReset={onResetErrorBoundary}>
        <SellerCatalogue />
      </ErrorBoundary>
    );

  return <></>;
};

export default Catalogue;
