import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { generatePath } from "react-router-dom";

interface ReadNotificationData {
  notifications: string[];
}

export default function useReadNotifications() {
  const queryClient = useQueryClient();

  const readNotifications = (data: ReadNotificationData) => {
    const request: Request = {
      url: ENDPOINTS.notifications.read,
      config: {
        method: "patch",
        data,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<Response<null>, Error, ReadNotificationData>(
    readNotifications,
    {
      onSettled: (data, error, id) => {
        queryClient.invalidateQueries([ENDPOINTS.notifications.all]);
      },
    }
  );

  return { data, mutate, ...rest };
}
