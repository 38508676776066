import {  Mesh, Vector2 } from "three"
import { useThree } from "@react-three/fiber"
import { MutableRefObject, useCallback, useEffect } from "react"
import { Line2 } from "three/examples/jsm/lines/Line2"
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry"
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial"

interface FreeDrawToolProps {
  enabled: boolean,
  meshName: string,
  color: string,
  suffix: string,
  size: number,
  refMesh: MutableRefObject<Mesh>,
  /*updateLine: (line: Line) => void*/
}

export default function FreeDrawTool(props: FreeDrawToolProps) {
  const { raycaster,  gl } = useThree();
  const MAX_POINTS: number = 1000;
  let mouseDown: boolean = false;
  let drawRange: number = 1;
  let index: number;

  //let lineMaterial: MeshLineMaterial;
  //let geometryLine: MeshLineGeometry;
  let line2: Line2;
  let geo2: LineGeometry;
  let mat2: LineMaterial;

  let pos2: Float32Array;

  const startLine = useCallback(() => {
    //raycaster.setFromCamera(pointer, camera);
    let intersect = raycaster.intersectObject(props.refMesh.current, false);
    if (intersect.length > 0) {
      drawRange = 2;
      let localPoint = props.refMesh.current.worldToLocal(intersect[0].point);
      mouseDown = true;
      //geometry = new BufferGeometry();
      //geometry = new LineGeometry();
      //geometryLine = new MeshLineGeometry();
      //geo2 = new LineGeometry();

      pos2 = new Float32Array(MAX_POINTS * 3);
      pos2[0] = localPoint.x;
      pos2[1] = localPoint.y;
      pos2[2] = localPoint.z;
      pos2[3] = localPoint.x;
      pos2[4] = localPoint.y;
      pos2[5] = localPoint.z;


      geo2 = new LineGeometry();
      geo2.setPositions(pos2);
      geo2.setDrawRange(0,drawRange);

      mat2 = new LineMaterial({
        color: 0xFF0000,
        linewidth: props.size,
        resolution: gl.getSize(new Vector2())
      })

      line2 = new Line2(geo2, mat2);
      line2.geometry.instanceCount = drawRange;

      line2.geometry.attributes.position.needsUpdate = true;

      props.refMesh.current.add(line2);
      index = 6;
    }

  }, [props.enabled])

  // dichiarate cosi' non prendono le props
  const endLine = useCallback(() => {
    mouseDown = false;
    //props.updateLine(line);
    //console.log(props.refMesh.current)
  }, [props.enabled])

  const drawLine = useCallback(() => {
    if (mouseDown) {
      //console.log("Adding points...")
      //raycaster.setFromCamera(pointer, camera);
      let intersect = raycaster.intersectObject(props.refMesh.current, false);
      if (intersect.length > 0) {
        drawRange += 1;
        let point = intersect[0].point;
        let pointLocal = props.refMesh.current.worldToLocal(point);
        pos2[index++] = pointLocal.x;
        pos2[index++] = pointLocal.y;
        pos2[index++] = pointLocal.z;
        //let newGeo  = new LineGeometry();
        //newGeo.setPositions(pos2);
        //line2.geometry = newGeo;
        //console.log(pos2)
        line2.geometry.setPositions(pos2);
        line2.geometry.setDrawRange(0, drawRange-1)
        line2.geometry.attributes.position.needsUpdate = true;
        line2.geometry.instanceCount = drawRange-1;
      }
    }
  }, [props.enabled])

  // gli eventi dovrebbero essere agganciati 
  // al dom del render
  useEffect(() => {
    if (props.enabled == true) {
      console.log(" Free draw Adding event listener")
      gl.domElement.addEventListener('mousedown', startLine, false);
      gl.domElement.addEventListener('mouseup', endLine, false);
      gl.domElement.addEventListener('mousemove', drawLine, false);

      gl.domElement.addEventListener('touchstart', startLine, false);
      gl.domElement.addEventListener('touchend', endLine, false);
      gl.domElement.addEventListener('touchmove', drawLine, false);


    } else if (props.enabled == false) {
      //gl.domElement.removeEventListener('pointerdown', startLine, false);
      //gl.domElement.removeEventListener('pointerup', endLine, false);
      //gl.domElement.removeEventListener('pointermove', drawLine, false);
    }
    console.log("Free draw added")

    return () => {
      gl.domElement.removeEventListener('mousedown', startLine, false);
      gl.domElement.removeEventListener('mouseup', endLine, false);
      gl.domElement.removeEventListener('mousemove', drawLine, false);

      gl.domElement.removeEventListener('touchstart', startLine, false);
      gl.domElement.removeEventListener('touchend', endLine, false);
      gl.domElement.removeEventListener('touchmove', drawLine, false);
    }
  }, [props.enabled])


  return (
    <></>
  )
}